import React, { useEffect, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import apiClient from '../../../../services/apiClient';
import contractThumbnail from '../../../../images/feed/contract.png';
import { Contract } from '../../../../types';
import FeedPanelSkeleton from '../FeedPanelSkeleton/FeedPanelSkeleton';
import ContractStatus from '../../../ContractList/ContractStatus/ContractStatus';
import ContractDetail from '../../../ContractList/ContractDetail/ContractDetail';
import NewRibbon from '../../../shared/NewRibbon/NewRibbon';
import { useFilters } from '../../../Layout/NavigationFiltersProvider/NavigationFiltersProvider';

export default function ContractFeed({
  setHasContracts,
}: {
  setHasContracts: (hasContracts: boolean) => void;
}) {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [contractId, setContractId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { domains, categories, groups, resetTrigger } = useFilters();

  useEffect(() => {
    setLoading(true);
    apiClient
      .get('/contracts', {
        params: {
          domain:
            domains.length > 0 && categories.length === 0
              ? domains.join(',')
              : null,
          categories: categories.length > 0 ? categories.join(',') : null,
          group: groups.length > 0 ? groups.join(',') : null,
          page: 1,
          perPage: 3,
          statuses: '1,2,3',
        },
      })
      .then((response) => {
        setContracts(response.data.records);
        setHasContracts(response.data.records.length > 0);
        setLoading(false);
      })
      .catch(() => {
        // TODO: handle error response
      });
  }, [domains, categories, groups]);

  useEffect(() => {
    if (resetTrigger) {
      apiClient
        .get('/contracts', {
          params: {
            domain: null,
            categories: null,
            group: null,
            page: 1,
            perPage: 3,
            statuses: '1,2,3',
          },
        })
        .then((response) => {
          setContracts(response.data.records);
          setHasContracts(response.data.records.length > 0);
          setLoading(false);
        })
        .catch(() => {
          // TODO: handle error response
        });
    }
  }, [resetTrigger]);

  const handleViewAll = () => {
    const search = createSearchParams();
    if (searchParams.get('navDomain')) {
      search.set('navDomain', searchParams.get('navDomain') || '');
    }
    if (searchParams.get('navGroup')) {
      search.set('navGroup', searchParams.get('navGroup') || '');
    }
    navigate({
      pathname: '/domains/contracts',
      search: search.toString(),
    });
  };

  if (loading) {
    return <FeedPanelSkeleton />;
  }

  if (contracts.length === 0) return null;

  const getTimingValue = (
    startDate: Date | undefined,
    endDate: Date | undefined,
  ) => {
    if (startDate && startDate) {
      const startDateQuarter =
        moment(startDate).quarter() + 'Q' + moment(startDate).format('YY');
      const endDateQuarter =
        moment(endDate).quarter() + 'Q' + moment(endDate).format('YY');
      return startDateQuarter + ' - ' + endDateQuarter;
    } else {
      return 'No date';
    }
  };

  return (
    <Paper elevation={3}>
      <Box textAlign="center" height={200}>
        <img
          src={contractThumbnail}
          style={{ height: 200 }}
          alt="Latest Contracts"
        />
      </Box>
      <Typography
        sx={{ p: 1.5, color: 'white', backgroundColor: 'primary.main' }}
      >
        Latest Contracts
      </Typography>
      <Table size="small">
        <TableBody>
          {contracts.map((contract) => (
            <TableRow
              key={contract.id}
              onClick={() => setContractId(contract.id)}
              sx={{ cursor: 'pointer' }}
              style={{ position: 'relative' }}
            >
              <NewRibbon postDate={contract.startDate} postType={'programs'} />
              <TableCell sx={{ width: '100%', fontWeight: 'bold' }}>
                {contract.name}

                <Typography variant="subtitle2" component="div">
                  <Stack
                    direction="row"
                    gap={5}
                    justifyContent={'space-between'}
                  >
                    <Stack direction="row" gap={1}>
                      <Typography
                        variant="overline"
                        display="inline-block"
                        component="div"
                      >
                        Supplier:{' '}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="inline-block"
                        component="div"
                      >
                        {contract.supplier.name}
                      </Typography>
                    </Stack>
                    <Stack direction="row" gap={1}>
                      <Typography
                        variant="overline"
                        display="inline-block"
                        component="div"
                      >
                        Status:{' '}
                      </Typography>
                      <ContractStatus value={contract.status} />
                    </Stack>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <Typography
                      variant="overline"
                      display="inline-block"
                      component="div"
                    >
                      Timing:{' '}
                    </Typography>
                    <Typography
                      variant="overline"
                      display="inline-block"
                      component="div"
                    >
                      {getTimingValue(contract.startDate, contract.endDate)}{' '}
                    </Typography>
                  </Stack>
                </Typography>
              </TableCell>
            </TableRow>
          ))}

          {contractId && (
            <ContractDetail
              handleContractDialogClose={() => setContractId(null)}
              contractId={Number(contractId)}
              isFeed={true}
            />
          )}
        </TableBody>
      </Table>
      <Box textAlign="right">
        <Button sx={{ m: 0.5 }} onClick={handleViewAll}>
          View all
        </Button>
      </Box>
    </Paper>
  );
}
