import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Divider,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from '@mui/material';
import { TreeItem, TreeView } from '@mui/lab';
import { grey } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import apiClient from '../../../services/apiClient';

export default function ComparaisonPage(props: { selectedProducts: any[] }) {
  const [allSections, setAllSections] = useState<any[]>([]);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [isStandard, setIsStandard] = useState<boolean>(true);
  const [isHighlight, setIsHighlight] = useState<boolean>(false);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const compareTreeRef = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  function getAllIds(arr: any, type = 'all') {
    const ids: any[] = [];
    arr.forEach((obj: any) => {
      if (type === 'field' && obj.type === 'field') {
        ids.push(obj.id.toString());
      } else if (type === 'all') {
        ids.push(obj.id.toString());
      }
      if (obj.subSections) {
        ids.push(...getAllIds(obj.subSections, type));
      }
    });
    return ids;
  }
  function areAllValuesIdentical(arr: any) {
    if (arr?.length === 0) {
      return false; // Array is empty, so values are not identical
    }

    const firstValue = arr[0];
    for (let i = 1; i < arr?.length; i++) {
      if (arr[i] !== firstValue) {
        return false; // Values are not identical
      }
    }

    return true; // All values are identical
  }

  useEffect(() => {
    apiClient
      .post(`/product/treeViewCompare/${props.selectedProducts.join(',')}`, {
        backendEndpoint: `${process.env.REACT_APP_API_ENTRYPOINT}`,
      })
      .then((res) => {
        setAllSections(res.data);
        setExpanded(getAllIds(res.data));
        setIsLoading(false);
      });
  }, [props.selectedProducts, isHighlight]);

  function findElementById(id: any, sections: any) {
    for (let section of sections) {
      if (section.id === id) {
        return section;
      } else if (section.subSections) {
        let result: any = findElementById(id, section.subSections);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  const haveSubSections = (id: any) => {
    return findElementById(id, allSections)?.subSections?.length >= 1;
  };

  const getTreeItemsFromData = (treeItems: any) => {
    return treeItems.map((treeItemData: any) => {
      let children;
      if (treeItemData.subSections && treeItemData.subSections.length > 0) {
        children = getTreeItemsFromData(treeItemData.subSections);
      }

      const getContentStyle = (treeItemData: any) => {
        return {
          marginTop: treeItemData.parentId === null ? '12px' : 'unset',
          display:
            isFocus &&
            treeItemData?.valueData &&
            areAllValuesIdentical(treeItemData?.valueData)
              ? 'none'
              : '-webkit-box',

          backgroundColor:
            isHighlight &&
            treeItemData?.valueData &&
            !areAllValuesIdentical(treeItemData?.valueData)
              ? '#E8E8E8!important'
              : 'unset',
        };
      };
      const getIconContainerStyle = (treeItemData: any) => {
        return {
          borderTop:
            treeItemData.parentId === null &&
            treeItemData.type !== 'productImage' &&
            treeItemData.type !== 'modelName'
              ? 1
              : 'none',
          borderBottom:
            treeItemData.type !== 'field' &&
            treeItemData.type !== 'productImage' &&
            treeItemData.type !== 'modelName'
              ? 1
              : 'none!important',
          borderColor:
            treeItemData.title === undefined ||
            treeItemData.type === 'productImage' ||
            treeItemData.type === 'modelName'
              ? 'white'
              : treeItemData.parentId === null
              ? 'black'
              : grey[300],
          marginRight: 'unset',
          padding:
            treeItemData.title === undefined
              ? '0px'
              : haveSubSections(treeItemData.id)
              ? '3px'
              : '3px',
          paddingBottom:
            treeItemData.title === undefined
              ? '0px'
              : haveSubSections(treeItemData.id)
              ? '3px'
              : '21px',
          color: treeItemData.id < 0 ? 'white' : '#e41b13',
        };
      };
      const getTreeViewStyle = (treeItemData: any) => {
        return {
          display:
            isFocus &&
            treeItemData?.valueData &&
            areAllValuesIdentical(treeItemData?.valueData)
              ? 'none!important'
              : 'unset!important',
        };
      };
      const getLabelStyle = (treeItemData: any) => {
        return {
          fontWeight:
            treeItemData.parentId === null && treeItemData.type !== 'field'
              ? 'bold'
              : 10,
          color:
            treeItemData.parentId === null && treeItemData.type !== 'field'
              ? 'black'
              : grey[800],
          borderTop:
            treeItemData.parentId === null ||
            treeItemData.type === 'productImage' ||
            treeItemData.type === 'modalName'
              ? 1
              : 'none',
          borderColor:
            treeItemData.title === undefined ||
            treeItemData.type === 'productImage' ||
            treeItemData.type === 'modalName'
              ? '#fff!important'
              : treeItemData.parentId === null
              ? 'black'
              : '#e0e0e0!important',
          borderImage:
            treeItemData.type === 'field'
              ? 'linear-gradient(to right, transparent 21px, #e0e0e0 68px) 1'
              : 'unset',

          borderBottom:
            treeItemData.type !== 'productImage' &&
            treeItemData.type !== 'modelName'
              ? 1
              : 'none!important',
          marginLeft:
            treeItemData.type === 'field'
              ? '17px'
              : treeItemData.parentId != null &&
                !haveSubSections(treeItemData.id)
              ? '-9px'
              : 'none',
        };
      };
      const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
        },
      }));
      return (
        <TreeItem
          key={treeItemData.id}
          nodeId={treeItemData.id.toString()}
          label={
            <div>
              {treeItemData.type === 'field' ? (
                <Grid container alignItems="center">
                  <Grid item xs={2} sx={{ ml: 4 }}>
                    <Stack direction="row" spacing={1}>
                      <Typography sx={{ fontSize: '13px', marginTop: 0.5 }}>
                        {treeItemData.title}
                      </Typography>
                      {treeItemData.tooltip && (
                        <HtmlTooltip
                          title={
                            <span
                              dangerouslySetInnerHTML={{
                                __html: treeItemData.tooltip,
                              }}
                            ></span>
                          }
                        >
                          <InfoIcon
                            sx={{
                              fontSize: '15px',
                              marginTop: '5px !important',
                            }}
                          />
                        </HtmlTooltip>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={8}>
                    <Typography sx={{ color: 'black', fontSize: '13px' }}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: treeItemData.value,
                        }}
                      ></span>
                    </Typography>
                  </Grid>
                </Grid>
              ) : treeItemData.type === 'productImage' ? (
                <Grid container alignItems="center">
                  <Grid item xs={2} sx={{ ml: 4 }}></Grid>
                  <Grid item xs={8}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: treeItemData.value,
                      }}
                    ></span>
                  </Grid>
                </Grid>
              ) : treeItemData.type === 'modelName' ? (
                <Grid container alignItems="center">
                  <Grid item xs={2} sx={{ ml: 4 }}></Grid>
                  <Grid item xs={8}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: treeItemData.title,
                      }}
                    ></span>
                  </Grid>
                </Grid>
              ) : treeItemData.parentId === null ? (
                <Typography sx={{ fontWeight: 'bold' }}>
                  {treeItemData.title}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: '13px', marginTop: 0.5 }}>
                  {treeItemData.title}
                </Typography>
              )}
            </div>
          }
          sx={{
            '& .MuiTreeItem-label': getLabelStyle(treeItemData),
            '& .MuiTreeItem-iconContainer': getIconContainerStyle(treeItemData),
            '& .MuiTreeItem-content': getContentStyle(treeItemData),
            '& .MuiTreeItem-root': getTreeViewStyle(treeItemData),
          }}
          disabled={false}
          children={children}
        />
      );
    });
  };
  const getTreeStyle = () => {
    return {
      marginLeft: '0 !important',
    };
  };
  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };
  const DataTreeView = ({ treeItems }: any) => {
    return (
      <TreeView
        aria-label="section tree"
        defaultCollapseIcon={<RemoveIcon />}
        defaultExpandIcon={<AddIcon />}
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          '.MuiCollapse-root': getTreeStyle(),
        }}
        expanded={expanded}
        onNodeToggle={handleToggle}
        multiSelect
      >
        {getTreeItemsFromData(treeItems)}
      </TreeView>
    );
  };

  const generateExcel = (data: any) => {
    const flattenedData: any[] = [];
    const productNames = data.filter((d: any) => d.id === -3)[0].subSections[0]
      .noHtmlTitle;
    const flattenData = (section: any, prefix: any) => {
      if (section.type !== 'field' && section.id > 0) {
        if (section.subSections) {
          section.subSections.forEach((subSection: any) => {
            if (subSection.valueData) {
              const resultObject: any = {};

              for (let i = 0; i < productNames.length; i++) {
                const productName = productNames[i];
                const valueIndex: any =
                  i < subSection.valueData.length ? i : undefined; // Check if index exists in valueData
                resultObject['Model'] = `${subSection.title}`;
                resultObject[productName] = subSection.valueData[valueIndex];
              }
              flattenedData.push(resultObject);
            }
            flattenData(subSection, `${section.title} - `);
          });
        }
      }
    };

    data.forEach((item: any) => {
      flattenData(item, '');
    });

    // Create an Excel worksheet
    const ws = XLSX.utils.json_to_sheet(flattenedData, { header: [] });
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'compare-products');
    XLSX.writeFile(wb, 'compare-products.xlsx');
  };

  const generatePDF = () => {
    let doc = new jsPDF({
      orientation: 'landscape',
    });
    const margin = 10;
    let yPosition = margin;
    const pageHeight = 297;
    doc.html(compareTreeRef.current, {
      callback: function (doc) {
        doc.save('compare-products.pdf');
      },
      autoPaging: 'text',
      margin: margin,
      x: -6,
      y: yPosition,
      width: 320,
      windowWidth: 810,
    });
    yPosition += pageHeight;
  };

  return (
    <>
      <Divider sx={{ marginBottom: 2 }} />

      <Stack direction="row" spacing={2}>
        <ButtonGroup sx={{ ml: 2 }}>
          <Button
            variant={isStandard ? 'contained' : 'outlined'}
            onClick={() => {
              setIsHighlight(false);
              setIsStandard(true);
              setIsFocus(false);
            }}
          >
            Standard
          </Button>
          <Button
            variant={isHighlight ? 'contained' : 'outlined'}
            onClick={() => {
              setIsHighlight(true);
              setIsStandard(false);
              setIsFocus(false);
            }}
          >
            Highlight
          </Button>
          <Button
            variant={isFocus ? 'contained' : 'outlined'}
            onClick={() => {
              setIsHighlight(false);
              setIsStandard(false);
              setIsFocus(true);
            }}
          >
            Focus
          </Button>
        </ButtonGroup>
        <Button variant="contained" onClick={generatePDF}>
          Download as PDF
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            generateExcel(allSections);
          }}
        >
          Download as Excel
        </Button>
      </Stack>
      <Grid
        item
        xs={12}
        sx={{ m: 2, p: 2, display: 'align-center' }}
        ref={compareTreeRef}
      >
        {allSections.length > 0 && <DataTreeView treeItems={allSections} />}
      </Grid>
      {isLoading && (
        <Box
          component="div"
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <CircularProgress
            sx={{ width: '30px', height: '30px', mb: '10px' }}
          />
        </Box>
      )}
    </>
  );
}
