import { SyntheticEvent, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UploadForm from '../UploadForm/UploadForm';
import RecordFilterSelect from '../../../ContractList/ContractFilter/RecordFilterSelect/RecordFilterSelect';
import {
  Dialog,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import apiClient from '../../../../services/apiClient';

interface filterType {
  subcategories: [];
}

interface ProductCatalog {
  id: number;
  name: string;
  status: number;
  subcategories: [];
}
export interface SimpleDialogProps {
  open: boolean;
  pcDetail: ProductCatalog;
  onClose: (value: string) => void;
}

export default function Configuration(props: SimpleDialogProps) {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [pcStatus, setPcStatus] = useState<number>(props.pcDetail.status);
  const [pcSubcategories, setPcSubcategories] = useState(
    props.pcDetail.subcategories,
  );

  const [selectedFiltersEdit, setSelectedFiltersEdit] = useState<filterType>({
    subcategories: [],
  });
  const [associatedSubcategoriesEdit, setAssociatedSubCategoriesEdit] =
    useState([]);

  const { onClose, pcDetail, open } = props;

  const handleClose = () => {
    onClose(pcDetail.name);
  };

  const configuration = {
    status: 'Change status',
    subcategories: 'Change associated subcategories',
    files: 'Update file',
  };

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const handleRecordChangeEdit = (
    source: any,
    checked: any,
    record: any,
    id: any,
  ) => {
    const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEdit));
    if (checked) {
      newFiltersEdit[source] = [...(newFiltersEdit[source] || []), record];
    } else {
      newFiltersEdit[source] = newFiltersEdit[source].filter(
        (item: any) => item.id !== record.id,
      );
    }
    setSelectedFiltersEdit(newFiltersEdit);
  };

  const handleFilterCancelEdit = (source: string, id: any) => {
    const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEdit));
    newFiltersEdit.associatedSubcategoriesEdit = associatedSubcategoriesEdit;
    setSelectedFiltersEdit(newFiltersEdit);
  };

  const handleFilterApplyEdit = (source: any, id: any) => {
    const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEdit));
    setAssociatedSubCategoriesEdit(newFiltersEdit.reports);
    apiClient
      .post(`/productCatalog`, {
        id: id,
        subcategories: selectedFiltersEdit.subcategories,
      })
      .then((res: any) => {
        setPcSubcategories(res.data.subcategories);
      })
      .catch((err: any) => {
        console.log(err);
      });
    setSelectedFiltersEdit({
      subcategories: [],
    });
    setAssociatedSubCategoriesEdit([]);
  };

  const handleChangeType = (
    event: React.MouseEvent<HTMLElement>,
    newStatusPc: any,
    row: any,
  ) => {
    apiClient
      .post(`/productCatalog`, {
        id: row.id,
        status: newStatusPc,
        subcategories: row.subcategories,
      })
      .then((res: any) => {
        setPcStatus(res.data.status);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
      <DialogTitle> {pcDetail.name} configuration</DialogTitle>
      <Accordion
        expanded={expanded === configuration.status}
        onChange={handleChange(configuration.status)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Change status</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ToggleButtonGroup
            sx={{ m: 2 }}
            color="error"
            value={pcStatus}
            exclusive
            onChange={(event, newStatusPc) =>
              handleChangeType(event, newStatusPc, pcDetail)
            }
            aria-label="pcStatus"
          >
            <ToggleButton value={0}>Archived</ToggleButton>
            <ToggleButton value={1}>Active</ToggleButton>
          </ToggleButtonGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === configuration.subcategories}
        onChange={handleChange(configuration.subcategories)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Change associated subcategories</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <RecordFilterSelect
            source="subcategories"
            property={'name'}
            id={pcDetail.id}
            label="Associated subcategories"
            selected={selectedFiltersEdit?.subcategories}
            onRecordChange={handleRecordChangeEdit}
            onFilterApply={handleFilterApplyEdit}
            onFilterCancel={handleFilterCancelEdit}
            displaySelectedNumber={false}
          />
          <Typography variant="body2" sx={{ fontWeight: 'bold', marginTop: 2 }}>
            Selected Subcategories:
          </Typography>

          {pcSubcategories.map((s: any, index: any) => {
            if (index === pcSubcategories.length - 1) {
              return `${s.name}`;
            } else {
              return `${s.name} | `;
            }
          })}
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === configuration.files}
        onChange={handleChange(configuration.files)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Update file</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            path="productCatalog/upload"
            pathId={12}
            subPath={pcDetail.name}
            inputId={`productCatalog-${pcDetail.name}`}
            inputName="productCatalog"
            inputAccept=".zip"
            isUpdate= {true}
          />
        </AccordionDetails>
      </Accordion>
    </Dialog>
  );
}
