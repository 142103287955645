import { useEffect, useState } from 'react';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import apiClient from '../../../../services/apiClient';
import NegotiationDetails from '../../../NegotiationList/TableViewList/NegotiationDetails/NegotiationDetails';
import Button from '@mui/material/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function NegotiationsSearch({
  filter,
  onNavigate,
  onResultFound,
  onLoading,
}: {
  filter: any;
  onNavigate: () => void;
  onResultFound: any;
  onLoading: any;
}) {
  const [negotiations, setNegotiations] = useState<any>();
  const [negotiationId, setNegotiationId] = useState<
    number | null | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isClicked, setIsClicked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { search, domain, category, subcategory, group, region, natco } =
    filter;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isClicked || !search) {
      setNegotiations(null);
      setPage(1);
    }
    if (search) {
      onLoading(true);
      setIsLoading(true);

      apiClient
        .get('/negotiations', {
          params: {
            search,
            page,
            perPage: 3,
            groups: group,
            statuses: '0,1',
          },
        })
        .then((res) => {
          onLoading(false);
          // console.log("res.data.records", res.data.records);
          if (res.data.records.length > 0) {
            // console.log("this case is true");
            onResultFound(true);  // Pass `true` to indicate that results were found
          } else {
            // console.log("this case is false");

            onResultFound(false);  // Pass `false` if no results were found
          }
          if (isClicked) {
            res.data.records = (negotiations?.records || []).concat(
              res.data.records,
            );
          }
          setNegotiations(res.data);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setIsClicked(false);
          setIsLoading(false);
        });
    }
  }, [search, domain, category, subcategory, group, region, natco, page]);

  const handleViewAll = () => {
    searchParams.delete('search');
    searchParams.set('search', search.toString());
    setSearchParams(searchParams);
    navigate({
      pathname: `/domains/negotiations`,
      search: searchParams.toString(),
    });
    onNavigate();
  };

  return (
    <>
      {(negotiations?.records?.length > 0 || isLoading) && (
        <Box sx={{ mt: 2 }}>
          <Divider />

          <Stack direction="row" paddingY={1.5} p={1} spacing={1}>
            <Typography color="primary">
              <ClearAllIcon />
            </Typography>
            <Typography color="gray">
              Negotiations{' '}
              {negotiations && `(${negotiations?.pagination.total})`}
            </Typography>
          </Stack>
          <Divider />
          {(negotiations?.records || []).map((negotiation: any, i: number) => (
            <CardActionArea
              sx={{ p: 1, px: 2 }}
              key={i}
              onClick={() => {
                setNegotiationId(negotiation.id);
              }}
            >
              <Box sx={{ typography: 'subtitle2' }}>{negotiation.title}</Box>
              <Typography color="gray">
                <Stack direction="row" spacing={0.5}>
                  <Box>
                    Indicative Start:{' '}
                    {moment(negotiation.startDate).format('Q[Q]Y')}{' '}
                  </Box>
                </Stack>
              </Typography>
            </CardActionArea>
          ))}
          <Divider />
        </Box>
      )}

      {isLoading && (
        <>
          <Box sx={{ p: 2 }} width="100%">
            <Skeleton
              animation="wave"
              height={10}
              width="40%"
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={10} width="60%" />
          </Box>
        </>
      )}
      {negotiations?.records?.length !== 0 &&
        negotiations?.records?.length < negotiations?.pagination?.total && (
          <CardActionArea
            sx={{ p: 1, px: 2 }}
            onClick={() => {
              setIsLoading(true);
              setIsClicked(true);
              setPage(page + 1);
            }}
          >
            <Stack direction="row" spacing={1} p={1}>
              <ManageSearchIcon color="primary" />
              <Typography color="primary" style={{ marginTop: 2 }}>
                Show more negotiations
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                flexGrow={1}
                justifyContent="flex-end"
              >
                <Button onClick={handleViewAll} variant="outlined">
                  Open Negotiations
                </Button>
              </Stack>
            </Stack>
          </CardActionArea>
        )}
      <NegotiationDetails
        negotiationId={negotiationId}
        setNegotiationId={setNegotiationId}
        onClose={() => {
          setNegotiationId(undefined);
        }}
      />
    </>
  );
}
