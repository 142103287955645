import { Button, ButtonGroup, Pagination, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useNav } from '../NavProvider';
import { useSearchParams } from 'react-router-dom';
import Executive from './Executive';
import Operational from './Operational';

export default function Staff() {
  const { supplierId } = useNav();
  const [searchParams, setSearchParams] = useSearchParams();
  const [view, setView] = useState(searchParams.get('view') !== 'executives');
  const [count, setCount] = useState(0);
  const page = Number(searchParams.get('page') || 1);
  const perPage = Number(searchParams.get('perPage') || 10);

  const handleClick = (newValue: boolean) => {
    searchParams.delete('page');
    searchParams.delete('perPage');
    setCount(0);
    searchParams.set('view', newValue ? 'operationals' : 'executives');
    setSearchParams(searchParams);
    setView(newValue);
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    if (newPage > 1) {
      searchParams.set('page', newPage.toString());
    } else {
      searchParams.delete('page');
    }
    setSearchParams(searchParams);
  };

  return (
    <>
      <ButtonGroup sx={{ m: 2 }}>
        <Button
          variant={view ? 'contained' : 'outlined'}
          onClick={() => handleClick(true)}
        >
          Operational
        </Button>
        <Button
          variant={!view ? 'contained' : 'outlined'}
          onClick={() => handleClick(false)}
        >
          Executive
        </Button>
      </ButtonGroup>

      <>
        {
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            p={2}
          >
            <Pagination
              count={Math.ceil(count / perPage)}
              page={page}
              onChange={handlePageChange}
            />
          </Stack>
        }
        {!view && (
          <Executive
            filters={{ page, perPage, supplierId }}
            setCount={setCount}
          />
        )}
        {view && (
          <Operational
            filters={{ page, perPage, supplierId }}
            setCount={setCount}
          />
        )}
        {
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            p={2}
            pt={0}
          >
            <Pagination
              count={Math.ceil(count / perPage)}
              page={page}
              onChange={handlePageChange}
            />
          </Stack>
        }
      </>
    </>
  );
}
