import React, { useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Role } from '../../../data';
import { useAuth } from '../../AuthProvider';
import TabPanel from '../../ui/mui/TabPanel/TabPanel';
import Layout from '../../Layout/Layout';
import Guard from '../../shared/Guard/Guard';
import ContactUpload from './ContactUpload/ContactUpload';
import ContractUpload from './ContractUpload/ContractUpload';
import NegotiationUpload from './NegotiationUpload/NegotiationUpload';
import PublicNewsUpload from './PublicNewsUpload/PublicNewsUpload';
import SuppliersUpload from './ReferentialsUpload/SuppliersUpload';

type TabType = {
  value: string;
  label: string;
  role: string;
};

const uploadTabs: TabType[] = [
  { value: 'suppliers', label: 'Suppliers', role: Role.SupplierManager },
  { value: 'contacts', label: 'Contacts', role: Role.ContactManager },
  { value: 'contracts', label: 'Contracts', role: Role.ContractManager },
  {
    value: 'negotiations',
    label: 'Negotiations',
    role: Role.NegotiationManager,
  },
  { value: 'publicNews', label: 'Public News', role: Role.PublicNewsManager },
];

interface GuardedTabPanelProps {
  value: string;
  index: string;
  children: any;
}

const GuardedTabPanel = ({ value, index, children }: GuardedTabPanelProps) => {
  return (
    <TabPanel value={value} index={index}>
      <Guard
        role={uploadTabs.find((tab) => tab.value === index)?.role || ''}
        message
      >
        {children}
      </Guard>
    </TabPanel>
  );
};

export default function Upload() {
  const navigate = useNavigate();
  const { hasRole } = useAuth();
  const match = useMatch('/admin/upload/:tab/*');
  const matchedTab: string = match?.params.tab || 'suppliers';

  const handleChangeTab = (event: React.SyntheticEvent, newTab: any) => {
    navigate({
      pathname: `/admin/upload/${newTab}`,
    });
  };

  useEffect(() => {
    if (matchedTab === 'suppliers' && !hasRole(Role.SupplierManager)) {
      const role = uploadTabs.find((tab) => hasRole(tab.role));
      navigate({
        pathname: `/admin/upload/${role?.value}`,
      });
    }
  }, [matchedTab]);

  return (
    <Layout noBreadcrumbs>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        sx={{ p: 2 }}
      >
        <Typography>Admin</Typography>
        <Typography>Data upload</Typography>
      </Breadcrumbs>
      <Divider />
      <Guard role={Role.ReferentialManager} message>
        <Tabs value={matchedTab} onChange={handleChangeTab} variant="fullWidth">
          {uploadTabs.map(
            (tab) =>
              hasRole(tab.role) && (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ),
          )}
        </Tabs>
        <Divider />
        <GuardedTabPanel value={matchedTab} index="suppliers">
          <SuppliersUpload />
        </GuardedTabPanel>
        <GuardedTabPanel value={matchedTab} index="contacts">
          <ContactUpload />
        </GuardedTabPanel>
        <GuardedTabPanel value={matchedTab} index="contracts">
          <ContractUpload />
        </GuardedTabPanel>
        <GuardedTabPanel value={matchedTab} index="negotiations">
          <NegotiationUpload />
        </GuardedTabPanel>
        <GuardedTabPanel value={matchedTab} index="publicNews">
          <PublicNewsUpload />
        </GuardedTabPanel>
      </Guard>
    </Layout>
  );
}
