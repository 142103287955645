import React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Tab, Tabs } from '@mui/material';

import Layout from '../../Layout/Layout';
import Guard from '../../shared/Guard/Guard';
import { Role } from '../../../data';
import TabPanel from '../../ui/mui/TabPanel/TabPanel';
import { useMatch, useNavigate } from 'react-router';
import { useAuth } from '../../AuthProvider';
import PowerBiRoles from './PowerBiRoles';
import ProductCatalogRoles from './ProductCatalogRoles';

type TabType = {
  value: string;
  label: string;
  role: string;
};

const uploadTabs: TabType[] = [
  { value: 'powerBi', label: 'Power BI', role: Role.PowerBIManager },
  {
    value: 'productCatalog',
    label: 'Product Catalog',
    role: Role.ProductCatalogManager,
  },
];

interface GuardedTabPanelProps {
  value: string;
  index: string;
  children: any;
}

const GuardedTabPanel = ({ value, index, children }: GuardedTabPanelProps) => {
  return (
    <TabPanel value={value} index={index}>
      <Guard
        role={uploadTabs.find((tab) => tab.value === index)?.role || ''}
        message
      >
        {children}
      </Guard>
    </TabPanel>
  );
};

export default function ToolsRoles() {
  const navigate = useNavigate();
  const { hasRole } = useAuth();
  const match = useMatch('/admin/toolsRoles/:tab/*');
  const matchedTab: string = match?.params.tab || 'powerBi';

  const handleChangeTab = (event: React.SyntheticEvent, newTab: any) => {
    navigate({
      pathname: `/admin/toolsRoles/${newTab}`,
    });
  };
  return (
    <Layout noBreadcrumbs>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        sx={{ p: 2 }}
      >
        <Typography>Admin</Typography>
        <Typography>Tools Roles</Typography>,
      </Breadcrumbs>
      <Divider />
      <Guard role={Role.PowerBIManager || Role.ProductCatalogManager} message>
        <Tabs value={matchedTab} onChange={handleChangeTab} variant="fullWidth">
          {uploadTabs.map(
            (tab) =>
              hasRole(tab.role) && (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ),
          )}
        </Tabs>
        <Divider />
        <GuardedTabPanel value={matchedTab} index="powerBi">
          <PowerBiRoles />
        </GuardedTabPanel>
        <GuardedTabPanel value={matchedTab} index="productCatalog">
          <ProductCatalogRoles />
        </GuardedTabPanel>
      </Guard>
    </Layout>
  );
}
