import React, { useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import apiClient from '../../../../services/apiClient';
import DialogTitle from '../../../ui/mui/DialogTitle/DialogTitle';

interface UserCreateDialogProps {
  open: boolean;
  onClose: () => void;
}

export default function UserCreateDialog({
  open,
  onClose,
}: UserCreateDialogProps) {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<number>();

  const handleClose = () => {
    onClose();
    setError(undefined);
    setEmail('');
  };

  const handleSubmit = () => {
    setError(undefined);
    setSubmitting(true);
    apiClient
      .post('users', { email })
      .then(() => {
        setSubmitting(false);
        onClose();
      })
      .catch((error) => {
        setError(error.response.status);
        setSubmitting(false);
      });
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle onClose={handleClose}>New User</DialogTitle>
      <DialogContent>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(event.target.value)
          }
          variant="filled"
          fullWidth
          required
        />
        {error && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error === 422 ? 'User already exists' : 'An error occurred'}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={submitting} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          disabled={submitting}
          variant="contained"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
