import {
  Avatar,
  Badge,
  Box,
  Button,
  Chip,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import apiClient from '../../../../services/apiClient';
import GetAppIcon from '@mui/icons-material/GetApp';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import UploadIcon from '@mui/icons-material/Upload';
import EmailIcon from '@mui/icons-material/Email';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailResults from '../EmailResults';
import LoadingButton from '@mui/lab/LoadingButton';

export default function Feedback() {
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 5 },
    {
      field: 'title',
      headerName: 'Title',
      width: 100,

      renderCell: (params: any) => (
        <Tooltip title={params.row.title}>
          <Typography noWrap maxWidth={100}>
            {params.row.title}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'date',
      headerName: 'Date',
      type: 'dateTime',
      width: 150,
      renderCell: (params: any) => (
        <Tooltip title={moment(params.row.date).format('DD-MM-YYYY HH:mm')}>
          <Typography noWrap maxWidth={150}>
            {moment(params.row.date).format('DD-MM-YYYY HH:mm')}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'group',
      headerName: 'Group',
      width: 5,
      type: 'singleSelect',
      renderCell: (params: any) => {
        return `${params.row.group.code || ''}`;
      },
    },
    {
      field: 'domains',
      headerName: 'Domains',
      width: 100,
      editable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Tooltip
              title={
                (params.row.domains || [])
                  .map((d: any) => `${d?.name}`)
                  .join(', ') || 'ALL'
              }
            >
              <Chip
                label={`${params.row?.domains[0]?.code || 'ALL'}`}
                variant="outlined"
              />
            </Tooltip>
            {params.row.domains?.length > 1 ? (
              <Badge
                badgeContent={` +${params.row.domains.length - 1}`}
                color="primary"
              ></Badge>
            ) : (
              ''
            )}
          </>
        );
      },
    },
    {
      field: 'speakers',
      headerName: 'Speakers',
      width: 100,

      renderCell: (params: any) => {
        return (
          <>
            {params.row.speakers.length > 0 && (
              <>
                <Tooltip
                  title={params.row.speakers
                    .map((s: any) => `${s?.firstName} ${s?.lastName}`)
                    .join(', ')}
                >
                  <Chip
                    avatar={
                      <Avatar
                        alt={params.row.speakers[0].lastName[0]}
                        src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${params.row.speakers[0].email}`}
                      />
                    }
                    label={`${params.row.speakers[0].firstName[0]}.${params.row.speakers[0].lastName[0]}`}
                    variant="outlined"
                  />
                </Tooltip>
                {params.row.speakers.length > 1 ? (
                  <Badge
                    badgeContent={` +${params.row.speakers.length - 1}`}
                    color="primary"
                  ></Badge>
                ) : (
                  ''
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      field: 'surveyUrl',
      headerName: 'Survey URL',
      type: 'text',
      width: 100,
      editable: true,
      renderCell: (params) => {
        return params.row.surveyUrl;
      },
      valueGetter: (params) => {
        return params.row.surveyUrl;
      },
    },
    {
      field: 'files',
      headerName: 'Attachements',
      sortable: false,
      width: 230,
      renderCell: (params) => {
        const uploadFiles = async (e: any) => {
          e.stopPropagation();
          const data = new FormData();

          [...params.row.files].forEach((file: any) => {
            data.append('attachements', file);
          });

          await apiClient
            .post(`admin/sessions/attachements/upload/${params.row.id}`, data)
            .then((res: any) => {
              console.log(res);
            });

          await apiClient
            .put(`admin/sessions/${params.row.id}`, {
              ...params.row,
              attachements: [...params.row.files].map((file: any) => file.name),
            })
            .then((res: any) => {
              params.row.attachements = [...params.row.files].map(
                (file: any) => file.name,
              );
              params.row.files = null;
              setRows([...rows]);
            });
        };
        const deleteFiles = async (e: any) => {
          e.stopPropagation();
          await apiClient.delete(
            `admin/sessions/attachements/${params.row.id}`,
          );
          await apiClient
            .put(`admin/sessions/${params.row.id}`, {
              ...params.row,
              attachements: [],
            })
            .then((res: any) => {
              params.row.attachements = [];
              params.row.files = null;
              setRows([...rows]);
            });
        };

        return (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            spacing={0.5}
          >
            <input
              accept="*"
              id={params.row.id}
              type="file"
              onChange={({ target }: any) => {
                params.row.files = target.files;
                setRows([...rows]);
              }}
              hidden
              multiple
            />
            <Button
              component="label"
              variant="outlined"
              size="small"
              htmlFor={params.row.id}
              disabled={(params.row.attachements || []).length > 0}
            >
              <AttachFileIcon />
              {(params.row.attachements || []).length > 0 &&
                (params.row.attachements || []).length}
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={uploadFiles}
              disabled={(params.row.files || []).length === 0}
            >
              <UploadIcon />{' '}
              {(params.row.files || []).length > 0 && params.row.files.length}
            </Button>
            <Button
              size="small"
              variant="outlined"
              onClick={deleteFiles}
              disabled={(params.row.attachements || []).length === 0}
            >
              <DeleteIcon />
            </Button>
          </Stack>
        );
      },
    },
  ];
  const enrollmentColumns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 5 },
    {
      field: 'firstName',
      headerName: 'First name',
      width: 100,
      editable: true,
      renderCell: (params: any) => (
        <Tooltip title={params.row.user.firstName}>
          <Typography noWrap maxWidth={100}>
            {params.row.user.firstName}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 100,
      renderCell: (params: any) => (
        <Tooltip title={params.row.user.lastName}>
          <Typography noWrap maxWidth={100}>
            {params.row.user.lastName}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 260,
      renderCell: (params: any) => (
        <Tooltip title={params.row.user.email}>
          <Typography noWrap maxWidth={260}>
            {params.row.user.email}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'date',
      headerName: 'Enrollment Date',
      width: 150,
      renderCell: (params: any) => (
        <Tooltip title={moment(params.row.date).format('DD-MM-YYYY HH:mm')}>
          <Typography noWrap maxWidth={150}>
            {moment(params.row.date).format('DD-MM-YYYY HH:mm')}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'sessionId',
      headerName: 'Session Id',
      width: 100,
      renderCell: (params: any) => {
        return `${params.row.session.id || ''}`;
      },
    },
    {
      field: 'group',
      headerName: 'Group',
      width: 5,
      renderCell: (params: any) => {
        return `${params.row.session.group.code || ''}`;
      },
    },
  ];

  const [errors, setErrors] = useState<any>([]);
  const [success, setSuccess] = useState<any>([]);
  const [displayResults, setDisplayResults] = useState(false);

  const [rows, setRows] = useState<any[]>([]);
  const [enrollmentRows, setEnrollmentRows] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [requestFeedbackIsLoading, setRequestFeedbackIsLoading] =
    useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedEnrollments, setSelectedEnrollments] = useState([]);
  const getEnrollments = () => {
    if (selectedIds.length > 0) {
      apiClient
        .get('/enrollments/admin', {
          params: { sessions: selectedIds.join(',') },
        })
        .then((res) => {
          setEnrollmentRows(res.data.records);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const requestFeedback = () => {
    setRequestFeedbackIsLoading(true);
    apiClient
      .post(`/enrollments/request-feedback/${selectedEnrollments.join(',')}`)
      .then((res: any) => {
        setRequestFeedbackIsLoading(false);
        setDisplayResults(true);
        setErrors(
          res.data.errors.map((error: any, index: number) => ({
            id: index,
            ...error,
          })),
        );
        setSuccess(
          res.data.success.map((success: any, index: number) => ({
            id: index,
            ...success,
          })),
        );
      });
  };

  useEffect(() => {
    if (rows.length === 0) {
      apiClient
        .get('admin/sessions', { params: { hasEnrollments: true } })
        .then((res) => {
          setRows(res.data.records);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [rows]);

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ p: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
        >
          <Button
            disabled={selectedIds.length === 0}
            onClick={getEnrollments}
            variant="outlined"
            startIcon={<GetAppIcon />}
          >
            Get Enrollments
          </Button>
        </Stack>
      </Box>
      <Box sx={{ mt: 3 }}>
        <DataGrid
          onSelectionModelChange={(ids: any) => {
            setSelectedIds(ids);
          }}
          loading={isLoading}
          autoHeight
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          processRowUpdate={async (session: any) => {
            return await apiClient
              .put(`admin/sessions/${session.id}`, session)
              .then((res: any) => {
                return session;
              })
              .catch((err: any) => {
                console.log(err);
              });
          }}
          experimentalFeatures={{ newEditingApi: true }}
        />
        {enrollmentRows.length > 0 && (
          <>
            <Box sx={{ p: 1 }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                spacing={1}
              >
                <LoadingButton
                  loading={requestFeedbackIsLoading}
                  disabled={selectedEnrollments.length === 0}
                  loadingPosition="start"
                  variant="outlined"
                  startIcon={<EmailIcon />}
                  onClick={requestFeedback}
                >
                  Request Feedback
                </LoadingButton>
              </Stack>
            </Box>
            <DataGrid
              onSelectionModelChange={(ids: any) => {
                setSelectedEnrollments(ids);
              }}
              loading={isLoading}
              autoHeight
              rows={enrollmentRows}
              columns={enrollmentColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </>
        )}
      </Box>
      <Box sx={{ mt: 1 }}>
        <EmailResults
          errors={errors}
          success={success}
          displayResults={displayResults}
        />
      </Box>
    </Box>
  );
}
