import React, { useEffect, useState } from 'react';
import debounce from '@mui/utils/debounce';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Profile } from '../../../types';
import { Role, ROLE_NAMES } from '../../../data';
import apiClient from '../../../services/apiClient';
import Layout from '../../Layout/Layout';
import Guard from '../../shared/Guard/Guard';
import UserCreateDialog from './UserCreateDialog/UserCreateDialog';
import UserEditDialog from './UserEditDialog/UserEditDialog';

const UserListSkeleton = () => (
  <>
    {Array(10)
      .fill(null)
      .map((_, row) => (
        <TableRow key={row}>
          {Array(4)
            .fill(null)
            .map((_, cell) => (
              <TableCell key={cell}>
                <Skeleton />
              </TableCell>
            ))}
        </TableRow>
      ))}
  </>
);

const Action = {
  list: 'list',
  create: 'create',
  edit: 'edit',
};

export default function UserList() {
  const [users, setUsers] = useState<Profile[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [email, setEmail] = useState<string>('');
  const [action, setAction] = useState<string>(Action.list);
  const [user, setUser] = useState<Profile>();
  const perPage = 50;
  const isEmpty = !loading && total === 0;

  useEffect(() => {
    if (action === Action.list) {
      setLoading(true);
      apiClient
        .get('users', {
          params: {
            page,
            perPage,
            email,
          },
        })
        .then((response) => {
          setUsers(response.data.records);
          setTotal(response.data.pagination.total);
          setLoading(false);
        })
        .catch(() => {
          // TODO: handle error response
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, page, perPage, email]);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setEmail(event.target.value);
  };

  const handleEditClick = (user: Profile) => {
    setAction(Action.edit);
    setUser(user);
  };

  const pagination = (
    <Stack direction="row" justifyContent="flex-end" spacing={2} p={2}>
      <Pagination
        count={Math.ceil(total / perPage)}
        page={page}
        onChange={(even, newPage) => setPage(newPage)}
      />
    </Stack>
  );

  return (
    <Layout noBreadcrumbs>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        sx={{ p: 2 }}
      >
        <Typography>Admin</Typography>
        <Typography>User management</Typography>,
      </Breadcrumbs>
      <Divider />
      <Guard role={Role.Admin} message>
        <Stack direction="row" justifyContent="space-between">
          <TextField
            label="Search"
            sx={{ m: 1 }}
            defaultValue={email}
            onChange={debounce(handleEmailChange, 300)}
            variant="filled"
          />
          <Button
            sx={{ m: 1 }}
            startIcon={<AddIcon />}
            onClick={() => setAction(Action.create)}
          >
            New user
          </Button>
        </Stack>
        <Divider />
        {pagination}
        <Table size="small">
          <TableHead
            sx={(theme) => ({ backgroundColor: theme.palette.grey[300] })}
          >
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Roles</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {isEmpty && (
              <TableRow>
                <TableCell colSpan={4}>
                  <Typography sx={{ textAlign: 'center' }} variant="h6">
                    No users found
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {loading ? (
              <UserListSkeleton />
            ) : (
              users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>
                    <Typography>
                      {user.firstName} {user.lastName}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{user.email}</Typography>
                  </TableCell>
                  <TableCell>
                    {user.roles
                      .filter((role) => role !== Role.User)
                      .slice(0, 4)
                      .map(
                        (role) =>
                          ROLE_NAMES[role] && (
                            <Chip
                              key={role}
                              size="small"
                              label={ROLE_NAMES[role]}
                              sx={{ m: 0.5 }}
                            />
                          ),
                      )}
                    {user.roles.length <= 5 &&
                      (user.productCatalogRoles || [])
                        .slice(0, 5 - (user.roles.length - 1))
                        .map((role) => (
                          <Chip
                            key={role}
                            size="small"
                            label={role.name}
                            sx={{ m: 0.5 }}
                          />
                        ))}
                    {user.roles.length + user.productCatalogRoles?.length > 5 &&
                      `+${
                        user.roles.length + user.productCatalogRoles?.length - 5
                      }`}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      size="small"
                      startIcon={<EditIcon />}
                      sx={{ m: 0.5 }}
                      onClick={() => handleEditClick(user)}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {pagination}
        <UserCreateDialog
          open={action === Action.create}
          onClose={() => setAction(Action.list)}
        />
        {user && (
          <UserEditDialog
            user={user}
            open={action === Action.edit}
            onClose={() => setAction(Action.list)}
          />
        )}
      </Guard>
    </Layout>
  );
}
