import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import apiClient from '../../../services/apiClient';
import ConfirmDialog from '../../ui/mui/ConfirmDialog/ConfirmDialog';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import { iconOptions } from '../../ToolsPage/ProductCatalog/ProductView';

interface Field {
  icon: number;
  id: number | null;
  technicalId: string;
  headerName: string;
  headerSeparator: string;
  headerOrder: number;
  parentHeaderId: number | null | undefined;
  index: number;
  displaySeparator: boolean;
  productCatalogId: number;
}

export default function HeaderConfig({
  fieldRecords,
  productCatalogSelected,
}: any) {
  const [headersList, setHeadersList] = useState<any[]>([]);
  const [savedHeaders, setSavedHeaders] = useState<boolean>(false);
  const [fields, setFields] = useState<any>([]);
  const [fieldValuesHeader, setFieldValuesHeader] = useState<Field[]>([]);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [alert, setAlert] = useState<{
    severity: AlertColor | undefined;
    message: string;
  }>();

  const [fieldValues, setFieldValues] = useState<Field>({
    id: null,
    icon: 0,
    technicalId: '',
    headerName: '',
    headerSeparator: '',
    parentHeaderId: null,
    index: 0,
    headerOrder: 1,
    displaySeparator: false,
    productCatalogId: productCatalogSelected.id,
  });

  const getLastHeaderOrder = () => {
    return headersList.length > 0
      ? headersList[headersList.length - 1].headerOrder
      : null;
  };

  const handleHeaderNameChange = (event: any) => {
    const newFieldValues = fieldValues;
    newFieldValues.headerName = event.target.value;
    newFieldValues.headerOrder = getLastHeaderOrder() + 1;
    setFieldValues(newFieldValues);
  };

  const handleHeaderNameChangeField = (event: any, indexField: any) => {
    const newFieldValuesHeader = fieldValuesHeader;
    newFieldValuesHeader[indexField].headerName = event.target.value;
    setFieldValuesHeader(newFieldValuesHeader);
  };
  const handleIconChange = (event: any) => {
    const newFieldValues = fieldValues;
    newFieldValues.icon = event.target.value;
    setFieldValues(newFieldValues);
  };
  const handleChangeAutoComplete = (value: any) => {
    const newFieldValues = fieldValues;
    newFieldValues.id = value?.id;
    newFieldValues.technicalId = value?.technicalId;
    newFieldValues.index = 0;
    setFieldValues(newFieldValues);
  };

  const handleHeaderSeparatorChange = (event: any) => {
    const newFieldValues = fieldValues;
    newFieldValues.headerSeparator = event.target.value;
    setFieldValues(newFieldValues);
    const newFieldsValuesHeader = [...fieldValuesHeader];
    newFieldsValuesHeader.map(
      (nfh: any) => (nfh.headerSeparator = event.target.value),
    );
    setFieldValuesHeader(newFieldsValuesHeader);
  };

  const Header = () => {
    const addField = () => {
      fieldValues.displaySeparator = true;
      setFields((prevFields: any) => {
        const newFields = [...prevFields];
        newFields.push('');
        return newFields;
      });
      fieldValues.parentHeaderId = fieldValues.id;

      setFieldValuesHeader((prevFieldValuesHeader: any) => {
        let newFieldsValuesHeader = [...prevFieldValuesHeader];
        if (newFieldsValuesHeader) {
          newFieldsValuesHeader.push({
            id: null,
            technicalId: '',
            headerSeparator: fieldValues.headerSeparator,
            parentHeaderId: fieldValues.id,
            icon: fieldValues?.icon,
            headerName: '',
            headerOrder: fieldValues.headerOrder,
            productCatalogId: productCatalogSelected.id,
          });
        } else {
          newFieldsValuesHeader = [
            {
              id: null,
              technicalId: '',
              headerSeparator: fieldValues.headerSeparator,
              parentHeaderId: fieldValues.id,
              icon: fieldValues?.icon,
              headerName: '',
              headerOrder: fieldValues.headerOrder,
              productCatalogId: productCatalogSelected.id,
            },
          ];
        }

        return newFieldsValuesHeader;
      });
    };

    const handleHeaderChangeAutoComplete = (value: any, indexField: number) => {
      const newFieldValuesHeader = fieldValuesHeader;
      newFieldValuesHeader[indexField].id = value?.id;
      newFieldValuesHeader[indexField].technicalId = value?.technicalId;
      setFieldValuesHeader(newFieldValuesHeader);
    };
    const Field = (indexField: any) => {
      indexField = indexField.indexField;

      return (
        <Grid item xs={10} sx={{ ml: 11 }}>
          <Stack direction="row" spacing={2}>
            <TextField
              error
              required
              id="outlined-required"
              label="Name"
              defaultValue={
                fieldValuesHeader[indexField]?.headerName === null
                  ? undefined
                  : fieldValuesHeader[indexField]?.headerName
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleHeaderNameChangeField(event, indexField)
              }
            />
            <Autocomplete
              defaultValue={
                fieldValuesHeader[indexField]?.id === null
                  ? undefined
                  : fieldValuesHeader[indexField]
              }
              onChange={(event: any, v: any) =>
                handleHeaderChangeAutoComplete(v, indexField)
              }
              options={fieldRecords}
              isOptionEqualToValue={(option: any, value: any) =>
                option.id === value.id
              }
              fullWidth
              getOptionLabel={(option: any) => `${option.technicalId} `}
              renderOption={(
                props: any,
                option: any,
                { selected }: { selected: boolean },
              ) => (
                <Box container="li" {...props} key={`${option.id}`}>
                  {`${option.technicalId}`}
                </Box>
              )}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder="Choose field ..."
                />
              )}
            />
          </Stack>
        </Grid>
      );
    };
    const Fields = () => {
      return (
        <Box
          component="div"
          style={{ marginRight: '51px', marginLeft: '-7px' }}
        >
          {fields.map((field: any, index1: number) => (
            <div key={index1}>
              <Box sx={{ mb: 2 }}>
                <Field indexField={index1} />
              </Box>
            </div>
          ))}
        </Box>
      );
    };

    return (
      <Grid
        item
        xs={12}
        sx={{ border: 1, borderRadius: 2, p: 1, borderColor: 'red' }}
      >
        <Stack direction="column" spacing={3}>
          <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <Select
              label="icon"
              error
              required
              defaultValue={fieldValues?.icon}
              onChange={(event: any) => handleIconChange(event)}
              sx={{ width: 80 }}
              placeholder="Icon"
            >
              {iconOptions.map((iconO: any, index: any) => (
                <MenuItem key={index} value={index}>
                  {iconO}
                </MenuItem>
              ))}
            </Select>

            <TextField
              error
              required
              id="outlined-required"
              label="Name"
              defaultValue={fieldValues?.headerName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleHeaderNameChange(event)
              }
            />
            <Autocomplete
              defaultValue={fieldValues?.id === null ? undefined : fieldValues}
              onChange={(event: any, v: any) => handleChangeAutoComplete(v)}
              options={fieldRecords}
              isOptionEqualToValue={(option: any, value: any) =>
                option.id === value.id
              }
              fullWidth
              getOptionLabel={(option: any) => `${option.technicalId} `}
              renderOption={(
                props: any,
                option: any,
                { selected }: { selected: boolean },
              ) => (
                <Box container="li" {...props} key={`${option.id}`}>
                  {`${option.technicalId}`}
                </Box>
              )}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  variant="filled"
                  placeholder="Choose field ..."
                />
              )}
            />

            <IconButton aria-label="add" onClick={() => addField()}>
              <AddIcon />
            </IconButton>
          </Stack>
          <Grid item xs={10}>
            {fieldValues?.displaySeparator && (
              <TextField
                error
                required
                id="outlined-required"
                label="Separator"
                sx={{ ml: 11, width: 110 }}
                defaultValue={fieldValues?.headerSeparator}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleHeaderSeparatorChange(event)
                }
              />
            )}
          </Grid>
          <Fields />
        </Stack>
      </Grid>
    );
  };

  const handleHeadersSubmit = () => {
    let final = [fieldValues, ...fieldValuesHeader.flat()];
    final = final.filter((fh) => {
      return fh?.id !== null && fh;
    });

    apiClient.post('/field', final).then((result) => {
      setSavedHeaders(!savedHeaders);
      setAlert({
        severity: 'success',
        message: 'Header is saved successfully',
      });
      setFields([]);
      setFieldValuesHeader([]);
      setFieldValues({
        id: null,
        icon: 0,
        technicalId: '',
        headerName: '',
        headerSeparator: '',
        parentHeaderId: null,
        index: 0,
        headerOrder: 1,
        displaySeparator: false,
        productCatalogId: productCatalogSelected.id,
      });
    });
  };
  const handleOrderChangeTop = (params: any) => {
    const currentIndex = headersList.findIndex(
      (e) => e.headerOrder === params.row.headerOrder,
    );
    const previousIndex = currentIndex - 1;
    if (previousIndex < 0) {
      return null;
    }
    const previousElement = headersList[previousIndex];
    if (previousElement.headerOrder >= params.row.headerOrder) {
      return null;
    }
    const headerOrderSave = previousElement.headerOrder;
    const newHeadersList = [...headersList];
    newHeadersList[previousIndex].headerOrder = params.row.headerOrder;
    newHeadersList[currentIndex].headerOrder = headerOrderSave;
    //reBuild the first format
    const result: any[] = [];
    newHeadersList.forEach((item: any) => {
      const ids = item.id.split(', ');
      const headerNames = item.headerNames.split(', ');
      const headerstechnicalIds = item.headerstechnicalIds.split(', ');

      for (let i = 0; i < ids.length; i++) {
        result.push({
          headerName: headerNames[i].trim(),
          headerOrder: item.headerOrder,
          icon: item.icon,
          technicalId: headerstechnicalIds[i].trim(),
          id: Number(ids[i].trim()),
          headerNames: [],
          headerstechnicalIds: [],
        });
      }
    });
    apiClient.post('/field', result).then((result) => {
      setSavedHeaders(!savedHeaders);
    });
  };

  const handleOrderChangeBottom = (params: any) => {
    const currentIndex = headersList.findIndex(
      (e) => e.headerOrder === params.row.headerOrder,
    );
    const nextIndex = currentIndex + 1;
    if (nextIndex >= headersList.length) {
      return null;
    }
    const nextElement = headersList[nextIndex];
    if (nextElement.headerOrder <= params.row.headerOrder) {
      return null;
    }
    const headerOrderSave = nextElement.headerOrder;
    const newHeadersList = [...headersList];
    newHeadersList[nextIndex].headerOrder = params.row.headerOrder;
    newHeadersList[currentIndex].headerOrder = headerOrderSave;
    //reBuild the first format
    const result: any[] = [];
    newHeadersList.forEach((item: any) => {
      const ids = item.id.split(', ');
      const headerNames = item.headerNames.split(', ');
      const headerstechnicalIds = item.headerstechnicalIds.split(', ');

      for (let i = 0; i < ids.length; i++) {
        result.push({
          headerName: headerNames[i].trim(),
          headerOrder: item.headerOrder,
          technicalId: headerstechnicalIds[i].trim(),
          id: Number(ids[i].trim()),
          icon: item.icon,
          headerNames: [],
          headerstechnicalIds: [],
        });
      }
    });
    apiClient.post('/field', result).then((result) => {
      setSavedHeaders(!savedHeaders);
    });
  };

  useEffect(() => {
    apiClient
      .get('/field/', {
        params: {
          productCatalogId: productCatalogSelected.id,
          isHeader: true,
        },
      })
      .then((res) => {
        const headersData = res.data.record.filter((h: any) => {
          return h.isMainHeader === false && h.headerOrder !== null;
        });
        const groupedArray = Object.values(
          headersData.reduce((acc: any, curr: any) => {
            const { headerOrder, icon } = curr;
            const obj = acc[headerOrder] || {
              headerOrder,
              icon,
              headerNames: [],
              id: [],
              headerstechnicalIds: [],
            };

            obj.headerNames.push(curr.headerName);
            obj.id.push(curr.id);
            obj.headerstechnicalIds.push(curr.technicalId);
            acc[headerOrder] = obj;

            return acc;
          }, {}),
        );

        groupedArray.forEach((obj: any) => {
          obj.headerNames = obj.headerNames.join(', ');
          obj.id = obj.id.join(', ');
          obj.headerstechnicalIds = obj.headerstechnicalIds.join(', ');
        });
        setHeadersList(groupedArray);
      });
  }, [productCatalogSelected, savedHeaders]);
  const getFirstFieldId = () => {
    return headersList.length > 0 ? headersList[0].id : null;
  };
  const getLastFieldId = () => {
    return headersList.length > 0
      ? headersList[headersList.length - 1].id
      : null;
  };

  const handleDeleteHeaders = () => {
    if (selectedIds.length > 0) {
      const newSelectedIds = selectedIds
        .map((element: any) => {
          if (element.includes(',')) {
            return element.split(',').map((id: any) => Number(id.trim()));
          }
          return Number(element);
        })
        .flat();
      const dataToDelete = newSelectedIds.map((si) => ({
        id: si,
        headerOrder: null,
        headerName: null,
        parentHeaderId: null,
        headerSeparator: null,
        icon: null,
      }));

      apiClient.post('/field', dataToDelete).then(() => {
        setSavedHeaders(!savedHeaders);
        setAlert({
          severity: 'success',
          message: 'Headers successfully deleted',
        });
      });
    }
  };
  const columns: GridColDef[] = [
    {
      field: 'headerNames',
      headerName: 'Header Name',
      width: 200,
    },
    {
      field: 'icon',
      headerName: 'Icon',
      width: 50,
      renderCell: (params: any) => {
        return (
          <Box>
            {iconOptions.map((iconO: any, index: any) =>
              index === params.row.icon ? (
                <MenuItem key={index} value={index}>
                  {iconO}
                </MenuItem>
              ) : null,
            )}
          </Box>
        );
      },
    },
    {
      field: 'headerstechnicalIds',
      headerName: 'Field ',
      width: 300,
    },
    {
      field: 'id',
      headerName: 'Order ',
      width: 300,
      renderCell: (params: any) => {
        return (
          <Stack spacing={1} direction="row">
            {getFirstFieldId() === params.row.id && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => handleOrderChangeBottom(params)}
              >
                <SouthIcon />
              </Button>
            )}
            {getLastFieldId() === params.row.id && (
              <Button
                size="small"
                variant="outlined"
                onClick={() => handleOrderChangeTop(params)}
                sx={{ ml: 9 }}
              >
                <NorthIcon />
              </Button>
            )}

            {params.row.id !== getFirstFieldId() &&
              params.row.id !== getLastFieldId() && (
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => handleOrderChangeBottom(params)}
                  >
                    <SouthIcon />
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => handleOrderChangeTop(params)}
                  >
                    <NorthIcon />
                  </Button>
                </>
              )}
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(undefined);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alert]);
  return (
    <Stack direction="column" spacing={3}>
      <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
        Headers{' '}
      </Typography>
      <>
        <Header />
        <Box display="flex" justifyContent="center">
          <Button
            sx={{ ml: 2 }}
            onClick={handleHeadersSubmit}
            variant="outlined"
            startIcon={<AddIcon />}
          >
            Add header
          </Button>
        </Box>
        {headersList.length === 0 && (
          <Alert severity="error" sx={{ m: 2 }}>
            No header is inserted, please insert headers
          </Alert>
        )}
      </>
      {headersList.length > 0 && (
        <>
          {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
          <Button
            disabled={selectedIds.length === 0}
            sx={{ m: 2 }}
            onClick={() => {
              setOpenConfirm(true);
            }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Delete selection
          </Button>
          <DataGrid
            onSelectionModelChange={(ids: any) => {
              setSelectedIds(ids);
            }}
            autoHeight
            rows={headersList}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </>
      )}
      <ConfirmDialog
        title="Confirm deleting Headers"
        open={openConfirm}
        setOpen={setOpenConfirm}
        onConfirm={handleDeleteHeaders}
      >
        <Typography>
          This action will delete the selected headers. Are you sure ?
        </Typography>
      </ConfirmDialog>
    </Stack>
  );
}
