import React from 'react';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PublicNewsSkeleton from '../PublicNewsSkeleton/PublicNewsSkeleton';
import { PublicNews } from '../types';
import NewRibbon from '../../shared/NewRibbon/NewRibbon';

interface PublicNewsListProps {
  loading: boolean;
  isEmpty: boolean;
  posts: PublicNews[];
  onPostSelect: (postId: number) => void;
}

export default function PublicNewsList({
  loading = true,
  isEmpty = false,
  posts,
  onPostSelect,
}: PublicNewsListProps) {
  return (
    <div>
      <Grid container spacing={1} p={1}>
        {posts.length === 0 &&
          Array(8)
            .fill(null)
            .map((v, i) => (
              <Grid key={i} item lg={6} sx={{ width: '100%' }}>
                {loading && <PublicNewsSkeleton />}
              </Grid>
            ))}
        {isEmpty && (
          <Grid item sx={{ width: '100%' }}>
            <Box p={2}>
              <Typography textAlign="center" color="primary" variant="h5">
                No public news available for the current filter criteria
              </Typography>
            </Box>
          </Grid>
        )}
        {posts.map((news: PublicNews) => (
          <Grid key={news.id} item lg={6} sx={{ width: '100%' }}>
            <Paper elevation={3} onClick={() => onPostSelect(news.id)}>
              <CardActionArea sx={{ p: 2, minHeight: 240 }}>
                <NewRibbon postDate={news.date} postType={'updates'} />
                <Stack direction="row" spacing={2} alignItems="center">
                  <Box>
                    <Typography
                      variant="h6"
                      component="h3"
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2,
                      }}
                    >
                      {news.title}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'gray' }}>
                      {new Intl.DateTimeFormat('en-GB', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      }).format(new Date(news.date))}
                    </Typography>
                  </Box>
                </Stack>
                <Typography
                  variant="body2"
                  sx={{
                    display: '-webkit-box',
                    overflow: 'hidden',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 3,
                    fontStyle: 'italic',
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  {news.excerpt}
                </Typography>
                <Typography variant="body2" sx={{ color: 'gray' }}>
                  {'Read full article on: '}
                  <a href={news.url} target="_blank">
                    {news.source}
                  </a>
                </Typography>
              </CardActionArea>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
