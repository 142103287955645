import { useEffect, useState } from 'react';
import moment from 'moment';
import Box from '@mui/material/Box';
import {
  Button,
  Chip,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { INegotiation } from '../../../types';
import RowsSkeleton from '../../shared/RowsSkeleton/RowsSkeleton';
import ArrowForwardSharpIcon from '@mui/icons-material/ArrowForwardSharp';
import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import DiamondIcon from '@mui/icons-material/Diamond';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import NegotiationStatus from '../TableViewList/NegotiationStatus/NegotiationStatus';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router';
import { useNav } from '../../NavProvider';
import { EmptyRow } from '../../ui/mui/EmptyRow/EmptyRow';
import NegotiationPptDownload from './NegotiationPptDownload';
import { useSearchParams } from 'react-router-dom';
const Item = styled(Paper)(({ theme }: any) => ({
  backgroundColor: 'transparent',
  ...theme.typography.subtitle2,
  padding: theme.spacing(1),
  textAlign: 'center',
  boxShadow: 'none',
  borderRadius: 0,
}));
export const getPosition = (
  negotiation: any,
  headerValue: any,
  quarterValue: any,
) => {
  const start = new Date(negotiation.startDate);
  const end = new Date(negotiation.endDate);
  const formatting = 'Q[Q]Y';
  const lastQuarter = moment(quarterValue).add(3, 'Q');
  let isBefore,
    isAfter: boolean = false;
  if (moment(start).isBefore(quarterValue, 'Q')) {
    start.setFullYear(quarterValue.year());
    start.setMonth(quarterValue.month());
    start.setDate(1);
    isBefore = true;
  }

  if (moment(end).isAfter(lastQuarter, 'Q')) {
    end.setFullYear(lastQuarter.year());
    end.setMonth(lastQuarter.endOf('quarter').month());
    end.setDate(moment(end).daysInMonth());
    isAfter = true;
  }

  const period = moment(end).diff(moment(start), 'days') + 1;
  const quarterIndex = headerValue.indexOf(moment(start).format(formatting));

  const monthIndex = moment(start).month() % 3;
  const dayIndex = (moment(start).date() - 1) % moment(end).daysInMonth();

  const width = Math.floor(((period > 0 ? period : 0) * 100) / 365);
  const marginLeft = Math.floor(
    25 * quarterIndex + 8.5 * monthIndex + 0.26 * dayIndex,
  );
  return { width: width <= 100 ? width : 100, marginLeft, isBefore, isAfter };
};
export default function RoadmapViewList({
  negotiations,
  isLoading,
  filter,
  setNegotiationId,
}: {
  isLoading: boolean;
  negotiations: INegotiation[];
  filter: any;
  setNegotiationId: (id: number | null) => void;
}) {
  interface HeadCell {
    name: string;
    label: string;
    sortBy?: string;
    px?: number;
  }
  const navigate = useNavigate();
  const { supplierId } = useNav();
  const pathPrefix = supplierId ? `/suppliers/${supplierId}` : '/domains';
  const headCells: HeadCell[] = [
    { name: 'group', label: 'Group', px: 0.5 },
    { name: 'category', label: 'Category', px: 0.5 },
    { name: 'region', label: 'Region', px: 0.5 },
    { name: 'natcos', label: 'Natcos', px: 0.5 },
    { name: 'title', label: 'Title', px: 0.5 },
    { name: 'isHot', label: 'Hot', px: 0.5 },
    { name: 'isTcap', label: 'TC@P', px: 0.5 },
    { name: 'supplier', label: 'Supplier', px: 0.5 },
    { name: 'status', label: 'Status', px: 2 },
  ];

  const formatting = 'Q[Q]Y';

  const [quarterStart, setQuarterStart] = useState<any>(
    moment(new Date()).startOf('quarter').subtract(1, 'Q'),
  );

  const [header, setHeader] = useState<string[]>(
    Array(4)
      .fill(null)
      .map((e, i) => moment(quarterStart).add(i, 'Q').format(formatting)),
  );
  const [positions, setPositions] = useState<any[]>([]);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const calculatePositions = (headerValue: any, quarterValue: any) =>
    (negotiations || []).map((negotiation) =>
      getPosition(negotiation, headerValue, quarterValue),
    );

  const updateView = (shift: number) => {
    const header = Array(4)
      .fill(null)
      .map((e, i) =>
        moment(quarterStart)
          .add(i + shift, 'Q')
          .format(formatting),
      );

    const quarter = moment(quarterStart).add(shift, 'Q');
    const positions = calculatePositions(header, quarter);

    setHasNext(positions.some((p) => p.isAfter));
    setHasPrevious(positions.some((p) => p.isBefore));
    setPositions(positions);
    setHeader(header);
    setQuarterStart(quarter);
  };

  useEffect(() => {
    if (negotiations) {
      const quarter = moment(new Date())
        .startOf('quarter')
        .subtract(1, 'quarter');
      setQuarterStart(quarter);
      setHeader(
        Array(4)
          .fill(null)
          .map((e, i) => moment(quarter).add(i, 'Q').format(formatting)),
      );
      const positions = calculatePositions(header, quarterStart);

      setHasNext(positions.some((p) => p.isAfter));
      setHasPrevious(positions.some((p) => p.isBefore));
      setPositions(positions);
    }
  }, [negotiations]);

  return (
    <Grid container sx={{ mt: -2, ml: 0.2 }}>
      <Grid item xs={12}>
        <Stack direction="row" justifyContent="space-between" spacing={1} p={1}>
          <Button
            variant="outlined"
            disabled={!hasPrevious}
            startIcon={<ArrowBackSharpIcon />}
            fullWidth
            onClick={() => {
              updateView(-1);
            }}
          >
            Previous Quarter
          </Button>
          <Button
            variant="outlined"
            endIcon={<ArrowForwardSharpIcon />}
            fullWidth
            disabled={!hasNext}
            onClick={() => {
              updateView(1);
            }}
          >
            Next Quarter
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={8}>
        <TableContainer component="div">
          <Table aria-label="collapsible table">
            <TableHead
              sx={(theme) => ({ backgroundColor: theme.palette.grey[300] })}
            >
              <TableRow>
                {headCells.map((cell) => (
                  <TableCell sx={{ px: cell.px || 1, py: 1 }} key={cell.name}>
                    {cell.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <RowsSkeleton headLength={9} />
              ) : (
                <>
                  {negotiations.length > 0 ? (
                    negotiations.map((negotiation) => (
                      <TableRow
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                          const search = searchParams.get('search');
                          navigate(search ? `${pathPrefix}/negotiations/${negotiation.id}?search=${search}` : `${pathPrefix}/negotiations/${negotiation.id}`);
                          setNegotiationId(negotiation.id);
                        }}
                        key={negotiation.id}
                      >
                        <TableCell sx={{ px: 0.5 }}>
                          {negotiation.groups
                            .map((group) => group.code)
                            .join(',')}
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }}>
                          {negotiation.category.code}
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }}>
                          <Tooltip
                            title={negotiation.regions
                              .map((region) => region.name)
                              .sort()
                              .join(' ,')}
                          >
                            <Typography noWrap sx={{ maxWidth: 100 }}>
                              {negotiation.regions
                                .map((region) => region.name)
                                .sort()
                                .join(' ,')}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }}>
                          <Tooltip
                            title={negotiation.natcos
                              .map((natco) => natco.name)
                              .sort()
                              .join(' ,')}
                          >
                            <Typography noWrap sx={{ maxWidth: 100 }}>
                              {negotiation.natcos
                                .map((natco) => natco.code)
                                .sort()
                                .join(' ,')}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }}>
                          <Tooltip title={negotiation.title}>
                            <Typography noWrap sx={{ maxWidth: 200 }}>
                              {negotiation.title}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }}>
                          {negotiation.isHot && (
                            <LocalFireDepartmentIcon color="primary" />
                          )}
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }}>
                          {negotiation.isTcap && (
                            <DiamondIcon color="primary" />
                          )}
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }}>
                          <Tooltip
                            title={negotiation.suppliers
                              .map((supplier) => supplier.name)
                              .sort()
                              .join(' ,')}
                          >
                            <Typography noWrap sx={{ maxWidth: 100 }}>
                              {negotiation.suppliers
                                .map((supplier) => supplier.name)
                                .sort()
                                .join(' ,')}
                            </Typography>
                          </Tooltip>
                        </TableCell>
                        <TableCell sx={{ px: 0.5 }}>
                          <NegotiationStatus value={negotiation.status} />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <EmptyRow
                      colSpan={11}
                      message="No data was found for the selected criteria"
                    />
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          sx={(theme) => ({
            backgroundColor: theme.palette.grey[300],
          })}
          height="41px"
        >
          {header.map((h) => (
            <Grid sx={{ borderRight: '1px solid black' }} item xs={3}>
              <Item>{h}</Item>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ position: 'relative' }}>
          <div
            style={{
              margin: 0,
              padding: 0,
              zIndex: 1,
              width: '100%',
              position: 'absolute',
              top: 0,

              backgroundImage:
                'linear-gradient(90deg, rgb(114 114 114 / 38%) 0.5px, transparent 1px)',
              backgroundSize: '8.3%',
            }}
          >
            <TableContainer component="div">
              <Table aria-label="collapsible table">
                <TableBody>
                  {isLoading ? (
                    <RowsSkeleton headLength={4} />
                  ) : (
                    <>
                      {positions.length > 0 ? (
                        positions.map((p, i) => (
                          <TableRow key={i}>
                            <TableCell sx={{ px: 0.5 }}>
                              <div
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  top: -10,
                                  position: 'relative',
                                  backgroundImage:
                                    'linear-gradient(0deg, rgba(0,40,159,1) 0%, rgba(0,40,159,1) 50%, rgba(255,255,255,0) 51%, rgba(255,255,255,0) 51%)',
                                  width: `${p.width}%`,
                                  marginLeft: `${p.marginLeft}%`,
                                }}
                              >
                                <Chip
                                  style={{
                                    backgroundColor: 'transparent',
                                    width: '0%',
                                  }}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell sx={{ px: 0.5 }}>
                            <Chip
                              style={{
                                backgroundColor: 'transparent',
                                width: '0%',
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Box>
      </Grid>
      <Grid item xs={8}></Grid>
      <Grid item xs={4}>
        <Box sx={{ p: 1 }}>
          <NegotiationPptDownload
            filter={filter}
            header={header}
            quarterValue={quarterStart}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
