import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Skeleton,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import apiClient from '../../services/apiClient';
import { useNav } from '../NavProvider';

function ExecutiveSkeleton() {
  return (
    <Grid padding={0.5} sm={6}>
      <Card sx={{ border: '1px solid lightgrey' }}>
        <CardHeader
          avatar={
            <Avatar sx={{ width: 87, height: 87 }} aria-label="recipe">
              <></>
            </Avatar>
          }
          subheader={<Skeleton height={10} width="30%" />}
          title={<Skeleton height={10} width="50%" />}
        ></CardHeader>
        <Divider />
        <CardContent>
          <Skeleton height={10} width="100%" />
          <Skeleton height={10} width="100%" />
          <Skeleton height={10} width="30%" />
        </CardContent>
      </Card>
    </Grid>
  );
}

export default function Executive({
  filters,
  setCount,
}: {
  filters: {
    supplierId: number | null;
    page: number | null;
    perPage: number | null;
  };
  setCount: (count: number) => void;
}) {
  const [executives, setExecutives] = useState([]);
  const { supplierId, page, perPage } = filters;
  const [isLoading, setIsLoading] = useState(true);
  const { supplier } = useNav();

  useEffect(() => {
    if (supplierId) {
      setIsLoading(true);
      apiClient
        .get(`executives`, {
          params: {
            suppliers: supplierId,
            page,
            perPage,
          },
        })
        .then(({ data }) => {
          setCount(data.pagination.total);
          setExecutives(data.records);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [supplierId, page, perPage]);

  return (
    <Box sx={{ p: 2, pt: 0.5 }}>
      {!isLoading && (
        <Box sx={{ p: 1 }}>
          <Typography color={'primary'}>EXECUTIVE PROFILES</Typography>
          <Divider />
        </Box>
      )}

      <Grid container sx={{ mt: 2 }}>
        {isLoading &&
          Array(6)
            .fill(null)
            .map((element, index) => <ExecutiveSkeleton key={index} />)}
        {!isLoading &&
          (executives || []).map((executive: any, i: number) => {
            return (
              <Grid padding={0.5} sm={6} key={i}>
                <Card sx={{ height: '98%', border: '1px solid lightgrey' }}>
                  <CardHeader
                    sx={{ p: 1 }}
                    avatar={
                      supplier && (
                        <Avatar
                          src={`${
                            process.env.REACT_APP_API_ENTRYPOINT
                          }/api/executives/avatars/${encodeURIComponent(
                            `${executive.image}`,
                          )}`}
                          sx={{
                            width: 87,
                            height: 87,
                            border: '0.1px solid lightgray',
                          }}
                          variant="circular"
                        />
                      )
                    }
                    title={
                      <Box
                        sx={{
                          typography: 'body1',
                          textTransform: 'uppercase',
                          fontWeight: '500',
                        }}
                      >
                        {executive.name}
                      </Box>
                    }
                    subheader={
                      <Typography sx={{ fontSize: '11px' }}>
                        {executive?.position}
                      </Typography>
                    }
                  ></CardHeader>
                  <Box sx={{ p: 1 }}>
                    <Divider sx={{ background: 'red' }} />
                  </Box>
                  <CardContent sx={{ p: 1 }}>
                    <Typography
                      sx={{
                        fontWeight: '400',
                        fontSize: '12px',
                        textAlign: 'justify',
                      }}
                    >
                      {executive?.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        {!isLoading && (executives || []).length === 0 && (
          <>
            <Typography sx={{ textAlign: 'center' }} variant="h6">
              No executive profiles available for {supplier?.name}
            </Typography>
          </>
        )}
      </Grid>
    </Box>
  );
}
