import { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Benchmark from './Benchmark';
import { ListItemIcon, Typography } from '@mui/material';
import SustainabilityIcon from '../shared/Icons/SustainabilityIcon';
import Logo from '../../images/sustainability.jpg';
import {
  Bot,
} from '../MarketResearchChatbot/MarketReasearchChatbot';

export default function Sustainability() {
  const [isBenchmark, setIsBenchmark] = useState<boolean>(true);
  return (
    <>
      <Stack direction="row" alignItems="center" gap={1} sx={{ p: 1 }}>
        <ListItemIcon
          sx={{
            minWidth: 0,
          }}
          className={'icon-selected'}
        >
          <SustainabilityIcon />
        </ListItemIcon>
        <Typography component="h6" variant="h6">
          Sustainability
        </Typography>
      </Stack>
      <Divider />

      <Stack direction="row" justifyContent="space-between" sx={{ m: 2 }}>
        <ButtonGroup variant="contained" aria-label="full width button group">
          <Button
            sx={{ flex: 1 }}
            variant={isBenchmark ? 'contained' : 'outlined'}
            onClick={() => setIsBenchmark(true)}
          >
            Sustainability Benchmark
          </Button>
          <Button
            variant={!isBenchmark ? 'contained' : 'outlined'}
            onClick={() => setIsBenchmark(false)}
          >
            Sustainability ChatBot
          </Button>
        </ButtonGroup>
      </Stack>

      {isBenchmark && <Benchmark />}
      {!isBenchmark && (
        <Bot
          open={!isBenchmark}
          setOpen={()=> {}}
          onClose={() => {
            setIsBenchmark(true);
          }}
          id={2}
          title={"Sustainability"}
          icon={<SustainabilityIcon />}
          logo={Logo}
        />
      )}
    </>
  );
}
