import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '../../../ui/mui/DialogTitle/DialogTitle';
import StarRating from './StarRating/StarRating';

export interface FeedbackDialogProps {
  onClose: () => void;
}

export default function FeedbackDialog({ onClose }: FeedbackDialogProps) {
  const [isRating, setIsRating] = useState<boolean>(false);

  return (
    <Dialog
      onClose={onClose}
      open
      maxWidth="sm"
      sx={{ textAlign: 'center' }}
      fullWidth
    >
      <DialogTitle onClose={onClose}>Send us a feedback</DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        {isRating ? (
          <StarRating />
        ) : (
          <List
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          >
            <ListItem disablePadding>
              <ListItemButton onClick={() => setIsRating(true)}>
                <ListItemText
                  sx={{ textAlign: 'center' }}
                  primary="How do you like the website?"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  window.location.href =
                    'mailto:my@buyin.pro?subject=Report an error:';
                }}
              >
                <ListItemText
                  sx={{ textAlign: 'center' }}
                  primary="Report an error"
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding color="primary">
              <ListItemButton
                onClick={() => {
                  window.location.href =
                    'mailto:my@buyin.pro?subject=Request help:';
                }}
              >
                <ListItemText
                  sx={{
                    textAlign: 'center',
                  }}
                  primary="Request help"
                />
              </ListItemButton>
            </ListItem>
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
}
