import React, { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import newsClient from '../../../services/newsClient';
import { Referential } from '../../../types';
import { useNav } from '../../NavProvider';
import NewsList from '../NewsList/NewsList';
import BuyinNewsShow from '../BuyinNewsShow/BuyinNewsShow';
import { POST_TYPES } from '../data';

export default function BuyinNewsList() {
  const { groups, isReady } = useNav();
  const navigate = useNavigate();
  const [news, setNews] = useState([]);
  const [error, setError] = useState<string | null>();
  const match = useMatch('/:tab/:id');
  const postId = match?.params.id;
  const { trackPageView } = useMatomo();

  useEffect(() => {
    if (error || !isReady) return;
    newsClient
      .post('/posts', {
        post_type: [POST_TYPES.BUYIN_NEWS],
        group: groups.map((group: Referential) => group.id),
      })
      .then((response) => {
        setNews(response.data);
      })
      .catch(({ response }) => {
        setError(
          response?.data?.Message ||
            response?.data?.detail ||
            response?.data?.title ||
            'An error occurred, please try again',
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, isReady]);

  const handlePostSelect = (postId: number) => {
    trackPageView({
      documentTitle: 'Home/BuyinNews_Detail',
      href:`${window.location.origin}/Home/BuyinNews_Detail`
    });
    navigate(`/buyin-news/${postId}`);
  };

  const handlePostDialogClose = () => {
    navigate(`/buyin-news`);
  };

  if (error) {
    return (
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <Alert severity="error">{error}</Alert>
        <Button
          onClick={() => setError(null)}
          startIcon={<RefreshIcon />}
          variant="contained"
          sx={{ mt: 1 }}
        >
          Try again
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      <NewsList
        loading={news.length === 0}
        isEmpty={false}
        posts={news}
        onPostSelect={handlePostSelect}
      />
      {postId && (
        <BuyinNewsShow id={Number(postId)} onClose={handlePostDialogClose} />
      )}
    </Box>
  );
}
