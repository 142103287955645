import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import SupportIcon from '@mui/icons-material/Support';
import FeedbackDialog from './FeedbackDialog/FeedbackDialog';
import GetAppDialog from './GetAppDialog/GetAppDialog';
import FaqDialog from './FaqDialog/FaqDialog';
import { useAuth } from '../../AuthProvider';
import redLogo from '../../../images/red-logo.png';
import SearchInput from './SearchInput/SearchInput';
import { useLocation } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmailIcon from '@mui/icons-material/Email';

const DIALOGS = {
  FEEDBACK: 1,
  GET_APP: 2,
  FAQ: 3,
};

interface StyledAppBarProps extends MuiAppBarProps {
  open: boolean;
}

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<StyledAppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

interface AppBarProps extends StyledAppBarProps {
  onMenuClick: (event: React.SyntheticEvent) => void;
}

export default function AppBar({ open, onMenuClick }: AppBarProps) {
  const [dialog, setDialog] = React.useState<null | number>();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );
  const location = useLocation();
  const { logout, profile } = useAuth();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <StyledAppBar position="fixed" open={open}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <IconButton
          color="inherit"
          onClick={onMenuClick}
          edge="start"
          sx={{ mr: 2 }}
        >
          {location.pathname.startsWith('/supplier-spend-financials') ? (
            <ArrowBackIcon />
          ) : (
            <MenuIcon />
          )}
        </IconButton>
        <Box sx={{ flexGrow: 0.2 }}>
          <img
            src={redLogo}
            alt="BuyIn"
            style={{ maxHeight: 55, padding: 5 }}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <SearchInput />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          <Tooltip title="Contact us">
            <IconButton
              size="large"
              color="inherit"
              onClick={() => {
                window.location.href =
                  'mailto:my@buyin.pro?subject=About mybuyin.pro:';
              }}
            >
              <EmailIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Get mobile app">
            <IconButton
              size="large"
              color="inherit"
              onClick={() => setDialog(DIALOGS.GET_APP)}
            >
              <PhoneIphoneIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="FAQ">
            <IconButton
              size="large"
              color="inherit"
              onClick={() => setDialog(DIALOGS.FAQ)}
            >
              <SupportIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Button
          variant="text"
          sx={{ color: 'white', textTransform: 'none', marginLeft: '10px' }}
          startIcon={<AccountCircleIcon />}
          onClick={handleOpenUserMenu}
        >
          {profile?.firstName} {profile?.lastName}
        </Button>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          <MenuItem onClick={handleCloseUserMenu}>
            <Stack direction="row" spacing={2} onClick={logout}>
              <LogoutIcon />
              <Typography textAlign="center">Logout</Typography>
            </Stack>
          </MenuItem>
        </Menu>
      </Toolbar>
      {dialog === DIALOGS.FEEDBACK && (
        <FeedbackDialog onClose={() => setDialog(null)} />
      )}
      {dialog === DIALOGS.GET_APP && (
        <GetAppDialog onClose={() => setDialog(null)} />
      )}
      {dialog === DIALOGS.FAQ && <FaqDialog onClose={() => setDialog(null)} />}
    </StyledAppBar>
  );
}
