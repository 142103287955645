export function capitalizeName(name: string): string {
  const splitName = name.toLowerCase().split(' ');
  const namesUpper = [];

  for (const n of splitName) {
    namesUpper.push(n[0].toUpperCase() + n.slice(1));
  }

  return namesUpper.join(' ');
}

export function nfdSearch(text: string, query: string): boolean | string {
  return (
    text &&
    text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .includes(
        (query || '')
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase(),
      )
  );
}
