import React, { useState, useEffect } from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import moment from 'moment';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import EmailIcon from '@mui/icons-material/Email';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import DiamondIcon from '@mui/icons-material/Diamond';
import ShareIcon from '@mui/icons-material/Share';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { INegotiation, Referential } from '../../../../types';
import apiClient from '../../../../services/apiClient';
import DialogTitle from '../../../ui/mui/DialogTitle/DialogTitle';
import NegotiationStatus from '../NegotiationStatus/NegotiationStatus';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

function ColumnRow({
  name,
  value,
}: {
  name: string;
  value?: number | string | JSX.Element;
}) {
  return (
    <>
      {value && (
        <TableRow>
          <TableCell width={80} variant="head">
            {name}
          </TableCell>
          <TableCell variant="body">{value}</TableCell>
        </TableRow>
      )}
    </>
  );
}

function ReferentialRow({
  list,
  label,
}: {
  list?: Referential[];
  label: string;
  reference?: string;
}) {
  return (
    <>
      {!!list?.length && (
        <TableRow>
          <TableCell width={80} variant="head">
            {label}
          </TableCell>
          <TableCell variant="body">
            <Grid container>
              <Grid item xs={12} sm={12} md={12} xl={8}>
                {list.map((referential, index) => (
                  <Tooltip key={index} title={referential.name}>
                    <Chip
                      key={index}
                      label={referential.code}
                      variant="filled"
                      sx={{ m: 0.25 }}
                      size="small"
                    />
                  </Tooltip>
                ))}
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

function NegotiationSkeleton() {
  return (
    <>
      {Array(5)
        .fill(null)
        .map((e, i) => (
          <TableRow key={i}>
            <TableCell variant="head" width={80} sx={{ p: 2 }}>
              <Skeleton animation="wave" height={10} width="100%" />
            </TableCell>
            <TableCell variant="body" sx={{ p: 2 }}>
              <Skeleton animation="wave" height={10} width="80%" />
            </TableCell>
          </TableRow>
        ))}
      <TableRow>
        <TableCell>
          <Skeleton variant="circular">
            <Avatar />
          </Skeleton>
        </TableCell>
        <TableCell>
          <Skeleton animation="wave" height={10} width="10%" />
          <Skeleton animation="wave" height={10} width="5%" />
          <Skeleton animation="wave" height={10} width="5%" />
        </TableCell>
      </TableRow>
    </>
  );
}

export default function NegotiationDetails({
  negotiationId,
  setNegotiationId,
  onClose,
}: {
  negotiationId: number | null | undefined;
  setNegotiationId: (id?: number | null) => void;
  onClose: () => void;
}) {
  const { trackPageView } = useMatomo();
  const useStyles = makeStyles((theme: Theme) => ({
    backDrop: {
      backdropFilter: 'blur(3px)',
    },
  }));
  const classes = useStyles();
  const [negotiation, setNegotiation] = useState<INegotiation | null>();
  useEffect(() => {
    if (negotiationId) {
      apiClient
        .get(`/negotiations/${negotiationId}`)
        .then((response) => {
          setNegotiation(response?.data?.record);
          trackPageView({
            documentTitle: 'Programs/Program_Detail',
            href:`${window.location.origin}/Programs/Program_Detail`
          });
        })
        .catch((error) => {
          // TODO: handle error response
          console.log(error);
        });
    }
  }, [negotiationId, trackPageView]);

  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%' } }}
      open={!!negotiationId}
      maxWidth="lg"
      fullWidth
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle
        actions={{
          onClickBack: negotiation?.parentId
            ? () => {
                setNegotiation(null);
                setNegotiationId(negotiation?.parentId);
              }
            : undefined,
        }}
        onClose={() => {
          setNegotiation(null);
          onClose();
        }}
      >
        <Typography sx={{ p: 1, fontWeight: 'bold' }}>
          {!negotiation ? (
            <Skeleton width="30%" height={10} />
          ) : (
            negotiation?.title
          )}
        </Typography>
        {negotiation?.parentId ? (
          <></>
        ) : (
          <IconButton
            aria-label="close"
            disabled={!negotiation}
            href={`mailto:?subject=${negotiation?.title}&body=Hey, I´d like to share this link with you %0A${process.env.REACT_APP_MOBILE_API}/deeplink/programs/${negotiation?.id}`}
            sx={{
              position: 'absolute',
              right: 50,
              top: 8,
              color: (theme) => theme.palette.error.light,
            }}
          >
            <ShareIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ p: 2 }} dividers>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableBody>
              {!negotiation ? (
                <NegotiationSkeleton />
              ) : (
                <>
                  <ColumnRow
                    name="Description"
                    value={negotiation?.description}
                  />
                  <ColumnRow name="Id" value={negotiation?.id} />
                  <ReferentialRow
                    label="Groups scope"
                    list={negotiation?.groups}
                  />
                  <ReferentialRow
                    label="Regions scope"
                    list={negotiation?.regions}
                  />
                  <ReferentialRow
                    label="Natcos scope"
                    list={negotiation?.natcos}
                  />
                  <ColumnRow
                    name="Status"
                    value={<NegotiationStatus value={negotiation?.status} />}
                  />
                  <ColumnRow
                    name="Supplier"
                    value={negotiation?.suppliers
                      ?.map((supplier) => supplier.name)
                      .join(', ')}
                  />
                  <ColumnRow
                    name="Indicative timing"
                    value={`${moment(negotiation?.startDate).format(
                      'MMMYY',
                    )} until
                  ${moment(negotiation?.endDate).format('MMMYY')}`}
                  />
                  {negotiation?.isHot && (
                    <ColumnRow
                      name="Hot"
                      value={<LocalFireDepartmentIcon color="primary" />}
                    />
                  )}
                  {negotiation?.isTcap && (
                    <ColumnRow
                      name="TC@P"
                      value={<DiamondIcon color="primary" />}
                    />
                  )}
                  {negotiation?.projects.length > 0 && (
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        Projects
                      </TableCell>
                      <TableCell>
                        <List sx={{ marginLeft: '-23px' }}>
                          {negotiation?.projects.map((project) => (
                            <ListItem key={project.id}>
                              <ListItemIcon>
                                <ArrowRightIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={`${project.title} (${project.id})`}
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                  setNegotiation(null);
                                  setNegotiationId(project.id);
                                }}
                              />
                            </ListItem>
                          ))}
                        </List>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction="row" alignItems="center" spacing={3} paddingY={2}>
          {negotiation?.contacts?.map((contact) => (
            <React.Fragment key={contact.id}>
              <Avatar
                alt={`${contact.firstName} ${contact.lastName}`}
                src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${contact.email}`}
                sx={{ width: 87, height: 87 }}
              />
              <Typography>
                {contact.firstName} {contact.lastName}
                <br />
                {contact.jobTitle}
                <br />
                {contact.mobile}
                <br />
                {contact.fixed}
                <br />
                <Stack direction="row" gap={1}>
                  <Typography
                    component="a"
                    href={`mailto:${contact.email}`}
                    sx={{ cursor: 'pointer' }}
                    color="primary"
                  >
                    <EmailIcon />
                  </Typography>
                  {contact.email}
                </Stack>
              </Typography>
            </React.Fragment>
          ))}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
