import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

export default function FeedPanelSkeleton() {
  return (
    <Paper elevation={1}>
      <Skeleton
        variant="rectangular"
        height={200}
        sx={{ mb: 2 }}
        color="primary"
      />
      <Divider />
      {[1, 2, 3].map((key) => (
        <React.Fragment key={key}>
          <Box p={1}>
            <Skeleton />
            <Skeleton width="30%" />
            <Skeleton width="40%" />
          </Box>
          {key < 3 && <Divider />}
        </React.Fragment>
      ))}
    </Paper>
  );
}
