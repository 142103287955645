import React, { useEffect, useState } from 'react';
import { useMatch } from 'react-router';
import Box from '@mui/material/Box';
import Layout from '../Layout/Layout';
import TabPanel from '../ui/mui/TabPanel/TabPanel';
import FeedTab from './FeedTab/FeedTab';
import ContactList from '../ContactList/ContactList';
import ContractList from '../ContractList/ContractList';
import UpdateList from '../news/UpdateList/UpdateList';
import PayloadProvider from '../news/PayloadProvider';
import StrategyList from '../news/StrategyList/StrategyList';
import { POST_TYPES } from '../news/data';
import NegotiationList from '../NegotiationList/NegotiationList';
import { NavigationFiltersProvider } from '../Layout/NavigationFiltersProvider/NavigationFiltersProvider';
import ToolsTab from './ToolsTab/ToolsTab';
import Sustainability from '../Sustainability/Sustainability';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

function DomainTabs() {
  const match = useMatch('/domains/:tab/*');
  const matchedTab: string = match?.params.tab || 'feed';
  const [reclick, setReClick] = useState(false);
  const { trackPageView } = useMatomo();


  const changeReClickVal = () => {
    setReClick(false);
  };

  const capitalizeFirstLetter = (word: any) => {
    if (!word) return '';
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  useEffect((() => {
    if (matchedTab === "negotiations") {
      trackPageView({
        documentTitle: 'Programs/Programs',
        href: `${window.location.origin}/` + 'Programs/Programs'
      });
    } else {
      trackPageView({
        documentTitle: `${capitalizeFirstLetter(matchedTab)}/${capitalizeFirstLetter(matchedTab)}`,
        href: `${window.location.origin}/` + `${capitalizeFirstLetter(matchedTab)}/${capitalizeFirstLetter(matchedTab)}`
      });
    }


  }), [matchedTab])



  return (

    <NavigationFiltersProvider matchedTab={matchedTab}>
      <Box sx={{ width: '100%' }}>
        <TabPanel value={matchedTab} index="feed" >
          <FeedTab />
        </TabPanel>
        <TabPanel value={matchedTab} index="updates" >
          <PayloadProvider type={POST_TYPES.UPDATES} fromSearch={false}>
            <UpdateList />
          </PayloadProvider>
        </TabPanel>
        <TabPanel value={matchedTab} index="negotiations">
          <NegotiationList />
        </TabPanel>
        <TabPanel value={matchedTab} index="contracts">
          <ContractList />
        </TabPanel>
        <TabPanel value={matchedTab} index="strategies">
          <PayloadProvider type={POST_TYPES.UPDATES} fromSearch={false}>
            <StrategyList />
          </PayloadProvider>
        </TabPanel>
        <TabPanel value={matchedTab} index="contacts" >
          <ContactList />
        </TabPanel>

        <TabPanel value={matchedTab} index="sustainability">
          <Sustainability />
        </TabPanel>

        <TabPanel value={matchedTab} index="tools">
          <ToolsTab reclick={reclick} changeReClickVal={changeReClickVal} />
        </TabPanel>
      </Box>
    </NavigationFiltersProvider>


  );
}

export default function DomainPage() {
  return (
    <Layout noBreadcrumbs>
      <DomainTabs />
    </Layout>
  );
}
