import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import {
  INegotiationFilter,
  INegotiationMetaData,
  Status,
} from '../../../types';
import DialogSelect from '../../ui/mui/DialogSelect/DialogSelect';
import { useNav } from '../../NavProvider';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Chip, TextField } from '@mui/material';
import { useAuth } from '../../AuthProvider';
import { useFilters } from '../../Layout/NavigationFiltersProvider/NavigationFiltersProvider';
import DiamondIcon from '@mui/icons-material/Diamond';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';

import { NEGOTIATION_STATUSES } from '../../../data';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import RecordSelect from '../../shared/RecordSelect/RecordSelect';

export default function NegotiationFilters({
  metaData,
  filters,
  onFiltersChange,
}: {
  metaData?: INegotiationMetaData | null;
  filters?: INegotiationFilter;
  onFiltersChange: (filter: any) => void;
}) {
  const [statuses, setStatuses] = useState<any>(metaData?.statuses);
  const [minValue, setMinValue] = useState<number>(metaData?.tco?.min);
  const [maxValue, setMaxValue] = useState<number>(metaData?.tco?.max);
  const [minStartDate, setMinStartDate] = useState<Date | null>(
    new Date(metaData?.startDate?.min),
  );
  const [maxStartDate, setMaxStartDate] = useState<Date | null>(
    new Date(metaData?.startDate?.max),
  );
  const [minEndDate, setMinEndDate] = useState<Date | null>(
    new Date(metaData?.endDate?.min),
  );
  const [maxEndDate, setMaxEndDate] = useState<Date | null>(
    new Date(metaData?.endDate?.max),
  );
  const [tco, setTco] = useState<number[]>([]);
  const [isHot, setIsHot] = useState<boolean>(false);
  const [isTcap, setIsTcap] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);
  // const { supplierId } = useNav();
  const { profile } = useAuth();
  const { resetTrigger, setOtherFilterValues, otherFilterValues } =
    useFilters();
  const [filterValues, setFilterValues] = useState<any>(null);

  const [statusesInitialValues, setStatusesInitialValues] = useState<string[]>([
    '0',
    '1',
  ]);
  const [searchParams, setSearchParams] = useSearchParams();

  // const [regions, setRegions] = useState<any>([]);
  const [natcos, setNatcos] = useState<any>([]);
  // const [regionInitialValues, seetRegionInitialValues] = useState<any>([]);
  const [natcoInitialValues, setNatcoInitialValues] = useState<any>([]);
  const [searchValues, setSearchValues] = useState<any>();

  const handleStatusChange = (selection: string[]) => {
    setStatusesInitialValues(selection);
    setFilterValues([
      ...(filterValues || []).filter(
        ({ dimension }: { dimension: string }) => dimension !== 'Status',
      ),
      ...selection.map((s) => ({
        dimension: 'Status',
        label: NEGOTIATION_STATUSES.find((st) => st.value === Number(s))?.name,
        id: s,
      })),
    ]);

    onFiltersChange({
      ...filters,
      statuses: selection.length > 0 ? selection.join(',') : null,
    });
  };

  // const handleRegionChange = (f: any) => {

  //   seetRegionInitialValues(f.regions?.split(',') || []);
  //   setFilterValues([
  //       ...(filterValues || []).filter(({ dimension }: { dimension: string }) => dimension !== 'Region')]
  //       .concat(
  //           (f.regions?.split(',') || [])
  //               .map((regionId: any) => ({
  //                   dimension: 'Region',
  //                   label: regions.find((region:any) => region.id === Number(regionId))?.code,
  //                   id: regionId
  //               })),
  //       ));
  //   onFiltersChange({ ...filters, ...f });
  // }

  const handleNatcoChange = (f: any) => {
    setNatcoInitialValues(f.natcos?.split(',') || []);
    setFilterValues(
      [
        ...(filterValues || []).filter(
          ({ dimension }: { dimension: string }) => dimension !== 'Natco',
        ),
      ].concat(
        (f.natcos?.split(',') || []).map((natcoId: any) => ({
          dimension: 'Natco',
          label: natcos.find((natco: any) => natco.id === Number(natcoId))
            ?.code,
          id: natcoId,
        })),
      ),
    );
    onFiltersChange({ ...filters, ...f });
  };

  const handleHotChange = () => {
    setIsHot(!isHot);
    setFilterValues(
      !isHot
        ? [
            ...(filterValues || []).filter(
              ({ dimension }: { dimension: string }) => dimension !== 'Hot',
            ),
            {
              dimension: 'Hot',
              label: '',
              icon: <LocalFireDepartmentIcon color="primary" />,
              id: 'HOT',
            },
          ]
        : [
            ...(filterValues || []).filter(
              ({ dimension }: { dimension: string }) => dimension !== 'Hot',
            ),
          ],
    );
    onFiltersChange({ ...filters, isHot: !isHot || null });
  };

  const handleTcapChange = () => {
    setIsTcap(!isTcap);

    setFilterValues(
      !isTcap
        ? [
            ...(filterValues || []).filter(
              ({ dimension }: { dimension: string }) => dimension !== 'TC@P',
            ),
            {
              dimension: 'TC@P',
              label: '',
              icon: <DiamondIcon color="primary" />,
              id: 'TCAP',
            },
          ]
        : [
            ...(filterValues || []).filter(
              ({ dimension }: { dimension: string }) => dimension !== 'TC@P',
            ),
          ],
    );

    onFiltersChange({ ...filters, isTcap: !isTcap || null });
  };

  const handleTcoChange = (event: any, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setTco(newValue as number[]);
    }
  };

  const handleTcoChangeCommitted = (event: any, value: any) => {
    setFilterValues(
      (filterValues || [])
        .filter(({ dimension }: { dimension: string }) => dimension !== 'TCO')
        .concat(
          value?.length === 2
            ? [
                {
                  dimension: 'TCO',
                  label: '[' + value[0] + ',' + value[1] + ']',
                  id: 'TCO',
                },
              ]
            : [],
        ),
    );

    onFiltersChange({
      ...filters,
      minTco: value[0] || null,
      maxTco: value[1] || null,
    });
  };

  const handleDateChange = (value: any, key: string) => {
    let date = value ? new Date(value) : null;
    // const dimension = key === 'startDate' ? 'Start Date': 'End Date'
    date?.setDate(1);

    setFilterValues(
      [
        ...(filterValues || []).filter(({ id }: { id: string }) => id !== key),
      ].concat(
        value
          ? [
              {
                dimension: key == 'startDate' ? 'Start Date' : 'End Date',
                label: moment(date).format('MMM yyyy'),
                id: key,
              },
            ]
          : [],
      ),
    );

    onFiltersChange({
      ...filters,
      [key]: date,
    });
  };

  useEffect(() => {
    if (resetTrigger) {
      setMinStartDate(null);
      setMaxStartDate(null);
      setStartDate(null);
      setMinEndDate(null);
      setMaxEndDate(null);
      setEndDate(null);
      setTco([]);
      setMinValue(0);
      setMaxValue(0);
      setIsTcap(false);
      setIsHot(false);
      setOtherFilterValues(null);
      setFilterValues(null);
      setStatusesInitialValues([]);
      setNatcoInitialValues([]);
      // seetRegionInitialValues([]);

      onFiltersChange({
        startDate: null,
        endDate: null,
        suppliers: null,
        minTco: null,
        maxTco: null,
        isHot: null,
        isTcap: null,
        domains: null,
        categories: null,
        groups: null,
        regions: null,
        natcos: null,
      });
    }
  }, [resetTrigger]);

  useEffect(() => {
    const initialStatusesFilterValues = [
      ...['0', '1'].map((s) => ({
        dimension: 'Status',
        label: NEGOTIATION_STATUSES.find((st) => st.value === Number(s))?.name,
        id: s,
      })),
    ];

    setFilterValues(initialStatusesFilterValues);
  }, [searchParams]);

  const deleteValue = (id: string, dimension: string) => {
    switch (id) {
      case 'HOT':
        {
          handleHotChange();
        }
        break;
      case 'TCAP':
        {
          handleTcapChange();
        }
        break;
      case 'TCO':
        {
          handleTcoChange(null, [minValue, maxValue]);
          handleTcoChangeCommitted(null, []);
        }
        break;
      case 'startDate':
        {
          setStartDate(null);
          handleDateChange(null, 'startDate');
        }
        break;
      case 'endDate':
        {
          setStartDate(null);
          handleDateChange(null, 'endDate');
        }
        break;
      default: {
        if (dimension === 'Status') {
          handleStatusChange(
            (statusesInitialValues || []).filter(
              (status: string) => status !== id,
            ),
          );
        }
        // if (dimension === 'Region') {
        //   handleRegionChange(
        //     {
        //         ...filters,
        //         regions: (regionInitialValues || []).filter((r: string) => r !== id).join(',') || null
        //     });
        // }
        if (dimension === 'Natco') {
          handleNatcoChange({
            ...filters,
            natcos:
              (natcoInitialValues || [])
                .filter((natcoId: string) => natcoId !== id)
                .join(',') || null,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (searchParams.get('search') !== null) {
      setSearchValues(
        <React.Fragment>
          <Chip
            onDelete={() => {
              console.log('delete');
              searchParams.delete('search');
              setSearchParams(searchParams);
            }}
            label={`search:${searchParams.get('search')} `}
            sx={{ m: 0.5 }}
          />
        </React.Fragment>,
      );
    } else {
      setSearchValues(null);
    }
  }, [searchParams]);

  useEffect(() => {
    if (filterValues) {
      setOtherFilterValues(
        filterValues
          .map((filterValue: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <Chip
                  key={filterValue.id}
                  onDelete={() => {
                    deleteValue(filterValue.id, filterValue.dimension);
                  }}
                  label={`${filterValue.dimension} ${
                    filterValue.label && ' : ' + filterValue.label
                  } `}
                  icon={filterValue?.icon}
                  sx={{ m: 0.5 }}
                />
              </React.Fragment>
            );
          })
          .concat(searchValues),
      );
    }
  }, [filterValues, searchValues]);

  useEffect(() => {
    if (tco.length === 0 && metaData) {
      setMinValue(metaData?.tco?.min);
      setMaxValue(metaData?.tco?.max);
      setTco([metaData?.tco?.min, metaData?.tco?.max]);
    }
    setStatuses(metaData?.statuses);
    setMinStartDate(new Date(metaData?.startDate?.min));
    setMaxStartDate(new Date(metaData?.startDate?.max));
    setMinEndDate(new Date(metaData?.startDate?.min));
    setMaxEndDate(new Date(metaData?.startDate?.max));
    // eslint-disable-next-line
  }, [metaData, open]);

  return (
    <>
      {/* <Grid item xs={12} sm={6} md={4} xl={3}>

        <RecordSelect label='Region'
          reference='regions'
          source='name'
          filters={filters}
          setRecords={setRegions}
          filter={{ hasNegotiations: true }}
          onFiltersChange={handleRegionChange}
        />
      </Grid> */}
      <Grid item xs={12} sm={6} md={4} xl={3}>
        <RecordSelect
          label="Natco"
          reference="natcos"
          source="name"
          filters={filters}
          setRecords={setNatcos}
          filter={{ hasContracts: true }}
          onFiltersChange={handleNatcoChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={3}>
        <DialogSelect
          label={`Status (${
            (filters?.statuses?.split(',') || []).length || 'All'
          })`}
          title="Status"
          choices={(statuses || []).map(({ name, value }: Status) => ({
            name: name,
            value: `${value}`,
          }))}
          initialValues={statusesInitialValues}
          onChange={handleStatusChange}
          onOpen={setOpen}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            views={['year', 'month']}
            maxDate={maxStartDate}
            minDate={minStartDate}
            onChange={(value: any) => {
              setStartDate(value);
              handleDateChange(value, 'startDate');
            }}
            value={startDate}
            renderInput={(params: any) => (
              <TextField
                {...params}
                fullWidth={true}
                size="small"
                InputLabelProps={{
                  sx: {
                    color: 'primary.main',
                    fontSize: '15px',
                    textTransform: 'uppercase',
                    marginLeft: '-4px',
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="End date"
            views={['year', 'month']}
            maxDate={maxEndDate}
            minDate={minEndDate}
            onChange={(value: any) => {
              setEndDate(value);
              handleDateChange(value, 'endDate');
            }}
            value={endDate}
            renderInput={(params: any) => (
              <TextField
                {...params}
                fullWidth={true}
                size="small"
                InputLabelProps={{
                  sx: {
                    color: 'primary.main',
                    fontSize: '15px',
                    textTransform: 'uppercase',
                    marginLeft: '-4px',
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>

      {!profile?.isCustomer && (
        <Grid item xs={12} sm={6} md={4} xl={3}>
          <Typography align="center" sx={{ px: 1, height: 40 }}>
            Estimated TCO in million
            <Slider
              sx={{ mt: -2 }}
              size="small"
              min={minValue}
              max={maxValue}
              value={tco}
              onChange={handleTcoChange}
              onChangeCommitted={handleTcoChangeCommitted}
              valueLabelDisplay="auto"
            />
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={4} xl={3}>
        <Stack direction={'row'} spacing={1}>
          <Box>
            <Switch checked={isHot} onChange={handleHotChange} />
            Hot
            <Switch checked={isTcap} onChange={handleTcapChange} />
            TC@P
          </Box>
        </Stack>
      </Grid>
    </>
  );
}
