import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import newsClient from '../../../../services/newsClient';
import { Topic } from '../../types';
import DialogTitle from '../../../ui/mui/DialogTitle/DialogTitle';
import { makeStyles } from '@mui/styles';

interface TopicSelectProps {
  open: boolean;
  selected: Topic[];
  initialValues: number[];
  onClose: () => void;
  onChange: (topics: Topic[], close?: boolean) => void;
}

export default function TopicSelect({
  open,
  selected,
  initialValues,
  onClose,
  onChange,
}: TopicSelectProps) {
  const [topics, setTopics] = useState<Topic[]>([]);
  const [selection, setSelection] = useState<number[]>(
    initialValues.length > 0
      ? initialValues
      : selected.map((topic) => topic.id),
  );

  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: 'blur(3px)',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    newsClient
      .get('/topics.json')
      .then((response) => {
        setTopics(response.data);
        if (initialValues.length > 0) {
          onChange(
            response.data.filter((topic: any) =>
              initialValues.includes(topic.id),
            ),
          );
        }
      })
      .catch((error) => {
        // TODO: handle error response
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setSelection(
      initialValues.length > 0
        ? initialValues
        : selected.map((topic) => topic.id),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle onClose={onClose}>
        <Typography>&#8205;</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {topics.map((topic: Topic) => (
            <Grid key={topic.id} item xs={3}>
              <Button
                variant={
                  selection.includes(topic.id) ? 'contained' : 'outlined'
                }
                fullWidth
                onClick={() => {
                  if (selection.includes(topic.id)) {
                    setSelection(
                      selection.filter((topicId) => topicId !== topic.id),
                    );
                  } else {
                    setSelection([...selection, topic.id]);
                  }
                }}
              >
                {topic.name}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="success"
          onClick={() => {
            setSelection([]);
          }}
        >
          Clear
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onChange(topics.filter((topic) => selection.includes(topic.id)));
          }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
