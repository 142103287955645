import Chip from '@mui/material/Chip';
import { PRICE_TYPES_PRODUCT_CATALOG } from '../../../data';

interface PriceTypeProps {
  value?: number;
}

export default function FilterType({ value }: PriceTypeProps) {
  const priceType = PRICE_TYPES_PRODUCT_CATALOG.find(
    (type: any) => type.value === value,
  );
  return <Chip label={priceType?.name} />;
}
