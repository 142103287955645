import { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import newsClient from '../../../services/newsClient';
import apiClient from '../../../services/apiClient';
import { ALL, useNav } from '../../NavProvider';
import { usePayload } from '../PayloadProvider';
import NewsList from '../NewsList/NewsList';
import PublicNewsList from '../../PublicNews/PublicNewsList/PublicNewsList';
import UpdateShow from '../UpdateShow/UpdateShow';
import { POST_TYPES } from '../data';
import { Topic, Update } from '../types';
import TopicSelect from './TopicSelect/TopicSelect';
import PublicNewsShow from '../../PublicNews/PublicNewsShow/PublicNewsShow';

export default function UpdateList() {
  const { domainNodeId, supplierId, groupNodeId, setIsFrozen } = useNav();
  const { payload } = usePayload();
  const navigate = useNavigate();
  const [updates, setUpdates] = useState<Update[]>([]);
  const [publicNews, setPublicNews] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [isBuyinArticles, setIsBuyinArticles] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [hasNext, setHasNext] = useState<boolean | null>();
  const [openTopics, setOpenTopics] = useState<boolean>(false);
  const [topics, setTopics] = useState<Topic[]>([]);
  const pathPrefix = supplierId ? `/suppliers/${supplierId}` : '/domains';
  const match = useMatch(`${pathPrefix}/:tab/:id`);
  const matchTopics = useMatch(`${pathPrefix}/:tab/topics/:topics`);
  const topicParams = matchTopics?.params.topics;
  const postId = match?.params.id;
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get('search');

  const isLoaded = updates.length > 0 || publicNews.length > 0;

  useEffect(() => {
    if (!payload) return;

    setUpdates([]);
    setPublicNews([]);
    setLoading(true);
    if (isBuyinArticles) {
      newsClient
        .post('/posts', {
          post_type: [POST_TYPES.UPDATES],
          page,
          itemsPerPage: 21,
          search,
          ...payload,
          topic: (topicParams?.split(',') || []).map((p) => Number(p)),
        })
        .then((response) => {
          setUpdates((response.data || []).slice(0, 20));
          setIsEmpty(response.data?.length <= 0);
          setHasNext(response.data?.length > 20);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          // TODO: handle error response
        });
    } else {
      apiClient
        .get('/news', {
          params: {
            page: page,
            perPage: 21,
            search,
            subcategory: payload.product.join(','),
            topic: (topicParams?.split(',') || []).map((p) => Number(p)),
            suppliers: payload.vendor.join(','),
          },
        })
        .then((response) => {
          setPublicNews((response.data.records || []).slice(0, 20));
          setIsEmpty(response.data.records?.length <= 0);
          setHasNext(response.data.records?.length > 20);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          // TODO: handle error response
        });
    }
  }, [page, payload, isBuyinArticles, topicParams, searchParams]);

  useEffect(() => {
    setPage(1);
  }, [payload, topics, isBuyinArticles]);

  const newSearchParams = createSearchParams();
  if (domainNodeId && domainNodeId !== ALL) {
    newSearchParams.set('navDomain', domainNodeId);
  } else {
    newSearchParams.delete('navDomain');
  }
  if (groupNodeId && groupNodeId !== ALL) {
    newSearchParams.set('navGroup', groupNodeId);
  } else {
    newSearchParams.delete('navGroup');
  }

  const handleTopicsChange = (newTopics: Topic[], close: boolean = false) => {
    if (topics !== newTopics) {
      setTopics(newTopics);
      navigate({
        pathname:
          newTopics.length > 0
            ? `${pathPrefix}/updates/topics/${newTopics
                .map((topic) => topic.id)
                .join(',')}`
            : `${pathPrefix}/updates/`,
        search: newSearchParams.toString(),
      });
    }
    setOpenTopics(!close ? false : openTopics);
  };

  const handlePostSelect = (postId: number) => {
    setIsFrozen(true);
    const search = searchParams.get('search');
    navigate(
      search
        ? `${pathPrefix}/updates/${postId}?search=${search}`
        : `${pathPrefix}/updates/${postId}`,
    );
  };

  const handlePostDialogClose = () => {
    const search = searchParams.get('search');
    if (search) {
      newSearchParams.set('search', search);
    }
    navigate({
      pathname:
        topics.length > 0
          ? `${pathPrefix}/updates/topics/${topics
              .map((topic) => topic.id)
              .join(',')}`
          : `${pathPrefix}/updates/`,
      search: newSearchParams.toString(),
    });
    setIsFrozen(false);
  };

  const Paginator = (
    <Stack direction="row" justifyContent="space-between" spacing={1} p={1}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIosIcon />}
        disabled={!isLoaded || page <= 1}
        onClick={() => setPage(page - 1)}
        fullWidth
      >
        Previous
      </Button>
      <Button
        variant="outlined"
        endIcon={<ArrowForwardIosIcon />}
        disabled={!isLoaded || !hasNext}
        onClick={() => setPage(page + 1)}
        fullWidth
      >
        Next
      </Button>
    </Stack>
  );

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <ButtonGroup sx={{ m: 2 }}>
          <Button
            variant={isBuyinArticles ? 'contained' : 'outlined'}
            onClick={() => setIsBuyinArticles(true)}
          >
            Buyin Articles
          </Button>
          <Button
            variant={!isBuyinArticles ? 'contained' : 'outlined'}
            onClick={() => setIsBuyinArticles(false)}
          >
            Public news
          </Button>
        </ButtonGroup>

        {!supplierId && (
          <>
            <Button
              sx={{ m: 2 }}
              variant="outlined"
              onClick={() => {
                setOpenTopics(true);
              }}
            >
              Topics filter
            </Button>
            <TopicSelect
              open={openTopics}
              selected={topics}
              initialValues={(topicParams?.split(',') || []).map((topicId) =>
                Number(topicId),
              )}
              onClose={() => setOpenTopics(false)}
              onChange={handleTopicsChange}
            />
          </>
        )}
      </Stack>
      <Divider />
      {topics.map((topic) => (
        <Chip
          key={topic.id}
          onDelete={() => {
            navigate({
              pathname:
                topics.filter((t) => t.id !== topic.id).length > 0
                  ? `${pathPrefix}/updates/topics/${topics
                      .filter((t) => t.id !== topic.id)
                      .map((topic) => topic.id)
                      .join(',')}`
                  : `${pathPrefix}/updates/`,
              search: newSearchParams.toString(),
            });
            setTopics(topics.filter((t) => t.id !== topic.id));
          }}
          label={topic.name}
          sx={{ m: 0.5 }}
        />
      ))}
      {topics.length > 0 && <Divider />}
      {isBuyinArticles && (
        <NewsList
          loading={loading}
          isEmpty={isEmpty}
          posts={updates}
          onPostSelect={handlePostSelect}
          notFoundMessage="No updates available for the current filter criteria"
        />
      )}
      {!isBuyinArticles && (
        <PublicNewsList
          loading={loading}
          isEmpty={isEmpty}
          posts={publicNews}
          onPostSelect={handlePostSelect}
        />
      )}
      {Paginator}
      {postId && isBuyinArticles && (
        <UpdateShow
          id={Number(postId)}
          onClose={handlePostDialogClose}
          isRoot={true}
        />
      )}
      {postId && !isBuyinArticles && (
        <PublicNewsShow id={Number(postId)} onClose={handlePostDialogClose} />
      )}
    </Box>
  );
}
