import React, { useEffect, useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  ClickAwayListener,
  Divider,
  IconButton,
  keyframes,
  styled,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import CardContent from '@mui/material/CardContent';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AiBot from '../../../images/output.png';
import CloseIcon from '@mui/icons-material/Close';
import { QuestionInput } from '../../SustainabilityChatbot/QuestionInput';
import { ErrorCircleRegular } from '@fluentui/react-icons';
import MessageBox from '../../Sustainability/chat/MessageBox';
import { Answer } from '../../SustainabilityChatbot/Answer';
import styles from '../../Sustainability/chat/Chat.module.css';
import { Stack } from '@fluentui/react';
import HeaderLogo from '../../Sustainability/chat/HeaderLogo';
import apiClient from '../../../services/apiClient';
import { UserManager } from 'oidc-client';
import { authSettings } from '../../../data';
import { isEmpty } from 'lodash-es';
import StrategyShow from '../../news/StrategyShow/StrategyShow';
import UpdateShow from '../../news/UpdateShow/UpdateShow';
import BuyinNewsShow from '../../news/BuyinNewsShow/BuyinNewsShow';
import { useMatch } from 'react-router';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

const enum messageStatus {
  NotRunning = 'Not Running',
  Processing = 'Processing',
  Done = 'Done',
}

const moveBottom = keyframes`
  from {
    bottom: -100%;
    }
    to {
      bottom: 0;
  }
`;

const expandAnimation = keyframes`
  from {
    width: 45%;
    // top: calc(100vh - 450px);
    bottom: 0;

  }
  to {
    width: 95%;
    // top: 67px;
    bottom: 0;
  }
`;

const reduceAnimation = keyframes`
  from {
    width: 95%;
    top: 67px;
    bottom: 0;
  }
  to {
    bottom: 0;
    width: 45%;
    // top: calc(100vh - 450px);
  }
`;

const AnimatedCard = styled(Card)<{ isFirstOpen: boolean; expanded: boolean }>`
  animation: ${({ isFirstOpen, expanded }) =>
    isFirstOpen ? moveBottom : expanded ? expandAnimation : reduceAnimation}
    0.5s ease-in-out;
  animation-fill-mode: forwards;
  width: ${({ expanded }) => (expanded ? '95%' : '45%')};
`;

export default function MbiNewsChatBot() {
  const match = useMatch('/domains/:tab/*');
  const isAdminPage = useMatch('/admin/*');
  const matchedTab: string | undefined = match?.params.tab;
  const [open, setOpen] = React.useState(true);
  const [isDisplayed, setIsDisplayed] = React.useState(false);
  const [isExpanded, setIsExpanded] = React.useState(false);
  const [ASSISTANT, TOOL, ERROR] = ['assistant', 'tool', 'error'];
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeCitation, setActiveCitation] = React.useState<any>();
  const [articleId, setArticleId] = useState<number | null>(null);
  const [articleType, setArticleType] = useState<string | null>(null);
  const chatMessageStreamEnd = React.useRef<HTMLDivElement | null>(null);
  const [showLoadingMessage, setShowLoadingMessage] =
    React.useState<boolean>(false);
  const [processMessages, setProcessMessages] = React.useState<messageStatus>(
    messageStatus.NotRunning,
  );
  const [isCitationPanelOpen, setIsCitationPanelOpen] =
    React.useState<boolean>(false);
  const [messages, setMessages] = React.useState<any>([]);

  const abortFuncs = useRef([] as AbortController[]);
  const [botConfig, setBotConfig] = React.useState<any>();
  const [conversationId, setConversationId] = useState<string>();
  const [isFirstOpen, setIsFirstOpen] = useState(true);

  useEffect(() => {
    apiClient.get(`/mbnBots/1`).then((res: any) => {
      setBotConfig(res.data);
    });
  }, []);

  const parseCitationFromMessage = (message: any) => {
    if (message?.role && message?.role === 'tool') {
      try {
        const toolMessage = JSON.parse(message.content) as any;
        return toolMessage.citations;
      } catch {
        return [];
      }
    }
    return [];
  };

  const onShowCitation = (citation: any) => {
    const type = citation?.url?.replaceAll('//', '/')?.split('/')[3];
    switch (type) {
      case 'strategies':
        setArticleType('category-strategy');
        break;
      case 'updates':
        setArticleType('category-vendor-news');
        break;
      case 'buyin-news':
        setArticleType('buyin-news');
        break;
      default:
        setArticleType(null);
        break;
    }

    const urlArray = (citation?.url || '').split('/');
    const id =
      urlArray.length > 0 ? parseInt(urlArray[urlArray.length - 1]) : null;
    setArticleId(id);
    setActiveCitation(citation);
    setIsCitationPanelOpen(true);
  };

  const handleOpen = () => {
    setOpen(!open);
    setIsExpanded(false);
    setIsFirstOpen(true);
  };

  useEffect(() => {
    (async () => {
      if (processMessages === messageStatus.Done) {
        const chatHistory = messages.map((message: any) => {
          return {
            role: message.role,
            content: message.content,
          };
        });
        // await conversationApi(
        //   {
        //     botConfig,
        //     messages: chatHistory.filter((answer: any) => answer.role === 'user')
        //   },
        //   new AbortController().signal,
        //   false,
        // );
      }
    })();
  }, [processMessages]);
  useEffect(() => {
    if (open && chatMessageStreamEnd.current && messages.length > 0)
      chatMessageStreamEnd.current?.scrollIntoView({ behavior: 'auto' });
  }, [messages]);

  async function conversationApi(
    options: any,
    abortSignal: AbortSignal,
    streamFromAzureApi = true,
  ): Promise<Response> {
    const searchParams = new URLSearchParams();
    searchParams.append('streamFromAzureApi', streamFromAzureApi.toString());
    const userManager = new UserManager(authSettings);
    const user = await userManager.getUser();
    const response = await fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}/api/mbnBots/conversation?${searchParams}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user?.access_token}`,
        },
        body: JSON.stringify({
          ...options,
          conversationId,
        }),
        signal: abortSignal,
      },
    );

    return response;
  }

  const makeApiRequest = async (question: string) => {
    let assistantMessage = {} as any;
    let toolMessage = {} as any;
    let assistantContent = '';
    setIsLoading(true);
    setShowLoadingMessage(true);
    const abortController = new AbortController();
    abortFuncs.current.unshift(abortController);

    const userMessage: any = {
      role: 'user',
      content: question,
    };

    setMessages([...messages, userMessage]);

    const request: any = {
      botConfig,
      messages: [
        ...messages.filter((answer: any) => answer.role === 'user'),
        userMessage,
      ],
    };

    let result = {} as any;
    try {
      const response = await conversationApi(request, abortController.signal);
      if (!response.ok) {
        let errorChatMsg: any = {
          role: ERROR,
          content:
            "There was an error generating a response. Chat history can't be saved at this time. If the problem persists, please contact the site administrator.",
        };
        setMessages([...messages, userMessage, errorChatMsg]);
        setIsLoading(false);
        setShowLoadingMessage(false);
        abortFuncs.current = abortFuncs.current.filter(
          (a) => a !== abortController,
        );
        return;
      }
      if (response?.body) {
        const reader = response.body.getReader();
        let runningText = '';

        while (true) {
          setProcessMessages(messageStatus.Processing);
          const { done, value } = await reader.read();
          if (done) break;

          var text = new TextDecoder('utf-8').decode(value);
          const objects = text.split('\n');
          objects.forEach((obj) => {
            try {
              runningText += obj.replace('data:', '');

              result = JSON.parse(runningText);
              if (result.conversationId) {
                setConversationId(result.conversationId);
              }

              setShowLoadingMessage(false);
              result.choices[0].messages.forEach((resultMessage: any) => {
                if (resultMessage.role === ASSISTANT) {
                  assistantContent += resultMessage.content;
                  assistantMessage = resultMessage;
                  assistantMessage.content = assistantContent;
                }

                if (resultMessage.role === TOOL) {
                  toolMessage = resultMessage;
                }

                isEmpty(toolMessage)
                  ? setMessages([...messages, userMessage, assistantMessage])
                  : setMessages([
                      ...messages,
                      userMessage,
                      toolMessage,
                      assistantMessage,
                    ]);
              });

              runningText = '';
            } catch {}
          });
        }
      }
    } catch (e) {
      if (!abortController.signal.aborted) {
        let errorMessage =
          'An error occurred. Please try again. If the problem persists, please contact the site administrator.';
        if (result.error?.message) {
          errorMessage = result.error.message;
        } else if (typeof result.error === 'string') {
          errorMessage = result.error;
        }
        let errorChatMsg: any = {
          role: ERROR,
          content: errorMessage,
        };
        setMessages([...messages, errorChatMsg]);
      } else {
        setMessages([...messages, userMessage]);
      }
    } finally {
      setIsLoading(false);
      setShowLoadingMessage(false);
      abortFuncs.current = abortFuncs.current.filter(
        (a) => a !== abortController,
      );
      setProcessMessages(messageStatus.Done);
    }

    return abortController.abort();
  };

  useEffect(() => {
    setTimeout(() => {
      setIsDisplayed(true);
    }, 3000);
  }, []);

  return matchedTab === 'sustainability' || matchedTab === 'tools' || isAdminPage ? null : (
    <ClickAwayListener
      onClickAway={(e: any) => {
        if (open) {
          setOpen(false);
        }
      }}
    >
      <Box
        sx={{
          display: isDisplayed ? 'block' : 'none',
        }}
      >
        {!articleId ? null : (
          <>
            {articleType === 'category-vendor-news' && (
              <UpdateShow
                isRoot
                id={articleId}
                onClose={() => {
                  setArticleType(null);
                  setArticleId(null);
                }}
              />
            )}
            {articleType === 'category-strategy' && (
              <StrategyShow
                id={articleId}
                onClose={() => {
                  setArticleType(null);
                  setArticleId(null);
                }}
              />
            )}
            {articleType === 'buyin-news' && (
              <BuyinNewsShow
                id={articleId}
                onClose={() => {
                  setArticleType(null);
                  setArticleId(null);
                }}
              />
            )}
          </>
        )}

        <AnimatedCard
          isFirstOpen={isFirstOpen}
          expanded={isExpanded}
          sx={{
            position: 'fixed',
            top: isExpanded ? 67 : 'auto',
            right: 0,
            zIndex: (theme) => theme.zIndex.drawer -1,
            display: open ? 'block' : 'none',
            boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
          }}
        >
          <CardHeader
            style={{
              cursor: 'pointer',
            }}
            avatar={
              <Avatar
                className="icon-selected"
                sx={{
                  backgroundColor: grey[200],
                }}
              >
                <SmartToyIcon color="primary" />
              </Avatar>
            }
            action={
              //iconButton group
              <>
                <IconButton onClick={handleOpen}>
                  <CloseIcon />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setIsFirstOpen(false);
                    setIsExpanded(!isExpanded);
                  }}
                >
                  <OpenInFullIcon />
                </IconButton>
              </>
            }
            title="myBuyIn.pro chatbot"
          />
          <Divider
            sx={{
              width: '100%',
              backgroundColor: grey[200],
            }}
          />

          <CardContent
            sx={{
              p: 0,
              width: 'auto',
            }}
          >
            {!messages || messages.length < 1 ? (
              <HeaderLogo
                title={
                  'Ask me anything related to category strategies, news or updates'
                }
                height={
                  isExpanded ? 'calc(100vh - 250px)' : 'calc(100vh - 400px)'
                }
                logo={AiBot}
              />
            ) : (
              <Box
                component="div"
                sx={{
                  p: 1,
                  width: 'auto',
                  zIndex: (theme) => theme.zIndex.appBar + 1,
                  height: isExpanded
                    ? 'calc(100vh - 250px)'
                    : 'calc(100vh - 400px)',
                  overflow: 'hidden',
                  overflowX: 'hidden',
                  overflowY: 'auto',
                }}
              >
                {messages.map((answer: any, index: number) => (
                  <React.Fragment key={index}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent:
                          answer.role === 'user' ? 'flex-end' : 'flex-start',
                        p: 0.5,
                      }}
                    >
                      {answer.role === 'user' ? (
                        <MessageBox
                          direction="right"
                          isFirst={messages[index - 1]?.role !== 'user'}
                          isLast={messages[index + 1]?.role !== 'user'}
                          hasNext={messages[index + 1]?.role === 'user'}
                        >
                          {answer.content}
                        </MessageBox>
                      ) : answer.role === 'assistant' ? (
                        <MessageBox direction="left">
                          <Answer
                            answer={{
                              answer: answer.content,
                              citations: parseCitationFromMessage(
                                messages[index - 1],
                              ),
                            }}
                            onCitationClicked={(c) => onShowCitation(c)}
                          />
                        </MessageBox>
                      ) : answer.role === ERROR ? (
                        <div className={styles.chatMessageError}>
                          <Stack
                            horizontal
                            className={styles.chatMessageErrorContent}
                          >
                            <ErrorCircleRegular
                              className={styles.errorIcon}
                              style={{ color: 'rgba(182, 52, 67, 1)' }}
                            />
                            <span>Error</span>
                          </Stack>
                          <span className={styles.chatMessageErrorContent}>
                            {answer.content}
                          </span>
                        </div>
                      ) : null}
                    </Box>
                  </React.Fragment>
                ))}
                <div ref={chatMessageStreamEnd} />
              </Box>
            )}
            <Divider />
          </CardContent>
          <Box
            sx={{
              p: 1,
            }}
          >
            <QuestionInput
              isLoading={isLoading}
              placeholder="Type a new question..."
              disabledButton={isLoading || (messages && messages.length === 0)}
              clearOnSend
              onStopGeneration={() => {
                abortFuncs.current.forEach((a) => a.abort());
                setShowLoadingMessage(false);
                setIsLoading(false);
              }}
              onSend={(message) => {
                setIsLoading(true);
                makeApiRequest(message);
              }}
              disabled={false}
              onDeleteClick={() => {
                setMessages([]);
              }}
              displayChatHistoryButton={false}
            />
          </Box>
        </AnimatedCard>
        <img
          onClick={handleOpen}
          style={{
            position: 'fixed',
            bottom: 10,
            right: 10,
            cursor: 'pointer',
            zIndex: 9999,
            width: '75px',
            height: '75px',
            display: open ? 'none' : 'block',
          }}
          width={200}
          src={AiBot}
        />
      </Box>
    </ClickAwayListener>
  );
}
