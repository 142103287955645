import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import apiClient from '../../../../../services/apiClient';

const StyledRating = styled(Rating)(({ theme }) => ({
  fontSize: theme.spacing(7),
  '& .MuiRating-iconFilled': {
    color: theme.palette.primary.main,
  },
  '& .MuiRating-iconHover': {
    color: theme.palette.primary.main,
  },
}));

export default function StarRating() {
  const [rating, setRating] = useState<number | null>(5);
  const [comment, setComment] = useState<string>('');
  const [isRated, setIsRated] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const handleRatingSubmit = () => {
    setSubmitting(true);
    apiClient
      .post('/rating', { rating, comment })
      .then(() => {
        setIsRated(true);
        setSubmitting(false);
      })
      .catch(() => {
        setIsRated(true);
        setError(true);
        setSubmitting(false);
      });
  };

  if (error) {
    return <Alert severity="error">An error occurred, please try again</Alert>;
  }

  if (isRated) {
    return <Alert severity="success">Your rating is sent successfully</Alert>;
  }

  return (
    <Stack m={1} spacing={1} alignItems="center">
      <StyledRating
        value={rating}
        precision={0.5}
        onChange={(event, newValue) => setRating(newValue)}
        size="large"
      />
      <TextField
        multiline
        rows={2}
        fullWidth
        label="Optional comment"
        value={comment}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setComment(event.target.value)
        }
      />
      <Button
        disabled={submitting}
        variant="contained"
        onClick={handleRatingSubmit}
      >
        Rate with {rating} stars
      </Button>
    </Stack>
  );
}
