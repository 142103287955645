export default function MarketIntelligencePackIcon({
  className,
}: {
  className?: string;
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      zoomAndPan="magnify"
      viewBox="0 0 56.879997 56.999997"
      height="75"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="4190cf888a">
          <path
            d="M 13 15 L 43 15 L 43 42 L 13 42 Z M 13 15 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="14b73e283d">
          <path
            d="M 43.667969 15.050781 L 40.957031 43.835938 L 13.613281 41.261719 L 16.324219 12.476562 Z M 43.667969 15.050781 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="37a5fdbbba">
          <path
            d="M 43.667969 15.050781 L 40.957031 43.835938 L 13.613281 41.261719 L 16.324219 12.476562 Z M 43.667969 15.050781 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="83e2dfb9c8">
          <path
            d="M 13 15 L 43 15 L 43 42 L 13 42 Z M 13 15 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="ae9addd648">
          <path
            d="M 43.667969 15.285156 L 40.957031 44.070312 L 13.613281 41.492188 L 16.324219 12.710938 Z M 43.667969 15.285156 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="74a3908ff4">
          <path
            d="M 43.667969 15.285156 L 40.957031 44.070312 L 13.613281 41.492188 L 16.324219 12.710938 Z M 43.667969 15.285156 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#4190cf888a)">
        <g clip-path="url(#14b73e283d)">
          <g clip-path="url(#37a5fdbbba)">
            <path
              fill="#ffffff"
              d="M 23.425781 34.21875 C 25.25 36.128906 27.65625 37.308594 30.296875 37.558594 C 33.242188 37.835938 36.121094 36.929688 38.402344 35.003906 C 40.683594 33.078125 42.097656 30.367188 42.378906 27.367188 C 42.664062 24.371094 41.78125 21.441406 39.898438 19.125 C 36.011719 14.347656 29.019531 13.6875 24.308594 17.660156 C 22.027344 19.582031 20.613281 22.292969 20.332031 25.292969 C 20.078125 27.984375 20.769531 30.609375 22.269531 32.796875 L 14.160156 39.636719 C 13.964844 39.796875 13.855469 40.027344 13.835938 40.261719 C 13.8125 40.496094 13.878906 40.738281 14.039062 40.9375 C 14.355469 41.328125 14.929688 41.382812 15.3125 41.054688 Z M 25.464844 19.078125 C 29.402344 15.757812 35.25 16.308594 38.5 20.308594 C 41.746094 24.304688 41.1875 30.261719 37.25 33.582031 C 35.339844 35.191406 32.933594 35.949219 30.472656 35.71875 C 28.007812 35.488281 25.785156 34.292969 24.210938 32.355469 C 20.960938 28.355469 21.523438 22.402344 25.464844 19.078125 Z M 25.464844 19.078125 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
          </g>
        </g>
      </g>
      <g clip-path="url(#83e2dfb9c8)">
        <g clip-path="url(#ae9addd648)">
          <g clip-path="url(#74a3908ff4)">
            <path
              fill="#ffffff"
              d="M 23.425781 34.449219 C 25.25 36.359375 27.65625 37.542969 30.296875 37.792969 C 33.242188 38.070312 36.121094 37.160156 38.402344 35.238281 C 40.683594 33.3125 42.097656 30.601562 42.378906 27.601562 C 42.664062 24.601562 41.78125 21.675781 39.898438 19.359375 C 36.011719 14.578125 29.019531 13.921875 24.308594 17.890625 C 22.027344 19.816406 20.613281 22.527344 20.332031 25.527344 C 20.078125 28.214844 20.769531 30.84375 22.269531 33.03125 L 14.160156 39.867188 C 13.964844 40.03125 13.855469 40.257812 13.835938 40.492188 C 13.8125 40.730469 13.878906 40.972656 14.039062 41.167969 C 14.355469 41.5625 14.929688 41.613281 15.3125 41.289062 Z M 25.464844 19.3125 C 29.402344 15.992188 35.25 16.542969 38.5 20.539062 C 41.746094 24.539062 41.1875 30.492188 37.25 33.816406 C 35.339844 35.425781 32.933594 36.183594 30.472656 35.953125 C 28.007812 35.71875 25.785156 34.523438 24.210938 32.589844 C 20.960938 28.589844 21.523438 22.632812 25.464844 19.3125 Z M 25.464844 19.3125 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
