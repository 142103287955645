import { useEffect, useState } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import newsClient from '../../../services/newsClient';
import { ALL, useNav } from '../../NavProvider';
import NewsList from '../NewsList/NewsList';
import StrategyShow from '../StrategyShow/StrategyShow';
import { POST_TYPES } from '../data';
import { usePayload } from '../PayloadProvider';
import { Strategy } from '../types';

export default function StrategyList() {
  const { domainNodeId, groupNodeId, supplierId, setIsFrozen } = useNav();
  const { payload } = usePayload();
  const navigate = useNavigate();
  const [strategies, setStrategies] = useState<Strategy[]>([]);
  const [hasNext, setHasNext] = useState<boolean | null>();
  const [page, setPage] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const pathPrefix = supplierId ? `/suppliers/${supplierId}` : '/domains';
  const match = useMatch(`${pathPrefix}/:tab/:id`);
  const [searchParams] = useSearchParams();
  const postId = match?.params.id;
  const isLoaded = strategies.length > 0;
  const search = searchParams.get('search');
  useEffect(() => {
    if (!payload) return;
    setStrategies([]);
    setLoading(true);
    newsClient
      .post('/posts', {
        post_type: [POST_TYPES.STRATEGIES],
        ...payload,
        page,
        itemsPerPage: 21,
        search,
      })
      .then((response) => {
        setStrategies((response.data || []).slice(0, 20));
        setIsEmpty(response.data?.length <= 0);
        setHasNext(response.data?.length > 20);
        setLoading(false);
      })
      .catch((error) => {
        // TODO: handle error response
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload, page, searchParams]);

  useEffect(() => {
    setPage(1);
  }, [payload]);

  const handlePostSelect = (postId: number) => {
    setIsFrozen(true);
    const search = searchParams.get('search');

    navigate(search ? `${pathPrefix}/strategies/${postId}?search=${search}` : `${pathPrefix}/strategies/${postId}`);
  };

  const handlePostDialogClose = () => {
    const newSearchParams = createSearchParams();
    if (domainNodeId && domainNodeId !== ALL) {
      newSearchParams.set('navDomain', domainNodeId);
    } else {
      newSearchParams.delete('navDomain');
    }
    if (groupNodeId && groupNodeId !== ALL) {
      newSearchParams.set('navGroup', groupNodeId);
    } else {
      newSearchParams.delete('navGroup');
    }
    const search = searchParams.get('search');
    if (search) {
      newSearchParams.set('search', search);
    }
    navigate({
      pathname: `${pathPrefix}/strategies`,
      search: newSearchParams.toString(),
    });
    setIsFrozen(false);
  };

  const Paginator = (
    <Stack direction="row" justifyContent="space-between" spacing={1} p={1}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIosIcon />}
        disabled={!isLoaded || page <= 1}
        onClick={() => setPage(page - 1)}
        fullWidth
      >
        Previous
      </Button>
      <Button
        variant="outlined"
        endIcon={<ArrowForwardIosIcon />}
        disabled={!isLoaded || !hasNext}
        onClick={() => setPage(page + 1)}
        fullWidth
      >
        Next
      </Button>
    </Stack>
  );

  return (
    <Box>
      <NewsList
        loading={loading}
        isEmpty={isEmpty}
        posts={strategies}
        notFoundMessage="No strategies available for the current filter criteria"
        onPostSelect={handlePostSelect}
      />
      {Paginator}
      {postId && (
        <StrategyShow id={Number(postId)} onClose={handlePostDialogClose} />
      )}
    </Box>
  );
}
