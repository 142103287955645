import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useMatch } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import apiClient from '../services/apiClient';
import { Supplier } from '../types';

export const ALL = 'all';

interface NavContextInterface {
  domains: Array<any>;
  groups: Array<any>;
  domain: any;
  group: any;
  supplierId: number | null;
  supplier: Supplier | null;
  domainNodeId: string | null;
  groupNodeId: string | null;
  loadedTree: boolean;
  isReady: boolean;
  isFrozen: boolean;
  setIsFrozen: (isFrozen: boolean) => void;
}

const initialNav: NavContextInterface = {
  domains: [],
  groups: [],
  domain: null,
  group: null,
  supplierId: null,
  supplier: null,
  domainNodeId: null,
  groupNodeId: null,
  loadedTree: false,
  isReady: false,
  isFrozen: false,
  setIsFrozen: () => {},
};

const NavContext = createContext<NavContextInterface>(initialNav);

export function searchNav(
  items: Array<any>,
  ids: Array<string>,
  isGroup: boolean = false,
) {
  const result = items.find((item) => item.id.toString() === ids[0]);
  const found: any = { id: result.id, code: result.code, name: result.name };
  if (!ids[1]) {
    return found;
  }
  const child = result[isGroup ? 'regions' : 'categories'].find(
    (item: any) => item.id.toString() === ids[1],
  );
  found[isGroup ? 'region' : 'category'] = {
    id: child.id,
    code: child.code,
    name: child.name,
  };
  if (!ids[2]) {
    return found;
  }
  const node = child[isGroup ? 'natcos' : 'subcategories'].find(
    (item: any) => item.id.toString() === ids[2],
  );
  found[isGroup ? 'region' : 'category'][isGroup ? 'natco' : 'subcategory'] = {
    id: node.id,
    code: node.code,
    name: node.name,
  };

  return found;
}

interface NavProviderProps {
  children: any;
}

export const NavProvider: FC<NavProviderProps> = ({ children }) => {
  const [nav, setNav] = useState(initialNav);
  const [supplier, setSupplier] = useState<Supplier | null>(null);
  const [isFrozen, setIsFrozen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const matchSupplier = useMatch('/suppliers/:supplierId/*');
  const supplierId = matchSupplier && Number(matchSupplier.params.supplierId);
  const navDomain = (searchParams.get('navDomain') || ALL).split('-');
  const navGroup = (searchParams.get('navGroup') || ALL).split('-');

  const isAllDomain = navDomain[0] === ALL;
  const isAllGroup = navGroup[0] === ALL;

  const isDomain = navDomain.length === 1;
  const isCategory = navDomain.length === 2;
  const isSubcategory = navDomain.length === 3;

  const isGroup = navGroup.length === 1;
  const isRegion = navGroup.length === 2;
  const isNatco = navGroup.length === 3;

  const isDomainSet =
    nav?.domain?.id === Number(navDomain[0]) && !nav.domain?.category;
  const isCategorySet =
    nav?.domain?.category?.id === Number(navDomain[1]) &&
    !nav.domain?.category?.subcategory;
  const isSubcategorySet =
    nav.domain?.category?.subcategory?.id === Number(navDomain[2]);

  const isGroupSet =
    nav?.group?.id === Number(navGroup[0]) && !nav.group?.region;
  const isRegionSet =
    nav?.group?.region?.id === Number(navGroup[1]) && !nav.group?.region?.natco;
  const isNatcoSet = nav.group?.region?.natco?.id === Number(navGroup[2]);

  useEffect(() => {
    if (isFrozen) return;
    if (nav.loadedTree) {
      const newNav: Partial<NavContextInterface> = {};

      if (
        (!isAllDomain && isDomain && !isDomainSet) ||
        (isCategory && !isCategorySet) ||
        (isSubcategory && !isSubcategorySet)
      ) {
        newNav.domain = searchNav(nav.domains, navDomain);
        newNav.domainNodeId = searchParams.get('navDomain');
      } else if (isAllDomain && nav.domain !== null) {
        newNav.domain = null;
        newNav.domainNodeId = null;
      }

      if (
        (!isAllGroup && isGroup && !isGroupSet) ||
        (isRegion && !isRegionSet) ||
        (isNatco && !isNatcoSet)
      ) {
        newNav.group = searchNav(nav.groups, navGroup, true);
        newNav.groupNodeId = searchParams.get('navGroup');
      } else if (isAllGroup && nav.group !== null) {
        newNav.group = null;
        newNav.groupNodeId = null;
      }
      if (!nav.isReady) {
        newNav.isReady = true;
      }
      if (Object.keys(newNav).length > 0) {
        setNav({ ...nav, ...newNav });
      }
    } else {
      apiClient
        .get('/navigation')
        .then((response) => {
          setNav({ ...nav, ...response.data, loadedTree: true });
        })
        .catch((error) => {
          // TODO: handle error response
          console.log(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nav, searchParams]);

  useEffect(() => {
    if (supplierId) {
      setSupplier(null);
      apiClient.get(`/suppliers/${supplierId}`).then(({ data }) => {
        setSupplier(data.record);
      });
    } else {
      setSupplier(null);
    }
  }, [supplierId]);

  return (
    <NavContext.Provider
      value={{
        ...nav,
        supplier,
        supplierId: matchSupplier && Number(matchSupplier.params.supplierId),
        isFrozen,
        setIsFrozen,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export function useNav() {
  const context = useContext<NavContextInterface>(NavContext);
  if (context === undefined) {
    throw new Error('NavContext must be used within a NavProvider');
  }

  return context;
}
