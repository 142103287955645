import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { SortDirection } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import TableSortLabel from '@mui/material/TableSortLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import Collapse from '@mui/material/Collapse';
import Pagination from '@mui/material/Pagination';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import debounce from '@mui/utils/debounce';
import visuallyHidden from '@mui/utils/visuallyHidden';
import apiClient from '../../services/apiClient';
import { Contact } from '../../types';
import { useNav } from '../NavProvider';
import { EmptyRow } from '../ui/mui/EmptyRow/EmptyRow';
import { useFilters } from '../Layout/NavigationFiltersProvider/NavigationFiltersProvider';
interface HeadCell {
  name: string;
  label?: string;
  sortBy?: string;
}

const headCells: HeadCell[] = [
  { name: 'contact', label: 'Contact', sortBy: 'lastName' },
  { name: 'department', label: 'Department', sortBy: 'department' },
  { name: 'details' },
];

const SkeletonRow = () => (
  <TableRow>
    <TableCell>
      <Stack
        direction="row"
        gap={3}
        alignItems="center"
        sx={{ paddingRight: 4 }}
      >
        <Skeleton variant="circular">
          <Avatar sx={{ width: 87, height: 87 }} />
        </Skeleton>
        <Box sx={{ width: '100%' }}>
          <Skeleton width="100%" />
          <Skeleton width="90%" />
        </Box>
      </Stack>
    </TableCell>
    <TableCell>
      <Skeleton width={150} />
    </TableCell>
    <TableCell>
      <Skeleton width={90} />
    </TableCell>
  </TableRow>
);

function Row(props: { row: Contact }) {
  const { row } = props;
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <TableRow onClick={() => setOpen(!open)} sx={{ cursor: 'pointer' }}>
        <TableCell sx={{ border: 0 }}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <Avatar
              alt={`${row.lastName} ${row.firstName}`}
              src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${row.email}`}
              sx={{ width: 87, height: 87 }}
            />
            <Typography>
              {row.lastName} {row.firstName}
              <br />
              {row.jobTitle}
            </Typography>
          </Stack>
        </TableCell>
        <TableCell sx={{ border: 0 }}>
          {row.category?.name ||
            row.subcategory?.name ||
            row.otherCategory ||
            row?.domain?.name}
        </TableCell>
        <TableCell sx={{ border: 0 }}>
          {open ? (
            <Stack direction="row">
              <Typography color="primary">Hide</Typography>
              <KeyboardArrowUpIcon color="primary" />
            </Stack>
          ) : (
            <Stack direction="row">
              <Typography color="primary">Details</Typography>
              <KeyboardArrowDownIcon color="primary" />
            </Stack>
          )}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={open ? null : { m: 0, p: 0 }} />
        <TableCell sx={open ? null : { m: 0, p: 0 }}>
          <Collapse in={open} timeout="auto">
            <Stack direction="row" gap={1}>
              <LocationOnIcon color="primary" />
              <Typography>
                {row.street} {row.postCode} {row.city}
              </Typography>
            </Stack>
          </Collapse>
        </TableCell>
        <TableCell sx={open ? null : { m: 0, p: 0 }}>
          <Collapse in={open} timeout="auto">
            <Stack direction="row" gap={1}>
              <PhoneIphoneIcon color="primary" />
              <Typography>{row.mobile}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <PhoneIcon color="primary" />
              <Typography>{row.fixed}</Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <EmailIcon color="primary" />
              <Typography>{row.email}</Typography>
            </Stack>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function ContactList() {
  const { supplierId } = useNav();
  const [contacts, setContacts] = useState([]);
  const [contactsCount, setContactsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page') || 1);
  const perPage = Number(searchParams.get('perPage') || 50);
  const orderBy = searchParams.get('sortBy') || 'lastName';
  const order: SortDirection =
    searchParams.get('order') === 'desc' ? 'desc' : 'asc';
  const isEmpty = !loading && !error && contacts.length === 0;
  const location = useLocation();
  const { domains, categories, subcategories, isReady, resetTrigger } =
    useFilters();
  useEffect(() => {
    setError(undefined);
    setLoading(true);
    setContacts([]);
    // const navDomain = searchParams.get('navDomain')?.split('-');
    if (isReady || location.pathname === '/contacts') {
      apiClient
        .get('/contacts', {
          params: {
            page,
            perPage,
            sortBy: orderBy,
            order: order.toUpperCase(),
            department: searchParams.get('department'),
            fullName: searchParams.get('contact'),
            domains:
              domains.length > 0 &&
              categories.length === 0 &&
              subcategories.length === 0
                ? domains.join(',')
                : null,
            categories:
              categories.length > 0 && subcategories.length === 0
                ? categories.join(',')
                : null,
            subcategories:
              subcategories?.length > 0 ? subcategories.join(',') : null,
            hasOtherCategory: location.pathname === '/contacts' || null,
            suppliers: supplierId ? String(supplierId) : null,
          },
        })
        .then((response) => {
          setContacts(response.data.records);
          setContactsCount(response.data.pagination.total);
          setLoading(false);
        })
        .catch((error) => {
          setError(error?.response?.data?.error || 'Something went wrong');
          setLoading(false);
        });
    }
  }, [
    domains,
    categories,
    subcategories,
    order,
    orderBy,
    page,
    perPage,
    searchParams,
    isReady,
    supplierId
  ]);

  useEffect(() => {
    if (resetTrigger) {
      apiClient
        .get('/contacts', {
          params: {
            page,
            perPage,
            sortBy: orderBy,
            order: order.toUpperCase(),
            department: searchParams.get('department'),
            fullName: searchParams.get('contact'),
            domains: null,
            categories: null,
            subcategories: null,
            groups: null,
            suppliers: supplierId ? String(supplierId) : null,
            hasOtherCategory: location.pathname === '/contacts' || null,
          },
        })
        .then((response) => {
          setContacts(response.data.records);
          setContactsCount(response.data.pagination.total);
          setLoading(false);
        })
        .catch((error) => {
          setError(error?.response?.data?.error || 'Something went wrong');
          setLoading(false);
        });
    }
  }, [resetTrigger]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    if (newPage > 1) {
      searchParams.set('page', newPage.toString());
    } else {
      searchParams.delete('page');
    }
    setSearchParams(searchParams);
  };

  const handleSortChange = (property: string) => {
    searchParams.set('sortBy', property);
    searchParams.set('order', order === 'desc' ? 'desc' : 'asc');
    setSearchParams(searchParams);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      searchParams.set(event.target.name, event.target.value);
    } else {
      searchParams.delete(event.target.name);
    }
    searchParams.delete('page');
    setSearchParams(searchParams);
  };

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        width="100%"
      >
        <Stack direction="row" gap={1} p={1}>
          <TextField
            label="Contact"
            name="contact"
            variant="filled"
            size="small"
            defaultValue={searchParams.get('contact')}
            onChange={debounce(handleFilterChange, 300)}
          />
          <TextField
            label="Department"
            name="department"
            variant="filled"
            size="small"
            defaultValue={searchParams.get('department')}
            onChange={debounce(handleFilterChange, 300)}
          />
        </Stack>
        <Pagination
          count={Math.ceil(contactsCount / perPage)}
          page={page}
          onChange={handlePageChange}
        />
      </Stack>
      <TableContainer component="div">
        <Table>
          <TableHead
            sx={(theme) => ({ backgroundColor: theme.palette.grey[300] })}
          >
            <TableRow>
              {headCells.map((cell) => (
                <TableCell
                  key={cell.name}
                  onClick={() => cell.sortBy && handleSortChange(cell.sortBy)}
                  sortDirection={orderBy === cell.sortBy ? order : false}
                >
                  {cell.sortBy && !isEmpty ? (
                    <TableSortLabel
                      active={orderBy === cell.sortBy}
                      direction={orderBy === cell.sortBy ? order : 'asc'}
                      onClick={() =>
                        cell.sortBy && handleSortChange(cell.sortBy)
                      }
                    >
                      {cell.label}
                      {orderBy === cell.sortBy ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    cell.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array(5)
                  .fill(null)
                  .map((v, i) => <SkeletonRow key={i} />)
              : contacts.map((row: Contact) => <Row key={row.id} row={row} />)}
            {isEmpty && (
              <EmptyRow colSpan={3} message="No contacts available" />
            )}
            {error && <EmptyRow colSpan={3} message={error} />}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" justifyContent="flex-end" spacing={2} p={2}>
        <Pagination
          count={Math.ceil(contactsCount / perPage)}
          page={page}
          onChange={handlePageChange}
        />
      </Stack>
    </Box>
  );
}
