import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import EmailIcon from '@mui/icons-material/Email';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import { Contract } from '../../../types';
import ContractStatus from '../ContractStatus/ContractStatus';
import apiClient from '../../../services/apiClient';
import { makeStyles } from '@mui/styles';
import { Skeleton, Theme } from '@mui/material';

function ContractSkeleton() {
  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          size="small"
          stickyHeader
        >
          <TableBody>
            {Array(5)
              .fill(null)
              .map((e, i) => (
                <TableRow key={i}>
                  <TableCell variant="head" width={80} sx={{ p: 2 }}>
                    <Skeleton animation="wave" height={10} width="100%" />
                  </TableCell>
                  <TableCell variant="body" sx={{ p: 2 }}>
                    <Skeleton animation="wave" height={10} width="80%" />
                  </TableCell>
                </TableRow>
              ))}
            <TableRow>
              <TableCell>
                <Skeleton variant="circular">
                  <Avatar />
                </Skeleton>
              </TableCell>
              <TableCell>
                <Skeleton animation="wave" height={10} width="10%" />
                <Skeleton animation="wave" height={10} width="5%" />
                <Skeleton animation="wave" height={10} width="5%" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default function ContractDetail(props: {
  handleContractDialogClose: any;
  contractId: number | undefined;
  isFeed: boolean;
}) {
  const navigate = useNavigate();
  const [contractDetails, setContractDetails] = useState<Contract>();
  const [contractToDisplay, setContractToDisplay] = useState<
    Contract | undefined
  >();
  const [isFeed] = useState<Boolean>(props.isFeed);
  const useStyles = makeStyles((theme: Theme) => ({
    backDrop: {
      backdropFilter: 'blur(3px)',
    },
  }));
  const classes = useStyles();

  const { trackPageView } = useMatomo();

  useEffect(() => {
    apiClient
      .get(`/contracts/${props.contractId}`)
      .then((response) => {
        setContractToDisplay(response.data.record);
        setContractDetails(response.data.record);
        trackPageView({
          documentTitle: 'Contracts/Contract_Detail',
        });
      })
      .catch((error) => {
        //TODO: handle error response
        console.log(error);
      });
  }, [props.contractId]);
  const changeDialogData = (
    contract: Contract | undefined,
    ToAmendment: boolean,
  ) => {
    if (isFeed) {
      if (contract?.parentId) {
        setContractToDisplay(contract);
      } else {
        setContractToDisplay(contractDetails);
      }
    } else {
      if (ToAmendment) {
        navigate(`/domains/contracts/${contract?.id}`);
      } else {
        navigate(`/domains/contracts/${contract?.parentId}`);
      }
    }
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={props.handleContractDialogClose}
        maxWidth="md"
        fullWidth
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <span> {contractToDisplay?.name}</span>
            <span>
              {!contractToDisplay?.parentId && (
                <IconButton onClick={props.handleContractDialogClose}>
                  <CloseIcon />
                </IconButton>
              )}

              {contractToDisplay?.parentId && (
                <ArrowBackIos
                  color="primary"
                  onClick={() =>
                    changeDialogData(
                      isFeed ? contractDetails : contractToDisplay,
                      false,
                    )
                  }
                  sx={{ cursor: 'pointer', marginLeft: '10px' }}
                />
              )}
            </span>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {contractToDisplay && (
            <>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table"
                  size="small"
                  stickyHeader
                >
                  <TableBody>
                    <TableRow sx={{ borderTop: 'groove' }}>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        Description
                      </TableCell>
                      <TableCell> {contractToDisplay?.description}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        Buyin Contract Id
                      </TableCell>
                      <TableCell>
                        {' '}
                        {contractToDisplay?.buyinContractId}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        Group Contract Id
                      </TableCell>
                      <TableCell>
                        {' '}
                        {contractToDisplay?.groupContractId}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        Supplier
                      </TableCell>
                      <TableCell>
                        {' '}
                        {contractToDisplay?.supplier?.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Type</TableCell>
                      <TableCell> {contractToDisplay?.type}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                      <TableCell>
                        <ContractStatus value={contractToDisplay?.status} />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        Start Date
                      </TableCell>
                      <TableCell>
                        {contractToDisplay?.startDate && contractToDisplay
                          ? moment(contractToDisplay?.startDate).format(
                              'YYYY-MM-DD',
                            )
                          : ''}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        End Date
                      </TableCell>
                      <TableCell>
                        {contractToDisplay?.endDate && contractToDisplay
                          ? moment(contractToDisplay?.endDate).format(
                              'YYYY-MM-DD',
                            )
                          : ''}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        (T)ISA agreed
                      </TableCell>
                      <TableCell>
                        {contractToDisplay?.isaAgreed ? 'Y' : 'N'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>
                        Data Processing/Protection Agreement
                      </TableCell>
                      <TableCell>
                        {contractToDisplay?.dataProcessingAgreed}
                      </TableCell>
                    </TableRow>
                    {!contractToDisplay?.parentId && (
                      <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>
                          Amendements
                        </TableCell>
                        <TableCell>
                          <List sx={{ marginLeft: '-23px' }}>
                            {contractToDisplay?.amendments.map((amendment) => (
                              <ListItem key={amendment.id}>
                                <ListItemIcon>
                                  <ArrowRightIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary={amendment.name}
                                  sx={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    changeDialogData(amendment, true)
                                  }
                                />
                              </ListItem>
                            ))}
                          </List>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              {!contractToDisplay?.parentId &&
                contractToDisplay?.buyinSourcer && (
                  <Stack direction="row" alignItems="center" spacing={3}>
                    <Avatar
                      alt={`${contractToDisplay?.buyinSourcer?.lastName} ${contractToDisplay?.buyinSourcer?.firstName}`}
                      src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${contractToDisplay?.buyinSourcer?.email}`}
                      sx={{ width: 87, height: 87 }}
                    />
                    <Typography component={'span'}>
                      {contractToDisplay?.buyinSourcer?.lastName}{' '}
                      {contractToDisplay?.buyinSourcer?.firstName}
                      <br />
                      {contractToDisplay?.buyinSourcer?.jobTitle}
                      <br />
                      Mobile: {contractToDisplay?.buyinSourcer?.mobile}
                      <br />
                      Office: {contractToDisplay?.buyinSourcer?.fixed}
                      <br />
                      <Stack direction="row" gap={1}>
                        <Typography component={'span'}>
                          {contractToDisplay?.buyinSourcer?.email}
                        </Typography>
                        <EmailIcon color="primary" />
                      </Stack>
                      <br />
                    </Typography>
                  </Stack>
                )}
            </>
          )}
          {!contractToDisplay && <ContractSkeleton />}
        </DialogContent>
      </Dialog>
    </>
  );
}
