import React from 'react';
import ReactDOM from 'react-dom';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { MatomoProvider, createInstance } from '@jonkoops/matomo-tracker-react';
import './index.css';
import App from './App';

const reactPlugin = new ReactPlugin();

const appInsights = new ApplicationInsights({
  config: {
    // connectionString: `${process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING}`,
    instrumentationKey: `${process.env.REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING}`,
    enableAutoRouteTracking: true,
    enableCorsCorrelation: false,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    extensions: [reactPlugin],
  },
});
if (process.env.REACT_APP_APPLICATIONINSIGHTS_ENABLE) {
  appInsights.loadAppInsights();
}
appInsights.addTelemetryInitializer((envelope) => {
  let itemTags = envelope.tags;
  itemTags = itemTags || [];
  itemTags['ai.cloud.role'] = 'MyBuyIn Pro Web';
});

const instance = createInstance({
  urlBase: `${process.env.REACT_APP_MATOMO_URL}`,
  siteId: parseInt(`${process.env.REACT_APP_MATOMO_SITEID}`),
  trackerUrl: `${process.env.REACT_APP_MATOMO_URL}/piwik.php`,
});

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <App />
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
