import { ToolCard } from '../DomainPage/PowerBiReports/ToolCard';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';

export default function ToolsList({ productCatalogs, handlePcClick }: any) {
  return (
    <>
      {productCatalogs.map((pc: any, index: any) => {
        return (
          <ToolCard
            label={pc.name}
            icon={
              <WysiwygIcon
                sx={{
                  fontSize: '50px',
                  color: 'white',
                }}
              />
            }
            color={'primary.main'}
            onClick={() => {
              handlePcClick(pc);
            }}
            key={index}
          />
        );
      })}
    </>
  );
}
