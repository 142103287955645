import * as React from 'react';
import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '../../../ui/mui/DialogTitle/DialogTitle';
import { Box } from '@mui/material';
import newsClient from '../../../../services/newsClient';
import { POST_TYPES } from '../../../news/data';

export interface FeedbackDialogProps {
  onClose: () => void;
}

export default function FaqDialog({ onClose }: FeedbackDialogProps) {
  const [post, setPost] = useState<any>();
  useEffect(() => {
    newsClient
      .post('/posts', {
        post_type: [POST_TYPES.WEBPAGE],
        id: [process.env.REACT_APP_FAQ_ID],
      })
      .then((response) => {
        console.log(response.data);
        setPost(response.data);
      })
      .catch((error) => {
        // TODO: handle error response
        console.log(error);
      });
  }, []);

  const getStyle = () => {
    return {
      color: 'rgb(228, 27, 19)',
    };
  };

  return !post ? null : (
    <Dialog
      onClose={onClose}
      open
      maxWidth="md"
      sx={{ '.MuiDialogTitle-root': getStyle() }}
      fullWidth
    >
      <DialogTitle onClose={onClose}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {post.title}
        </Box>
      </DialogTitle>
      <Box padding={2} dangerouslySetInnerHTML={{ __html: post.content }} />
    </Dialog>
  );
}
