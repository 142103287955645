import AddIcon from '@mui/icons-material/Add';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  ListItemIcon,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNav } from '../../NavProvider';
import DialogSelect, { Choice } from '../../ui/mui/DialogSelect/DialogSelect';
import RecordSelect from '../../shared/RecordSelect/RecordSelect';
import { useAuth } from '../../AuthProvider';
import { useNavigate } from 'react-router';
import ContractsIcon from '../../shared/Icons/ContractIcon';
import NegotiationsIcon from '../../shared/Icons/NegotiationsIcon';
import StrategiesIcon from '../../shared/Icons/StrategiesIcon';
import UpdatesIcon from '../../shared/Icons/UpdatesIcon';
import ContactsIcon from '../../shared/Icons/ContactsIcon';
import FeedIcon from '../../shared/Icons/FeedIcon';

interface DialogSelectProps {
  label: string;
  title: string;
  disabled?: boolean;
  loading?: boolean;
  initialValues?: string[];
  // setInitialValues: (selection: string[]) => void,
  choices: Choice[];
  isHidden?: boolean;
  onChange: (selection: string[]) => void;
  onClose?: () => void;
}

interface FilterValue {
  dimension: string;
  id: string;
  label: string;
  icon?: any;
  onDelete?: () => void;
}

interface FiltersContextValues {
  domains: Array<string>;
  categories: Array<string>;
  subcategories: Array<string>;
  groups: Array<string>;
  regions: Array<string>;
  natcos: Array<string>;
  suppliers: Array<string>;
  isReady: boolean;
  setOtherFilters: (value: any) => void;
  setOtherFilterValues: (values: any) => void;
  otherFilterValues: any;
  resetTrigger: number;
}

const defautFilterValues = {
  domains: [],
  categories: [],
  subcategories: [],
  groups: [],
  regions: [],
  natcos: [],
  suppliers: [],
  isReady: false,
  setOtherFilters: () => { },
  setOtherFilterValues: (values: any) => { },
  otherFilterValues: null,
  resetTrigger: 0,
};
export const FiltersContext =
  createContext<FiltersContextValues>(defautFilterValues);

interface FiltersContextProviderProps {
  children: JSX.Element;
  matchedTab: string;
}

export const NavigationFiltersProvider: FC<FiltersContextProviderProps> = ({
  children,
  matchedTab = 'feed',
}) => {
  const { isReady, domains, groups, supplierId, supplier } = useNav();
  const { profile } = useAuth();

  const [filters, setFilters] =
    useState<FiltersContextValues>(defautFilterValues);
  const [supplierFilter, setSupplierFilter] = useState<any>({});
  const [otherFilters, setOtherFilters] = useState();
  const [domainInitialValues, setDomainInitialValues] = useState<string[]>([]);
  const [domainsChoices, setDomainsChoices] = useState<Choice[]>([]);
  const [categoriesChoices, setCategoriesChoices] = useState<Choice[]>([]);
  const [categoryInitialValues, setCategoryInitialValues] = useState<string[]>(
    [],
  );
  const [subcategoriesChoices, setSubcategoriesChoices] = useState<Choice[]>(
    [],
  );
  const [subcategoriesInitialValues, setSubcategoryInitialValues] = useState<
    string[]
  >([]);
  const [groupChoices, setGroupChoices] = useState<Choice[]>([]);
  const [groupInitialValues, setGroupInitialValues] = useState<string[]>([]);
  const [regionChoices, setRegionChoices] = useState<Choice[]>([]);
  const [regionInitialValues, setRegionInitialValues] = useState<string[]>([]);
  const [natcoChoices, setNatcoChoices] = useState<Choice[]>([]);
  const [natcoInitialValues, setNatcoInitialValues] = useState<string[]>([]);
  const [supplierInitialValues, setSupplierInitialValues] = useState<string[]>(
    [],
  );
  const [supplierChoices, setSupplierChoices] = useState<any[]>([]);
  const [filterValues, setFilterValues] = useState<FilterValue[]>([]);
  const [otherFilterValues, setOtherFilterValues] = useState<any>(null);
  const navigate = useNavigate();

  // const [isReady, setIsReady] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [resetTrigger, setResetTrigger] = useState(0);
  const isReseted: any = useRef();

  const noFilterTabs = ['tools', 'profile', 'key-staff', 'swot-analysis', 'sustainability'];
  const otherFiltersTabs = ['negotiations', 'contracts'];

  const findNodes = (nodes: any[], dimension: string, ids: string[]) => {
    let foundNodes: any = [];
    if (!dimension) {
      return nodes.filter(({ id }) => ids.includes(String(id)));
    }

    nodes.forEach((node) => {
      if (node[dimension] && ids.includes(String(node.id))) {
        foundNodes = [...foundNodes, node[dimension]];
      } else {
        Object.keys(node).forEach((key) => {
          if (typeof node[key] === 'object') {
            foundNodes = [
              ...foundNodes,
              ...findNodes(node[key], dimension, ids),
            ];
          }
        });
      }
    });
    return foundNodes.flat();
  };

  const handleDomainChange = (selection: string[]) => {
    const selectedDomains = !!selection.length
      ? domains.filter((domain) => selection.includes(`${domain.id}`))
      : domains;

    const selectedCategories = selectedDomains
      .map((domain: any) => domain.categories)
      .flat();

    const selectedSubcategories = selectedCategories
      .map((category: any) => category.subcategories)
      .flat();

    const newCatgoryChoices: Choice[] = selectedCategories.map(
      ({ name, id }) => ({
        name,
        value: `${id}`,
      }),
    );
    const newSubcategoryChoices: Choice[] = selectedSubcategories.map(
      ({ name, id }) => ({
        name,
        value: `${id}`,
      }),
    );

    const newCategoryInitialValues = categoryInitialValues.filter(
      (initialValue) =>
        newCatgoryChoices.some(
          (newChoice: any) => newChoice.value === initialValue,
        ),
    );

    const newSubCategoryInitialValues = subcategoriesInitialValues.filter(
      (iv) => newSubcategoryChoices.some((ch: any) => ch.value === iv),
    );

    setCategoriesChoices(newCatgoryChoices);
    setCategoryInitialValues(newCategoryInitialValues);
    setSubcategoriesChoices(newSubcategoryChoices);
    setSubcategoryInitialValues(newSubCategoryInitialValues);
    setDomainInitialValues(selection);
  };

  const handleCategoryChange = (selection: string[]) => {
    const selectedCategories = (
      !!selection.length
        ? domains.map((domain) =>
          domain.categories.filter((category: any) =>
            selection.includes(`${category.id}`),
          ),
        )
        : domains.map((domain) => domain.categories)
    ).flat();

    const selectedSubcategories = selectedCategories
      .map((category: any) => category.subcategories)
      .flat();

    const newSubcategoryChoices: Choice[] = selectedSubcategories.map(
      ({ name, id }) => ({
        name,
        value: `${id}`,
      }),
    );

    const newSubCategoryInitialValues = subcategoriesInitialValues.filter(
      (iv) => newSubcategoryChoices.some((ch: any) => ch.value === iv),
    );

    setSubcategoriesChoices(newSubcategoryChoices);
    setSubcategoryInitialValues(newSubCategoryInitialValues);
    setCategoryInitialValues(selection);
  };

  const handleSubcategoryChange = (selection: string[]) => {
    setSubcategoryInitialValues(selection);
  };

  const handleResetFilters = () => {
    isReseted.current = false;
    setResetTrigger(resetTrigger + 1);
    handleGroupChange([]);
    setDomainInitialValues([]);
    setCategoryInitialValues([]);
    setSubcategoryInitialValues([]);
    setGroupInitialValues([]);
    setRegionInitialValues([]);
    setNatcoInitialValues([]);
    setSupplierInitialValues([]);
    setSupplierFilter({});
    setFilterValues([]);
    setIsTouched(false);
  };

  const handleGroupChange = (selection: string[]) => {
    const selectedGroups = !!selection.length
      ? groups.filter((group) => selection.includes(`${group.id}`))
      : groups;

    const selectedRegions = selectedGroups
      .map((group: any) => group.regions)
      .flat();

    const selectedNatcos = selectedRegions
      .map((region: any) => region.natcos)
      .flat();

    const newRegionChoices: Choice[] = selectedRegions.map(({ name, id }) => ({
      name,
      value: `${id}`,
    }));
    const newNatcoChoices: Choice[] = selectedNatcos.map(({ name, id }) => ({
      name,
      value: `${id}`,
    }));

    const newRegionInitialValues = regionInitialValues.filter((initialValue) =>
      newRegionChoices.some(
        (newChoice: any) => newChoice.value === initialValue,
      ),
    );

    const newNatcoInitialValues = natcoInitialValues.filter((iv) =>
      newNatcoChoices.some((ch: any) => ch.value === iv),
    );

    setNatcoChoices(newNatcoChoices);
    setNatcoInitialValues(newNatcoInitialValues);

    setRegionChoices(newRegionChoices);
    setRegionInitialValues(newRegionInitialValues);

    setGroupInitialValues(selection);
  };

  const handleRegionChange = (selection: string[]) => {
    const selectedRegions = (
      !!selection.length
        ? groups.map((group) =>
          group.regions.filter((region: any) =>
            selection.includes(`${region.id}`),
          ),
        )
        : groups.map((group) => group.regions)
    ).flat();

    const selectedNatcos = selectedRegions
      .map((region: any) => region.natcos)
      .flat();

    const newNatcoChoices: Choice[] = selectedNatcos.map(({ name, id }) => ({
      name,
      value: `${id}`,
    }));

    const newNatcoInitialValues = natcoInitialValues.filter((iv) =>
      newNatcoChoices.some((ch: any) => ch.value === iv),
    );

    setNatcoChoices(newNatcoChoices);
    setNatcoInitialValues(newNatcoInitialValues);

    setRegionInitialValues(selection);
  };

  const handleNatcoChange = (selection: string[]) => {
    setNatcoInitialValues(selection);
  };

  useEffect(() => {
    setResetTrigger(0);
    if (matchedTab === 'negotiations') {
      handleRegionChange([]);
      handleNatcoChange([]);
    }
  }, [matchedTab]);

  const dialogSelects: Array<DialogSelectProps> = [
    {
      label: 'Domain',
      title: 'Domain',
      disabled: !isReady,
      loading: !isReady,
      initialValues: domainInitialValues,
      choices: domainsChoices,
      onChange: handleDomainChange,
      isHidden: profile?.isCustomer,
    },
    {
      label: 'Category',
      title: 'Category',
      disabled: !isReady,
      loading: !isReady,
      initialValues: categoryInitialValues,
      choices: categoriesChoices,
      onChange: handleCategoryChange,
    },
    {
      label: 'Subcategory',
      title: 'Subcategory',
      disabled: !isReady,
      loading: !isReady,
      initialValues: subcategoriesInitialValues,
      choices: subcategoriesChoices,
      onChange: handleSubcategoryChange,
      isHidden: ['negotiations', 'contracts'].includes(matchedTab),
    },
    {
      label: 'Group',
      title: 'Group',
      disabled: !isReady,
      loading: !isReady,
      initialValues: groupInitialValues,
      choices: groupChoices,
      onChange: handleGroupChange,
      isHidden: profile?.isCustomer || ['contacts'].includes(matchedTab),
    },
    {
      label: 'Region',
      title: 'Region',
      disabled: !isReady,
      loading: !isReady,
      initialValues: regionInitialValues,
      choices: regionChoices,
      onChange: handleRegionChange,
      isHidden: [
        'contacts',
        'updates',
        'contracts',
        'negotiations',
        'feed',
        'strategies',
      ].includes(matchedTab),
    },
    {
      label: 'Natco',
      title: 'Natco',
      disabled: !isReady,
      loading: !isReady,
      initialValues: natcoInitialValues,
      choices: natcoChoices,
      onChange: handleNatcoChange,
      isHidden: [
        'contacts',
        'updates',
        'strategies',
        'contracts',
        'negotiations',
        'feed',
      ].includes(matchedTab),
    },
  ];

  useEffect(() => {
    if (isReady) {
      const initialDomainsChoices: Array<Choice> = [];
      const initialCategoriesChoices: Array<Choice> = [];
      const initialSubcategoriesChoices: Array<Choice> = [];
      const initalGroupChoices: Array<Choice> = [];
      const initialRegionChoices: Array<Choice> = [];
      const initialNatcoChoices: Array<Choice> = [];

      domains.forEach((domain) => {
        initialDomainsChoices.push({
          name: domain.name,
          value: `${domain.id}`,
        });
        domain.categories.forEach((category: any) => {
          initialCategoriesChoices.push({
            name: category.name,
            value: `${category.id}`,
          });
          category.subcategories.forEach((subcategory: any) => {
            initialSubcategoriesChoices.push({
              name: subcategory.name,
              value: `${subcategory.id}`,
            });
          });
        });
      });

      groups.forEach((group) => {
        initalGroupChoices.push({
          name: group.name,
          value: `${group.id}`,
        });
        group.regions.forEach((region: any) => {
          initialRegionChoices.push({
            name: region.name,
            value: `${region.id}`,
          });
          region.natcos.forEach((natco: any) => {
            initialNatcoChoices.push({
              name: natco.name,
              value: `${natco.id}`,
            });
          });
        });
      });
      setDomainsChoices(initialDomainsChoices);
      setCategoriesChoices(initialCategoriesChoices);
      setSubcategoriesChoices(initialSubcategoriesChoices);
      setGroupChoices(initalGroupChoices);
      setRegionChoices(initialRegionChoices);
      setNatcoChoices(initialNatcoChoices);
      return;
    }
  }, [isReady]);

  useEffect(() => {
    if (!isReseted.current) {
      isReseted.current = true;
      return;
    }

    setFilters({
      ...filters,
      domains: domainInitialValues,
      categories: categoryInitialValues,
      subcategories: subcategoriesInitialValues,
      groups: groupInitialValues,
      regions: regionInitialValues,
      natcos: natcoInitialValues,
      suppliers: supplierInitialValues,
    });

    setFilterValues(
      findNodes(domains, '', domainInitialValues)
        .map(({ code, id }: any) => ({
          label: code,
          id,
          dimension: 'Domain',
          // isHidden: matchedTab === ''
        }))
        .concat(
          findNodes(
            domains.map(({ categories }) => categories).flat(),
            '',
            categoryInitialValues,
          ).map(({ code, id }: any) => ({
            label: code,
            id,
            dimension: 'Category',
          })),
        )
        .concat(
          findNodes(
            domains
              .map(({ categories }) =>
                categories
                  .map(({ subcategories }: any) => subcategories)
                  .flat(),
              )
              .flat(),
            '',
            subcategoriesInitialValues,
          ).map(({ name, id }: any) => ({
            label: name,
            id,
            dimension: 'Subcategory',
          })),
        )
        .concat(
          findNodes(groups, '', groupInitialValues).map(
            ({ code, id }: any) => ({
              label: code,
              id,
              dimension: 'Group',
            }),
          ),
        )
        .concat(
          findNodes(
            groups.map(({ regions }) => regions).flat(),
            '',
            regionInitialValues,
          ).map(({ code, id }: any) => ({
            label: code,
            id,
            dimension: 'Region',
          })),
        )
        .concat(
          findNodes(
            groups
              .map(({ regions }) =>
                regions.map(({ natcos }: any) => natcos).flat(),
              )
              .flat(),
            '',
            natcoInitialValues,
          ).map(({ name, id }: any) => ({
            label: name,
            id,
            dimension: 'Natco',
          })),
        )
        .concat(
          supplier
            ? [
              {
                id: supplier.id,
                label: supplier.name,
                dimension: 'Supplier',
              },
            ]
            : supplierInitialValues.map((initialValue: string) => {
              return {
                id: initialValue,
                label: supplierChoices?.find(
                  (choice: any) => choice.id === Number(initialValue),
                )?.name,
                dimension: 'Supplier',
              };
            }),
        ),
    );
  }, [
    categoryInitialValues,
    subcategoriesInitialValues,
    domainInitialValues,
    groupInitialValues,
    regionInitialValues,
    natcoInitialValues,
    supplierInitialValues,
    supplier,
  ]);

  if (noFilterTabs.includes(matchedTab)) return <>{children}</>;


  const TabContent = ({ icon: IconComponent, title, children }: any) => (
    <>
      <Divider />
      <Stack direction="row" alignItems="center" gap={1} sx={{ p: 1 }}>
        <ListItemIcon
          sx={{ minWidth: 0 }}
          className="icon-selected"
        >
          <IconComponent />
        </ListItemIcon>
        <Typography component="h6" variant="h6">
          {title}
        </Typography>
      </Stack>
      <Divider />
      {children}
    </>
  );
  return (

    <FiltersContext.Provider
      value={{
        ...filters,
        isReady,
        setOtherFilters,
        resetTrigger,
        setOtherFilterValues,
        otherFilterValues,
      }}
    >
      <Divider />
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Box
          sx={{
            position: 'sticky',
            top: 63,
            backgroundColor: 'white',
            zIndex: 5,
          }}
        >
          {matchedTab === 'feed' && (
            <>
              <TabContent icon={FeedIcon} title="Feed">  </TabContent>
            </>
          )}
          {matchedTab === 'contracts' && (
            <>
              <TabContent icon={ContractsIcon} title="Contracts">  </TabContent>
            </>
          )}
          {matchedTab === 'negotiations' && (
            <>
              <TabContent icon={NegotiationsIcon} title="Negotiation Roadmap" >  </TabContent>
            </>
          )}
          {matchedTab === 'strategies' && (
            <>
              <TabContent icon={StrategiesIcon} title="Strategies" >  </TabContent>
            </>
          )}
          {matchedTab === 'updates' && (
            <>
              <TabContent icon={UpdatesIcon} title="Updates" >  </TabContent>
            </>
          )}
          {matchedTab === 'contacts' && (
            <>
              <TabContent icon={ContactsIcon} title="Contacts">  </TabContent>
            </>
          )}

          <Box p={1}>
            <Accordion
              expanded={expanded}
              onChange={() => setExpanded(!expanded)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="span" color="primary">
                  {expanded ? (
                    <Stack direction="row">
                      <RemoveIcon />
                      Hide filters
                    </Stack>
                  ) : (
                    <Stack direction="row">
                      <AddIcon />
                      <Typography>Show filters</Typography>
                    </Stack>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {dialogSelects.map(
                    (dialogSelect: DialogSelectProps, index) => (
                      <React.Fragment key={`input${index}`}>
                        {!dialogSelect.isHidden && (
                          <Grid item xs={12} sm={6} md={4} xl={3}>
                            <DialogSelect
                              {...dialogSelect}
                              label={`${dialogSelect.label} (${!dialogSelect.initialValues?.length
                                ? 'ALL'
                                : dialogSelect.initialValues?.length
                                })`}
                              onOpen={setIsTouched}
                              disabled={dialogSelect.choices.length === 0}
                            />
                          </Grid>
                        )}
                      </React.Fragment>
                    ),
                  )}
                  {!['feed', 'strategies', 'contacts'].includes(matchedTab) && (
                    <Grid item xs={12} sm={6} md={4} xl={3}>
                      <RecordSelect
                        label="Supplier"
                        reference="suppliers"
                        source="name"
                        filter={{}}
                        filters={supplierFilter}
                        onFiltersChange={(f) => {
                          setIsTouched(true);
                          setSupplierInitialValues(
                            f.suppliers?.split(',') || [],
                          );
                          setSupplierFilter(f);
                        }}
                        setRecords={setSupplierChoices}
                        disabled={!!supplierId}
                        useVirtualization
                      />
                    </Grid>
                  )}
                  {(otherFiltersTabs.includes(matchedTab) && otherFilters) || (
                    <></>
                  )}
                </Grid>
                <Grid item container sx={{ pt: 2 }}>
                  <Button
                    disabled={!otherFilterValues && !isTouched}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleResetFilters}
                    startIcon={<RotateLeftIcon />}
                  >
                    Reset filters
                  </Button>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box>
            {filterValues.map((s, index) => (
              <React.Fragment key={index}>
                <Chip
                  key={s.id}
                  onDelete={() => {
                    if (s.dimension === 'Supplier' && !supplierId) {
                      setSupplierFilter({
                        suppliers: supplierInitialValues
                          .filter((supplierId) => supplierId !== s.id)
                          .join(','),
                      });
                      setSupplierInitialValues(
                        supplierInitialValues.filter(
                          (supplierId) => supplierId !== s.id,
                        ),
                      );
                      return;
                    } else if (supplierId) {
                      navigate(`/domains/${matchedTab}`);
                    }
                    const dialogSelect = dialogSelects.find(
                      (ds) => s.dimension === ds.label,
                    );
                    dialogSelect?.onChange(
                      (dialogSelect.initialValues || []).filter(
                        (iv) => iv !== String(s.id),
                      ),
                    );
                  }}
                  label={`${s.dimension} ${s.label && ' : ' + s.label} `}
                  icon={s?.icon}
                  sx={{ m: 0.5 }}
                />
              </React.Fragment>
            ))}
            {((matchedTab === 'contracts' ||
              matchedTab === 'negotiations' ||
              matchedTab === 'strategies' ||
              matchedTab === 'updates'
            ) &&
              otherFilterValues) || <></>}

            <Divider />
          </Box>

          {filterValues.length > 0 && <Divider />}
        </Box>

        {children}
      </Box>
    </FiltersContext.Provider>
  );
};

export function useFilters() {
  const context = useContext<FiltersContextValues>(FiltersContext);
  if (context === undefined) {
    throw new Error('NavContext must be used within a NavProvider');
  }

  return context;
}
