import React, { useEffect, useState } from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import apiClient from '../../../services/apiClient';
import EnrolDialog from './EnrolDialog/EnrolDialog';
import { IconButton, Link, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import PlayCircleOutlineRoundedIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LockIcon from '@mui/icons-material/Lock';
import ArticleDialog from './ArticleDialog/ArticleDialog';
import UpdateShow from '../../news/UpdateShow/UpdateShow';

export const downloadBlocker = (id: number): any => {
  return apiClient
    .get(`/enrollments/blocker`, {
      responseType: 'blob',
      params: { sessionId: id },
    })
    .then((response) => {
      //TODO: find a better way to download the blocker
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `invitation.ics`);
      document.body.appendChild(link);
      link.click();
      document.removeChild(link);
    })
    .catch((error) => {
      // TODO: handle error response
      console.log(error);
    });
};

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<PlayCircleOutlineRoundedIcon sx={{ color: '#e41b13' }} />}
    {...props}
  />
))(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

export default function SummitBooking() {
  const [dialogArticle, setDialogArticle] = React.useState<null | number>();
  const [dialogUpdate, setDialogUpdate] = React.useState<null | number>();
  const [pastSessions, setPastSessions] = useState();
  const [upcomingSessions, setUpcomingSessions] = useState();
  const [enrollments, setEnrollments] = useState();
  const [session, setSession] = useState();
  const [pastSessionsIsLoading, setPastSessionsIsLoading] = useState(true);
  const [enrollmentsIsLoading, setEnrollmentsIsLoading] = useState(true);
  const [upcomingSessionsIsLoading, setUpcomingSessionsIsLoading] =
    useState(true);
  const [openEnrolModal, setOpenEnrolModal] = useState(false);
  const [update, setUpdate] = useState(true);
  const handleCancel = (id: number) => {
    return apiClient
      .put(`/enrollments/${id}`)
      .then(() => setUpdate(true))
      .catch((error) => {
        // TODO: handle error response
        console.log(error);
      });
  };
  const { trackPageView } = useMatomo();
  trackPageView({
    documentTitle: 'Home/Summit-booking',
    href:`${window.location.origin}/Home/Summit-booking`
  });

  const accordions: any = [
    {
      header: 'Your enrolled sessions',
      sessions: (enrollments || []).map((enrollment: any) => ({
        ...enrollment.session,
        enrollmentId: enrollment.id,
        questions: enrollment.questions,
        expectations: enrollment.expectations,
      })),
      cancelButton: true,
      blockerButton: true,
      expandIcon: true,
      noSession: 'You are not enrolled to any session',
      isLoading: enrollmentsIsLoading,
    },
    {
      header: 'Your upcoming sessions',
      sessions: upcomingSessions,
      enrolButton: true,
      expandIcon: true,
      noSession: 'Sorry, there is no upcoming session at the moment',
      isLoading: upcomingSessionsIsLoading,
    },
    {
      header: 'Your past sessions',
      sessions: pastSessions,
      expandIcon: true,
      showArticle: true,
      noSession: 'Sorry, there is no past session',
      isLoading: pastSessionsIsLoading,
    },
  ];
  useEffect(() => {
    if (update) {
      setUpdate(false);
      setPastSessionsIsLoading(true);
      setUpcomingSessionsIsLoading(true);
      setEnrollmentsIsLoading(true);
      apiClient
        .get('/sessions', {
          params: {
            type: 'PAST',
            sortBy : 'date',
            order: 'DESC'
          },
        })
        .then((res) => {
          setPastSessions(res.data.records);
          setPastSessionsIsLoading(false);
        })
        .catch((error) => {
          // TODO: handle error response
          console.log(error);
        });

      apiClient
        .get('/sessions', {
          params: {
            type: 'UPCOMING',
            sortBy : 'date',
            order: 'DESC'
          },
        })
        .then((res) => {
          setUpcomingSessions(res.data.records);
          setUpcomingSessionsIsLoading(false);
        })
        .catch((error) => {
          // TODO: handle error response
          console.log(error);
        });
      apiClient
        .get('/enrollments')
        .then((res) => {
          setEnrollmentsIsLoading(false);
          setEnrollments(res.data.records);
        })
        .catch((error) => {
          // TODO: handle error response
          console.log(error);
        });
    }
  }, [update]);

  return (
    <>
      {dialogArticle && (
        <ArticleDialog onClose={() => setDialogArticle(null)} />
      )}
      {dialogUpdate && (
        <UpdateShow
          id={Number(dialogUpdate)}
          onClose={() => setDialogUpdate(null)}
          isRoot={true}
        />
      )}

      <EnrolDialog
        session={session}
        open={openEnrolModal}
        onClose={() => {
          setOpenEnrolModal(false);
        }}
        onSave={() => {
          setOpenEnrolModal(false);
          setUpdate(true);
        }}
      />
      <Grid container direction="row" alignItems="center">
        <Grid sx={{ m: 3 }} item xs={12} sm={12} md={12} xl={12}>
          Welcome to the BuyIn Summit! We are looking forward to connecting with
          you on the hot topics, market trends and current projects. Please find
          below the agenda and the sessions you can join. For more information
          on how to register, check this{' '}
          <Link
            component="button"
            variant="body1"
            onClick={() => setDialogArticle(1)}
          >
            article
          </Link>
          .
          <Grid sx={{ mt: 3 }} item xs={12} sm={12} md={12} xl={12}>
            {accordions.map((accordion: any, index: any) => (
              <Accordion defaultExpanded key={index}>
                <MuiAccordionSummary>
                  <Typography variant="subtitle2" sx={{ mr: -5 }}>
                    {accordion.header}
                  </Typography>
                </MuiAccordionSummary>
                <AccordionDetails>
                  {accordion.isLoading && <CircularProgress />}
                  {(accordion.sessions || [])?.length === 0 &&
                    !accordion.isLoading && (
                      <Typography>{accordion.noSession}</Typography>
                    )}

                  {(accordion.sessions || [])?.map(
                    (session: any, index: any) => (
                      <Accordion key={index}>
                        <AccordionSummary
                          expandIcon={
                            accordion.expandIcon ? (
                              <PlayCircleOutlineRoundedIcon
                                sx={{ color: '#e41b13' }}
                              />
                            ) : (
                              false
                            )
                          }
                        >
                          <Stack
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={1}
                            // container
                            sx={{
                              width: '100%',
                            }}
                            onClick={(e) => {
                              if (session.enrollmentId) {
                                e.preventDefault();
                                e.stopPropagation();
                                setOpenEnrolModal(true);
                                setSession(session);
                              }
                            }}
                          >
                            <Grid item sm={3}>
                              <Typography variant="subtitle2">
                                {session.title}&nbsp;({session?.group?.code})
                              </Typography>
                            </Grid>
                            <Grid item sm={1}>
                              <Typography
                                sx={{
                                  color: 'text.secondary',
                                  flexShrink: 0,
                                }}
                              >
                                {session.type}
                              </Typography>
                            </Grid>
                            <Grid item sm={3}>
                              {session.speakers.length > 0 ? (
                                <>
                                  <Tooltip
                                    title={session.speakers
                                      .map(
                                        (s: any) =>
                                          `${s?.firstName} ${s?.lastName}`,
                                      )
                                      .join(', ')}
                                  >
                                    <Chip
                                      avatar={
                                        <Avatar
                                          alt={session.speakers[0].lastName[0]}
                                          src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${session.speakers[0].email}`}
                                        />
                                      }
                                      label={`${session.speakers[0].firstName[0]}.${session.speakers[0].lastName}`}
                                      variant="outlined"
                                    />
                                  </Tooltip>
                                  {session.speakers.length > 1 ? (
                                    <Badge
                                      badgeContent={` +${
                                        session.speakers.length - 1
                                      }`}
                                      color="primary"
                                    ></Badge>
                                  ) : (
                                    ''
                                  )}
                                </>
                              ) : null}
                            </Grid>
                            <Grid item sm={2}>
                              <Typography
                                sx={{
                                  color: 'text.secondary',
                                  flexShrink: 0,
                                }}
                              >
                                {moment(session?.date).format('DD-MM-YYYY')}
                              </Typography>
                            </Grid>
                            <Grid item sm={1}>
                              <Typography
                                sx={{
                                  color: 'text.secondary',
                                  flexShrink: 0,
                                }}
                              >
                                {moment(new Date(session?.date)).format(
                                  'HH:mm',
                                )}
                              </Typography>
                            </Grid>

                            <Grid item sm={1}>
                              <Typography
                                sx={{
                                  color: 'text.secondary',
                                  flexShrink: 0,
                                }}
                              >
                                {session?.duration}&nbsp;min
                              </Typography>
                            </Grid>
                            <Grid item sm={2}>
                              <Stack
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                                spacing={1}
                                sx={{
                                  width: '100%',
                                }}
                              >
                                {accordion.enrolButton && (
                                  <Button
                                    sx={{ width: '100%' }}
                                    disabled={session?.isLocked}
                                    variant="outlined"
                                    onClick={(e: any) => {
                                      e.stopPropagation();
                                      setOpenEnrolModal(true);
                                      setSession(session);
                                    }}
                                  >
                                    {(session.isLocked && <LockIcon />) ||
                                      'Join'}
                                  </Button>
                                )}

                                {accordion.blockerButton && (
                                  <IconButton
                                    color="primary"
                                    component="label"
                                    onClick={(e: any) => {
                                      e.stopPropagation();
                                      downloadBlocker(session.id);
                                    }}
                                  >
                                    <DateRangeIcon />
                                  </IconButton>
                                )}
                                {accordion.cancelButton && (
                                  <Button
                                    sx={{ width: '100%' }}
                                    variant="outlined"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleCancel(session.enrollmentId);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                )}
                                {accordion.showArticle &&
                                  session?.article?.id && (
                                    <Button
                                      sx={{ width: '100%' }}
                                      disabled={session?.isLocked}
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        setDialogUpdate(session?.article?.id);
                                      }}
                                    >
                                      <PlayArrowRoundedIcon />
                                    </Button>
                                  )}
                              </Stack>
                            </Grid>
                          </Stack>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>{session.description}</Typography>
                        </AccordionDetails>
                      </Accordion>
                    ),
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
