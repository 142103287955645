import React from 'react';
import { useMatch, useNavigate } from 'react-router';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Role } from '../../../data';
import { useAuth } from '../../AuthProvider';
import TabPanel from '../../ui/mui/TabPanel/TabPanel';
import Layout from '../../Layout/Layout';
import Guard from '../../shared/Guard/Guard';
import SessionManagement from './SessionManagement/SessionManagement';
import SummitInvite from './SummitInvite/SummitInvite';
import Feedback from './Feedback/Feedback';
import EnrollmentList from './EnrollmentList/EnrollmentList';
type TabType = {
  value: string;
  label: string;
  role: string;
};

const tabs: TabType[] = [
  { value: 'sessions', label: 'Sessions', role: Role.SummitManager },
  {
    value: 'enrollments',
    label: 'Enrollments',
    role: Role.SummitManager,
  },
  {
    value: 'invitations',
    label: 'Invitations',
    role: Role.SummitManager,
  },
  {
    value: 'feedback',
    label: 'Feedback',
    role: Role.SummitManager,
  },
];

interface GuardedTabPanelProps {
  value: string;
  index: string;
  children: any;
}

const GuardedTabPanel = ({ value, index, children }: GuardedTabPanelProps) => {
  return (
    <TabPanel value={value} index={index}>
      <Guard role={tabs.find((tab) => tab.value === index)?.role || ''} message>
        {children}
      </Guard>
    </TabPanel>
  );
};

export default function SummitManager() {
  const navigate = useNavigate();
  const { hasRole } = useAuth();
  const match = useMatch('/admin/summit/:tab/*');
  const matchedTab: string = match?.params.tab || 'sessions';

  const handleChangeTab = (event: React.SyntheticEvent, newTab: any) => {
    navigate({
      pathname: `/admin/summit/${newTab}`,
    });
  };

  return (
    <Layout noBreadcrumbs>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        sx={{ p: 2 }}
      >
        <Typography>Admin</Typography>
        <Typography>Digital summit</Typography>
      </Breadcrumbs>
      <Divider />
      <Guard role={Role.SummitManager} message>
        <Tabs value={matchedTab} onChange={handleChangeTab} variant="fullWidth">
          {tabs.map(
            (tab) =>
              hasRole(tab.role) && (
                <Tab key={tab.value} value={tab.value} label={tab.label} />
              ),
          )}
        </Tabs>
        <Divider />
        <GuardedTabPanel value={matchedTab} index="sessions">
          <SessionManagement />
        </GuardedTabPanel>
        <GuardedTabPanel value={matchedTab} index="enrollments">
          <EnrollmentList />
        </GuardedTabPanel>
        <GuardedTabPanel value={matchedTab} index="invitations">
          <SummitInvite />
        </GuardedTabPanel>
        <GuardedTabPanel value={matchedTab} index="feedback">
          <Feedback />
        </GuardedTabPanel>
      </Guard>
    </Layout>
  );
}
