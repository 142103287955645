import { TableCell, TableRow, Typography } from '@mui/material';

interface EmptyRowProps {
  message: string;
  colSpan: number;
}

export const EmptyRow = ({ message, colSpan }: EmptyRowProps) => (
  <TableRow>
    <TableCell colSpan={colSpan}>
      <Typography sx={{ textAlign: 'center' }} variant="h6">
        {message}
      </Typography>
    </TableCell>
  </TableRow>
);
