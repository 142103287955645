import React, { useState } from 'react';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { nfdSearch } from '../../../../utils';
import { List, AutoSizer } from 'react-virtualized';
import { Box } from '@mui/material';

export interface Choice {
  name: string;
  value: string;
}

export default function DialogSelect({
  label,
  title,
  disabled,
  loading,
  initialValues = [],
  choices,
  onOpen,
  onChange,
  onClose,
}: {
  label: string;
  title: string;
  disabled?: boolean;
  loading?: boolean;
  initialValues?: string[];
  choices: Choice[];
  onOpen: (open: boolean) => void;
  onChange: (selection: string[]) => void;
  onClose?: () => void;
}) {
  const [selection, setSelection] = useState<string[]>(initialValues);
  const [open, setOpen] = useState<boolean>(false);
  const [touched, setIsTouched] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const handleClose = () => {
    setOpen(false);
    setSearch('');
    onClose && onClose();
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setIsTouched(true);
    setSelection(
      checked
        ? [...selection, event.target.value]
        : selection.filter((selected) => selected !== event.target.value),
    );
  };

  const handleCancel = () => {
    setSelection(initialValues);
    setOpen(false);
    setIsTouched(false);
    // handleClose();
  };

  const handleApply = () => {
    // setOpen(false);
    // setIsTouched(false);
    handleClose();
    onChange(selection);
  };

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        style={{ height: 40 }}
        disabled={disabled}
        onClick={() => {
          setSelection(initialValues);
          setOpen(true);
          onOpen(true);
        }}
      >
        {label}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <span>
              Select {title}{' '}
              {Boolean(selection.length) && `(${selection.length})`}
            </span>

            <TextField
              variant="outlined"
              value={search}
              size="small"
              onChange={handleSearch}
              placeholder="Search..."
            />
          </Stack>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={0}>
              {choices
                .filter((choice: Choice) => nfdSearch(choice.name, search))
                .map(({ value, name }) => (
                  <Grid key={value} item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          value={value}
                          checked={
                            !!selection.find((selected) => selected === value)
                          }
                          onChange={handleSelect}
                        />
                      }
                      label={name}
                    />
                  </Grid>
                ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Button
              disabled={selection.length <= 0}
              onClick={() => {
                setIsTouched(true);
                setSelection([]);
              }}
              startIcon={<RotateLeftIcon />}
            >
              Clear
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              onClick={handleApply}
              variant="contained"
              color="primary"
              disabled={!touched}
              autoFocus
            >
              Apply
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
