import { useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import { alpha, styled } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';

import { POST_TYPES } from '../../../news/data';
import PayloadProvider from '../../../news/PayloadProvider';
import ContactsSearch from './ContactsSearch';
import ContractsSearch from './ContractsSearch';
import NegotiationsSearch from './NegotiationsSearch';
import StrategiesSearch from './StrategiesSearch';
import SupplierSearch from './SupplierSearch';
import UpdatesSearch from './UpdatesSearch';
import PublicNewsSearch from './PublicNewsSearch';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderTopLeftRadius: theme.shape.borderRadius * 3,
  borderTopRightRadius: theme.shape.borderRadius * 3,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  width: '100%',
  maxWidth: 700,
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
  },
}));

export default function SearchInput() {
  const [showSearch, setShowSearch] = useState(false);
  const [search, setSearch] = useState('');
  const [trackSearch, setTrackSearch] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [searchParams] = useSearchParams();
  const [filter, setFilter] = useState<any>();
  const timeout: any = useRef();
  const { trackSiteSearch } = useMatomo();
  const [supplierHasResult, setSupplierHasResult] = useState(false);
  const [updatesHasResult, setUpdatesHasResult] = useState(false);
  const [negotiationsHasResult, setNegotiationsHasResult] = useState(false);
  const [publicNewsHasResult, setPublicNewsHasResult] = useState(false);
  const [strategiesHasResult, setStrategiesHasResult] = useState(false);
  const [contactsHasResult, setContactsHasResult] = useState(false);
  const [contractsHasResult, setContractsHasResult] = useState(false);
  const [supplierLoading, setSupplierLoading] = useState(true);
  const [updatesLoading, setUpdatesLoading] = useState(true);
  const [negotiationsLoading, setNegotiationsLoading] = useState(true);
  const [publicNewsloading, setPublicNewsLoading] = useState(true);
  const [strategiesLoading, setStrategiesLoading] = useState(true);
  const [contactsLoading, setContactsLoading] = useState(true);
  const [contractsLoading, setContractsLoading] = useState(true);

  const [isResultFound, setIsResultFound] = useState(false);


  useEffect(() => {
    const navDomain = searchParams.get('navDomain')?.split('-') || [];
    const navGroup = searchParams.get('navGroup')?.split('-') || [];

    setFilter({
      domain: navDomain.length === 1 ? navDomain[0] : null,
      category: navDomain.length >= 2 ? navDomain[1] : null,
      subCategory: navDomain.length === 3 ? navDomain[2] : null,
      group: navGroup.length === 1 ? navGroup[0] : null,
      region: navGroup.length >= 2 ? navGroup[1] : null,
      natco: navGroup.length === 3 ? navGroup[2] : null,
    });
  }, [searchParams]);

  const [isLoadingResults, setIsLoadingResults] = useState(true);

  useEffect(() => {
    // Set loading to true when search starts
    setIsLoadingResults(true);
    setIsResultFound(false);

    // Check if all loading flags are false (i.e. loading is done)
    const loadingComplete =
      !supplierLoading &&
      !updatesLoading &&
      !negotiationsLoading &&
      !publicNewsloading &&
      !strategiesLoading &&
      !contactsLoading &&
      !contractsLoading;
    if (loadingComplete) {
      // When loading is complete, check for results

      setIsResultFound(supplierHasResult || updatesHasResult || negotiationsHasResult || publicNewsHasResult || strategiesHasResult || contactsHasResult);

      setIsLoadingResults(false); // Set loading to false after results check
    }
  }, [
    supplierLoading,
    updatesLoading,
    negotiationsLoading,
    publicNewsloading,
    strategiesLoading,
    contactsLoading,
    contractsLoading,
    supplierHasResult,
    updatesHasResult,
    negotiationsHasResult,
    publicNewsHasResult,
    strategiesHasResult,
    contactsHasResult,
    contractsHasResult,
  ]);

  useEffect(() => {
    // Execute this effect only when loading is complete
    if (!isLoadingResults && trackSearch && trackSearch.length > 1) {

      // Track based on the result found or not
      if (isResultFound) {
        console.log("---------------------found", trackSearch);
        trackSiteSearch({ keyword: trackSearch });
      } else {
        console.log("-----------------------not found", trackSearch);

        trackSiteSearch({ keyword: trackSearch, count: 0 }); // 0 results found
      }

      // Reset search state for the next search
      setIsResultFound(false);
      setTrackSearch('');
      resetLoadingStates();
    }
  }, [isResultFound, trackSearch, isLoadingResults]);

  const resetLoadingStates = () => {
    setSupplierLoading(true);
    setNegotiationsLoading(true);
    setContactsLoading(true);
    setContractsLoading(true);
    setUpdatesLoading(true);
    setStrategiesLoading(true);
    setPublicNewsLoading(true);
    setIsLoadingResults(true);
    setSupplierHasResult(false);
    setNegotiationsHasResult(false);
    setContactsHasResult(false);
    setContactsHasResult(false);
    setUpdatesHasResult(false);
    setStrategiesHasResult(false);
    setPublicNewsHasResult(false);
  };


  const handleInputChange = (event: any) => {
    clearTimeout(timeout.current);
    setSearch('');

    const value = event.target.value;
    setInputValue(value);

    timeout.current = setTimeout(() => {
      if (value.length > 1) {
        setSearch(value);
        setTrackSearch(value);
        setShowSearch(value.length > 0);
      }
    }, 1000);
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setSearch('');
        setInputValue('');
        setShowSearch(false);
      }}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Search
          sx={{
            borderBottomLeftRadius: (theme) =>
              showSearch ? 0 : theme.shape.borderRadius * 3,
            borderBottomRightRadius: (theme) =>
              showSearch ? 0 : theme.shape.borderRadius * 3,
          }}
        >
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ 'aria-label': 'search' }}
            onClick={() => setShowSearch(search?.length > 0)}
            onChange={handleInputChange}
            value={inputValue}
          />
          <Paper
            sx={{
              position: 'absolute',
              width: '100%',
              maxWidth: 700,
              borderBottomLeftRadius: (theme) => theme.shape.borderRadius * 3,
              borderBottomRightRadius: (theme) => theme.shape.borderRadius * 3,
            }}
            hidden={!showSearch}
            square
          >
            <Box
              sx={{
                mb: 1,
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '50vh',
                overflow: 'hidden',
                overflowY: 'scroll',
              }}
            >
              <SupplierSearch
                filter={{ search, ...filter }}
                onNavigate={() => {
                  setSearch('');
                  setInputValue('');
                  setShowSearch(false);
                }}
                onResultFound={setSupplierHasResult}
                onLoading={setSupplierLoading}
              />
              <PayloadProvider type={POST_TYPES.UPDATES} fromSearch={true}>
                <UpdatesSearch
                  filter={{ search }}
                  onNavigate={() => {
                    setSearch('');
                    setInputValue('');
                    setShowSearch(false);
                  }}
                  onResultFound={setUpdatesHasResult}
                  onLoading={setUpdatesLoading}

                />
              </PayloadProvider>

              <NegotiationsSearch
                filter={{ search, ...filter }}
                onNavigate={() => {
                  setSearch('');
                  setInputValue('');
                  setShowSearch(false);
                }}
                onResultFound={setNegotiationsHasResult}
                onLoading={setNegotiationsLoading}

              />

              <PayloadProvider type={POST_TYPES.STRATEGIES} fromSearch={true}>
                <StrategiesSearch
                  filter={{ search }}
                  onNavigate={() => {
                    setSearch('');
                    setInputValue('');
                    setShowSearch(false);
                  }}
                  onResultFound={setStrategiesHasResult}
                  onLoading={setStrategiesLoading}

                />
              </PayloadProvider>

              <PublicNewsSearch
                filter={{ search, ...filter }}
                onNavigate={() => {
                  setSearch('');
                  setInputValue('');
                  setShowSearch(false);
                }}
                onResultFound={setPublicNewsHasResult}
                onLoading={setPublicNewsLoading}

              />

              <ContractsSearch
                filter={{ search, ...filter }}
                onNavigate={() => {
                  setSearch('');
                  setInputValue('');
                  setShowSearch(false);
                }}
                onResultFound={setContractsHasResult}
                onLoading={setContractsLoading}

              />

              <ContactsSearch
                filter={{ search, ...filter }}
                onNavigate={() => {
                  setSearch('');
                  setInputValue('');
                  setShowSearch(false);
                }}
                onResultFound={setContactsHasResult}
                onLoading={setContactsLoading}
              />
            </Box>
          </Paper>
        </Search>
      </Grid>
    </ClickAwayListener>
  );
}
