import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';
import PublicNewsSkeleton from '../PublicNewsSkeleton/PublicNewsSkeleton';
import { PublicNews } from '../types';
import apiClient from '../../../services/apiClient';
import { makeStyles } from '@mui/styles';

interface PublicNewsShowProps {
  id: number | null;
  onClose: () => void;
}

export default function PublicNewsShow({ id, onClose }: PublicNewsShowProps) {
  const [news, setNews] = useState<PublicNews>();
  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: 'blur(3px)',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    apiClient
      .get(`/news/${id}`, {})
      .then((response) => {
        console.log(response.data.record);
        setNews(response.data.record);
      })
      .catch((error) => {
        // TODO: handle errors
        console.log(error);
      });
  }, []);

  return (
    <Dialog
      open={true}
      maxWidth="md"
      fullWidth
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle>
        <Typography>&#8205;</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {news ? (
        <DialogContent>
          <Typography variant="h5" component="h1">
            {news.title}
          </Typography>
          <Typography variant="caption" sx={{ color: 'gray' }}>
            {new Intl.DateTimeFormat('en-GB', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }).format(new Date(news.date))}{' '}
            By {news.source}
          </Typography>
          <div dangerouslySetInnerHTML={{ __html: news.excerpt }} />
          <Stack direction="row" alignItems="center" spacing={3} paddingY={2}>
            <Typography sx={{ color: 'gray' }}>
              {'Read the full article on : '}
              <a href={news.url} target="_blank">
                {news.source}
              </a>
            </Typography>
          </Stack>
        </DialogContent>
      ) : (
        <PublicNewsSkeleton />
      )}
    </Dialog>
  );
}
