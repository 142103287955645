import { SyntheticEvent, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import UploadForm from '../UploadForm/UploadForm';

const section = {
  publicNews: 'publicNews',
};

export default function PublicNewsUpload() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box p={1}>
      <Accordion
        expanded={expanded === section.publicNews}
        onChange={handleChange(section.publicNews)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Main public news upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={3}
            path="news/upload"
            inputId="public-news-sheet"
            inputName="news"
            inputAccept=".csv"
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
