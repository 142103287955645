import React, { useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import apiClient from '../../../../services/apiClient';
import { Contact } from '../../../../types';
import FeedPanelSkeleton from '../FeedPanelSkeleton/FeedPanelSkeleton';
import { useFilters } from '../../../Layout/NavigationFiltersProvider/NavigationFiltersProvider';

export default function ContactFeed({
  setHasContacts,
}: {
  setHasContacts: (hasContacts: boolean) => void;
}) {
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { categories, domains, groups, resetTrigger } = useFilters();

  useEffect(() => {
    setLoading(true);
    apiClient
      .get('/contacts', {
        params: {
          page: 1,
          perPage: 3,
          domains:
            domains.length > 0 && categories.length === 0
              ? domains.join(',')
              : null,
          category: categories.length > 0 ? categories.join(',') : null,
          groups: groups.length > 0 ? groups.join(',') : null,
          sortBy: 'jobTitle',
          order: 'DESC',
        },
      })
      .then((response) => {
        setContacts(response.data.records);
        setHasContacts(response.data.records.length > 0);
        setLoading(false);
      })
      .catch(() => {
        // TODO: handle error response
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, domains, groups]);

  useEffect(() => {
    if (resetTrigger) {
      setLoading(true);
      apiClient
        .get('/contacts', {
          params: {
            page: 1,
            perPage: 3,
            domains: null,
            category: null,
            groups: null,
            sortBy: 'jobTitle',
            order: 'DESC',
          },
        })
        .then((response) => {
          setContacts(response.data.records);
          setHasContacts(response.data.records.length > 0);
          setLoading(false);
        })
        .catch(() => {
          // TODO: handle error response
          setLoading(false);
        });
    }
  }, [resetTrigger]);

  const handleViewAll = () => {
    const search = createSearchParams();
    search.set('navDomain', searchParams.get('navDomain') || '');
    if (searchParams.get('navGroup')) {
      search.set('navGroup', searchParams.get('navGroup') || '');
    }
    navigate({
      pathname: '/domains/contacts',
      search: search.toString(),
    });
  };

  // if (!category && !domain) return null;

  if (loading) {
    return <FeedPanelSkeleton />;
  }

  if (contacts.length === 0) return null;

  return (
    <Paper elevation={3}>
      <Typography
        sx={{ p: 1.5, color: 'white', backgroundColor: 'primary.main' }}
      >
        Category Team
      </Typography>
      <Table size="small">
        <TableBody>
          {contacts.map((contact) => (
            <TableRow key={contact.id}>
              <TableCell sx={{ p: 0.5 }}>
                <Avatar
                  alt={`${contact.lastName} ${contact.firstName}`}
                  src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${contact.email}`}
                  sx={{ width: 80, height: 80 }}
                />
              </TableCell>
              <TableCell sx={{ p: 0.5 }}>
                <Typography variant="subtitle2" component="div">
                  {contact.lastName} {contact.firstName}
                  <br />
                  {contact.jobTitle}
                </Typography>
              </TableCell>
              <TableCell sx={{ p: 0.5 }}>
                <Stack direction="row" gap={0.5}>
                  <PhoneIphoneIcon color="primary" />
                  <Typography variant="caption">{contact.mobile}</Typography>
                </Stack>
                <Stack direction="row" gap={0.5}>
                  <PhoneIcon color="primary" />
                  <Typography variant="caption">{contact.fixed}</Typography>
                </Stack>
                <Stack direction="row" gap={0.5}>
                  <EmailIcon color="primary" />
                  <Typography variant="caption">{contact.email}</Typography>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Box textAlign="right">
        <Button sx={{ m: 0.5 }} onClick={handleViewAll}>
          View all
        </Button>
      </Box>
    </Paper>
  );
}
