import { useState, useEffect } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridFooter,
  GridFooterContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';

import apiClient from '../../../../services/apiClient';
import moment from 'moment';

function CustomFooter() {
  return (
    <GridFooterContainer>
      <GridFooter />
      <GridToolbarExport printOptions={{ disableToolbarButton: false }} />
    </GridFooterContainer>
  );
}
export default function EnrollmentList() {
  const columns: GridColDef[] = [
    {
      field: 'user.firstName',
      headerName: 'First name',
      width: 80,
      renderCell: (params: any) => (
        <Tooltip title={params.row.user.firstName}>
          <Typography noWrap maxWidth={80}>
            {params.row.user.firstName}
          </Typography>
        </Tooltip>
      ),
      valueGetter: (params) => `${params.row.user.firstName}`,
    },
    {
      field: 'user.lastName',
      headerName: 'Last name',
      width: 80,
      renderCell: (params: any) => (
        <Tooltip title={params.row.user.lastName}>
          <Typography noWrap maxWidth={80}>
            {params.row.user.lastName}
          </Typography>
        </Tooltip>
      ),
      valueGetter: (params) => `${params.row.user.lastName}`,
    },
    {
      field: 'user.userName',
      headerName: 'Email',
      width: 80,
      renderCell: (params: any) => (
        <Tooltip title={params.row.user.email}>
          <Typography noWrap maxWidth={80}>
            {params.row.user.email}
          </Typography>
        </Tooltip>
      ),
      valueGetter: (params) => `${params.row.user.email}`,
    },
    {
      field: 'session.group.code',
      headerName: 'Group',
      width: 80,
      renderCell: (params: any) => (
        <Tooltip title={params.row.session.group.code}>
          <Typography noWrap maxWidth={80}>
            {params.row.session.group.code}
          </Typography>
        </Tooltip>
      ),
      valueGetter: (params) => `${params.row.session.group.code}`,
    },
    {
      field: 'user.customerFunction',
      headerName: 'Customer Function',
      width: 80,
      renderCell: (params: any) => (
        <Tooltip title={params.row.user.customerFunction || 'BuyIn'}>
          <Typography noWrap maxWidth={80}>
            {params.row.user.customerFunction || 'BuyIn'}
          </Typography>
        </Tooltip>
      ),
      valueGetter: (params) => `${params.row.user.customerFunction || 'BuyIn'}`,
    },
    {
      field: 'session.title',
      headerName: 'Title',
      width: 80,
      renderCell: (params: any) => (
        <Tooltip title={params.row.session.title}>
          <Typography noWrap maxWidth={80}>
            {params.row.session.title}
          </Typography>
        </Tooltip>
      ),
      valueGetter: (params) => `${params.row.session.title}`,
    },
    {
      field: 'date',
      headerName: 'Enrolled',
      width: 80,
      renderCell: (params: any) => (
        <Tooltip title={moment(params.row.date).format('DD-MM-YYYY HH:mm')}>
          <Typography noWrap maxWidth={80}>
            {moment(params.row.date).format('DD-MM-YYYY HH:mm')}
          </Typography>
        </Tooltip>
      ),
      valueGetter: (params) =>
        `${moment(params.row.date).format('DD-MM-YYYY HH:mm')}`,
    },
    {
      field: 'type',
      headerName: 'Enrollment',
      width: 80,
      renderCell: (params: any) => (
        <Tooltip
          title={
            (params.row.type === 0 && 'spontaneous') ||
            (params.row.type === 1 && 'invited') ||
            (params.row.type === 2 && 'enrolled By BuyIn')
          }
        >
          <Typography noWrap maxWidth={80}>
            {(params.row.type === 0 && 'spontaneous') ||
              (params.row.type === 1 && 'invited') ||
              (params.row.type === 2 && 'enrolled By BuyIn')}
          </Typography>
        </Tooltip>
      ),
      valueGetter: (params) =>
        `${
          (params.row.type === 0 && 'spontaneous') ||
          (params.row.type === 1 && 'invited') ||
          (params.row.type === 2 && 'enrolled By BuyIn')
        }`,
    },
    {
      field: 'expectations',
      headerName: 'Expectations',
      width: 80,
      renderCell: (params: any) => (
        <Tooltip title={params.row.expectations}>
          <Typography noWrap maxWidth={80}>
            {params.row.expectations}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'questions',
      headerName: 'questions',
      width: 80,
      renderCell: (params: any) => (
        <Tooltip title={params.row.questions}>
          <Typography noWrap maxWidth={80}>
            {params.row.questions}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'status',
      headerName: 'status',
      width: 80,
      renderCell: (params: any) => (
        <Tooltip title={(params.row.status && 'confirmed') || 'canceled'}>
          <Typography noWrap maxWidth={80}>
            {(params.row.status && 'confirmed') || 'canceled'}
          </Typography>
        </Tooltip>
      ),
      valueGetter: (params) =>
        `${(params.row.status && 'confirmed') || 'canceled'}`,
    },
  ];

  const [isLoading, setIsLoading] = useState(true);

  const [rows, setRows] = useState<any[]>([]);

  useEffect(() => {
    apiClient.get('/enrollments/admin', { params: { page: 1 } }).then((res) => {
      setRows(res.data.records);
      setIsLoading(false);
    });
  }, []);

  return (
    <Box sx={{ mt: 3 }}>
      <DataGrid
        loading={isLoading}
        autoHeight
        rows={rows}
        columns={columns}
        pageSize={80}
        rowsPerPageOptions={[80]}
        checkboxSelection
        disableSelectionOnClick
        components={{ Footer: CustomFooter }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}
