import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

interface RowsSkeletonProps {
  lines?: number;
  headLength: number;
}

export default function RowsSkeleton({ lines, headLength }: RowsSkeletonProps) {
  return (
    <>
      {Array(lines || 5)
        .fill(null)
        .map((element, i) => (
          <TableRow key={i}>
            {Array(headLength)
              .fill(null)
              .map((v, i) => (
                <TableCell key={i}>
                  <Skeleton width={50} />
                </TableCell>
              ))}
          </TableRow>
        ))}
    </>
  );
}
