import React, { useEffect, useState } from 'react';
import {
  useMatch,
  useSearchParams,
  createSearchParams,
} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TableViewIcon from '@mui/icons-material/TableView';
import ForkRightSharpIcon from '@mui/icons-material/ForkRightSharp';
import TableViewList from './TableViewList/TableViewList';
import RoadmapViewList from './RoadmapViewList/RoadmapViewList';
import {
  INegotiationFilter,
  INegotiationMetaData,
  INegotiation,
} from '../../types';
import apiClient from '../../services/apiClient';
import NegotiationFilters from './NegotiationFilter/NegotiationFilters';
import { useNav, ALL } from '../NavProvider';
import { useNavigate } from 'react-router';

import NegotiationDetails from './TableViewList/NegotiationDetails/NegotiationDetails';
import { useFilters } from '../Layout/NavigationFiltersProvider/NavigationFiltersProvider';
import { Button, ButtonGroup, Divider } from '@mui/material';
// import ContractsIcon from '../shared/Icons/ContractIcon';
// import { NEGOTIATION_STATUSES } from '../../data';

export default function NegotiationList() {
  const { supplierId, domainNodeId, groupNodeId, setIsFrozen } = useNav();
  const navigate = useNavigate();

  const pathPrefix = supplierId ? `/suppliers/${supplierId}` : '/domains';
  const match = useMatch(`${pathPrefix}/:tab/:id`);

  const [negotiations, setNegotiations] = useState<INegotiation[]>([]);
  const [negotiationId, setNegotiationId] = useState<number | null | undefined>(
    Number(match?.params.id) || null,
  );
  const [metaData, setMetaData] = useState<INegotiationMetaData | null>();
  const [isLoading, setIsLoading] = useState(true);
  const [length, setLength] = useState<number>(0);
  const [tableView, setView] = useState(true);

  const [searchParams, setSearchParams] = useSearchParams();

  const page = Number(searchParams.get('page') || 1);
  const perPage = Number(searchParams.get('perPage') || 10);
  const orderBy = searchParams.get('sortBy') || 'title';
  const order = searchParams.get('order')?.toUpperCase() || 'ASC';

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    if (newPage >= 1) {
      searchParams.set('page', newPage.toString());
    } else {
      searchParams.delete('page');
    }
    setSearchParams(searchParams);
  };

  const {
    domains,
    categories,
    groups,
    regions,
    natcos,
    setOtherFilterValues,
    setOtherFilters,
    suppliers,
    isReady,
  } = useFilters();

  const [filter, setFilter] = useState<INegotiationFilter>();
  const search = searchParams.get('search');

  useEffect(() => {
    if (isReady) {
      const newFilter = {
        domains:
          categories.length === 0 && domains.length > 0
            ? domains.join(',')
            : null,
        categories: categories.length > 0 ? categories.join(',') : null,
        groups:
          (filter?.natcos?.split(',') || []).length === 0 &&
          (filter?.regions?.split(',') || []) &&
          groups.length > 0
            ? groups.join(',')
            : null,
        // regions: natcos.length === 0 && regions.length > 0 ? regions.join(',') : null,
        // natcos: natcos.length > 0 ? natcos.join(',') : null,
        suppliers: supplierId
          ? String(supplierId)
          : suppliers.join(',') || null,
        search,
      };

      setFilter({
        statuses: '0,1',
        ...filter,
        ...newFilter,
      });
    }
  }, [domains, categories, groups, regions, suppliers, isReady, searchParams]);

  useEffect(() => {
    if (filter) {
      setOtherFilters(
        <NegotiationFilters
          metaData={metaData}
          filters={filter}
          onFiltersChange={setFilter}
        />,
      );

      setIsLoading(true);
      setNegotiations([]);
      setMetaData(null);

      apiClient
        .get('/negotiations', {
          params: {
            ...filter,
            suppliers: supplierId
              ? String(supplierId)
              : suppliers.join(',') || null,
            page,
            perPage,
            sortBy: orderBy,
            order: order.toUpperCase(),
          },
        })
        .then((res) => {
          setLength(res.data.pagination.total);
          setNegotiations(res.data.records);
          setMetaData(res.data.filters);
          setIsLoading(false);
          setOtherFilters(
            <NegotiationFilters
              metaData={res.data.filters}
              filters={filter}
              onFiltersChange={setFilter}
            />,
          );
        })
        .catch((error) => {
          // TODO: handle error response
          console.log(error);
        });
    }
  }, [filter, page, perPage, order, orderBy]);

  useEffect(() => () => setOtherFilterValues(null), []);

  return (
    <>
<ButtonGroup sx={{ m: 2 }}>
          <Button
            variant={tableView ? 'contained' : 'outlined'}
            onClick={() => setView(true)}
          >
            <TableViewIcon /> List view
          </Button>
          <Button
            variant={tableView ? 'outlined' : 'contained'}
            onClick={() => setView(false)}
          >
           <ForkRightSharpIcon /> Roadmap view
          </Button>
        </ButtonGroup>
        <Divider />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        p={2}
      >
        <Pagination
          count={Math.ceil(length / perPage)}
          page={page}
          onChange={handlePageChange}
        />
      </Stack>

      {tableView ? (
        <TableViewList
          setNegotiationId={setNegotiationId}
          negotiations={negotiations}
          isLoading={isLoading}
        />
      ) : (
        <RoadmapViewList
          setNegotiationId={setNegotiationId}
          negotiations={negotiations}
          isLoading={isLoading}
          filter={{
            suppliers: supplierId && String(supplierId),
            ...filter,
            page: 1,
            perPage: 0,
            // statuses: null,
          }}
        />
      )}
      <NegotiationDetails
        negotiationId={negotiationId}
        onClose={() => {
          const newSearchParams = createSearchParams();
          if (domainNodeId && domainNodeId !== ALL) {
            newSearchParams.set('navDomain', domainNodeId);
          } else {
            newSearchParams.delete('navDomain');
          }
          if (groupNodeId && groupNodeId !== ALL) {
            newSearchParams.set('navGroup', groupNodeId);
          } else {
            newSearchParams.delete('navGroup');
          }
          const search = searchParams.get('search');
          if (search) {
            newSearchParams.set('search', search);
          }

          navigate({
            pathname: `${pathPrefix}/negotiations`,
            search: newSearchParams.toString(),
          });
          setIsFrozen(false);
          setNegotiationId(null);
        }}
        setNegotiationId={setNegotiationId}
      />
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        p={2}
      >
        <Pagination
          count={Math.ceil(length / perPage)}
          page={page}
          onChange={handlePageChange}
        />
      </Stack>
    </>
  );
}
