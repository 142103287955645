import {
  Box,
  Breadcrumbs,
  Chip,
  Divider,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Layout from '../../Layout/Layout';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Guard from '../../shared/Guard/Guard';
import { Role } from '../../../data';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import { Editor } from 'material-jsoneditor';
import { useState } from 'react';
import * as yup from 'yup';
import apiClient from '../../../services/apiClient';
import { Field, Formik, Form } from 'formik';
import { useEffect } from 'react';
import { Home } from '@mui/icons-material';

interface TextFieldProps {
  label: string;
  name: 'reportId' | 'datasetId' | 'workplaceId';
  multiline?: boolean;
  xs: number;
}

export default function SpendReportManager() {
  const [settingsString, setSettingsString] = useState('');
  const [filtersString, setFiltersString] = useState<null | string>(null);
  const [isEditable, setIsEditable] = useState(false);

  const validationSchema = yup.object().shape({
    reportId: yup.string().required('the spend report Id is Required'),
    datasetId: yup.string().required('the spend dataaset Id is Required'),
    workplaceId: yup.string().required('the spend workplace Id is Required'),
    settings: yup.object().typeError("'settings' must be a json object"),
    filters: yup
      .array()
      .typeError("'filters' must be an array of json object(s)"),
  });

  const [initialValues, setInitialValues] = useState<any>();

  const textFilelds: TextFieldProps[] = [
    {
      label: 'Report Id',
      name: 'reportId',
      xs: 6,
    },
    {
      label: 'Dataset Id',
      name: 'datasetId',
      xs: 6,
    },
    {
      label: 'Workplace Id',
      name: 'workplaceId',
      xs: 12,
    },
  ];

  const jsonEditors: any[] = [
    {
      label: 'Settings',
      name: 'settings',
      inputValue: settingsString,
      setInputValue: setSettingsString,
      test: (e: any) => {
        try {
          JSON.parse(e);
          return true;
        } catch (error) {
          return false;
        }
      },
    },
    {
      label: 'Filters',
      name: 'filters',
      inputValue: filtersString,
      setInputValue: setFiltersString,
      test: (e: any) => {
        try {
          JSON.parse(e);
          return Array.isArray(e);
        } catch (error) {
          return false;
        }
      },
    },
  ];

  useEffect(() => {
    apiClient
      .get('spends/1')
      .then((res) => {
        setInitialValues(
          res.data.record || {
            reportId: '',
            datasetId: '',
            workplaceId: '',
            settings: {},
            filters: [],
          },
        );

        setSettingsString(
          res.data.record.settings
            ? JSON.stringify(res.data.record.settings)
            : '',
        );
        setFiltersString(
          res.data.record.filters
            ? JSON.stringify(res.data.record.filters)
            : '',
        );
      })
      .catch((error) => {
        setInitialValues({
          reportId: '',
          datasetId: '',
          workplaceId: '',
          settings: {},
          filters: [],
        });
        setSettingsString('');
        setFiltersString('');
      });
  }, []);
  return (
    <Layout noBreadcrumbs>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        sx={{ p: 2 }}
      >
        <Typography>Admin</Typography>
        <Typography>Spend & Financials</Typography>,
      </Breadcrumbs>
      <Divider />
      <Guard role={Role.PowerBIManager} message>
        <>
          {initialValues && (
            <Formik
              validationSchema={validationSchema}
              initialValues={initialValues}
              onSubmit={(
                report: any,
                {
                  setSubmitting,
                  resetForm,
                }: { setSubmitting: any; resetForm: any },
              ) => {
                setSubmitting(true);
                console.log(report);
                apiClient
                  .post('/spends', {
                    ...report,
                    id: 1,
                  })
                  .then((res) => {
                    setInitialValues(res.data);

                    setSettingsString(
                      res.data.record.settings
                        ? JSON.stringify(res.data.record.settings)
                        : '',
                    );
                    setFiltersString(
                      res.data.record.filters
                        ? JSON.stringify(res.data.record.filters)
                        : '',
                    );
                  })
                  .catch((e) => {
                    console.log(e);
                  })
                  .finally(() => {
                    resetForm();
                    setSubmitting(false);
                    setIsEditable(false);
                  });
              }}
            >
              {({
                errors,
                touched,
                handleSubmit,
                submitForm,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid container sx={{ p: 1 }}>
                    {textFilelds.map((prop: TextFieldProps, index) => (
                      <Grid item xs={prop.xs} sx={{ p: 0.5 }} key={index}>
                        <Field name={prop.name}>
                          {({ field }: { field: any }) => (
                            <TextField
                              {...field}
                              margin="dense"
                              variant="filled"
                              multiline={prop.multiline || false}
                              helperText={
                                Boolean(touched[prop.name] && isEditable) &&
                                errors[prop.name]
                              }
                              error={Boolean(
                                errors[prop.name] &&
                                  touched[prop.name] &&
                                  isEditable,
                              )}
                              label={prop.label}
                              fullWidth
                              disabled={!isEditable}
                            />
                          )}
                        </Field>
                      </Grid>
                    ))}
                    {jsonEditors.map((jsonEditor, key) => (
                      <Field name={jsonEditor.name} key={key}>
                        {({ field }: { field: any }) => (
                          <>
                            <Grid sm={6} sx={{ p: 1 }} item>
                              <TextField
                                id="outlined-multiline-static"
                                label={jsonEditor.label}
                                multiline
                                rows={10.4}
                                fullWidth
                                value={jsonEditor.inputValue}
                                helperText={
                                  Boolean(
                                    touched[jsonEditor.name] && isEditable,
                                  ) && errors[jsonEditor.name]
                                }
                                error={Boolean(
                                  errors[jsonEditor.name] &&
                                    touched[jsonEditor.name] &&
                                    isEditable,
                                )}
                                disabled={!isEditable}
                                variant="filled"
                                onChange={(event: any) => {
                                  try {
                                    if (!event.target.value) {
                                      setFieldValue(jsonEditor.name, null);
                                    }
                                    setFieldValue(
                                      jsonEditor.name,
                                      JSON.parse(event.target.value),
                                    );
                                    jsonEditor.setInputValue(
                                      event.target.value,
                                    );
                                  } catch (e) {
                                    jsonEditor.setInputValue(
                                      event.target.value,
                                    );
                                    setFieldValue(
                                      jsonEditor.name,
                                      event.target.value,
                                    );
                                  }
                                }}
                              />
                            </Grid>
                            <Grid sx={{ p: 1 }} sm={6} item>
                              {isEditable && (
                                <Editor
                                  {...field}
                                  onChange={(e: any) => {
                                    if (jsonEditor?.test(e)) {
                                      jsonEditor.setInputValue(
                                        JSON.stringify(e, null, 2),
                                      );
                                    }
                                  }}
                                />
                              )}
                              {!isEditable && (
                                <>
                                  <Chip
                                    label="Root"
                                    icon={<Home fontSize="medium" />}
                                    size="small"
                                    sx={{ p: 0.5 }}
                                  />
                                  <Stack
                                    display="flex"
                                    flex="1"
                                    width="100%"
                                    direction="column"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                    spacing={2}
                                  >
                                    <TextField
                                      size="small"
                                      label={'Type'}
                                      fullWidth
                                      value={' '}
                                      disabled
                                      sx={{ mt: 2 }}
                                    />
                                  </Stack>
                                </>
                              )}
                            </Grid>
                          </>
                        )}
                      </Field>
                    ))}
                  </Grid>

                  <Stack direction="row-reverse" spacing={1} sx={{ p: 1.5 }}>
                    <LoadingButton
                      loading={isSubmitting}
                      loadingPosition="start"
                      startIcon={<SaveIcon />}
                      onClick={submitForm}
                      disabled={!isEditable}
                    >
                      Save
                    </LoadingButton>
                    <Stack direction="row">
                      <Switch
                        onChange={() => setIsEditable(!isEditable)}
                        checked={isEditable}
                      />
                      <Box
                        sx={{
                          textAlign: 'center',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          color={isEditable ? 'primary' : 'rgba(0, 0, 0, 0.26)'}
                          sx={{ fontWeight: 500 }}
                        >
                          EDIT
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Form>
              )}
            </Formik>
          )}
        </>
      </Guard>
    </Layout>
  );
}
