export default function SustainabilityIcon({
  className = 'buyin-icon',
}: {
  className?: string;
}) {
  return (

    <svg className="buyin-icon"
      xmlns="http://www.w3.org/2000/svg"  
      width="20" height="20" viewBox="0 0 24 24">
      <defs>
        <path id="9n47j6s9na" d="M0 0h21.834v21.832H0z" />
      </defs>
      <g transform="translate(1 1)" fill="none" fill-rule="evenodd">
        {/* <mask id="l6plyn7vib" fill="#fff">
          <use xlink:href="#9n47j6s9na" />
        </mask> */}
        <path d="m3.474 19.065-.726.726-.56.562-.007.004a.505.505 0 0 1-.695 0c-.002-.002-.005-.002-.006-.004a.5.5 0 0 1 0-.707l.56-.561.725-.725 2.068-2.068V11.5a.5.5 0 1 1 1 0v3.792l1.555-1.556 1.945-1.944V8.5a.5.5 0 1 1 1 0v2.292l3-3V6.5a.5.5 0 1 1 1 0v1h1a.5.5 0 1 1 0 1H14.04l-3 3h2.294a.5.5 0 1 1 0 1H10.04l-1.944 1.944L6.54 16h3.794a.5.5 0 1 1 0 1H5.54l-2.066 2.065zM21.687.145a.5.5 0 0 0-.408-.142C20.591.078 4.394 1.942.727 9.73c-1.187 2.52-.919 5.363.787 8.467l-.74.742c-.284.283-.44.66-.44 1.06s.156.778.44 1.06c.282.284.66.44 1.06.44s.777-.156 1.06-.44l.745-.745c1.828 1.003 3.575 1.519 5.206 1.519 4.4 0 8.045-3.717 10.54-10.75C21.23 5.88 21.824.608 21.83.554a.502.502 0 0 0-.144-.407z" fill="#E32119" mask="url(#l6plyn7vib)" />
      </g>
    </svg>
  );
}
