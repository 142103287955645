import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import apiClient from '../../services/apiClient';

import { Masonry } from '@mui/lab';
import { grey } from '@mui/material/colors';
import { useNav } from '../NavProvider';

function OperationalSkeleton() {
  return (
    <Grid padding={0.5} sm={6}>
      <Card sx={{ border: '1px solid lightgrey' }}>
        <CardHeader
          avatar={
            <Avatar
              sx={{
                width: 60,
                height: 60,
                border: '0.1px solid lightgray',
              }}
            >
              <></>
            </Avatar>
          }
          title={<Skeleton height={10} width="50%" />}
          subheader={<Skeleton height={10} width="30%" />}
        ></CardHeader>
        <Divider />
        <CardContent>
          <Skeleton height={10} width="20%" />

          <Skeleton height={10} width="50%" />

          <Stack direction={'row'} spacing={0.5} alignItems="center">
            <EmailIcon sx={{ color: grey[400] }} />
            <Skeleton height={10} width="40%" />
          </Stack>
          <Stack direction={'row'} spacing={0.5} alignItems="center">
            <StayCurrentPortraitIcon sx={{ color: grey[400] }} />
            <Skeleton height={10} width="20%" />
          </Stack>
          <Stack direction={'row'} spacing={0.5} alignItems="center">
            <PhoneEnabledIcon sx={{ color: grey[400] }} />
            <Skeleton height={10} width="20%" />
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
}
export default function Operational({
  filters,
  setCount,
}: {
  filters: {
    supplierId: number | null;
    page: number | null;
    perPage: number | null;
  };
  setCount: (count: number) => void;
}) {
  const [operationals, setOperationals] = useState([]);
  const { supplierId, page, perPage } = filters;
  const [isLoading, setIsLoading] = useState(true);
  const { supplier } = useNav();

  useEffect(() => {
    if (supplierId) {
      setIsLoading(true);
      apiClient
        .get(`operationals`, {
          params: {
            suppliers: supplierId,
            page,
            perPage,
          },
        })
        .then(({ data }) => {
          setCount(data.pagination.total);
          setOperationals(data.records);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [supplierId, page, perPage]);

  return (
    <Box sx={{ p: 2, pt: 0.5 }}>
      {!isLoading && (
        <Box sx={{ p: 1 }}>
          <Typography color={'primary'}>OPERATIONAL</Typography>
          <Divider />
        </Box>
      )}

      <Grid container sx={{ mt: 2 }}>
        {isLoading &&
          Array(6)
            .fill(null)
            .map((element, index) => <OperationalSkeleton key={index} />)}
        {!isLoading &&
          (operationals || []).map((operational: any, i: number) => {
            return (
              <Grid padding={0.5} sm={6} key={i}>
                <Card sx={{ height: '98%', border: '1px solid lightgrey' }}>
                  <CardHeader
                    sx={{ p: 1 }}
                    avatar={
                      <Avatar
                        sx={{
                          width: 60,
                          height: 60,
                          border: '0.1px solid lightgray',
                          bgcolor: 'lightgrey',
                        }}
                      >
                        <Typography sx={{ color: 'red' }}>
                          {operational.firstName[0]}
                        </Typography>
                        <Typography sx={{ color: 'red' }}>
                          {' '}
                          {operational.lastname[0]}
                        </Typography>
                      </Avatar>
                    }
                    title={
                      <Box
                        sx={{
                          typography: 'body1',
                          textTransform: 'uppercase',
                          fontWeight: 'medium',
                        }}
                      >
                        {operational.firstName} {operational.lastname}
                      </Box>
                    }
                    subheader={
                      <Typography sx={{ fontSize: '11px' }}>
                        {operational.jobTitle}
                      </Typography>
                    }
                  ></CardHeader>
                  <Box sx={{ p: 1 }}>
                    <Divider sx={{ background: 'red' }} />
                  </Box>
                  <CardContent sx={{ p: 1 }}>
                    <Typography variant="subtitle2">Address:</Typography>
                    <Typography>{operational.address}</Typography>
                    <br />
                    <Typography variant="subtitle2">Contact:</Typography>
                    {operational.email && (
                      <Stack
                        direction={'row'}
                        spacing={0.5}
                        alignItems="center"
                      >
                        <EmailIcon color="primary" />
                        <Typography>{operational.email}</Typography>
                      </Stack>
                    )}
                    {operational.fixed && (
                      <Stack
                        direction={'row'}
                        spacing={0.5}
                        alignItems="center"
                      >
                        <StayCurrentPortraitIcon color="primary" />
                        <Typography>{operational.fixed}</Typography>
                      </Stack>
                    )}
                    {operational.mobile && (
                      <Stack
                        direction={'row'}
                        spacing={0.5}
                        alignItems="center"
                      >
                        <PhoneEnabledIcon color="primary" />
                        <Typography>{operational.mobile}</Typography>
                      </Stack>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        {!isLoading && (operationals || []).length === 0 && (
          <>
            <Typography variant="h5">
              No operational profiles available for {supplier?.name}
            </Typography>
          </>
        )}
      </Grid>
    </Box>
  );
}
