import axios from 'axios';
import { UserManager } from 'oidc-client';
import { authSettings } from '../data';

const newsClient = axios.create({
  baseURL: process.env.REACT_APP_NEWS_API,
});

newsClient.interceptors.request.use(async (config) => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  config.headers = {
    Authorization: `Bearer ${user?.access_token}`,
    ...config.headers,
  };
  config.data = {
    apiKEY: ['32eaa8dfeeba6a956c4b032d931e860f321d6180c76700a7'],
    ...config.data,
  };
  return config;
});

export default newsClient;
