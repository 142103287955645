import moment from 'moment';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import DiamondIcon from '@mui/icons-material/Diamond';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import { INegotiation } from '../../../types';
import RowsSkeleton from '../../shared/RowsSkeleton/RowsSkeleton';
import NegotiationStatus from './NegotiationStatus/NegotiationStatus';
import { useNavigate } from 'react-router';
import { useNav } from '../../NavProvider';
import { EmptyRow } from '../../ui/mui/EmptyRow/EmptyRow';
import NewRibbon from '../../shared/NewRibbon/NewRibbon';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

export default function TableViewList({
  negotiations,
  isLoading,
  setNegotiationId,
}: {
  isLoading: boolean;
  negotiations: INegotiation[];
  setNegotiationId: (id: number | null) => void;
}) {
  interface HeadCell {
    name: string;
    label: string;
    sortBy?: string;
    px?: number;
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { supplierId, setIsFrozen } = useNav();
  const pathPrefix = supplierId ? `/suppliers/${supplierId}` : '/domains';

  const headCells: HeadCell[] = [
    { name: 'group', label: 'Group', px: 2 },
    { name: 'category', label: 'Category', px: 0.5 },
    { name: 'region', label: 'Region', px: 0.5 },
    { name: 'natcos', label: 'Natcos', px: 0.5 },
    { name: 'title', label: 'Title', px: 0 },
    { name: 'isHot', label: 'Hot', px: 0 },
    { name: 'isTcap', label: 'TC@P', px: 0 },
    { name: 'supplier', label: 'Supplier', px: 0.5 },
    { name: 'startDate', label: 'Start', px: 1 },
    { name: 'endDate', label: 'End', px: 2 },
    { name: 'status', label: 'Status', px: 2 },
  ];

  return (
    <>
      <TableContainer component="div">
        <Table aria-label="collapsible table">
          <TableHead
            sx={(theme) => ({ backgroundColor: theme.palette.grey[300] })}
          >
            <TableRow>
              {headCells.map((cell) => (
                <TableCell sx={{ px: cell.px || 1, py: 1 }} key={cell.name}>
                  {cell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <RowsSkeleton headLength={headCells.length} />
            ) : (
              <>
                {negotiations.length > 0 ? (
                  negotiations.map((negotiation) => (
                    <TableRow
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setIsFrozen(true);
                        const search = searchParams.get('search');
                        navigate(search ? `${pathPrefix}/negotiations/${negotiation.id}?search=${search}` : `${pathPrefix}/negotiations/${negotiation.id}`);
                        setNegotiationId(negotiation.id);
                      }}
                      key={negotiation.id}
                      style={{ position: 'relative' }}
                    >
                      <TableCell sx={{ py: 1 }}>
                        {negotiation.groups
                          .map((group) => group.code)
                          .join(',')}
                      </TableCell>
                      <TableCell sx={{ px: 0.5 }}>
                        {negotiation.category.code}
                      </TableCell>
                      <TableCell sx={{ px: 0.5 }}>
                        <Tooltip
                          title={negotiation.regions
                            .map((region) => region.name)
                            .sort()
                            .join(' ,')}
                        >
                          <Typography noWrap sx={{ maxWidth: 80 }}>
                            {negotiation.regions
                              .map((region) => region.name)
                              .sort()
                              .join(' ,')}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ px: 0.5 }}>
                        <Tooltip
                          title={negotiation.natcos
                            .map((natco) => natco.name)
                            .sort()
                            .join(' ,')}
                        >
                          <Typography noWrap sx={{ maxWidth: 80 }}>
                            {negotiation.natcos
                              .map((natco) => natco.code)
                              .sort()
                              .join(' ,')}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ px: 0.5 }}>
                        <Tooltip title={negotiation.title}>
                          <Typography noWrap sx={{ maxWidth: 180 }}>
                            {negotiation.title}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ px: 0.5 }}>
                        {negotiation.isHot && (
                          <LocalFireDepartmentIcon color="primary" />
                        )}
                      </TableCell>
                      <TableCell sx={{ px: 0.5 }}>
                        {negotiation.isTcap && <DiamondIcon color="primary" />}
                      </TableCell>
                      <TableCell sx={{ px: 0.5 }}>
                        {/* {getFirstValues(negotiation.suppliers, 1)} */}
                        <Tooltip
                          title={negotiation.suppliers
                            .map((supplier) => supplier.name)
                            .sort()
                            .join(' ,')}
                        >
                          <Typography noWrap sx={{ maxWidth: 100 }}>
                            {negotiation.suppliers
                              .map((supplier) => supplier.name)
                              .sort()
                              .join(' ,')}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell sx={{ px: 0.5 }}>
                        {moment(negotiation?.startDate).format('MMMYY')}
                      </TableCell>
                      <TableCell>
                        {moment(negotiation?.endDate).format('MMMYY')}
                      </TableCell>
                      <TableCell sx={{ px: 0.5 }}>
                        <NewRibbon
                          postDate={negotiation?.startDate}
                          postType={'programs'}
                        />
                        <NegotiationStatus value={negotiation.status} />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <EmptyRow
                    colSpan={11}
                    message="No data was found for the selected criteria"
                  />
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
