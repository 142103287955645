import { Close } from '@mui/icons-material';
import { Alert, Box, IconButton } from '@mui/material';
import { useEffect } from 'react';

export function ErrorAlert({
  message,
  timeout = 0,
  severity = 'error',
  onClose,
}: {
  message: string;
  timeout?: number;
  severity?: 'error' | 'warning' | 'info' | 'success';
  onClose?: () => void;
}) {
  useEffect(() => {
    if (timeout > 0) {
      const timer = setTimeout(() => {
        onClose?.();
      }, timeout * 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [timeout, onClose]);

  return (
    <Box sx={{ width: '100%', padding: 2 }}>
      <Alert
        severity={severity}
        action={
          onClose && (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                onClose();
              }}
            >
              <Close fontSize="inherit" />
            </IconButton>
          )
        }
        sx={{ mb: 2 }}
      >
        {message}
      </Alert>
    </Box>
  );
}
