import React, { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RotateLeft from '@mui/icons-material/RotateLeft';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
} from '@mui/material';
import RecordFilterSelect from '../../ContractList/ContractFilter/RecordFilterSelect/RecordFilterSelect';

interface Filter {
  field_id: number;
  filterName: string;
  filterType: number;
  values: any[];
}
export default function ProductCatalogFilters(props: {
  productCatalogId: number | null | undefined;
  filtersData: any[];
  onFiltersChange: any;
  filters: any;
  isLoadingFilters: boolean;
}) {
  const [selectedFilters, setSelectedFilters] = useState({});
  const [savedFilters, setSavedFilters] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [slideValue, setSlideValue] = useState();
  const [filterValues, setFilterValues] = useState<any>();

  const handleSliderChange = (
    event: Event | undefined,
    newValue: any,
    source: any,
    filterName: any,
  ) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    newFilters[source] = { label: filterName, value: newValue };
    setSelectedFilters(newFilters);
    props.onFiltersChange(newFilters);
  };

  const handleDropdownChange = (event: any, source: any, filterName: any) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    newFilters[source] = {
      label: filterName,
      value: [{ name: event.target.value }],
    };
    setSelectedFilters(newFilters);
    if (newFilters) {
      props.onFiltersChange(newFilters);
    }
  };

  const handleRecordChange = (
    source: any,
    checked: any,
    record: any,
    id: any,
    label: any,
  ) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    if (checked) {
      newFilters[source] = {
        label: label,
        value: [...(newFilters[source]?.value || []), record],
      };
    } else {
      newFilters[source] = {
        label: label,
        value: newFilters[source]?.value.filter(
          (item: any) => item.id !== record.id,
        ),
      };
    }
    setSelectedFilters(newFilters);
  };

  const handleFilterCancel = (source: string) => {
    setSelectedFilters(savedFilters);
  };

  const handleFilterApply = (
    key: string,
    newValue: Date | null,
    id: any,
    label: any,
  ) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    props.onFiltersChange(newFilters);
  };

  const handleFilterClear = (source: string, id: any, label: any) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    newFilters[source] = { label: label, value: [] };
    setSelectedFilters(newFilters);
    props.onFiltersChange(newFilters);
  };

  const handleModalOpen = () => {
    setSavedFilters(selectedFilters);
  };
  const getMinValue = (source: any) => {
    const filterValuesData = props.filtersData
      .find((fd) => fd['field_id'] === source)
      .values.filter((f: any) => !isNaN(Number(f)) && f !== null)
      .map((fv: any) => Number(fv));
    return Math.min(...filterValuesData);
  };
  const getValues = (source: any) => {
    const filterValuesData = props.filtersData
      .find((fd) => fd['field_id'] === source)
      .values.filter((f: any) => !isNaN(Number(f)) && f !== null)
      .map((fv: any) => (isNaN(Number(fv)) ? 0 : Number(fv)));
    return filterValuesData;
  };
  const getMaxValue = (source: any) => {
    const filterValuesData = props.filtersData
      .find((fd) => fd['field_id'] === source)
      .values.filter((f: any) => !isNaN(Number(f)) && f !== null)
      .map((fv: any) => (isNaN(Number(fv)) ? 0 : Number(fv)));
    return Math.max(...filterValuesData);
  };

  const handleReset = () => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    Object.entries(newFilters).forEach(([fieldId, values]: any) => {
      newFilters[fieldId] = { label: '', value: [] };
    });
    setSlideValue(undefined);
    setSelectedFilters(newFilters);
    props.onFiltersChange(newFilters);
  };

  const areAllFiltersEmpty = () => {
    for (const key in props.filters) {
      if (props.filters[key].value.length > 0) {
        return false;
      }
    }
    return true;
  };

  const getLabelStyle = () => {
    return {
      color: '#757575',
      top: '7px',
      backgroundColor: 'unset',
    };
  };

  useEffect(() => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    if (newFilters) {
      setFilterValues(
        Object.keys(newFilters).map((key: any, index: any) => {
          return (
            newFilters[key].value.length > 0 && (
              <React.Fragment key={key}>
                <Chip
                  key={key}
                  onDelete={() => {
                    console.log('i delete');
                    const newFilters = JSON.parse(
                      JSON.stringify(selectedFilters),
                    );
                    newFilters[key] = {
                      label: newFilters[key].label,
                      value: [],
                    };
                    setSelectedFilters(newFilters);
                    props.onFiltersChange(newFilters);
                  }}
                  label={
                    newFilters[key].label +
                    ': ' +
                    newFilters[key].value
                      .map((n: any) => n.name || n)
                      .join(' - ')
                  }
                  size="small"
                  sx={{ m: 0.5 }}
                />
              </React.Fragment>
            )
          );
        }),
      );
    }
  }, [selectedFilters]);

  return (
    <>
      <Box p={1}>
        {props.isLoadingFilters && (
          <Paper elevation={3}>
            <Box
              component="div"
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          </Paper>
        )}

        {!props.isLoadingFilters && (
          <>
            {' '}
            <Accordion
              expanded={expanded}
              onChange={() => setExpanded(!expanded)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography component="span" color="primary">
                  {expanded ? (
                    <Stack direction="row">
                      <RemoveIcon />
                      Hide filters
                    </Stack>
                  ) : (
                    <Stack direction="row">
                      <AddIcon />
                      <Typography>Show filters</Typography>
                    </Stack>
                  )}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  {props.filtersData.map((fd: Filter, index: any) => (
                    <Grid item xs={4}>
                      {/* {props.filtersData[fd.field_id]} */}
                      {fd.filterType === 2 && (
                        <>
                          <Typography
                            id="input-slider"
                            gutterBottom
                            sx={{ color: '#e41b13' }}
                          >
                            {fd.filterName}
                          </Typography>
                          <Slider
                            sx={{
                              mt: -1,
                              '& .MuiSlider-valueLabel': getLabelStyle(),
                            }}
                            size="small"
                            value={
                              slideValue || [
                                getMinValue(fd.field_id),
                                getMaxValue(fd.field_id),
                              ]
                            }
                            onChangeCommitted={(event: any, newValue: any) => {
                              setSlideValue(newValue);
                              setTimeout(() => {
                                handleSliderChange(
                                  event,
                                  newValue as number,
                                  fd.field_id,
                                  fd.filterName,
                                );
                              }, 500);
                            }}
                            min={getMinValue(fd.field_id)}
                            max={getMaxValue(fd.field_id)}
                            valueLabelDisplay="on"
                            marks={getValues(fd.field_id).map((fd: any) => {
                              return { value: fd };
                            })}
                          />
                        </>
                      )}
                      {fd.filterType === 1 && (
                        <FormControl sx={{ width: '100%' }} error size="small">
                          <InputLabel>{fd.filterName}</InputLabel>
                          <Select
                            error
                            value={
                              // @ts-ignore
                              (selectedFilters[fd.field_id]?.value &&
                                // @ts-ignore
                                selectedFilters[fd.field_id]?.value[0]?.name) ||
                              null
                            }
                            onChange={(event) =>
                              handleDropdownChange(
                                event,
                                fd.field_id,
                                fd.filterName,
                              )
                            }
                          >
                            {fd.values.map((ft: any) => (
                              <MenuItem value={ft}>{ft}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}

                      {fd.filterType === 0 && (
                        <RecordFilterSelect
                          key={index}
                          source={fd.field_id}
                          property={'name'}
                          id={fd.field_id}
                          label={fd.filterName}
                          // @ts-ignore
                          selected={selectedFilters[fd.field_id]?.value || []}
                          onRecordChange={handleRecordChange}
                          onFilterApply={handleFilterApply}
                          onFilterCancel={handleFilterCancel}
                          displaySelectedNumber={true}
                          productCatalogRecords={fd.values}
                          isProductCatalog={true}
                          onFilterClear={handleFilterClear}
                          onModalOpen={handleModalOpen}
                        />
                      )}
                    </Grid>
                  ))}
                  <Grid item container>
                    <Button
                      disabled={areAllFiltersEmpty()}
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleReset}
                      startIcon={<RotateLeft />}
                    >
                      Reset filters
                    </Button>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>{' '}
            {filterValues}
          </>
        )}
      </Box>
    </>
  );
}
