import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import {
  Avatar,
  Box,
  // Button,
  // ButtonGroup,
  CircularProgress,
  Divider,
  Grid,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { TreeItem, TreeView } from '@mui/lab';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/Info';
import { grey } from '@mui/material/colors';
// import moment from 'moment';
// import TrendGraph from './TrendGraph';
// import { DataGrid, GridColDef } from '@mui/x-data-grid';
import apiClient from '../../../services/apiClient';

// interface PriceData {
//   RowID: number;
//   Product_Name: string;
//   NatCoCode: string;
//   Local_Currency_Code: string;
//   Price_Local: number;
//   Conversion_Rate_Eur: number;
//   Price_In_Euro: number;
//   Comment: string;
//   Date_of_PricePoint: number;
//   Price_Type: string;
//   ModifiedOn: string;
//   UserID: string;
//   SMA_ID: string;
// }

export default function ProductDetail(props: {
  handleProductDialogClose: any;
  productId: number | undefined;
  // technicalProductId: number | undefined | null;
  open: boolean;
}) {
  const [allSections, setAllSections] = useState([]);
  const [product, setProduct] = useState<any>(null);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState<string>('details');
  // const [priceList, setPriceList] = useState<any[]>([]);
  // const [groupedData, setGroupedData] = useState<any[]>([]);
  // const [refreshPriceList, setRefreshPriceList] = useState<boolean>(false);
  // const [isTrends, setIsTrends] = useState<boolean>(false);

  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 400,
      padding: 0,
    },
    item: {
      padding: 0,
    },
    backDrop: {
      backdropFilter: 'blur(3px)',
    },
  }));
  const classes = useStyles();
  function getAllIds(arr: any) {
    const ids: any[] = [];
    arr.forEach((obj: any) => {
      ids.push(obj.id.toString());
      if (obj.subSections) {
        ids.push(...getAllIds(obj.subSections));
      }
    });
    return ids;
  }
  // function groupDataByPriceType(data: PriceData[]) {
  //   const groupedData: any = {};

  //   data.forEach((entry) => {
  //     if (!groupedData[entry.Price_Type]) {
  //       groupedData[entry.Price_Type] = [];
  //     }
  //     entry.Date_of_PricePoint = new Date(entry.Date_of_PricePoint).getTime();
  //     groupedData[entry.Price_Type].push(entry);
  //   });

  //   return groupedData;
  // }

  // useEffect(() => {
  //   apiClient
  //     .get('/price', {
  //       params: {
  //         productId: props.technicalProductId,
  //       },
  //     })
  //     .then((res) => {
  //       setPriceList(res.data.record);

  //       const sortedData: any = res.data.record
  //         .slice()
  //         .sort(
  //           (a: any, b: any) =>
  //             moment(a.Date_of_PricePoint).valueOf() -
  //             moment(b.Date_of_PricePoint).valueOf(),
  //         );

  //       setGroupedData(groupDataByPriceType(sortedData));
  //       setRefreshPriceList(!refreshPriceList);
  //     });
  // }, [props.technicalProductId]);
  useEffect(() => {
    setProduct(null);
    setIsLoading(true);
    apiClient.get(`/product/treeView/${props.productId}`).then((res) => {
      setAllSections(res.data);
      setExpanded(getAllIds(res.data));
      setIsLoading(false);
    });
    apiClient.get(`/product/${props.productId}`).then((res) => {
      setProduct(res.data);
    });
  }, [props.productId]);
  const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const getTreeStyle = () => {
    return {
      marginLeft: '0 !important',
    };
  };
  const DataTreeView = ({ treeItems }: any) => {
    return (
      <TreeView
        aria-label="section tree"
        defaultCollapseIcon={<RemoveIcon style={{ color: '#e41b13' }} />}
        defaultExpandIcon={<AddIcon style={{ color: '#e41b13' }} />}
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          '.MuiCollapse-root': getTreeStyle(),
        }}
        expanded={expanded}
        onNodeToggle={handleToggle}
        multiSelect
      >
        {getTreeItemsFromData(treeItems)}
      </TreeView>
    );
  };

  function findElementById(id: any, sections: any) {
    for (let section of sections) {
      if (section.id === id) {
        return section;
      } else if (section.subSections) {
        let result: any = findElementById(id, section.subSections);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  const haveSubSections = (id: any) => {
    return findElementById(id, allSections)?.subSections?.length >= 1;
  };

  const getTreeItemsFromData = (treeItems: any) => {
    return treeItems.map((treeItemData: any) => {
      let children;
      if (treeItemData.subSections && treeItemData.subSections.length > 0) {
        children = getTreeItemsFromData(treeItemData.subSections);
      }

      const getContainerStyle = (treeItemData: any) => {
        return {
          marginTop: treeItemData.parentId === null ? '12px' : 'unset',
        };
      };
      const getIconContainerStyle = (treeItemData: any) => {
        return {
          borderTop: treeItemData.parentId === null ? 1 : 'none',
          borderBottom: treeItemData.type !== 'field' ? 1 : 'none',
          borderColor:
            treeItemData.title === undefined
              ? 'white'
              : treeItemData.parentId === null
              ? 'black'
              : grey[300],
          marginRight: '0px',
          padding:
            treeItemData.title === undefined
              ? '0px'
              : haveSubSections(treeItemData.id)
              ? '3px'
              : '12px',
        };
      };
      const getLabelStyle = (treeItemData: any) => {
        return {
          fontWeight: treeItemData.parentId === null ? 'bold' : 10,
          color: treeItemData.parentId === null ? 'black' : grey[800],
          borderTop: treeItemData.parentId === null ? 1 : 'none',
          borderColor:
            treeItemData.title === undefined
              ? '#fff!important'
              : treeItemData.parentId === null
              ? 'black'
              : '#e0e0e0!important',
          borderImage:
            treeItemData.type === 'field'
              ? 'linear-gradient(to right, transparent 21px, #e0e0e0 68px) 1'
              : 'unset',

          borderBottom:
            treeItemData.type !== 'field'
              ? 1
              : treeItemData.type === 'field'
              ? 1
              : 'none',
          marginLeft:
            treeItemData.type === 'field'
              ? '17px'
              : treeItemData.parentId != null &&
                !haveSubSections(treeItemData.id)
              ? '-9px'
              : 'none',
        };
      };
      const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#f5f5f9',
          color: 'rgba(0, 0, 0, 0.87)',
          maxWidth: 220,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
        },
      }));
      return (
        <TreeItem
          key={treeItemData.id}
          nodeId={treeItemData.id.toString()}
          label={
            <div>
              {treeItemData.type === 'field' ? (
                <Grid container alignItems="center">
                  <Grid item xs={6} sx={{ ml: 4 }}>
                    <Stack direction="row" spacing={1}>
                      <Typography sx={{ fontSize: '13px', marginTop: 0.5 }}>
                        {treeItemData.title}
                      </Typography>
                      {treeItemData.tooltip && (
                        <HtmlTooltip
                          title={
                            <span
                              dangerouslySetInnerHTML={{
                                __html: treeItemData.tooltip,
                              }}
                            ></span>
                          }
                        >
                          <InfoIcon
                            sx={{
                              fontSize: '15px',
                              marginTop: '5px !important',
                            }}
                          />
                        </HtmlTooltip>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography sx={{ color: 'black', fontSize: '13px' }}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: treeItemData.value,
                        }}
                      ></span>
                    </Typography>
                  </Grid>
                </Grid>
              ) : treeItemData.parentId === null ? (
                <Typography sx={{ fontWeight: 'bold' }}>
                  {treeItemData.title}
                </Typography>
              ) : (
                <Typography sx={{ fontSize: '13px', marginTop: 0.5 }}>
                  {treeItemData.title}
                </Typography>
              )}
            </div>
          }
          sx={{
            '& .MuiTreeItem-label': getLabelStyle(treeItemData),
            '& .MuiTreeItem-iconContainer': getIconContainerStyle(treeItemData),
            '& .MuiTreeItem-content': getContainerStyle(treeItemData),
          }}
          disabled={false}
          children={children}
        />
      );
    });
  };

  // const columns: GridColDef[] = [
  //   {
  //     field: 'Product_Name',
  //     headerName: 'Product Name',
  //     width: 120,
  //   },
  //   {
  //     field: 'Date_of_PricePoint',
  //     headerName: 'Date of Price Point',
  //     width: 100,
  //     renderCell: (params) => (
  //       <Typography>
  //         {moment(params.row.Date_of_PricePoint).format('YYYY-MM')}
  //       </Typography>
  //     ),
  //   },
  //   {
  //     field: 'NatCoCode',
  //     headerName: 'Natco Code',
  //     width: 120,
  //   },
  //   {
  //     field: 'Price_Local',
  //     headerName: 'Price Local',
  //     width: 120,
  //   },
  //   {
  //     field: 'Conversion_Rate_Eur',
  //     headerName: 'Conversion Rate Eur',
  //     width: 140,
  //   },
  //   {
  //     field: 'Price_Type',
  //     headerName: 'Price Type',
  //     width: 100,
  //   },
  // ];

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleProductDialogClose}
        maxWidth="md"
        fullWidth
        BackdropProps={{
          classes: {
            root: classes.backDrop,
          },
        }}
      >
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={props.handleProductDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Stack direction="column">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Avatar
                  src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/product/images/${product?.productCatalogId}/${product?.imageReference}`}
                  sx={{
                    width: 90,
                    height: 'unset!important',
                    objectFit: 'cover',
                  }}
                  variant="rounded"
                />
              </Box>

              <Stack direction="row" spacing={2}>
                <Typography sx={{ fontSize: '15px' }}>Model</Typography>
                <Typography sx={{ fontWeight: 'bold', fontSize: '15px' }}>
                  {product?.name}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </DialogTitle>
        <Divider />
        <Tabs
          value={tab}
          onChange={(event, newTab) => setTab(newTab)}
          variant="fullWidth"
        >
          <Tab value="details" label="Details" />
          {/* {Object.keys(groupedData).length > 0 && (
            <Tab value="prices" label="Prices" />
          )} */}
        </Tabs>
        <Divider sx={{ mb: 1 }} />
        <DialogContent>
          {tab === 'details' && (
            <Stack direction="column">
              {!isLoading && (
                <Grid item xs={12} sx={{ m: 2, display: 'align-center' }}>
                  {allSections.length > 0 && (
                    <DataTreeView treeItems={allSections} />
                  )}
                </Grid>
              )}
            </Stack>
          )}
          {/* {tab === 'prices' && Object.keys(groupedData).length > 0 && (
            <>
              <Stack
                direction="row"
                spacing={2}
                sx={{ mb: 2, display: 'flex', justifyContent: 'flex-end' }}
              >
                <ButtonGroup sx={{ ml: 2 }}>
                  <Button
                    variant={isTrends ? 'outlined' : 'contained'}
                    onClick={() => {
                      setIsTrends(false);
                    }}
                  >
                    Table
                  </Button>
                  <Button
                    variant={isTrends ? 'contained' : 'outlined'}
                    onClick={() => {
                      setIsTrends(true);
                    }}
                  >
                    Price Trends
                  </Button>
                </ButtonGroup>
              </Stack>
              {!isTrends && (
                <DataGrid
                  autoHeight
                  rows={priceList}
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  checkboxSelection
                  disableSelectionOnClick
                  experimentalFeatures={{ newEditingApi: true }}
                  getRowId={(row) => row.RowID}
                />
              )}
              {isTrends && (
                <>
                  <TrendGraph groupedData={groupedData}></TrendGraph>
                </>
              )}
            </>
          )} */}
        </DialogContent>
        {isLoading && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <CircularProgress
              sx={{ width: '30px', height: '30px', mb: '10px' }}
            />
          </div>
        )}
      </Dialog>
    </>
  );
}
