export default function RefurbishedDashboardIcon({
  className,
}: {
  className?: string;
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      zoomAndPan="magnify"
      viewBox="0 0 56.879997 56.999997"
      height="75"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="69a102e662">
          <path
            d="M 20 12.890625 L 38 12.890625 L 38 26 L 20 26 Z M 20 12.890625 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="87c23e8926">
          <path
            d="M 28 25 L 43.949219 25 L 43.949219 43.574219 L 28 43.574219 Z M 28 25 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="448890624d">
          <path
            d="M 12.515625 25 L 27 25 L 27 41 L 12.515625 41 Z M 12.515625 25 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#69a102e662)">
        <path
          fill="#ffffff"
          d="M 28.707031 22.921875 C 28.605469 23.511719 29.007812 24.074219 29.601562 24.171875 L 35.222656 25.105469 C 35.28125 25.113281 35.34375 25.117188 35.402344 25.117188 C 35.925781 25.117188 36.386719 24.738281 36.476562 24.210938 L 37.410156 18.613281 C 37.511719 18.019531 37.109375 17.457031 36.515625 17.359375 C 35.921875 17.261719 35.359375 17.660156 35.257812 18.253906 L 34.78125 21.113281 C 34.402344 20.433594 33.992188 19.691406 33.53125 18.863281 L 31.132812 14.539062 C 30.554688 13.488281 29.484375 12.863281 28.28125 12.863281 C 27.082031 12.863281 26.011719 13.488281 25.433594 14.539062 L 22.996094 18.929688 C 22.066406 20.605469 21.320312 21.953125 20.683594 23.097656 C 20.390625 23.621094 20.582031 24.285156 21.109375 24.574219 C 21.632812 24.863281 22.300781 24.675781 22.59375 24.152344 C 23.230469 23.003906 23.976562 21.65625 24.910156 19.980469 L 27.34375 15.589844 C 27.617188 15.089844 28.09375 15.039062 28.28125 15.039062 C 28.472656 15.039062 28.945312 15.089844 29.222656 15.589844 L 31.621094 19.914062 C 32.164062 20.898438 32.644531 21.757812 33.078125 22.542969 L 29.960938 22.027344 C 29.367188 21.925781 28.800781 22.328125 28.707031 22.921875 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#87c23e8926)">
        <path
          fill="#ffffff"
          d="M 43.261719 34.75 L 40.597656 30.488281 C 39.582031 28.863281 38.769531 27.558594 38.074219 26.445312 C 37.757812 25.933594 37.082031 25.777344 36.570312 26.09375 C 36.0625 26.410156 35.902344 27.078125 36.21875 27.589844 C 36.910156 28.703125 37.730469 30.011719 38.746094 31.640625 L 41.40625 35.898438 C 41.710938 36.382812 41.527344 36.816406 41.429688 36.984375 C 41.339844 37.152344 41.0625 37.535156 40.488281 37.53125 L 35.53125 37.507812 C 34.398438 37.503906 33.414062 37.496094 32.515625 37.492188 L 34.488281 35.03125 C 34.863281 34.566406 34.789062 33.878906 34.316406 33.507812 C 33.847656 33.128906 33.160156 33.207031 32.78125 33.675781 L 29.226562 38.109375 C 29.1875 38.15625 29.152344 38.207031 29.125 38.257812 C 28.867188 38.714844 28.972656 39.300781 29.398438 39.636719 L 33.847656 43.179688 C 34.316406 43.554688 35.003906 43.480469 35.378906 43.011719 C 35.757812 42.546875 35.679688 41.855469 35.207031 41.480469 L 32.933594 39.671875 C 33.71875 39.671875 34.570312 39.679688 35.519531 39.683594 L 40.480469 39.707031 C 41.683594 39.710938 42.75 39.089844 43.335938 38.046875 C 43.925781 37 43.894531 35.765625 43.261719 34.75 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <g clip-path="url(#448890624d)">
        <path
          fill="#ffffff"
          d="M 25.867188 38.042969 C 24.550781 38.058594 23.007812 38.078125 21.078125 38.105469 L 16.046875 38.171875 C 15.472656 38.179688 15.195312 37.796875 15.097656 37.628906 C 15.003906 37.464844 14.816406 37.03125 15.113281 36.542969 L 17.699219 32.328125 C 18.289062 31.367188 18.804688 30.527344 19.269531 29.761719 L 20.363281 32.71875 C 20.570312 33.28125 21.199219 33.570312 21.765625 33.363281 C 22.332031 33.15625 22.621094 32.53125 22.410156 31.96875 L 20.441406 26.644531 C 20.421875 26.585938 20.394531 26.527344 20.363281 26.480469 C 20.105469 26.023438 19.550781 25.808594 19.039062 26 L 13.695312 27.960938 C 13.128906 28.167969 12.839844 28.792969 13.046875 29.355469 C 13.253906 29.917969 13.882812 30.207031 14.449219 30 L 17.179688 28.996094 C 16.773438 29.664062 16.328125 30.386719 15.835938 31.195312 L 13.25 35.410156 C 12.625 36.433594 12.605469 37.667969 13.199219 38.707031 C 13.796875 39.75 14.871094 40.359375 16.074219 40.347656 L 21.109375 40.277344 C 23.03125 40.253906 24.574219 40.234375 25.890625 40.214844 C 26.492188 40.210938 26.976562 39.714844 26.96875 39.117188 C 26.960938 38.519531 26.46875 38.035156 25.867188 38.042969 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
    </svg>
  );
}
