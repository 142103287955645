import React, { FormEvent, FormEventHandler, useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, AlertColor, Box, Chip, Grid, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import RecordFilterSelect from '../../ContractList/ContractFilter/RecordFilterSelect/RecordFilterSelect';
import ConfirmDialog from '../../ui/mui/ConfirmDialog/ConfirmDialog';
import { Role } from '../../../data';
import apiClient from '../../../services/apiClient';
import Layout from '../../Layout/Layout';
import Guard from '../../shared/Guard/Guard';
import RecordSelect from '../../shared/RecordSelect/RecordSelect';
import DialogList from '../../ui/mui/DialogList/DialogList';

interface filterType {
  subcategories: [];
  reports: [];
}

interface ReportRole {
  id?: number;
  name: string;
  Reports: [];
  subcategories: [];
}

export default function PowerBiRoles() {
  const [selectedFilters, setSelectedFilters] = useState<filterType>({
    subcategories: [],
    reports: [],
  });
  const [selectedFiltersEdit, setSelectedFiltersEdit] = useState<filterType>({
    subcategories: [],
    reports: [],
  });
  const [associatedSubcategories, setAssociatedSubCategories] = useState([]);
  const [associatedReports, setAssociatedReports] = useState([]);
  const [associatedReportsEdit, setAssociatedReportsEdit] = useState([]);
  const [roleName, setRoleName] = useState<string>('');
  const [initialValues, setInitialValues] = useState<any>([]);
  const [alert, setAlert] = useState<{
    severity: AlertColor | undefined;
    message: string;
  }>();
  const [rows, setRows] = useState<ReportRole[]>([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [refreshReport, setRefreshReport] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [choices, setChoices] = useState<
    {
      name: string;
      value: string;
      type: string;
    }[]
  >([]);
  const handleRecordChange = (
    source: any,
    checked: any,
    record: any,
    id: any,
  ) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    if (checked) {
      newFilters[source] = [...(newFilters[source] || []), record];
    } else {
      newFilters[source] = newFilters[source].filter(
        (item: any) => item.id !== record.id,
      );
    }
    setSelectedFilters(newFilters);
  };

  const handleFilterCancel = (source: string, id: any) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    newFilters.associatedReports = associatedReports;
    setSelectedFilters(newFilters);
  };

  const handleFilterApply = (source: any, id: any) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    setAssociatedReports(newFilters.reports);
  };

  useEffect(() => {
    if (refreshReport) {
      console.log('refreshing');
      apiClient.get('/powerBi/allReportRoles').then((res) => {
        setRows(res.data.records);
      });

      if (choices.length === 0) {
        apiClient
          .get('/reports')
          .then((res) => {
            setChoices(
              res.data.records.map((record: any) => ({
                value: `${record.id}`,
                name: record.name,
                type: 'report',
              })),
            );
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    }
  }, [refreshReport]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Role Name',
      width: 250,
      editable: true,
    },
    {
      field: 'reports',
      headerName: 'Associated Reports',
      width: 600,
      editable: false,
      renderCell: (params: any) => {
        return (
          <>
            <Tooltip
              title={params.row.reports?.map((s: any) => s.name).join(', ')}
            >
              <Typography
                noWrap
                sx={{
                  maxWidth: 600,
                }}
              >
                {params.row.reports?.map((s: any) => (
                  <Chip
                    key={s.id}
                    label={s.name}
                    sx={{ m: 0.5, cursor: 'pointer' }}
                  />
                ))}
              </Typography>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const handleDelete = () => {
    if (selectedIds.length > 0) {
      setRefreshReport(false);
      apiClient
        .delete(`powerBi/reportRoles/${selectedIds.join(',')}`)
        .then(() => {
          setAlert({
            severity: 'success',
            message: 'Roles successfully deleted',
          });
          setRefreshReport(true);
        })
        .catch((error) => {
          setAlert({
            severity: 'error',
            message: 'Error deleting roles',
          });

          console.log(error);
        });
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (
    event: FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (associatedReports.length !== 0) {
      setAlert(undefined);
      const data = {
        name: roleName,
        reports: associatedReports,
        subcategories: associatedSubcategories,
      };
      setRefreshReport(false);
      await apiClient
        .post('/powerBi/newRole', data)
        .then((result) => {
          if (result.data === 'Role successfully saved') {
            setAlert({
              severity: 'success',
              message: 'Role successfully saved',
            });
          } else {
            setAlert({
              severity: 'warning',
              message: 'Role already exists',
            });
          }
          emptyFields();
          setRefreshReport(true);
        })
        .catch((error) => {
          setAlert({
            severity: 'error',
            message: 'Error saving role',
          });
          console.log(error);
        });
    }
  };
  const emptyFields = () => {
    setRoleName('');
    setAssociatedSubCategories([]);
    setAssociatedReports([]);
    setSelectedFilters({
      subcategories: [],
      reports: [],
    });
  };
  const handleNewRole = () => {
    emptyFields();
    setAlert(undefined);
  };

  const processRowUpdate = async (reportRole: any) => {
    setAlert({
      severity: 'info',
      message: 'Updating ...',
    });
    setRefreshReport(false);

    apiClient
      .put(`/powerBi/reportRole/${reportRole?.id}`, reportRole)
      .then(() => {
        setRefreshReport(true);
        setAlert({
          severity: 'success',
          message: 'Successful update!',
        });
      })
      .catch((err: any) => {
        console.log(err);
        setRefreshReport(true);
        setAlert({
          severity: 'error',
          message: 'Error updating role',
        });
      });
    return reportRole;
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Box sx={{ m: 1 }} />
        <Button sx={{ m: 1 }} startIcon={<AddIcon />} onClick={handleNewRole}>
          Add new role
        </Button>
      </Stack>
      <Divider />

      <form onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <TextField
            sx={{ m: 2 }}
            error
            required
            id="outlined-required"
            label="Role Name"
            value={roleName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setRoleName(event.target.value)
            }
          />
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ ml: 2, mb: 2 }}>
            <RecordFilterSelect
              source="reports"
              property={'name'}
              label="Associated reports"
              selected={selectedFilters?.reports}
              onRecordChange={handleRecordChange}
              onFilterApply={handleFilterApply}
              onFilterCancel={handleFilterCancel}
            />
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            disabled={associatedReports.length === 0}
            sx={{
              borderLeft: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              m: 2,
            }}
          >
            Save Role
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            disabled={selectedIds.length === 0}
            sx={{ m: 2 }}
            onClick={() => {
              setOpenConfirm(true);
            }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Delete selection
          </Button>
        </Grid>
        {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
      </form>
      <DataGrid
        onSelectionModelChange={(ids: any) => {
          setSelectedIds(ids);
        }}
        onCellDoubleClick={(params: { id: any; field: string }) => {
          const row: any = rows.find((row) => row.id === params.id);
          setSelectedRow(row);
          if (params.field === 'reports') {
            setOpen(true);
            setInitialValues(
              (row?.reports || []).map((report: any) => ({
                name: `${report.name}`,
                value: `${report.id}`,
                type: 'report',
              })),
            );
          }
        }}
        autoHeight
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
      />

      <ConfirmDialog
        title="Confirm deleting sessions"
        open={openConfirm}
        setOpen={setOpenConfirm}
        onConfirm={handleDelete}
      >
        <Typography>
          This action will delete the selected roles. Are you sure ?
        </Typography>
      </ConfirmDialog>
      <DialogList
        choices={choices}
        title="Associated reports"
        setOpen={setOpen}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(selection: any[]) => {
          if (selectedRow && selectedRow.reports) {
            setRefreshReport(false);
            setAlert({
              severity: 'info',
              message: 'Updating report ...',
            });

            selectedRow.reports = selection.map((s) => ({
              id: Number(s.value),
            }));

            apiClient
              .put(`/powerBi/reportRole/${selectedRow?.id}`, selectedRow)
              .then(() => {
                setRows([...rows]);
                setAlert({
                  severity: 'success',
                  message: 'Successful update!',
                });
                setRefreshReport(true);
              })
              .catch((error) => {
                console.log(error);
                setRefreshReport(true);
                setAlert({
                  severity: 'error',
                  message: 'Error updating role',
                });
              });
          }
        }}
        initialValues={initialValues}
      />
    </>
  );
}
