import { CircularProgress, Stack, Typography } from '@mui/material';

export default function Loader() {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      spacing={4}
    >
      <Typography variant="h6" component="h2">
        Loading
      </Typography>
      <CircularProgress />
    </Stack>
  );
}
