import { Box, Divider, IconButton, Link, Stack } from '@mui/material';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { NATCO_BUYIN_POWERBI_PARAMETER } from '../../../data';
import { useAuth } from '../../AuthProvider';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import apiClient from '../../../services/apiClient';
import Loader from '../../ui/mui/Loader/Loader';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ShareIcon from '@mui/icons-material/Share';
import { useNavigate } from 'react-router';
import { ErrorAlert } from '../../ui/mui/ErrorAlert/ErrorAlert';

export function ReportShow({ reportId }: { reportId: any }) {
  const { profile } = useAuth();
  const [config, setConfig] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<null | string>(null);
  const navigate = useNavigate();
  const useStyles = makeStyles({
    embedContainer: {
      height: '100%',
      '& iframe': {
        border: 'none',
      },
    },
  });
  const classes = useStyles();

  const fetchReport = () => {
    apiClient
      .get(`/reports/${reportId}`, {
        params: { hasEmbedConfiguration: true },
      })
      .then((res: any) => {
        const report = res.data;
        setConfig({
          accessToken: `${report.embedConfiguration.token}`,
          embedUrl: `${report.embedConfiguration.url}`,
          id: `${report.record.powerBIId}`,
          tokenType: models.TokenType.Embed,
          type: 'report',
          parameterValues: report.record.isPaginated
            ? [
                {
                  name: `${report.record.powerBIParameter}`,
                  value: `${
                    profile?.isCustomer === false
                      ? NATCO_BUYIN_POWERBI_PARAMETER
                      : profile?.natco?.code
                  }`,
                },
              ]
            : undefined,
          filters: report.record.filters,
          settings: report.record.settings,
          pageName: report.record.hasPageId ? report.record.pageId : undefined,
        });
      })
      .catch((err) => {
        switch (err.response.status) {
          case 403:
            setError('You do not have access to this report');
            break;
          case 404:
            setError('Report not found');
            break;
          default:
            console.log(err);
            setError('Something went wrong');
            break;
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (reportId) {
      setIsLoading(true);
      fetchReport();
    }
  }, [reportId]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Stack direction="row" spacing={1} padding={1}>
        <IconButton
          onClick={() => {
            navigate(`/domains/tools`);
          }}
          sx={{
            color: 'primary.main',
            '&:hover': {
              color: 'primary.dark',
            },
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Link
          href={`mailto:?subject=Share%20Power BI Report%20&body=Hey, I´d like to share this link with you: %0A${window.location.href}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton
            sx={{
              color: 'primary.main',
              '&:hover': {
                color: 'primary.dark',
              },
            }}
          >
            <ShareIcon />
          </IconButton>
        </Link>
      </Stack>
      <Divider sx={{ marginBottom: 2 }} />
      {error && (
        <ErrorAlert
          timeout={10}
          message={error}
          onClose={() => {
            setError(null);
            navigate('/domains/tools');
          }}
        />
      )}
      <Box sx={{ height: '100vh' }}>
        {config && (
          <PowerBIEmbed
            embedConfig={config}
            cssClassName={classes.embedContainer}
          />
        )}
      </Box>
    </>
  );
}
