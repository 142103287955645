import React, { useEffect, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import apiClient from '../../../../services/apiClient';
import negotiationThumbnail from '../../../../images/feed/negotiation.png';
import { INegotiation } from '../../../../types';
import FeedPanelSkeleton from '../FeedPanelSkeleton/FeedPanelSkeleton';
import NegotiationDetails from '../../../NegotiationList/TableViewList/NegotiationDetails/NegotiationDetails';
import NegotiationStatus from '../../../NegotiationList/TableViewList/NegotiationStatus/NegotiationStatus';
import NewRibbon from '../../../shared/NewRibbon/NewRibbon';
import { useFilters } from '../../../Layout/NavigationFiltersProvider/NavigationFiltersProvider';
import { Tooltip } from '@mui/material';

export default function NegotiationFeed({
  setHasNegotiations,
}: {
  setHasNegotiations: (hasNegotiations: boolean) => void;
}) {
  const [negotiations, setNegotiations] = useState<INegotiation[]>([]);
  const [negotiationId, setNegotiationId] = useState<number | null | undefined>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(true);
  // const [searchParams] = useSearchParams();
  const { domains, categories, groups, regions, natcos, resetTrigger } =
    useFilters();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    apiClient
      .get('/negotiations', {
        params: {
          domains:
            categories?.length === 0 && domains.length > 0
              ? domains.join(',')
              : null,
          categories: categories?.length > 0 ? categories.join(',') : null,
          groups: groups?.length > 0 ? groups.join(',') : null,
          regions:
            natcos.length === 0 && regions.length > 0
              ? regions.join(',')
              : null,
          natcos: natcos.length > 0 ? natcos.join(',') : null,
          statuses: '0,1',
          page: 1,
          perPage: 3,
        },
      })
      .then((response) => {
        setNegotiations(response.data.records);
        setHasNegotiations(response.data.records.length > 0);
        setLoading(false);
      })
      .catch(() => {
        // TODO: handle error response
      });
  }, [domains, categories, groups, regions, natcos]);

  useEffect(() => {
    if (resetTrigger) {
      apiClient
        .get('/negotiations', {
          params: {
            domains: null,
            categories: null,
            groups: null,
            regions: null,
            natcos: null,
            statuses: '0,1',
            page: 1,
            perPage: 3,
          },
        })
        .then((response) => {
          setNegotiations(response.data.records);
          setHasNegotiations(response.data.records.length > 0);
          setLoading(false);
        })
        .catch(() => {
          // TODO: handle error response
        });
    }
  }, [resetTrigger]);

  const handleViewAll = () => {
    // const search = createSearchParams();
    // if (searchParams.get('navDomain')) {
    //   search.set('navDomain', searchParams.get('navDomain') || '');
    // }
    // if (searchParams.get('navGroup')) {
    //   search.set('navGroup', searchParams.get('navGroup') || '');
    // }
    navigate({
      pathname: '/domains/negotiations',
      // search: search.toString(),
    });
  };

  if (loading) {
    return <FeedPanelSkeleton />;
  }

  if (negotiations.length === 0) return null;

  const getTimingValue = (
    startDate: Date | undefined,
    endDate: Date | undefined,
  ) => {
    if (startDate && startDate) {
      const startDateQuarter =
        moment(startDate).quarter() + 'Q' + moment(startDate).format('YY');
      const endDateQuarter =
        moment(endDate).quarter() + 'Q' + moment(endDate).format('YY');
      return startDateQuarter + ' - ' + endDateQuarter;
    } else {
      return 'No date';
    }
  };

  return (
    <Paper elevation={3}>
      <Box textAlign="center" height={200}>
        <img
          src={negotiationThumbnail}
          style={{ height: 200 }}
          alt="Latest negotiations"
        />
      </Box>
      <Typography
        sx={{ p: 1.5, color: 'white', backgroundColor: 'primary.main' }}
      >
        Latest Negotiation Programs{' '}
      </Typography>
      <Table size="small">
        <TableBody>
          {negotiations.map((negotiation) => (
            <TableRow
              key={negotiation.id}
              onClick={() => setNegotiationId(negotiation.id)}
              sx={{ cursor: 'pointer' }}
              style={{ position: 'relative' }}
            >
              <NewRibbon
                postDate={negotiation.creationDate}
                postType={'contract'}
              />
              <TableCell sx={{ width: '100%', fontWeight: 'bold' }}>
                {negotiation.title}

                <Typography variant="subtitle2" component="div">
                  <Stack
                    direction="row"
                    gap={1}
                    justifyContent={'space-between'}
                  >
                    <Tooltip
                      title={negotiation.suppliers
                        .map((supplier) => supplier.name)
                        .sort()
                        .join(' ,')}
                    >
                      <Typography
                        variant="overline"
                        display="inline-block"
                        component="div"
                        noWrap
                        sx={{ maxWidth: 200, minWidth: 200 }}
                      >
                        Supplier:{' '}
                        {negotiation.suppliers
                          .map((supplier) => supplier.name)
                          .join(',')}
                      </Typography>
                    </Tooltip>
                    <Typography
                      variant="overline"
                      display="inline-block"
                      component="div"
                    >
                      Status:
                      <NegotiationStatus value={negotiation.status} />
                    </Typography>
                  </Stack>

                  <Stack direction="row" gap={1}>
                    <Typography
                      variant="overline"
                      display="inline-block"
                      component="div"
                    >
                      Timing:{' '}
                    </Typography>
                    <Typography
                      variant="overline"
                      display="inline-block"
                      component="div"
                    >
                      {getTimingValue(
                        negotiation.startDate,
                        negotiation.endDate,
                      )}{' '}
                    </Typography>
                  </Stack>
                </Typography>
              </TableCell>
            </TableRow>
          ))}

          {negotiationId && (
            <NegotiationDetails
              negotiationId={negotiationId}
              setNegotiationId={setNegotiationId}
              onClose={() => {
                setNegotiationId(null);
              }}
            />
          )}
        </TableBody>
      </Table>
      <Box textAlign="right">
        <Button sx={{ m: 0.5 }} onClick={handleViewAll}>
          View all
        </Button>
      </Box>
    </Paper>
  );
}
