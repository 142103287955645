import React, { useEffect, useState } from 'react';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import { nfdSearch } from '../../../../utils';

interface Choice {
  name: string;
  value: string;
  type: string;
}

export default function DialogList({
  title,
  loading,
  initialValues = [],
  choices,
  onChange,
  onClose,
  open,
  setOpen,
}: {
  title: string;
  loading?: boolean;
  initialValues?: any[];
  choices: Choice[];
  onChange: (selection: any[]) => void;
  onClose?: () => void;
  open: any;
  setOpen: any;
}) {
  const [selection, setSelection] = useState<any[]>(initialValues);
  const [touched, setIsTouched] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const handleClose = () => {
    setOpen(false);
    setSearch('');
    onClose && onClose();
  };

  useEffect(() => {
    setSelection(initialValues);
    // eslint-disable-next-line
  }, [open]);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSelect = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setIsTouched(true);
    const type = event.target.value.split('|')[1];
    const value = event.target.value.split('|')[0];

    setSelection(
      checked
        ? [
            ...selection,
            choices.find((c: any) => c.type === type && c.value === value),
          ]
        : selection.filter(
            (selected) =>
              (selected.type === type && selected.value !== value) ||
              selected.type !== type,
          ),
    );
  };

  const handleCancel = () => {
    setSelection(initialValues);
    setOpen(false);
    setIsTouched(false);
    setSearch('');
  };

  const handleApply = () => {
    setOpen(false);
    setIsTouched(false);
    onChange(selection);
    setSearch('');
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Stack direction="row" justifyContent="space-between">
            <Typography>{title}</Typography>
            <TextField
              variant="outlined"
              value={search}
              size="small"
              onChange={handleSearch}
              placeholder="Search..."
            />
          </Stack>
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid container spacing={0}>
              {choices
                .filter((choice: Choice) => nfdSearch(choice.name, search))
                .map(({ value, name, type }, index) => (
                  <Grid key={index} item xs={12} sm={6} md={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          value={`${value}|${type}`}
                          checked={
                            !!selection.find(
                              (selected) =>
                                selected.value === value &&
                                selected.type === type,
                            )
                          }
                          onChange={handleSelect}
                        />
                      }
                      label={name}
                    />
                  </Grid>
                ))}
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 2 }}
          >
            <Button
              disabled={selection.length <= 0}
              onClick={() => {
                setIsTouched(true);
                setSelection([]);
              }}
              startIcon={<RotateLeftIcon />}
            >
              Clear
            </Button>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              onClick={handleApply}
              variant="contained"
              color="primary"
              disabled={!touched}
              autoFocus
            >
              Apply
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
