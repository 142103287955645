import { createTheme } from '@mui/material';

export default createTheme({
  palette: {
    primary: {
      main: '#e41b13',
      light: '#ef5350',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: { backgroundColor: '#f4f4f4' },
      },
    },
  },
  zIndex: {
    drawer: 100,
  },
});
