export default function RetailPriceReviewIcon({
  className,
}: {
  className?: string;
}) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      zoomAndPan="magnify"
      viewBox="0 0 56.879997 56.999997"
      height="75"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="e2c7e4685a">
          <path
            d="M 14 12 L 43 12 L 43 44 L 14 44 Z M 14 12 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="0e9634e8ef">
          <path
            d="M 11.183594 16.089844 L 40.742188 11.359375 L 45.472656 40.921875 L 15.914062 45.652344 Z M 11.183594 16.089844 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="08d58faaf4">
          <path
            d="M 11.183594 16.089844 L 40.742188 11.359375 L 45.472656 40.921875 L 15.914062 45.652344 Z M 11.183594 16.089844 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#e2c7e4685a)">
        <g clip-path="url(#0e9634e8ef)">
          <g clip-path="url(#08d58faaf4)">
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
            <path
              fill="#ffffff"
              d="M 41.261719 15.863281 C 41.058594 14.746094 40.445312 13.785156 39.488281 13.085938 C 38.535156 12.386719 37.441406 12.097656 36.328125 12.25 L 28.46875 13.339844 C 27.730469 13.441406 27.105469 13.816406 26.667969 14.425781 L 14.675781 31.167969 C 13.8125 32.375 14.082031 34.066406 15.277344 34.941406 L 26.5 43.15625 C 27.101562 43.59375 27.816406 43.746094 28.492188 43.640625 C 29.167969 43.53125 29.804688 43.164062 30.234375 42.558594 L 42.230469 25.816406 C 42.667969 25.207031 42.828125 24.492188 42.691406 23.75 Z M 41.0625 24.960938 L 29.070312 41.707031 C 28.667969 42.261719 27.894531 42.386719 27.34375 41.980469 L 16.121094 33.765625 C 15.566406 33.363281 15.441406 32.582031 15.84375 32.023438 L 27.835938 15.28125 C 28.039062 15 28.300781 14.835938 28.636719 14.78125 C 28.644531 14.78125 28.65625 14.78125 28.667969 14.777344 L 36.527344 13.691406 C 37.277344 13.589844 37.988281 13.78125 38.648438 14.261719 C 39.304688 14.742188 39.707031 15.371094 39.84375 16.121094 L 41.277344 24.007812 C 41.339844 24.359375 41.269531 24.671875 41.0625 24.960938 Z M 33.375 17.84375 C 31.6875 18.113281 30.535156 19.71875 30.808594 21.421875 C 31.082031 23.128906 32.675781 24.292969 34.363281 24.023438 C 36.050781 23.753906 37.199219 22.148438 36.925781 20.445312 C 36.65625 18.738281 35.0625 17.574219 33.375 17.84375 Z M 34.132812 22.589844 C 33.230469 22.734375 32.375 22.109375 32.230469 21.195312 C 32.082031 20.28125 32.699219 19.421875 33.601562 19.277344 C 34.507812 19.132812 35.359375 19.757812 35.507812 20.671875 C 35.652344 21.585938 35.035156 22.445312 34.132812 22.589844 Z M 34.132812 22.589844 "
              fill-opacity="1"
              fill-rule="nonzero"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
