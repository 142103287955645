import { useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { TextField, Stack } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import SmsIcon from '@mui/icons-material/Sms';
import { grey } from '@mui/material/colors';
import StopIcon from '@mui/icons-material/Stop';

interface Props {
  onSend: (question: string, id?: string) => void;
  onDeleteClick: () => void;
  onStopGeneration: () => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
  conversationId?: string;
  disabledButton?: boolean;
  showLoadingMessage?: boolean;
  isLoading?: boolean;
  displayChatHistoryButton?: boolean;
}

export const QuestionInput = ({
  onSend,
  onDeleteClick,
  onStopGeneration,
  disabled,
  placeholder,
  clearOnSend,
  conversationId,
  disabledButton,
  showLoadingMessage,
  isLoading,
}: Props) => {
  const [question, setQuestion] = useState<string>('');
  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return;
    }

    if (conversationId) {
      onSend(question, conversationId);
    } else {
      onSend(question);
    }

    if (clearOnSend) {
      setQuestion('');
    }
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === 'Enter' && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const onQuestionChange = (_ev: any) => {
    setQuestion(_ev.target.value || '');
  };

  const sendQuestionDisabled = disabled || !question.trim();

  return (
    <>
      <Stack
        direction="row"
        spacing={1}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ p: 1, pt: 0, h: '80px', backgroundColor: 'white' }}
      >
        {disabledButton ? (
          <IconButton
            sx={{
              position: 'relative',
              w: '50px',
              h: '50px',
              color: (theme) => theme.palette.primary.main,
            }}
            onClick={onStopGeneration}
            disabled={!isLoading}
          >
            <Tooltip title="Stop generating" arrow placement="top">
              <StopIcon />
            </Tooltip>
          </IconButton>
        ) : null}
        {!disabledButton ? (
          <IconButton
            sx={{
              position: 'relative',
              w: '50px',
              h: '50px',
              color: (theme) => theme.palette.primary.main,
            }}
            onClick={onDeleteClick}
          >
            <Tooltip title="Delete Messages" arrow placement="top">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        ) : null}

        <TextField
          variant="filled"
          fullWidth
          disabled={disabled}
          placeholder={placeholder}
          value={question}
          onChange={onQuestionChange}
          onKeyDown={onEnterPress}
          autoComplete="off"
        />
        <IconButton
          sx={{
            position: 'relative',
            w: '50px',
            h: '50px',
            color: (theme) => theme.palette.primary.main,
          }}
          onClick={sendQuestion}
          onKeyDown={(e) =>
            e.key === 'Enter' || e.key === ' ' ? sendQuestion() : null
          }
          disabled={sendQuestionDisabled}
        >
          {showLoadingMessage}
          {showLoadingMessage ? (
            <SmsIcon
              sx={{
                color: grey[500],
                width: '20px',
                height: '20px',
                animation: 'fadeInOut 2s infinite',
              }}
            />
          ) : (
            <SendIcon />
          )}
        </IconButton>
      </Stack>
    </>
  );
};
