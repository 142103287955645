import { createContext, useContext, useEffect, useState } from 'react';
import { Category, Domain, Group, Referential, Region } from '../../types';
import { useNav } from '../NavProvider';
import { POST_TYPES } from './data';
import { useFilters } from '../Layout/NavigationFiltersProvider/NavigationFiltersProvider';
import Chip from '@mui/material/Chip';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

export interface Payload {
  domain: number[];
  category: number[];
  product: number[];
  group: number[];
  region: number[];
  natco: number[];
  vendor: number[];
}

interface PayloadContextValues {
  payload: Payload | null;
}

const defaultPayloadValues = {
  payload: {
    domain: [],
    category: [],
    product: [],
    group: [],
    region: [],
    natco: [],
    vendor: [],
  },
};

export const PayloadContext =
  createContext<PayloadContextValues>(defaultPayloadValues);

interface PayloadContextProviderProps {
  type: string;
  children: JSX.Element;
  fromSearch: boolean;
}

const PayloadProvider = ({ type, children, fromSearch }: PayloadContextProviderProps) => {
  const {
    isReady,
    supplierId,
    domains: domainsNodes,
    groups: groupsNodes,
  } = useNav();
  const {
    domains,
    categories,
    subcategories,
    groups,
    regions,
    natcos,
    suppliers,
    resetTrigger,
    setOtherFilterValues,
  } = useFilters();
  const [payload, setPayload] = useState<Payload | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (!isReady) return;

    const newPayload: Payload = {
      domain: [],
      category: [],
      product: [],
      group: [],
      region: [],
      natco: [],
      vendor: [],
    };

    if (type === POST_TYPES.UPDATES || type === POST_TYPES.STRATEGIES) {

      if (fromSearch === true){
        domainsNodes.forEach((domain: Domain) => {
          newPayload.domain.push(domain.id);
          (domain?.categories || []).forEach((category: Category) => {
            newPayload.category.push(category.id);
            (category?.subcategories || []).forEach(
              (subcategory: Referential) => {
                newPayload.product.push(subcategory.id);
              },
            );
          });
        });
      }else{
      if (subcategories.length > 0) {
        newPayload.product = subcategories.map((s) => Number(s));
      } else if (categories.length > 0) {
        newPayload.category = categories.map((c) => Number(c));
        newPayload.product = domainsNodes
          .map((domain) =>
            domain.categories
              .filter((c: any) => categories.includes(String(c.id)))
              .map((category: any) =>
                category.subcategories.map(
                  (subcategory: any) => subcategory.id,
                ),
              )
              .flat(),
          )
          .flat();
      } else if (domains?.length > 0) {
        newPayload.domain = domains.map((domain) => Number(domain));
        newPayload.category = domainsNodes
          .filter((d: Domain) => domains.includes(String(d.id)))
          .map((domain: any) =>
            domain.categories.map((category: any) => category.id),
          )
          .flat();
        newPayload.product = domainsNodes
          .filter((d: Domain) => domains.includes(String(d.id)))
          .map((domain: any) =>
            domain.categories
              .map((category: any) =>
                category.subcategories.map(
                  (subcategory: any) => subcategory.id,
                ),
              )
              .flat(),
          )
          .flat();
      } else {
        domainsNodes.forEach((domain: Domain) => {
          newPayload.domain.push(domain.id);
          (domain?.categories || []).forEach((category: Category) => {
            newPayload.category.push(category.id);
            (category?.subcategories || []).forEach(
              (subcategory: Referential) => {
                newPayload.product.push(subcategory.id);
              },
            );
          });
        });
      }
    }

    if(fromSearch === true){
      newPayload.natco = groupsNodes
          .map((group) =>
            group.regions
              .map((region: any) => region.natcos.map((natco: any) => natco.id))
              .flat(),
          )
          .flat();

        newPayload.region = groupsNodes
          .map((group) => group.regions.map((region: any) => region.id))
          .flat();
        newPayload.group = groupsNodes.map((group) => group.id);
    }else{
      if (natcos.length > 0) {
        newPayload.natco = natcos.map((natco) => Number(natco));

        newPayload.region = groupsNodes
          .map((group) =>
            group.regions.filter((region: any) =>
              region.natcos.some((natco: any) =>
                natcos.includes(String(natco.id)),
              ),
            ),
          )
          .flat()
          .map((region) => region.id);

        newPayload.group = groupsNodes
          .filter((group) =>
            group.regions.some((region: any) =>
              region.natcos.some((natco: any) =>
                natcos.includes(String(natco.id)),
              ),
            ),
          )
          .map((group) => group.id);
      } else if (regions.length > 0) {
        newPayload.natco = groupsNodes
          .map((group) =>
            group.regions
              .filter((region: any) => regions.includes(String(region.id)))
              .map((region: any) => region.natcos)
              .flat(),
          )
          .flat()
          .map((natco) => natco.id);

        newPayload.region = regions.map((region) => Number(region));
        newPayload.group = groupsNodes
          .filter((group) =>
            group.regions.some((region: any) =>
              regions.includes(String(region.id)),
            ),
          )
          .map((group) => group.id);
      } else if (groups.length > 0) {
        newPayload.group = groupsNodes
          .filter((group) => groups.includes(String(group.id)))
          .map((group) => group.id);
        newPayload.region = groupsNodes
          .filter((group) => groups.includes(String(group.id)))
          .map((group) => group.regions.map((region: any) => region.id))
          .flat();
        newPayload.natco = groupsNodes
          .filter((group) => groups.includes(String(group.id)))
          .map((group) =>
            group.regions
              .map((region: any) =>
                region.natcos.map((natco: any) => natco.id).flat(),
              )
              .flat(),
          )
          .flat();
      } else {
        newPayload.natco = groupsNodes
          .map((group) =>
            group.regions
              .map((region: any) => region.natcos.map((natco: any) => natco.id))
              .flat(),
          )
          .flat();

        newPayload.region = groupsNodes
          .map((group) => group.regions.map((region: any) => region.id))
          .flat();
        newPayload.group = groupsNodes.map((group) => group.id);
      }
    } 
    }

    if (supplierId) {
      newPayload.vendor = [supplierId];
    } else if (suppliers?.length > 0) {
      newPayload.vendor = suppliers.map((s) => Number(s));
    }

    setPayload(newPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isReady,
    domains,
    categories,
    subcategories,
    groups,
    regions,
    natcos,
    suppliers,
  ]);

  useEffect(() => {
    if (resetTrigger) {
      const newPayload: Payload = {
        domain: [],
        category: [],
        product: [],
        group: [],
        region: [],
        natco: [],
        vendor: [],
      };

      newPayload.natco = groupsNodes
        .map((group) =>
          group.regions
            .map((region: any) => region.natcos.map((natco: any) => natco.id))
            .flat(),
        )
        .flat();

      newPayload.region = groupsNodes
        .map((group) => group.regions.map((region: any) => region.id))
        .flat();
      newPayload.group = groupsNodes.map((group) => group.id);

      newPayload.vendor = supplierId ? [supplierId] : [];

      domainsNodes.forEach((domain: Domain) => {
        newPayload.domain.push(domain.id);
        (domain?.categories || []).forEach((category: Category) => {
          newPayload.category.push(category.id);
          (category?.subcategories || []).forEach(
            (subcategory: Referential) => {
              newPayload.product.push(subcategory.id);
            },
          );
        });
      });
      setPayload(newPayload);
    }
  }, [resetTrigger]);

  useEffect(() => {
    if (searchParams.get('search') !== null) {
      setOtherFilterValues(
        <React.Fragment>
          <Chip
            onDelete={() => {
              searchParams.delete('search');
              setSearchParams(searchParams);
            }}
            label={`search:${searchParams.get('search')} `}
            sx={{ m: 0.5 }}
          />
        </React.Fragment>,
      );
    } else {
      setOtherFilterValues(null);
    }
  }, [searchParams]);
  return (
    <PayloadContext.Provider value={{ payload }}>
      {children}
    </PayloadContext.Provider>
  );
};

export function usePayload() {
  const context = useContext(PayloadContext);

  if (context === undefined) {
    throw new Error('PayloadContext must be used within a PayloadProvider');
  }

  return context;
}

export default PayloadProvider;
