import * as React from 'react';
import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '../../../ui/mui/DialogTitle/DialogTitle';
import newsClient from '../../../../services/newsClient';
import { POST_TYPES } from '../../../news/data';
import PostSkeleton from '../../../news/PostSkeleton/PostSkeleton';

export interface FeedbackDialogProps {
  onClose: () => void;
}

export default function GetAppDialog({ onClose }: FeedbackDialogProps) {
  const [post, setPost] = useState<any>();

  useEffect(() => {
    newsClient
      .post('/posts', {
        post_type: [POST_TYPES.WEBPAGE],
        id: [process.env.REACT_APP_DOWNLOAD_LINK_ID],
      })
      .then((response) => {
        setPost(response.data);
      })
      .catch((error) => {
        // TODO: handle error response
        console.log(error);
      });
  }, []);

  return (
    <Dialog
      onClose={onClose}
      open
      maxWidth="sm"
      sx={{ textAlign: 'center' }}
      fullWidth
    >
      <DialogTitle onClose={onClose}>{post?.title}&#8205;</DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        {post ? (
          <div dangerouslySetInnerHTML={{ __html: post?.content }} />
        ) : (
          <PostSkeleton />
        )}{' '}
      </DialogContent>
    </Dialog>
  );
}
