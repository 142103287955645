import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { SyntheticEvent, useState } from 'react';

import UploadForm from '../UploadForm/UploadForm';

const section = {
  negotiations: 'negotiations',
  projects: 'projects',
};

export default function NegotiationUpload() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box p={1}>
      <Accordion
        expanded={expanded === section.negotiations}
        onChange={handleChange(section.negotiations)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Negotiations upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={0}
            path="negotiations/upload"
            subPath="negotiations"
            inputId="negotiations-sheet"
            inputName="negotiations"
            inputAccept=".csv"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === section.projects}
        onChange={handleChange(section.projects)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Projects upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={0}
            path="negotiations/projects/upload"
            subPath="projects"
            inputId="projects-sheet"
            inputName="projects"
            inputAccept=".csv"
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
