import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import { useAuth } from '../../AuthProvider';

interface GuardProps {
  role: string;
  message?: boolean | string;
  children: any;
}

export default function Guard({ role, message = false, children }: GuardProps) {
  const { hasRole } = useAuth();

  if (!hasRole(role)) {
    return message ? (
      <Box sx={{ p: 1 }}>
        <Alert variant="outlined" severity="error">
          {message === true
            ? "You don't have permission to access this content"
            : message}
        </Alert>
      </Box>
    ) : null;
  }

  return children;
}
