import { SyntheticEvent, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import apiClient from '../../../services/apiClient';
import HeaderConfig from './HeaderConfig';
import SectionConfig from './SectionConfig';
import FilterConfig from './FilterConfig';
import PriceConfig from './PriceConfig';

export interface SimpleDialogProps {
  open: boolean;
  selectedNode: any;
  onClose: (value: string) => void;
}

interface ProductCatalog {
  id: number;
  name: string;
  status: number;
  subcategories: [];
}

export default function UiConfiguration() {
  const [productCatalogSelected, setProductCatalogSelected] =
    useState<ProductCatalog>();
  const [pcRecords, setPcRecords] = useState<any>([]);
  const [fieldRecords, setFieldRecords] = useState<any>([]);
  const [expanded, setExpanded] = useState<string | false>(false);

  const section = {
    sections: 'Configure Sections',
    headers: 'Configure Headers',
    filters: 'Configure Filters',
    prices: 'Configure Prices',
  };

  useEffect(() => {
    if (productCatalogSelected) {
      apiClient
        .get('/field', {
          params: {
            productCatalogId: productCatalogSelected?.id,
          },
        })
        .then((res) => {
          setFieldRecords(res.data.record);
        });
    }

    console.log('productCatalogSelected', productCatalogSelected);
  }, [productCatalogSelected]);

  useEffect(() => {
    apiClient.get('/productCatalog').then((res) => {
      setPcRecords(res.data.records);
    });
  }, []);

  const handleChangePanel =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box sx={{ p: 2 }}>
      <Grid item xs={12} sx={{ m: 2 }}>
        <Autocomplete
          onChange={(event: any, v: any) => {
            setProductCatalogSelected(v);
          }}
          options={pcRecords}
          isOptionEqualToValue={
            (option: any, value: any) => option.id === value.id //&&
          }
          fullWidth
          getOptionLabel={(option: any) => `${option.name} `}
          renderOption={(
            props: any,
            option: any,
            { selected }: { selected: boolean },
          ) => (
            <Box container="li" {...props} key={`${option.id}`}>
              {`${option.name}`}
            </Box>
          )}
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant="filled"
              placeholder="Choose product catalog ..."
            />
          )}
        />
      </Grid>
      {productCatalogSelected && (
        <>
          <Accordion
            expanded={expanded === section.sections}
            onChange={handleChangePanel(section.sections)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Configure sections</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SectionConfig
                fieldRecords={fieldRecords}
                productCatalogSelected={productCatalogSelected}
              ></SectionConfig>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === section.headers}
            onChange={handleChangePanel(section.headers)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Configure headers</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <HeaderConfig
                fieldRecords={fieldRecords}
                productCatalogSelected={productCatalogSelected}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === section.filters}
            onChange={handleChangePanel(section.filters)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Configure filters</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FilterConfig
                fieldRecords={fieldRecords}
                productCatalogSelected={productCatalogSelected}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === section.prices}
            onChange={handleChangePanel(section.prices)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Configure Prices</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PriceConfig
                fieldRecords={fieldRecords}
                productCatalogSelected={productCatalogSelected}
              />
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Box>
  );
}
