export default function PCDashboardIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      zoomAndPan="magnify"
      viewBox="0 0 56.879997 56.999997"
      height="75"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <clipPath id="1666508e0c">
          <path
            d="M 14.332031 14.597656 L 42 14.597656 L 42 42.289062 L 14.332031 42.289062 Z M 14.332031 14.597656 "
            clip-rule="nonzero"
          />
        </clipPath>
      </defs>
      <g clip-path="url(#1666508e0c)">
        <path
          fill="#ffffff"
          d="M 40.765625 40.144531 L 16.375 40.144531 L 16.375 15.75 C 16.375 15.617188 16.347656 15.484375 16.296875 15.359375 C 16.246094 15.234375 16.171875 15.125 16.074219 15.027344 C 15.980469 14.933594 15.867188 14.859375 15.742188 14.808594 C 15.617188 14.753906 15.488281 14.730469 15.351562 14.730469 C 15.214844 14.730469 15.085938 14.753906 14.960938 14.808594 C 14.835938 14.859375 14.726562 14.933594 14.628906 15.027344 C 14.535156 15.125 14.460938 15.234375 14.40625 15.359375 C 14.355469 15.484375 14.332031 15.617188 14.332031 15.75 L 14.332031 41.164062 C 14.332031 41.300781 14.355469 41.429688 14.40625 41.554688 C 14.460938 41.679688 14.535156 41.792969 14.628906 41.886719 C 14.726562 41.984375 14.835938 42.058594 14.960938 42.109375 C 15.085938 42.160156 15.214844 42.1875 15.351562 42.1875 L 40.765625 42.1875 C 40.902344 42.1875 41.03125 42.160156 41.15625 42.109375 C 41.28125 42.058594 41.390625 41.984375 41.488281 41.886719 C 41.582031 41.792969 41.65625 41.679688 41.707031 41.554688 C 41.761719 41.429688 41.785156 41.300781 41.785156 41.164062 C 41.785156 41.027344 41.761719 40.898438 41.707031 40.773438 C 41.65625 40.648438 41.582031 40.539062 41.488281 40.441406 C 41.390625 40.347656 41.28125 40.273438 41.15625 40.21875 C 41.03125 40.167969 40.902344 40.144531 40.765625 40.144531 Z M 40.765625 40.144531 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill="#ffffff"
        d="M 18.640625 38.519531 C 18.964844 38.523438 19.230469 38.40625 19.4375 38.160156 L 24.546875 32.285156 L 27.511719 34.921875 C 27.621094 35.015625 27.742188 35.085938 27.878906 35.128906 C 28.015625 35.171875 28.15625 35.1875 28.300781 35.171875 C 28.441406 35.15625 28.574219 35.113281 28.699219 35.039062 C 28.824219 34.96875 28.929688 34.875 29.015625 34.757812 L 32.683594 29.742188 L 35.746094 31.558594 C 35.867188 31.628906 35.992188 31.675781 36.128906 31.695312 C 36.261719 31.714844 36.398438 31.707031 36.53125 31.671875 C 36.664062 31.636719 36.785156 31.578125 36.890625 31.496094 C 37 31.410156 37.089844 31.308594 37.15625 31.191406 L 41.757812 23.242188 C 41.882812 23 41.910156 22.746094 41.832031 22.484375 C 41.753906 22.21875 41.597656 22.019531 41.363281 21.882812 C 41.125 21.746094 40.875 21.710938 40.609375 21.777344 C 40.339844 21.839844 40.136719 21.988281 39.988281 22.21875 L 35.902344 29.269531 L 32.957031 27.503906 C 32.730469 27.367188 32.488281 27.328125 32.230469 27.378906 C 31.972656 27.433594 31.765625 27.566406 31.609375 27.777344 L 28.0625 32.640625 L 25.171875 30.058594 C 24.957031 29.867188 24.710938 29.78125 24.425781 29.800781 C 24.144531 29.820312 23.90625 29.933594 23.722656 30.148438 L 17.867188 36.820312 C 17.734375 36.972656 17.65625 37.148438 17.628906 37.34375 C 17.601562 37.542969 17.628906 37.730469 17.714844 37.914062 C 17.796875 38.09375 17.921875 38.238281 18.089844 38.347656 C 18.257812 38.453125 18.441406 38.507812 18.640625 38.507812 Z M 18.640625 38.519531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </svg>
  );
}
