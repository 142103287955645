import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
import { faIndustry } from '@fortawesome/free-solid-svg-icons';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { faDesktop } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faWifi } from '@fortawesome/free-solid-svg-icons';
import { faEur } from '@fortawesome/free-solid-svg-icons';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { faShuffle } from '@fortawesome/free-solid-svg-icons';
import { faSun } from '@fortawesome/free-solid-svg-icons';
import { faCube } from '@fortawesome/free-solid-svg-icons';
import { faCubes } from '@fortawesome/free-solid-svg-icons';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { faGears } from '@fortawesome/free-solid-svg-icons';
import { faSitemap } from '@fortawesome/free-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { faBuildingColumns } from '@fortawesome/free-solid-svg-icons';
import { faListUl } from '@fortawesome/free-solid-svg-icons';
import { faHashtag } from '@fortawesome/free-solid-svg-icons';
import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faDatabase } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { faSquareMinus } from '@fortawesome/free-solid-svg-icons';
import { faGaugeHigh } from '@fortawesome/free-solid-svg-icons';
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { faRightLong } from '@fortawesome/free-solid-svg-icons';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { faArrowsLeftRight } from '@fortawesome/free-solid-svg-icons';
import { faHouse } from '@fortawesome/free-solid-svg-icons';

export const iconOptions = [
  <FontAwesomeIcon
    icon={faEarthAmericas}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faWifi}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faEur}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faSpinner}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faBookmark}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faBarcode}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faIndustry}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faDesktop}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faShuffle}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faSun}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faCube}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faCubes}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faGear}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faGears}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faSitemap}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faUpRightAndDownLeftFromCenter}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faBuildingColumns}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faListUl}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faHashtag}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faBuilding}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faCheck}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faDatabase}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faSquare}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faServer}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faSquareMinus}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faGaugeHigh}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faMoneyBill}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faCalendar}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faRightLong}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faCircleInfo}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faSquareCheck}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faArrowsLeftRight}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
  <FontAwesomeIcon
    icon={faHouse}
    style={{ fontSize: '11px', marginBottom: '4px' }}
  />,
];

export default function ProductView({
  allProducts,
  productData,
  productCatalogId,
  buttonStates,
  handleProductSelect,
  handleProductSelectCompare,
}: any) {
  const getAvatarCSS = () => {
    return {
      width: 'unset',
      height: 'unset',
      textAlign: 'center',
      objectFit: 'cover',
      color: 'transparent',
      textIndent: '10000px',
      maxWidth: '90px',
      maxHeight: '90px',
    };
  };
  return (
    <Paper elevation={3} sx={{ backgroundColor: grey[200] }}>
      <Grid container>
        {allProducts.map((product: any, index: any) => (
          <>
            {(index % 3 === 0 || index === 0) && (
              <Grid item padding={0.5} sm={3}>
                <Card
                  key={index}
                  sx={{ height: '98%', backgroundColor: grey[200] }}
                >
                  <CardContent>
                    <Box sx={{ height: 140 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      ></Box>
                      <Box
                        sx={{
                          typography: 'caption',
                          textTransform: 'uppercase',
                          fontWeight: '500',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mt: 11.5,
                        }}
                      >
                        MODEL NAME
                      </Box>
                      <Box sx={{ p: 1 }}>
                        <Divider sx={{ background: 'red' }} />
                      </Box>
                    </Box>
                    {productData
                      ?.filter((pd: any) => pd.id === product.id)
                      .map((p: any) => (
                        <Stack direction="row" spacing={1}>
                          {iconOptions.map((iconO: any, index: any) => (
                            <>
                              {index === p.icon && (
                                <Box key={index}>{iconO}</Box>
                              )}
                            </>
                          ))}
                          <Typography
                            sx={{ fontWeight: 'bold' }}
                            variant="caption"
                          >
                            {p.name.charAt(0).toUpperCase() + p.name.slice(1)}
                          </Typography>
                        </Stack>
                      ))}
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid item key={product.id} padding={0.5} sm={3}>
              <Card
                key={index}
                sx={{ height: '98%', border: '1px solid lightgrey' }}
              >
                <CardContent>
                  <Box sx={{ height: 140 }}>
                    <Box sx={{ height: '80%' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '79%',
                        }}
                      >
                        <Avatar
                          src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/product/images/${productCatalogId}/${product.imageReference}`}
                          sx={{
                            width: 90,
                            height: 'unset!important',
                            objectFit: 'cover',
                            '& .MuiAvatar-img': getAvatarCSS(),
                          }}
                          variant="rounded"
                          onClick={() => handleProductSelect(product.id)}
                        />
                      </Box>
                      <Tooltip
                        title={product.productName}
                        placement="bottom-end"
                      >
                        <Box
                          sx={{
                            typography: 'caption',
                            textTransform: 'uppercase',
                            fontWeight: '500',
                            display: '-webkit-box',
                            alignItems: 'center',
                            justifyContent: 'center',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textAlign: 'center',
                          }}
                          onClick={() => handleProductSelect(product.id)}
                        >
                          {product.productName}
                        </Box>
                      </Tooltip>
                    </Box>
                    <Box sx={{ p: 1 }}>
                      <Divider sx={{ background: 'red' }} />
                    </Box>
                  </Box>
                  {productData
                    ?.filter((pd: any) => pd.id === product.id)
                    .map((p: any) => (
                      <Stack direction="row" spacing={1}>
                        {iconOptions.map((iconO: any, index: any) => (
                          <>
                            {index === p.icon && <Box key={index}>{iconO}</Box>}
                          </>
                        ))}
                        <Typography
                          variant="caption"
                          sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: '1',
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                          }}
                        >
                          {p.value ? p.value : ''}
                        </Typography>
                      </Stack>
                    ))}
                  <Grid container justifyContent="center" alignItems="center">
                    <Button
                      variant="contained"
                      color={buttonStates[product.id] ? 'success' : 'primary'}
                      size="small"
                      onClick={() => handleProductSelectCompare(product.id)}
                      sx={{ mt: 2 }}
                    >
                      Select Product
                    </Button>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </>
        ))}
      </Grid>
    </Paper>
  );
}
