import React, { useEffect, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { useMatch } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { SortDirection } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Pagination from '@mui/material/Pagination';
import { visuallyHidden } from '@mui/utils';
import { Tooltip, Typography } from '@mui/material';
import ContractFilter from './ContractFilter/ContractFilter';
import { Contract } from '../../types';
import ContractStatus from './ContractStatus/ContractStatus';
import apiClient from '../../services/apiClient';
import ContractDetail from './ContractDetail/ContractDetail';
import { ALL, useNav } from '../NavProvider';
import NewRibbon from '../shared/NewRibbon/NewRibbon';
import { useFilters } from '../Layout/NavigationFiltersProvider/NavigationFiltersProvider';
import ContractFilters from './ContractFilters/ContractFilters';

interface HeadCell {
  name: string;
  label: string;
  sortBy?: string;
}

const headCells: HeadCell[] = [
  { name: 'buyinContractId', label: 'Buyin ID' },
  { name: 'groupContractId', label: 'Group' },
  { name: 'category', label: 'Category' },
  { name: 'name', label: 'Name' },
  { name: 'supplier', label: 'Supplier' },
  { name: 'startDate', label: 'Start Date' },
  { name: 'endDate', label: 'End Date' },
  { name: 'buyinSourcer', label: 'Owner' },
  { name: 'status', label: 'Status' },
];

//Loading grid
const SkeletonRow = () => (
  <TableRow>
    {Array(9)
      .fill(null)
      .map((v, i) => (
        <TableCell key={i} sx={{ px: 0.5 }}>
          <Skeleton width={80} />
        </TableCell>
      ))}
  </TableRow>
);

function Row(props: {
  row: Contract;
  contractId: string | undefined;
  handleContractSelect: any;
}) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <TableRow
        key={props.row?.id}
        onClick={() => {
          props.handleContractSelect(props.row?.id);
          setOpen(!open);
        }}
        sx={{ cursor: 'pointer' }}
        style={{ position: 'relative' }}
      >
        <TableCell sx={{ px: 0.5 }}>
          <Tooltip title={props.row?.buyinContractId || ''}>
            <Typography noWrap sx={{ maxWidth: 80 }}>
              {props.row?.buyinContractId}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell sx={{ px: 0.75 }}>
          {props.row?.groups
            .map((g) => {
              return g.code;
            })
            .join(', ')}
        </TableCell>
        <TableCell sx={{ px: 0.5 }}>
          <Tooltip title={props.row?.category.name || ''}>
            <Typography noWrap sx={{ maxWidth: 80 }}>
              {props.row?.category.name}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell sx={{ px: 0.5 }}>
          <Tooltip title={props.row?.name || ''}>
            <Typography noWrap sx={{ maxWidth: 100 }}>
              {props.row?.name}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell sx={{ px: 0.75 }}>
          <Typography noWrap sx={{ maxWidth: 150 }}>
            {props.row?.supplier.name}
          </Typography>
        </TableCell>
        <TableCell sx={{ px: 0.75 }}>
          {props.row?.startDate
            ? moment(props.row?.startDate).format('MMM-YYYY')
            : ''}
        </TableCell>
        <TableCell sx={{ px: 0.75 }}>
          {props.row?.endDate
            ? moment(props.row?.endDate).format('MMM-YYYY')
            : ''}
        </TableCell>
        <TableCell sx={{ px: 0.5 }}>
          <Tooltip title={props.row?.buyinSourcer?.email || ''}>
            <Typography noWrap sx={{ maxWidth: 120 }}>
              {props.row?.buyinSourcer?.email}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell sx={{ px: 0.5 }}>
          <NewRibbon postDate={props.row?.startDate} postType={'contract'} />
          <ContractStatus value={props.row?.status} />
        </TableCell>
      </TableRow>
    </>
  );
}

export default function ContractList() {
  const [contracts, setContracts] = useState([]);
  const [contractsCount, setContractsCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page') || 1);
  const perPage = Number(searchParams.get('perPage') || 50);
  const orderBy = searchParams.get('sortBy') || 'lastname';
  const order: SortDirection =
    searchParams.get('order') === 'asc' ? 'asc' : 'desc';
  const [filters, setFilters] = useState<any>();
  const { domainNodeId, groupNodeId, supplierId, setIsFrozen } = useNav();
  const {
    domains,
    groups,
    categories,
    suppliers,
    isReady,
    setOtherFilters,
    setOtherFilterValues,
  } = useFilters();
  const pathPrefix = supplierId ? `/suppliers/${supplierId}` : '/domains';
  const match = useMatch(`${pathPrefix}/:tab/:id`);
  const contractId = match?.params.id;
  const navigate = useNavigate();
  const [metaData, setMetaData] = useState<any>();
  const search = searchParams.get('search');
  useEffect(() => {
    if (isReady) {
      const newFilter = {
        domains:
          categories.length === 0 && domains.length > 0
            ? domains.join(',')
            : null,
        categories: categories.length > 0 ? categories.join(',') : null,
        groups: groups.length > 0 ? groups.join(',') : null,
        suppliers: supplierId
          ? String(supplierId)
          : suppliers.join(',') || null,
      };
      setFilters({
        statuses: '1,2,3',
        ...filters,
        ...newFilter,
      });
    }
  }, [isReady, domains, groups, categories, suppliers]);

  useEffect(() => {
    setOtherFilters(
      <ContractFilters
        filters={filters}
        onFiltersChange={setFilters}
        metaData={metaData}
      />,
    );
    setLoading(true);

    if (filters) {
      apiClient
        .get('/contracts', {
          params: {
            statuses: '1,2,3',
            domain:
              categories.length === 0 && domains.length > 0
                ? domains.join(',')
                : null,
            categories: categories.length > 0 ? categories.join(',') : null,
            group: groups.length > 0 ? groups.join(',') : null,
            suppliers: supplierId
              ? String(supplierId)
              : suppliers.join(',') || null,
            ...filters,
            search,
            page: page,
            perPage: perPage,
          },
        })
        .then((response) => {
          setContracts(response.data.records);
          setContractsCount(response.data.pagination.total);
          setOtherFilters(
            <ContractFilters
              metaData={response.data.filters}
              filters={filters}
              onFiltersChange={setFilters}
            />,
          );
          setLoading(false);
        })
        .catch((error) => {
          //TODO: handle error response
          console.log(error);
          setLoading(false);
        });
    }
  }, [filters, page, perPage, order, orderBy, searchParams]);

  useEffect(() => () => setOtherFilterValues([]), []);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    if (newPage > 1) {
      searchParams.set('page', newPage.toString());
    } else {
      searchParams.delete('page');
    }
    setSearchParams(searchParams);
  };

  const handleSortChange = (property: string) => {
    searchParams.set('sortBy', property);
    searchParams.set('order', order === 'asc' ? 'desc' : 'asc');
    setSearchParams(searchParams);
  };

  const handleFiltersChange = (filters: any) => {
    setFilters(filters);
  };

  const handleContractSelect = (contractId: number) => {
    setIsFrozen(true);
    const search = searchParams.get('search');

    navigate(search ? `${pathPrefix}/contracts/${contractId}?search=${search}` : `${pathPrefix}/contracts/${contractId}`);
  };

  const handleContractDialogClose = () => {
    const newSearchParams = createSearchParams();
    if (domainNodeId && domainNodeId !== ALL) {
      newSearchParams.set('navDomain', domainNodeId);
    } else {
      newSearchParams.delete('navDomain');
    }
    if (groupNodeId && groupNodeId !== ALL) {
      newSearchParams.set('navGroup', groupNodeId);
    } else {
      newSearchParams.delete('navGroup');
    }
    const search = searchParams.get('search');
    if (search) {
      newSearchParams.set('search', search);
    }
    navigate({
      pathname: `${pathPrefix}/contracts`,
      search: newSearchParams.toString(),
    });


    setIsFrozen(false);
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        p={2}
      >
        <Pagination
          count={Math.ceil(contractsCount / perPage)}
          page={page}
          onChange={handlePageChange}
        />
      </Stack>

      <TableContainer component="div">
        <Table aria-label="collapsible table">
          <TableHead
            sx={(theme) => ({ backgroundColor: theme.palette.grey[300] })}
          >
            <TableRow>
              {headCells.map((cell) => (
                <TableCell
                  key={cell.name}
                  onClick={() => cell.sortBy && handleSortChange(cell.sortBy)}
                  sortDirection={orderBy === cell.sortBy ? order : false}
                  sx={{ px: 1, py: 1 }}
                >
                  {cell.sortBy ? (
                    <TableSortLabel
                      active={orderBy === cell.sortBy}
                      direction={orderBy === cell.sortBy ? order : 'asc'}
                      onClick={() =>
                        cell.sortBy && handleSortChange(cell.sortBy)
                      }
                    >
                      {cell.label}
                      {orderBy === cell.sortBy ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === 'desc'
                            ? 'sorted descending'
                            : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  ) : (
                    cell.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading
              ? Array(5)
                .fill(null)
                .map((v, i) => <SkeletonRow key={i} />)
              : contracts.map((row: Contract) => (
                <Row
                  key={row.id}
                  row={row}
                  contractId={contractId}
                  handleContractSelect={handleContractSelect}
                />
              ))}
            {contractId && (
              <ContractDetail
                handleContractDialogClose={handleContractDialogClose}
                contractId={Number(contractId)}
                isFeed={false}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        p={2}
      >
        <Pagination
          count={Math.ceil(contractsCount / perPage)}
          page={page}
          onChange={handlePageChange}
        />
      </Stack>
    </>
  );
}
