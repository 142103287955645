import { Box, Button, CircularProgress, Grid } from '@mui/material';
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridPrintExportMenuItem,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import apiClient from '../../../services/apiClient';
import ProductDetail from './ProductDetail';

export default function ProductTableView({
  filters,
  buttonStates,
  handleProductSelectCompare,
  productCatalogId,
}: any) {
  const [productData, setProductData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState<boolean>(true);
  const [productId, setProductId] = useState<number | null | undefined>(null);
  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    setIsLoading(true);

    apiClient
      .post(`/product/headers/${productCatalogId}`, filters, {
        params: {
          perPage: '',
          page: '',
          isFilters: false,
          isTableView: true,
        },
      })
      .then((res: any) => {
        setProductData(res.data.productHeaders);
        setIsLoading(false);
      });
  }, [productCatalogId, filters]);
  const handleCellClick = (params: any) => {
    if (params.field === 'productName') {
      setOpen(true);
      setProductId(params.row.id);
    }
  };
  function createColumns(data: any) {
    const columns = data.reduce((acc: any, obj: any) => {
      if (!acc.some((column: any) => column.field === obj.name)) {
        acc.push({
          field: obj.name,
          headerName: obj.name,
        });
      }
      return acc;
    }, []);

    columns.unshift({
      field: 'productName',
      headerName: 'Product Name',
      flex: 0.4,
    });

    columns.push({
      field: 'actions',
      headerName: 'Compare',
      flex: 0.25,
      renderCell: (params: any) => (
        <Button
          variant="contained"
          color={buttonStates[params.id] ? 'success' : 'primary'}
          size="small"
          onClick={() => handleProductSelectCompare(params.id)}
        >
          Select Product
        </Button>
      ),
    });

    const totalColumns = columns.length;
    const columnWidth = 1 / (totalColumns - 2);
    columns.forEach((column: any) => {
      if (column.field !== 'actions' && column.field !== 'productName') {
        column.flex = columnWidth;
      }
    });
    return columns;
  }
  function createRows(data: any, columns: any) {
    const rows: any = {};

    data.forEach((obj: any) => {
      const id = obj.id;
      const value = obj.value;

      if (!rows[id]) {
        rows[id] = { id, productName: obj.productName };
      }

      columns.forEach((column: any) => {
        if (obj.name === column.field) {
          rows[id][column.field] = value;
        }
      });
    });

    return Object.values(rows);
  }
  const handleProductDialogClose = () => {
    setOpen(false);
  };
  const columns = createColumns(productData);
  const rows: any = createRows(productData, columns);
  const handleSelectionModelChange = (newSelection: any) => {
    setSelectionModel(newSelection);
  };
  const handleExport = () => {
    
    const selectedRows = rows.filter((row: any, index: never) =>
      //@ts-ignore  
      selectionModel.includes(row.id),
    );

    const data = [
      columns
        .filter((c: any) => c.field !== 'actions')
        .map((column: any) => column.field),
      ...selectedRows.map((row: any) =>
        columns
          .filter((c: any) => c.field !== 'actions')
          .map((column: any) => row[column.field]),
      ),
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'products_List.xlsx');
  };
  const ExcelExportButton = () => (
    <>
      <Button color="primary" onClick={handleExport} size="small">
        <SaveAltIcon
          sx={{ color: 'primary', fontSize: '20px', marginRight: '4px' }}
        />
        Export to Excel
      </Button>
    </>
  );
  const GridToolbarExport = ({ csvOptions, printOptions, ...other }: any) => (
    <GridToolbarExportContainer {...other}>
      <GridPrintExportMenuItem options={printOptions} />
    </GridToolbarExportContainer>
  );
  return (
    <div style={{ width: '100%' }}>
      {isLoading && (
        <Grid container justifyContent="center" alignItems="center">
          <CircularProgress sx={{ m: 2 }} />
        </Grid>
      )}
      {!isLoading && (
        <>
          <DataGrid
            checkboxSelection
            autoHeight
            sx={{ m: 2, cursor: 'pointer' }}
            rows={rows}
            columns={columns}
            pageSize={30}
            localeText={{
              toolbarExport: 'Print',
            }}
            components={{
              Toolbar: (props) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    <GridToolbarColumnsButton
                      onResize={undefined}
                      onResizeCapture={undefined}
                    />
                    <GridToolbarFilterButton
                      onResize={undefined}
                      onResizeCapture={undefined}
                    />
                    <GridToolbarDensitySelector
                      onResize={undefined}
                      onResizeCapture={undefined}
                    />
                    <GridToolbarExport />
                    <ExcelExportButton />
                  </div>
                  <GridToolbarQuickFilter />
                </Box>
              ),
            }}
            onCellClick={(params: any) => handleCellClick(params)}
            onSelectionModelChange={handleSelectionModelChange}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
          {productId && (
            <ProductDetail
              handleProductDialogClose={handleProductDialogClose}
              productId={productId}
              open={open}
            />
          )}
        </>
      )}
    </div>
  );
}
