import { useMatomo } from "@jonkoops/matomo-tracker-react";

export default function LoginFailTrackPage() {

    const { trackEvent } = useMatomo();
    console.log('login-fail');
    trackEvent({
        category: 'login-fail',
        action: 'login-fail',
      });

    return(<></>)
}