import Chip from '@mui/material/Chip';
import { FILTER_TYPES_PRODUCT_CATALOG } from '../../../data';

interface FilterTypeProps {
  value?: number;
}

export default function FilterType({ value }: FilterTypeProps) {
  const filterType = FILTER_TYPES_PRODUCT_CATALOG.find(
    (type) => type.value === value,
  );
  return <Chip label={filterType?.name} />;
}
