import { useEffect, useState } from 'react';
import React from 'react';
import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import apiClient from '../../../services/apiClient';
import { PRICE_TYPES_PRODUCT_CATALOG } from '../../../data';
import PriceType from './PriceType';
import ConfirmDialog from '../../ui/mui/ConfirmDialog/ConfirmDialog';

export default function PriceConfig({
  fieldRecords,
  productCatalogSelected,
}: any) {
  const [fieldSelected, setFieldSelected] = useState<any>(null);
  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [refreshFiltersList, setRefreshFiltersList] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [alert, setAlert] = useState<{
    severity: AlertColor | undefined;
    message: string;
  }>();
  const [priceType, setPriceType] = useState<any>(0);

  useEffect(() => {
    apiClient
      .get('/field/', {
        params: {
          productCatalogId: productCatalogSelected.id,
          isPrice: true,
        },
      })
      .then((res) => {
        setFiltersList(res.data.record);
      });
  }, [productCatalogSelected, refreshFiltersList]);
  const replaceValue = () => {
    apiClient
      .post('/field', [
        {
          id: fieldSelected.id,
          isOverwrittenByDHPrice: true,
          priceType: priceType,
        },
      ])
      .then((result) => {
        setRefreshFiltersList(!refreshFiltersList);
        setFieldSelected(null);
        setPriceType(0);
      });
  };
  const columns: GridColDef[] = [
    {
      field: 'priceType',
      headerName: 'Price Type',
      width: 200,
      renderCell: (params: any) => {
        return <PriceType value={params.row?.priceType} />;
      },
    },
    {
      field: 'technicalId',
      headerName: 'Field ',
      width: 300,
    },
  ];

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(undefined);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alert]);

  const handleDeleteFilters = () => {
    if (selectedIds.length > 0) {
      const dataToDelete = selectedIds.map((si) => ({
        id: si,
        priceType: null,
        isOverwrittenByDHPrice: false,
      }));

      apiClient.post('/field', dataToDelete).then(() => {
        setRefreshFiltersList(!refreshFiltersList);
        setAlert({
          severity: 'success',
          message: 'Prices on fields no more applied',
        });
      });
    }
  };

  return (
    <Stack direction="column" spacing={2}>
      <Autocomplete
        onChange={(event: any, v: any) => {
          setFieldSelected(v);
        }}
        value={fieldSelected}
        options={fieldRecords}
        isOptionEqualToValue={
          (option: any, value: any) => option.id === value.id //&&
        }
        fullWidth
        getOptionLabel={(option: any) => `${option.technicalId} `}
        renderOption={(
          props: any,
          option: any,
          { selected }: { selected: boolean },
        ) => (
          <Box container="li" {...props} key={`${option.id}`}>
            {`${option.technicalId}`}
          </Box>
        )}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="filled"
            placeholder="Choose field ..."
          />
        )}
      />

      <Select
        error
        placeholder="Please select the remplacement price type"
        labelId="price-type"
        id="priceType"
        value={priceType}
        label="Price Type selection"
        onChange={(event: any) => setPriceType(event.target.value)}
      >
        {PRICE_TYPES_PRODUCT_CATALOG.map((ft: any) => (
          <MenuItem value={ft.value}>{ft.name}</MenuItem>
        ))}
      </Select>
      <Button sx={{ ml: 2 }} onClick={replaceValue} variant="outlined">
        Save the field to replace its value with datahub's price
      </Button>
      {filtersList.length > 0 && (
        <>
          {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
          <Button
            disabled={selectedIds.length === 0}
            sx={{ m: 2 }}
            onClick={() => {
              setOpenConfirm(true);
            }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Delete selection
          </Button>
          <DataGrid
            onSelectionModelChange={(ids: any) => {
              setSelectedIds(ids);
            }}
            autoHeight
            rows={filtersList}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </>
      )}

      <ConfirmDialog
        title="Confirm delete"
        open={openConfirm}
        setOpen={setOpenConfirm}
        onConfirm={handleDeleteFilters}
      >
        <Typography>
          This action will cancel overwritting this fields with Datahub price.
          Are you sure ?
        </Typography>
      </ConfirmDialog>
    </Stack>
  );
}
