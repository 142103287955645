import { SyntheticEvent, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import UploadForm from '../UploadForm/UploadForm';

const section = {
  suppliers: 'suppliers',
  affiliates: 'affiliates',
  risks: 'risks',
  swot: 'swot',
  ratings: 'ratings',
  sustainibilityScores: 'sustainibilityScores',
  operationals: 'operationals',
  executives: 'executives',
  executiveImages: 'executiveImages',
};

export default function SuppliersUpload() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box p={1}>
      <Accordion
        expanded={expanded === section.suppliers}
        onChange={handleChange(section.suppliers)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Suppliers list upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={4}
            path="suppliers/upload"
            subPath="suppliers"
            inputId="suppliers-sheet"
            inputName="suppliers"
            inputAccept=".csv"
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === section.affiliates}
        onChange={handleChange(section.affiliates)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Affiliates upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={4}
            path="affiliates/upload"
            subPath="affiliates"
            inputId="affiliates-sheet"
            inputName="affiliates"
            inputAccept=".csv"
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === section.risks}
        onChange={handleChange(section.risks)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Risks upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={4}
            path="risks/upload"
            subPath="risks"
            inputId="risks-sheet"
            inputName="risks"
            inputAccept=".csv"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === section.swot}
        onChange={handleChange(section.swot)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>SWOT Analysis Upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={4}
            path="swotAnalysis/upload"
            subPath="swotAnalysis"
            inputId="swotAnalysis-sheet"
            inputName="swotAnalysis"
            inputAccept=".csv"
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === section.sustainibilityScores}
        onChange={handleChange(section.sustainibilityScores)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Sustainability Scores Upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={4}
            path="sustainabilityScores/upload"
            subPath="sustainabilityScores"
            inputId="sustainabilityScores-sheet"
            inputName="sustainabilityScores"
            inputAccept=".csv"
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === section.ratings}
        onChange={handleChange(section.ratings)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>CSR Ratings upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={4}
            path="supplierRatings/upload"
            subPath="ratings"
            inputId="ratings-sheet"
            inputName="ratings"
            inputAccept=".csv"
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === section.operationals}
        onChange={handleChange(section.operationals)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Operationals upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={4}
            path="operationals/upload"
            subPath="operationals"
            inputId="operationals-sheet"
            inputName="operationals"
            inputAccept=".csv"
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === section.executives}
        onChange={handleChange(section.executives)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Executive profiles upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={4}
            path="executives/upload"
            subPath="executives/executives"
            inputId="executives-sheet"
            inputName="executives"
            inputAccept=".csv"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === section.executiveImages}
        onChange={handleChange(section.executiveImages)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Executive profiles images upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={4}
            path="executives/avatars/upload"
            subPath="executives/avatars"
            inputId="executives-images"
            inputName="avatars"
            inputAccept=".zip"
            progressKey="executiveImages"
            showProgressResult
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
