import React, { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { Profile } from '../../../../types';
import { ROLE_NAMES } from '../../../../data';
import apiClient from '../../../../services/apiClient';
import DialogTitle from '../../../ui/mui/DialogTitle/DialogTitle';

interface UserEditDialogProps {
  open: boolean;
  user: Profile;
  onClose: () => void;
}

const options = Object.keys(ROLE_NAMES).map((role) => ({
  value: role,
  name: ROLE_NAMES[role],
}));

export default function UserEditDialog({
  open,
  user,
  onClose,
}: UserEditDialogProps) {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const initialValue = options
    .filter((option) => user.roles.includes(option.value))
    .concat(
      user.productCatalogRoles.map((pcRole: any) => ({
        value: pcRole.id,
        name: pcRole.name,
        type: 'pcRole',
      })),
    );
  const [value, setValue] = useState<any[]>(initialValue);
  const [error, setError] = useState<string>();
  const [pCRolesOptions, setPCRolesOptions] = useState<any[]>(
    user.productCatalogRoles.map((pcRole: any) => ({
      value: pcRole.id,
      name: pcRole.name,
      type: 'pcRole',
    })),
  );
  const [productCatalogRoles, setProductCatalogRoles] = useState<any[]>([]);

  useEffect(() => {
    apiClient.get('/productCatalogRole').then((res) => {
      setProductCatalogRoles(res.data.records);
      setPCRolesOptions(
        res.data.records.map((pcRole: any) => ({
          value: pcRole.id,
          name: pcRole.name,
          type: 'pcRole',
        })),
      );
      setError(undefined);
      setValue(initialValue);
    });
  }, [open]);

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = () => {
    setError(undefined);
    setSubmitting(true);
    const pcRoles = value
      .map((role) => (role.type === 'pcRole' ? role.value : null))
      .filter((role) => role !== null);
    apiClient
      .put(`users/${user.id}`, {
        roles: value
          .map((role) =>
            !role.type || role.type !== 'pcRole' ? role.value : null,
          )
          .filter((role) => role !== null),
        productCatalogRoles: productCatalogRoles.filter((pc) =>
          pcRoles.includes(pc.id),
        ),
      })
      .then(() => {
        setSubmitting(false);
        onClose();
      })
      .catch((error) => {
        setError(error?.response?.data?.error || 'An error occurred');
        setSubmitting(false);
      });
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle onClose={onClose}>
        {user.firstName} {user.lastName} ({user.email})
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          options={[...options, ...pCRolesOptions]}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          getOptionLabel={(option) => option.name}
          value={value}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="filled"
              label="Roles"
              placeholder="Add roles"
            />
          )}
          onChange={(event, newValue) =>
            setValue(
              [...options, ...pCRolesOptions].filter(({ value }) =>
                newValue.map(({ value }) => value).includes(value),
              ),
            )
          }
          multiple
          fullWidth
        />
        {error && (
          <Alert severity="error" sx={{ mt: 1 }}>
            {error}
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={submitting} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          disabled={submitting}
          variant="contained"
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
