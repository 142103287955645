import { Box, Grid, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import { REPORT_LOGOS } from '../DomainPage/PowerBiReports/ReportList';
import { useNavigate } from 'react-router';

export default function ToolsGrid({
  productCatalogs,
  allReports,
  handlePcClick,
}: any) {
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Tool name',
      width: 1000,
      renderCell: (params: any) => (
        <Grid container sx={{ cursor: 'pointer' }}>
          <Grid
            item
            xs={1}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '-30px',
            }}
          >
            {params.row.powerBIId ? (
              <Box sx={{ marginRight: '11px' }}>
                {{
                  ...REPORT_LOGOS.find(
                    (logo: any) => logo.value === params.row.iconId,
                  )?.icon,
                  props: { className: 'tools-icon' },
                }}
              </Box>
            ) : (
              <WysiwygIcon />
            )}
          </Grid>
          <Grid item xs={11} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography> {params.value}</Typography>
          </Grid>
        </Grid>
      ),
    },
  ];

  const handleCellClick = (params: any) => {
    if (params.row.powerBIId) {
      navigate(`/domains/tools/powerBI/${params.row.id}`);
      return;
    }
    handlePcClick(params);
  };

  return (
    <DataGrid
      sx={{ m: 2 }}
      autoHeight
      rows={[...productCatalogs, ...allReports]}
      columns={columns}
      pageSize={5}
      rowsPerPageOptions={[5]}
      onCellClick={(params: any) => handleCellClick(params)}
      components={{ Toolbar: GridToolbar }}
      componentsProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
    />
  );
}
