import { useMatch } from 'react-router-dom';
import ToolsPage from '../../ToolsPage/ToolsPage';
import ProductCatalogPage from '../../ToolsPage/ProductCatalog/ProductCatalogPage';
import { ReportShow } from '../PowerBiReports/ReportShow';

export default function ToolsTab(props: {
  reclick: any;
  changeReClickVal: any;
}) {
  const reportId = useMatch('/domains/tools/powerBI/:id')?.params.id;
  const productCatalogId = useMatch('/domains/tools/productCatalog/:id')?.params
    .id;
  return productCatalogId ? (
    <ProductCatalogPage />
  ) : reportId ? (
    <ReportShow reportId={reportId} />
  ) : (
    <ToolsPage />
  );
}
