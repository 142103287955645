import React, { useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActionArea from '@mui/material/CardActionArea';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import newsClient from '../../../../services/newsClient';
import { Post } from '../../../news/types';
import { POST_TYPES } from '../../../news/data';
import { usePayload } from '../../../news/PayloadProvider';
import UpdateShow from '../../../news/UpdateShow/UpdateShow';
import BuyinNewsShow from '../../../news/BuyinNewsShow/BuyinNewsShow';
import FeedPanelSkeleton from '../FeedPanelSkeleton/FeedPanelSkeleton';
import PublicNewsShow from '../../../PublicNews/PublicNewsShow/PublicNewsShow';
import apiClient from '../../../../services/apiClient';
import NewRibbon from '../../../shared/NewRibbon/NewRibbon';
import StrategyShow from '../../../news/StrategyShow/StrategyShow';
import strategyThumbnail from '../../../../images/feed/strategy.png';

const FEED_NEWS_TITLES = {
  [POST_TYPES.BUYIN_NEWS]: 'Latest BuyIn News',
  [POST_TYPES.UPDATES]: 'Latest BuyIn Articles',
  [POST_TYPES.PUBLIC_NEWS]: 'Latest Public News',
  [POST_TYPES.STRATEGIES]: 'Latest Strategies',
};

export default function NewsFeed({
  type,
  setHasArticles,
}: {
  type: string;
  setHasArticles: (hasArticles: boolean) => void;
}) {
  const [posts, setPosts] = useState<Post[]>([]);
  const [postId, setPostId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const { payload } = usePayload();
  const navigate = useNavigate();

  const getData = (type: string, payload: any) => {
    newsClient
      .post('/posts', {
        post_type: [type],
        page: 1,
        itemsPerPage: 3,
        ...payload,
      })
      .then((response) => {
        setHasArticles(response.data.length > 0);
        setPosts(response.data);
        setLoading(false);
      })
      .catch(() => {
        // TODO: handle error response
      });
  };

  useEffect(() => {
    if (payload) {
      setLoading(true);
      switch (type) {
        case POST_TYPES.BUYIN_NEWS:
          getData(POST_TYPES.BUYIN_NEWS, payload);
          break;
        case POST_TYPES.UPDATES:
          getData(POST_TYPES.UPDATES, payload);
          break;
        case POST_TYPES.STRATEGIES:
          getData(POST_TYPES.STRATEGIES, payload);
          break;
        case POST_TYPES.PUBLIC_NEWS:
          // if(payload.category?.length) {
          apiClient
            .get('/news', {
              params: {
                page: 1,
                perPage: 3,
                subcategory: payload.product.join(','),
              },
            })
            .then((response) => {
              setHasArticles(response.data.records.length > 0);
              setPosts(response.data.records);
            })
            .catch(() => {
              // TODO: handle error response
            });

          setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  const searchData = (newType: string) => {
    const search = createSearchParams();
    if (searchParams.get('navDomain')) {
      search.set('navDomain', searchParams.get('navDomain') || '');
    }
    if (searchParams.get('navGroup')) {
      search.set('navGroup', searchParams.get('navGroup') || '');
    }
    navigate({ pathname: `/domains/${newType}`, search: search.toString() });
  };

  const handleViewAll = (): void => {
    switch (type) {
      case POST_TYPES.BUYIN_NEWS:
        navigate('/buyin-news');
        break;
      case POST_TYPES.PUBLIC_NEWS:
      case POST_TYPES.UPDATES:
        searchData('updates');
        break;
      case POST_TYPES.STRATEGIES:
        searchData('strategies');
        break;
    }
  };

  if (loading) {
    return <FeedPanelSkeleton />;
  }

  if (posts.length === 0) return null;

  return (
    <Paper elevation={3}>
      <Box textAlign="center">
        {type !== POST_TYPES.PUBLIC_NEWS && type !== POST_TYPES.STRATEGIES && (
          <img
            src={posts[0].thumbnail}
            style={{ height: 200 }}
            alt={posts[0].title}
          />
        )}
        {type === POST_TYPES.STRATEGIES && (
          <img
            src={strategyThumbnail}
            style={{ height: 200 }}
            alt="strategies"
          />
        )}
        {type === POST_TYPES.PUBLIC_NEWS && (
          <img
            src="/img/public news.png"
            style={{ height: 200 }}
            alt="public news"
          />
        )}
      </Box>
      <Typography
        sx={{ p: 1.5, color: 'white', backgroundColor: 'primary.main' }}
      >
        {FEED_NEWS_TITLES[type]}
      </Typography>
      <List sx={{ width: '100%' }}>
        {posts.map((post) => (
          <React.Fragment key={post.id}>
            <ListItem alignItems="flex-start" sx={{ p: 0 }}>
              <NewRibbon postDate={post.date} postType={type} />
              <CardActionArea sx={{ p: 1 }} onClick={() => setPostId(post.id)}>
                <Typography variant="subtitle2">{post.title}</Typography>
                {type !== POST_TYPES.PUBLIC_NEWS && (
                  <Typography component="span" variant="caption">
                    {new Intl.DateTimeFormat('en-GB', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    }).format(new Date(post.date))}
                    {' By '}
                    {post.author.name}
                  </Typography>
                )}
                {type === POST_TYPES.PUBLIC_NEWS && (
                  <Typography component="span" variant="caption">
                    {new Intl.DateTimeFormat('en-GB', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    }).format(new Date(post.date))}
                  </Typography>
                )}
                <Typography
                  variant="body2"
                  color="text.primary"
                  sx={{ marginTop: 1 }}
                >
                  {post.excerpt.length > 180
                    ? post.excerpt.slice(0, 180) + '...'
                    : post.excerpt}
                </Typography>
                {type === POST_TYPES.PUBLIC_NEWS && (
                  <Typography component="span" variant="caption">
                    {'READ ON: '}
                    <a href={post.url} target="_blank">
                      {post.source}
                    </a>
                  </Typography>
                )}
              </CardActionArea>
            </ListItem>
            <Divider component="li" />
          </React.Fragment>
        ))}
      </List>
      <Box textAlign="right">
        <Button sx={{ m: 0.5 }} onClick={handleViewAll}>
          View all
        </Button>
      </Box>
      {postId && type === POST_TYPES.BUYIN_NEWS && (
        <BuyinNewsShow id={postId} onClose={() => setPostId(null)} />
      )}
      {postId && type === POST_TYPES.PUBLIC_NEWS && (
        <PublicNewsShow id={postId} onClose={() => setPostId(null)} />
      )}
      {postId && type === POST_TYPES.UPDATES && (
        <UpdateShow id={postId} onClose={() => setPostId(null)} isRoot={true} />
      )}
      {postId && type === POST_TYPES.STRATEGIES && (
        <StrategyShow id={postId} onClose={() => setPostId(null)} />
      )}
    </Paper>
  );
}
