import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import pptxgen from 'pptxgenjs';
import PptxGenJS from 'pptxgenjs';
// @ts-ignore
import { parse } from 'himalaya';
import { Strategy } from '../types';
import { Link } from '@mui/material';

let pageNumber: number = 0;

function decodeHTMLEntity(htmlCode: string) {
  const el = document.createElement('html');
  el.innerHTML = htmlCode;
  const textArea = el.getElementsByTagName('textarea');
  if (textArea.length > 0) {
    return textArea[0].value;
  } else {
    return htmlCode;
  }
}

function getText(
  node: {
    tagName: any;
    parentindex?: any;
    pastTag?: any;
    type?: any;
    children?: any;
    url?: any;
    content?: any;
    acronym?: any;
    topic?: any;
    attributes?: any;
  },
  array: never[],
  tag: any[],
  parentindex: any[],
  url: string | null,
  acronym: string | null,
  topic: string | null,
) {
  if (node.tagName === 'img') return node;
  if (node.tagName === 'br') {
    node.parentindex = parentindex;
    node.pastTag.pop();
    node.pastTag.push('span');
    node.tagName = 'span';
    node.type = 'element';
    node.children = [{ type: 'text', content: '\n' }];
  }

  if (
    node.tagName === 'a' &&
    (node.pastTag || [])[(node?.pastTag?.length || 2) - 2] === 'span'
  ) {
    while ((node.children || []).length !== 0) {
      node = {
        ...node.children[0],
        pastTag: [],
        parentindex: [],
      };
    }
  }
  if (!node.children) {
    if (!node.pastTag) {
      node.pastTag = [];
    }

    node.parentindex = parentindex;

    if (acronym) {
      node.acronym = acronym;
      node.type = 'acronym';
    }
    if (topic) {
      node.topic = topic;
      node.type = 'topic';
      node.url = url;
    } else if (url) {
      node.url = url;
      node.type = 'link';
    }
    if (node.content) {
      node.content = decodeHTMLEntity(node.content).replace(/&amp;/g, '&');
    }

    return node;
  }

  let arr: any[] = [];

  node.children.forEach(
    (
      ch: {
        pastTag: any;
        parentindex: any;
        tagName: any;
        attributes?: any;
        type?: any;
        children?: any;
        url?: any;
        content?: any;
        acronym?: any;
      },
      index: any,
    ) => {
      ch.pastTag = tag;

      ch.parentindex = parentindex;

      if (ch.tagName) {
        ch.pastTag = ch.pastTag.concat(ch.tagName);
      }

      if (
        ch.tagName === 'a' &&
        ch.attributes.filter(function (att: { key: string }) {
          return att.key === 'data-is-topic';
        })[0]?.value === 'true'
      ) {
        topic = ch.attributes.filter(function (att: { key: string }) {
          return att.key === 'data-topic-id';
        })[0]?.value;
        url = ch.attributes.filter(function (att: { key: string }) {
          return att.key === 'href';
        })[0].value;
      } else if (ch.tagName === 'a') {
        url = ch.attributes.filter(function (att: { key: string }) {
          return att.key === 'href';
        })[0].value;
      }

      if (
        ch.tagName === 'span' &&
        ch.attributes.filter(function (att: { key: string }) {
          return att.key === 'data-is-acronym';
        })[0]?.value === 'true'
      ) {
        acronym = ch.attributes.filter(function (att: { key: string }) {
          return att.key === 'title';
        })[0]?.value;
      }

      // otherFormat

      arr = arr.concat(
        getText(
          ch,
          array,
          ch.pastTag,
          ch.parentindex.concat([index]),
          url,
          acronym,
          topic,
        ),
      );
      url = '';
      acronym = '';
      topic = '';
    },
  );
  return arr;
}

function recursiveParse(html: string): any[] {
  const json = parse(html);
  let result: any[] = [];
  json.forEach(function (element: { tagName: any }, indx: any) {
    result = result.concat(
      getText(element, [], [element.tagName], [indx], null, null, null),
    );
  });
  return result;
}

function getPPTOptions(node: any) {
  const options: any = {
    bold: node.pastTag.indexOf('strong') >= 0,
    fontSize: node.content === '\n' || node.text === '' ? 1 : 16,
    italic: node.pastTag.indexOf('i') >= 0 || node.pastTag.indexOf('em') >= 0,
    color: '000000',
    //strike: node.pastTag.indexOf('strike') >= 0,
    // subscript: node.pastTag.indexOf('sub') >= 0,
    // superscript: node.pastTag.indexOf('sup') >= 0,
    // underline: node.pastTag.indexOf('u') >= 0,
    // hyperlink: {
    //   url: 'https://github.com/gitbrent/pptxgenjs',
    //   tooltip: 'Visit Homepage',
    // },
  };
  // options.bold = node.pastTag.indexOf("strong") >= 0;
  // options.fontSize = 16;

  const hTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

  const filtredHTags = node.pastTag.filter(function (n: string) {
    return hTags.indexOf(n) !== -1;
  });

  const htag =
    filtredHTags.length > 0
      ? 'h' +
        Math.min(
          ...filtredHTags.map((e: string) => parseInt(e.replace('h', ''))),
        )
      : undefined;

  let scale = 1;

  switch (htag) {
    case undefined:
      scale = 1;
      break;
    case 'h1':
      scale = 2;
      break;
    case 'h2':
      scale = 1.5;
      break;
    case 'h3':
      scale = 1.17;
      break;
    case 'h4':
      scale = 1;
      break;
    case 'h5':
      scale = 0.83;
      break;
    case 'h6':
      scale = 0.67;
      break;
    default:
      scale = 1;
  }

  options.fontSize = Math.round(options.fontSize * scale);

  options.italic =
    node.pastTag.indexOf('i') >= 0 || node.pastTag.indexOf('em') >= 0;
  // options.strike = node.pastTag.indexOf("strike") >= 0;
  // options.subscript = node.pastTag.indexOf("sub") >= 0;
  // options.superscript = node.pastTag.indexOf("sup") >= 0;
  // options.underline = node.pastTag.indexOf("u") >= 0;
  if (
    node.type === 'link' ||
    node.type === 'acronym' ||
    node.type === 'topic'
  ) {
    options.hyperlink = {
      tooltip: node.topic
        ? `${node.url.split('topics=')[0].concat('topics=') + node.topic}`
        : node.url || node.acronym,
      url: node.topic
        ? `${node.url.split('topics=')[0].concat('topics=') + node.topic}`
        : node.url,
      slide: !node.url && !node.topic ? 1 : undefined,
    };

    if (node.type === 'link' || node.type === 'topic') {
      options.color = '0000FF';
      options.italic = true;
    }
    if (node.type === 'acronym') {
      options.color = 'C00000';
    }

    return {
      text: node.content,
      options,
      isList: node.pastTag.indexOf('li') >= 0 && node.content != '\n',

      isHtag: !!htag,
    };
  } else if (node.type === 'text') {
    if (node.content === '\n') {
      // options.indentLevel = 2;
      // options.bullet = false;
      return {
        text: '',
        options,
        isList: node.pastTag.indexOf('li') >= 0,
        isHtag: !!htag,
      };
    }

    return {
      text: node.content,
      options: options,
      isList: node.pastTag.indexOf('li') >= 0,
      isHtag: !!htag,
    };
  } else if (node.type === 'element' && node.tagName === 'img') {
    const src = node.attributes.filter(function (att: { key: string }) {
      return att.key === 'src';
    })[0].value;

    // var width = node.attributes.filter(function (att) {
    //   return att.key === "width";
    // })[0].value;

    return { isImage: true, path: src, w: 6, h: 2.8 };
  }
}

function removeRedundantNewlines(arr: any) {
  let result = [];
  let previousText = null;

  for (const item of arr) {
    if (item.text === '\n' && previousText === '\n') {
      continue; // Skip redundant '\n'
    }

    result.push(item);
    previousText = item.text;
  }

  return result;
}

function getRows(parsedHtml: any[]) {
  if (parsedHtml.length === 0) {
    return [
      {
        text: ' ',
        nodes: [
          getPPTOptions({
            type: 'text',
            content: ' ',
            pastTag: [],
            parentindex: [0],
          }),
        ],
      },
    ];
  }
  const array: any[] = [];

  if (
    parsedHtml[0].type === 'element' &&
    parsedHtml[0].pastTag.indexOf('img') >= 0
  ) {
    array.push(getPPTOptions(parsedHtml[0]));
  } else {
    array.push({
      text: parsedHtml[0].content,
      nodes: [getPPTOptions(parsedHtml[0])],
    });
  }

  for (let i = 1; i < parsedHtml.length; i++) {
    if (!parsedHtml[i].pastTag) {
      parsedHtml[i].pastTag = [];
      array.push(getPPTOptions(parsedHtml[i]));
    } else if (parsedHtml[i].pastTag.indexOf('img') >= 0) {
      array.push(getPPTOptions(parsedHtml[i]));
      //case List
    } else if (
      parsedHtml[i].pastTag.indexOf('li') >= 0 &&
      (parsedHtml[i].pastTag.indexOf('span') >= 0 ||
        parsedHtml[i].pastTag.indexOf('a') >= 0 ||
        parsedHtml[i].pastTag.indexOf('strong') >= 0 ||
        parsedHtml[i].pastTag.indexOf('u') >= 0 ||
        parsedHtml[i].pastTag.indexOf('p') >= 0)
    ) {
      let indexCopy;
      //case balise a and strong
      if (
        parsedHtml[i].pastTag.indexOf('strong') >= 0 &&
        parsedHtml[i].pastTag.indexOf('a') >= 0
      ) {
        indexCopy = parsedHtml[i].pastTag.indexOf('strong');
      } else if (
        parsedHtml[i].pastTag.indexOf('u') >= 0 &&
        parsedHtml[i].pastTag.indexOf('a') >= 0
      ) {
        indexCopy = parsedHtml[i].pastTag.indexOf('u');
      } else {
        indexCopy = Math.min(
          parsedHtml[i].pastTag.indexOf('span'),
          parsedHtml[i].pastTag.indexOf('a'),
          parsedHtml[i].pastTag.indexOf('strong'),
          parsedHtml[i].pastTag.indexOf('u'),
          parsedHtml[i].pastTag.indexOf('p'),
        );
        if (indexCopy === -1) {
          indexCopy = Math.max(
            parsedHtml[i].pastTag.indexOf('span'),
            parsedHtml[i].pastTag.indexOf('a'),
            parsedHtml[i].pastTag.indexOf('strong'),
            parsedHtml[i].pastTag.indexOf('u'),
            parsedHtml[i].pastTag.indexOf('p'),
          );
        }
      }

      const subArray = parsedHtml[i].parentindex.slice(0, indexCopy);
      //case balise a and strong

      if (
        parsedHtml[i - 1].pastTag.indexOf('strong') >= 0 &&
        parsedHtml[i - 1].pastTag.indexOf('a') >= 0
      ) {
        indexCopy = parsedHtml[i - 1].pastTag.indexOf('strong');
      } else if (
        parsedHtml[i - 1].pastTag.indexOf('u') >= 0 &&
        parsedHtml[i - 1].pastTag.indexOf('a') >= 0
      ) {
        indexCopy = parsedHtml[i - 1].pastTag.indexOf('u');
      } else {
        indexCopy = Math.min(
          parsedHtml[i - 1].pastTag.indexOf('span'),
          parsedHtml[i - 1].pastTag.indexOf('a'),
          parsedHtml[i - 1].pastTag.indexOf('strong'),
          parsedHtml[i - 1].pastTag.indexOf('u'),
          parsedHtml[i - 1].pastTag.indexOf('p'),
        );
        if (indexCopy === -1) {
          indexCopy = Math.max(
            parsedHtml[i - 1].pastTag.indexOf('span'),
            parsedHtml[i - 1].pastTag.indexOf('a'),
            parsedHtml[i - 1].pastTag.indexOf('strong'),
            parsedHtml[i - 1].pastTag.indexOf('u'),
            parsedHtml[i - 1].pastTag.indexOf('p'),
          );
        }
      }

      if (indexCopy === -1) indexCopy = subArray.length;
      const predSubArray = parsedHtml[i - 1].parentindex.slice(0, indexCopy);

      if (JSON.stringify(subArray) === JSON.stringify(predSubArray)) {
        if (array[array.length - 1].text) {
          array[array.length - 1].text =
            array[array.length - 1].text + parsedHtml[i].content;
        }
        array[array.length - 1].nodes = array[array.length - 1].nodes?.concat(
          getPPTOptions(parsedHtml[i]),
        );
      } else {
        array.push({
          text: parsedHtml[i].content,
          nodes: [getPPTOptions(parsedHtml[i])],
        });
      }
    } else if (
      parsedHtml[i].pastTag.indexOf('li') >= 0 &&
      (parsedHtml[i - 1].pastTag.indexOf('span') >= 0 ||
        parsedHtml[i - 1].pastTag.indexOf('a') >= 0 ||
        parsedHtml[i - 1].pastTag.indexOf('strong') >= 0 ||
        parsedHtml[i - 1].pastTag.indexOf('u') >= 0 ||
        parsedHtml[i - 1].pastTag.indexOf('p') >= 0)
    ) {
      let indexCopy1;
      //case balise a and strong
      if (
        parsedHtml[i - 1].pastTag.indexOf('strong') >= 0 &&
        parsedHtml[i - 1].pastTag.indexOf('a') >= 0
      ) {
        indexCopy1 = parsedHtml[i - 1].pastTag.indexOf('strong');
      } else if (
        parsedHtml[i - 1].pastTag.indexOf('u') >= 0 &&
        parsedHtml[i - 1].pastTag.indexOf('a') >= 0
      ) {
        indexCopy1 = parsedHtml[i - 1].pastTag.indexOf('u');
      } else {
        indexCopy1 = Math.min(
          parsedHtml[i - 1].pastTag.indexOf('span'),
          parsedHtml[i - 1].pastTag.indexOf('a'),
          parsedHtml[i - 1].pastTag.indexOf('strong'),
          parsedHtml[i - 1].pastTag.indexOf('u'),
          parsedHtml[i - 1].pastTag.indexOf('p'),
        );
        if (indexCopy1 === -1) {
          indexCopy1 = Math.max(
            parsedHtml[i - 1].pastTag.indexOf('span'),
            parsedHtml[i - 1].pastTag.indexOf('a'),
            parsedHtml[i - 1].pastTag.indexOf('strong'),
            parsedHtml[i - 1].pastTag.indexOf('u'),
            parsedHtml[i - 1].pastTag.indexOf('p'),
          );
        }
      }

      const subArray1 = parsedHtml[i].parentindex.slice(0, indexCopy1);

      if (
        parsedHtml[i].pastTag.indexOf('strong') >= 0 &&
        parsedHtml[i].pastTag.indexOf('a') >= 0
      ) {
        indexCopy1 = parsedHtml[i].pastTag.indexOf('strong');
      } else if (
        parsedHtml[i].pastTag.indexOf('u') >= 0 &&
        parsedHtml[i].pastTag.indexOf('a') >= 0
      ) {
        indexCopy1 = parsedHtml[i].pastTag.indexOf('u');
      } else {
        indexCopy1 = Math.min(
          parsedHtml[i].pastTag.indexOf('span'),
          parsedHtml[i].pastTag.indexOf('a'),
          parsedHtml[i].pastTag.indexOf('strong'),
          parsedHtml[i].pastTag.indexOf('u'),
          parsedHtml[i].pastTag.indexOf('p'),
        );
        if (indexCopy1 === -1) {
          indexCopy1 = Math.max(
            parsedHtml[i].pastTag.indexOf('span'),
            parsedHtml[i].pastTag.indexOf('a'),
            parsedHtml[i].pastTag.indexOf('strong'),
            parsedHtml[i].pastTag.indexOf('u'),
            parsedHtml[i].pastTag.indexOf('p'),
          );
        }
      }

      if (indexCopy1 === -1) indexCopy1 = subArray1.length;
      const predSubArray1 = parsedHtml[i - 1].parentindex.slice(0, indexCopy1);

      if (JSON.stringify(subArray1) === JSON.stringify(predSubArray1)) {
        if (array[array.length - 1].text) {
          array[array.length - 1].text =
            array[array.length - 1].text + parsedHtml[i].content;
        }
        array[array.length - 1].nodes = array[array.length - 1].nodes?.concat(
          getPPTOptions(parsedHtml[i]),
        );
      } else {
        array.push({
          text: parsedHtml[i].content,
          nodes: [getPPTOptions(parsedHtml[i])],
        });
      }
    } // case not list
    else if (
      parsedHtml[i].pastTag.indexOf('li') < 0 &&
      (parsedHtml[i].pastTag.indexOf('span') >= 0 ||
        parsedHtml[i].pastTag.indexOf('a') >= 0)
    ) {
      let indexCopy;
      if (
        parsedHtml[i].pastTag.indexOf('em') >= 0 &&
        parsedHtml[i].pastTag.indexOf('span') >= 0
      ) {
        indexCopy = parsedHtml[i].pastTag.indexOf('em');
      } else {
        indexCopy = Math.min(
          parsedHtml[i].pastTag.indexOf('span'),
          parsedHtml[i].pastTag.indexOf('a'),
        );
        if (indexCopy === -1) {
          indexCopy = Math.max(
            parsedHtml[i].pastTag.indexOf('span'),
            parsedHtml[i].pastTag.indexOf('a'),
          );
        }
      }
      const subArray = parsedHtml[i].parentindex.slice(0, indexCopy);

      if (
        parsedHtml[i - 1].pastTag.indexOf('em') >= 0 &&
        parsedHtml[i - 1].pastTag.indexOf('span') >= 0
      ) {
        indexCopy = parsedHtml[i - 1].pastTag.indexOf('em');
      } else {
        indexCopy = Math.min(
          parsedHtml[i - 1].pastTag.indexOf('span'),
          parsedHtml[i - 1].pastTag.indexOf('a'),
        );
        if (indexCopy === -1) {
          indexCopy = Math.max(
            parsedHtml[i - 1].pastTag.indexOf('span'),
            parsedHtml[i - 1].pastTag.indexOf('a'),
          );
        }
      }
      if (indexCopy === -1) indexCopy = subArray.length;
      const predSubArray = parsedHtml[i - 1].parentindex.slice(0, indexCopy);

      if (JSON.stringify(subArray) === JSON.stringify(predSubArray)) {
        if (array[array.length - 1].text) {
          array[array.length - 1].text =
            array[array.length - 1].text + parsedHtml[i].content;
        }
        array[array.length - 1].nodes = array[array.length - 1].nodes?.concat(
          getPPTOptions(parsedHtml[i]),
        );
      } else {
        array.push({
          text: parsedHtml[i].content,
          nodes: [getPPTOptions(parsedHtml[i])],
        });
      }
    } else if (
      parsedHtml[i].pastTag.indexOf('li') < 0 &&
      (parsedHtml[i - 1].pastTag.indexOf('span') >= 0 ||
        parsedHtml[i - 1].pastTag.indexOf('a') >= 0)
    ) {
      let indexCopy1;
      if (
        parsedHtml[i - 1].pastTag.indexOf('em') >= 0 &&
        parsedHtml[i - 1].pastTag.indexOf('span') >= 0
      ) {
        indexCopy1 = parsedHtml[i - 1].pastTag.indexOf('em');
      } else {
        indexCopy1 = Math.min(
          parsedHtml[i - 1].pastTag.indexOf('span'),
          parsedHtml[i - 1].pastTag.indexOf('a'),
        );
        if (indexCopy1 === -1) {
          indexCopy1 = Math.max(
            parsedHtml[i - 1].pastTag.indexOf('span'),
            parsedHtml[i - 1].pastTag.indexOf('a'),
          );
        }
      }
      const subArray1 = parsedHtml[i].parentindex.slice(0, indexCopy1);
      if (
        parsedHtml[i - 1].pastTag.indexOf('em') >= 0 &&
        parsedHtml[i - 1].pastTag.indexOf('span') >= 0
      ) {
        indexCopy1 = parsedHtml[i - 1].pastTag.indexOf('em');
      } else {
        indexCopy1 = Math.min(
          parsedHtml[i].pastTag.indexOf('span'),
          parsedHtml[i].pastTag.indexOf('a'),
        );
        if (indexCopy1 === -1) {
          indexCopy1 = Math.max(
            parsedHtml[i].pastTag.indexOf('span'),
            parsedHtml[i].pastTag.indexOf('a'),
          );
        }
      }
      if (indexCopy1 === -1) indexCopy1 = subArray1.length;
      const predSubArray1 = parsedHtml[i - 1].parentindex.slice(0, indexCopy1);

      if (JSON.stringify(subArray1) === JSON.stringify(predSubArray1)) {
        if (array[array.length - 1].text) {
          array[array.length - 1].text =
            array[array.length - 1].text + parsedHtml[i].content;
        }
        array[array.length - 1].nodes = array[array.length - 1].nodes.concat(
          getPPTOptions(parsedHtml[i]),
        );
      } else {
        array.push({
          text: parsedHtml[i].content,
          nodes: [getPPTOptions(parsedHtml[i])],
        });
      }
    } else {
      array.push({
        text: parsedHtml[i].content,
        nodes: [getPPTOptions(parsedHtml[i])],
      });
    }
  }
  return removeRedundantNewlines(array);
}

function createIntro(pptx: PptxGenJS, strategy: Strategy) {
  const intro = pptx.addSlide();
  const dateNow = new Date();
  const month =
    dateNow.getMonth() + 1 > 9
      ? dateNow.getMonth() + 1
      : '0' + (dateNow.getMonth() + 1).toString();
  const day =
    dateNow.getDate() > 9 ? dateNow.getDate() : '0' + dateNow.getDate();
  const properDate = day + '/' + month + '/' + dateNow.getFullYear();

  const heightOfCategory = Math.ceil(strategy.title.length / 20) * 0.35;

  intro.addShape(pptx.ShapeType.rect, {
    x: 0,
    y: 0,
    w: 5,
    h: 5,
    fill: { color: 'cccccc' },
  });
  intro.addShape(pptx.ShapeType.rect, {
    x: 2,
    y: 0.19,
    w: 3.5,
    h: 7.1,
    fill: { color: 'ffffff' },
    rotate: 45,
  });
  intro.addImage({
    path: '/img/handshake.png',
    x: 0.5,
    y: 0.3,
    w: 5.0,
    h: 5.0,
  });
  intro.addShape(pptx.ShapeType.rect, {
    x: 3.1,
    y: 2.9,
    w: 2.2,
    h: 2.2,
    fill: { color: 'e41b13' },
    rotate: 45,
  });
  intro.addShape(pptx.ShapeType.rect, {
    x: 3.15,
    y: 2.95,
    w: 2.1,
    h: 2.1,
    fill: { color: 'ffffff' },
    rotate: 45,
  });
  intro.addImage({
    path: '/img/BUYIN-alliance_final-02-01.png',
    x: 3.2,
    y: 3.64,
    w: 2.0,
    h: 0.7,
  });

  intro.addText(strategy.title, {
    x: 6.7,
    y: 5.5,
    w: 6,
    h: heightOfCategory,
    color: '000000',
    fontSize: 30,
    lineSpacing: 30,
    align: 'right',
  });

  intro.addText(
    (strategy.author.name || strategy.author.email) + ', ' + properDate,
    {
      x: 6.7,
      y: 6.5,
      w: 6,
      h: 0.5,
      color: 'C00000',
      fontSize: 24,
      align: 'right',
    },
  );
}

function createAgenda(pptx: PptxGenJS, strategy: Strategy) {
  const agenda = pptx.addSlide();
  const startingY = 1;
  createHeader(pptx, agenda, 'Agenda');

  if (
    strategy.regional_market.summary.length > 0 ||
    strategy.regional_market.detail.length > 0
  ) {
    agenda.addText(
      [
        {
          text: 'Category Description',
          options: {
            bullet: { type: 'number' },
            indentLevel: 0,
            lineSpacing: 28,
            bold: true,
          },
        },
        {
          text: 'Market perspective',
          options: {
            bullet: {
              type: 'number',
            },
            indentLevel: 0,
            lineSpacing: 56,
            bold: true,
          },
        },
        {
          text: 'Market & Trends',
          options: {
            bullet: { type: 'bullet' },
            indentLevel: 1,
            lineSpacing: 28,
          },
        },
        {
          text: 'Regional Trends',
          options: {
            bullet: { type: 'bullet' },
            indentLevel: 1,
            lineSpacing: 28,
          },
        },
        {
          text: 'Sourcing',
          options: {
            bullet: { type: 'number' },
            indentLevel: 0,
            lineSpacing: 56,
            bold: true,
          },
        },
        {
          text: 'Spend',
          options: {
            bullet: { type: 'bullet' },
            indentLevel: 1,
            lineSpacing: 28,
          },
        },
        {
          text: 'Opportunities & risks',
          options: {
            bullet: { type: 'bullet' },
            indentLevel: 1,
            lineSpacing: 28,
          },
        },
        {
          text: 'Sourcing strategy – objectives',
          options: {
            bullet: { type: 'bullet' },
            indentLevel: 1,
            lineSpacing: 28,
          },
        },
        {
          text: 'Sourcing strategy – methods',
          options: {
            bullet: { type: 'bullet' },
            indentLevel: 1,
            lineSpacing: 28,
          },
        },
      ],
      {
        x: 0.75,
        y: startingY,
        w: 12,
        h: 5,
        fontSize: 18,
      },
    );
  } else {
    agenda.addText(
      [
        {
          text: 'Category Description',
          options: {
            bullet: { type: 'number' },
            indentLevel: 0,
            lineSpacing: 28,
            bold: true,
          },
        },
        {
          text: 'Market perspective',
          options: {
            bullet: { type: 'number' },
            indentLevel: 0,
            lineSpacing: 56,
            bold: true,
          },
        },
        {
          text: 'Market & Trends',
          options: {
            bullet: { type: 'bullet' },
            indentLevel: 1,
            lineSpacing: 28,
          },
        },
        {
          text: 'Sourcing',
          options: {
            bullet: { type: 'number' },
            indentLevel: 0,
            lineSpacing: 56,
            bold: true,
          },
        },
        {
          text: 'Spend',
          options: {
            bullet: { type: 'bullet' },
            indentLevel: 1,
            lineSpacing: 28,
          },
        },
        {
          text: 'Opportunities & risks',
          options: {
            bullet: { type: 'bullet' },
            indentLevel: 1,
            lineSpacing: 28,
          },
        },
        {
          text: 'Sourcing strategy – objectives',
          options: {
            bullet: { type: 'bullet' },
            indentLevel: 1,
            lineSpacing: 28,
          },
        },
        {
          text: 'Sourcing strategy – methods',
          options: {
            bullet: { type: 'bullet' },
            indentLevel: 1,
            lineSpacing: 28,
          },
        },
      ],
      {
        x: 0.75,
        y: startingY,
        w: 12,
        h: 5,
        fontSize: 18,
      },
    );
  }
}

function createHeader(
  pptx: PptxGenJS,
  slide: PptxGenJS.Slide,
  pageName: string,
  title?: string,
) {
  slide.addText(pageName, {
    x: 0.55,
    y: 0.3,
    w: 6,
    color: '000000',
    fontSize: 24,
  });

  slide.addShape(pptx.ShapeType.line, {
    x: 0.5,
    y: 0.8,
    w: 5,
    h: 0,
    line: { color: 'e41b13', width: 1 },
  });

  if (title) {
    slide.addText(title, {
      x: 0.5,
      y: 1.1,
      w: 6,
      color: '404040',
      fontSize: 18,
      bold: true,
    });
  }

  /* footer  */
  pageNumber++;
  slide.addText(pageNumber.toString(), {
    x: 11.85,
    y: 7.1,
    w: 0.6,
    h: 0.3,
    fontSize: 12,
    color: '000000',
  });
  slide.addImage({
    path: '/img/buyin_logo.png',
    x: 0.5 / 2.54, // 0.5 cm
    y: 17.81 / 2.54, // 17.81 cm
    h: 1.2 / 2.54, // 1.2 cm
    w: 3.93 / 2.54, // 3.93 cm
  });

  slide.addImage({
    path: '/img/copy-right.png',
    x: 4.65 / 2.54, // 4.65 cm
    y: 18.14 / 2.54, // 18.14 cm
    w: 0.3346456693,
    h: 0.3346456693,
  });

  const year = new Date().getFullYear();
  const year_string: string = year.toString() + '/' + ((year + 1) % 1000);
  slide.addText('BuyIn ' + year_string + ' All rights reserved', {
    x: 5.3 / 2.54, // 5.3 cm
    y: 18.55 / 2.54, // 18.55 cm
    w: 6,
    color: '000000',
    fontSize: 12,
  });
}

function dataToUrl(url: string | URL) {
  return new Promise(function (resolve) {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.readAsDataURL(xhr.response);

      reader.onloadend = function () {
        resolve(reader.result);
      };
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
}

async function createSlides(
  pageName: string,
  pptx: PptxGenJS,
  title: string,
  content: any,
) {
  const slides = [pptx.addSlide()];
  let indexSlide: number = 0;
  createHeader(pptx, slides[indexSlide], pageName, title);
  let startingY = 1.4;
  let numberOfLines: number;
  const promises: { (): Promise<unknown>; (): Promise<unknown> }[] = [];
  if(title === 'Market & Trends - Details') console.log(content);
  content.forEach(function (element: any, index: number) {
    if (element.text && element.nodes) {
      promises.push(function () {
        return new Promise(function (resolve) {
          const indexBold = element.nodes?.findIndex(
            (node: any) => node.options.bold,
          );
          
          const nextLinesLength =
            Math.ceil(content[index + 1]?.text?.length / 120) * 0.25;
          const nextElementIsAnImage =
          indexBold >=0 && index !== content?.length - 2 && content[index+1]?.isImage
          if (indexBold >= 0 && nextElementIsAnImage) {
            console.log(content[index+1]);
          }
          if (
            startingY + numberOfLines > 5 ||
            isNaN(startingY) ||
            (indexBold >= 0 &&
              nextLinesLength + startingY + numberOfLines > 5) ||
            (nextElementIsAnImage && startingY + 3 > 5)

          ) {
            startingY = 1.4;
            indexSlide++;
            slides[indexSlide] = pptx.addSlide();

            createHeader(pptx, slides[indexSlide], pageName, title);
          }
          element.nodes = element.nodes.map((node: any) => {
            if (
              node?.options?.hyperlink?.tooltip &&
              !node.options.hyperlink?.url
            ) {
              return {
                ...node,
                options: {
                  ...node.options,
                  hyperlink: {
                    tooltip: node.options.hyperlink.tooltip,
                    slide: pageNumber + 1,
                  },
                },
              };
            }
            return node;
          });

          const scale =
            element.nodes.reduce(function (acc: any, current: any) {
              return acc + current.scale || 1;
            }, 0) / element.nodes.length;

          numberOfLines =
            Math.ceil(element.text.length / 120) * 0.25 +
            (scale - 0.75 > 0 ? scale - 0.75 : 0);
          if (element.text === '\n' || /^ +$/.test(element.text)) {
            numberOfLines = 0.05;
            startingY -= 0.25;
          }
          if (
            element.nodes.some(function (node: any) {
              return node.isList === true;
            })
          ) {
            slides[indexSlide].addText(
              [{ text: ' ', options: { color: 'C00000' } }].concat(
                element.nodes,
              ),
              {
                x: 0.5,
                y: startingY,
                w: 12,
                paraSpaceBefore: 0,
                h: numberOfLines,
                color: 'C00000',
                bullet: element.nodes.some(function (node: any) {
                  return node.isList === true;
                })
                  ? {}
                  : false,
                indentLevel: 0,
                // element.nodes.some(function (node: any) {
                //   return node.isList === true;
                // })
                //   ? 1
                //   : 0,
              },
            );
          } else {
            slides[indexSlide].addText(element.nodes, {
              x: 0.5,
              y: startingY,
              w: 12,
              h: numberOfLines,
              color: '000000',
            });
          }

          startingY += numberOfLines + 0.25;
          resolve(true);
        });
      });
    } else if (element.isImage) {
      promises.push(function () {
        return new Promise(function (resolve) {
          dataToUrl(element.path).then(function (data) {
            if (startingY > 3) {
              startingY = 1.4;
              indexSlide++;
              slides[indexSlide] = pptx.addSlide();
              createHeader(pptx, slides[indexSlide], pageName, title);
            }
            element.isImage = undefined;
            element.x = 0.5;
            element.y = startingY + numberOfLines;
            element.h = 3.7;
            delete element.path;
            element.data = data;

            slides[indexSlide].addImage(element);
            startingY = startingY + 3.7 + numberOfLines;
            resolve(true);
          });
        });
      });
    }
  });

  return promises.reduce(function (acc, currentPromise) {
    return new Promise(function (resolve) {
      return acc.then(function () {
        // @ts-ignore
        return resolve(currentPromise());
      });
    });
  }, Promise.resolve());
}

function objectivesMethods(
  pptx: PptxGenJS,
  title: string | undefined,
  array: any[],
) {
  const slides = [pptx.addSlide()];
  let indexobj = 0;
  let indexCard = 0;

  createHeader(pptx, slides[indexobj], 'Sourcing', title);

  let startingCards = 0;
  let y = 1.6;

  //       createHeader(pptx, slides[indexobj], "Sourcing", title);
  let ind = 1;
  array.forEach(function (obj) {
    indexCard = indexobj;
    const detail = getRows(recursiveParse(obj.detail)).filter(function (d) {
      return d.text !== ' ' && d.text !== ' ' && d.text;
    });

    startingCards = y - 0.1;

    detail.forEach(function (d, ind) {
      if (y > 5) {
        y = 1.6;
        indexobj++;
        if (ind === 0) {
          startingCards = y - 0.1;
          indexCard = indexobj;
        }

        slides[indexobj] = pptx.addSlide();

        createHeader(pptx, slides[indexobj], 'Sourcing', title);
      }

      const nb = Math.ceil(d.text.length / 150) * 0.65;
      slides[indexobj].addText(d.text, {
        x: 0.5,
        y: y,
        w: 12,
        h: nb,
        color: '000000',
        fill: { color: 'ecedee' },
        //padding: 3,
        bullet: d.nodes.some((n: { isList: any }) => n.isList),
      });
      y += nb;

      // if (detail.length === 1) {

      // }
    });
    y += 0.8;
    let lastNB = 0;
    obj.cards.forEach(function (card: { name: string }) {
      const nb = Math.ceil(card.name.length / 150) * 0.35;
      slides[indexCard].addText([{ text: card.name, options: {} }], {
        isTextBox: true,
        x: 0.5 + lastNB,
        //z: 2,
        y: startingCards - 0.25,
        w: card.name.length * 0.12,
        h: nb,
        color: 'FFFFFF',
        fill: { color: 'e41b13' },
        fontSize: 12,
        //padding: 1,
        bold: true,
        align: 'center',
      });
      lastNB = card.name.length * 0.12 + 0.15;
    });
    ind++;
  });
  //       });
}

function informationSlide(
  pptx: PptxGenJS,
  strategy: Strategy,
  contact: string | null,
) {
  var information = pptx.addSlide();

  information.addText('For more information,', {
    x: 0.5,
    y: 1,
    w: 6,
    color: 'e41b13',
    fontSize: 24,
  });
  information.addText('please contact:', {
    x: 0.5,
    y: 1.5,
    w: 6,
    color: 'e41b13',
    fontSize: 24,
  });
  information.addShape(pptx.ShapeType.rect, {
    x: 8.34,
    y: 2.5,
    w: 4.99,
    h: 5,
    fill: { color: 'cccccc' },
  });
  information.addShape(pptx.ShapeType.rect, {
    x: 7.84,
    y: 0.2,
    w: 3.5,
    h: 7.1,
    fill: { color: 'ffffff' },
    rotate: 45,
  });
  information.addImage({
    path: '/img/handshake.png',
    x: 8,
    y: 2.3,
    w: 5.0,
    h: 5.0,
  });
  information.addShape(pptx.ShapeType.rect, {
    x: 8.5,
    y: 2.6,
    w: 2.2,
    h: 2.2,
    fill: { color: 'e41b13' },
    rotate: 45,
  });
  information.addShape(pptx.ShapeType.rect, {
    x: 8.55,
    y: 2.65,
    w: 2.1,
    h: 2.1,
    fill: { color: 'ffffff' },
    rotate: 45,
  });
  information.addImage({
    path: '/img/BUYIN-alliance_final-02-01.png',
    x: 8.6,
    y: 3.35,
    w: 2.0,
    h: 0.7,
  });
  information.addText(strategy.author.name || strategy.author.email, {
    x: 2,
    y: 2.5,
    w: 8,
    color: '000000',
    fontSize: 16,
  });
  information.addText(
    [
      {
        text: strategy.author.email,
        options: {
          hyperlink: {
            url: 'mailto:' + strategy.author.email,
            tooltip: 'Email',
          },
        },
      },
    ],
    { x: 2, y: 2.9, w: 8, color: '000000', fontSize: 16 },
  );
  // ReferenceError: contact is not defined
  // information.addText(
  //     [
  //         {
  //             text:
  //                 contact && contact
  //                     ? contact.Mobile.includes("+")
  //                     ? contact.Mobile
  //                     : "+" + contact.Mobile
  //                     : "",
  //             options: {
  //                 hyperlink: {
  //                     url:
  //                         "tel:" +
  //                         (contact && contact()
  //                             ? "+" + contact().Mobile
  //                             : ""),
  //                     tooltip: "Mobile",
  //                 },
  //             },
  //         },
  //     ],
  //     { x: 2, y: 3.3, w: 8, color: "000000", fontSize: 16 }
  // );
  if (contact) {
    information.addImage({
      path: contact ? contact : '../contacts/api/contactsImage/0.jpg',
      x: 0.6,
      y: 2,
      w: 1.2,
      h: 1.5,
    });
  }
}

function createDescription(pptx: PptxGenJS, strategy: Strategy) {
  const description = pptx.addSlide();
  createHeader(pptx, description, 'Category description');

  const introParsed = strategy.excerpt;
  const heightOfIntro = Math.ceil(introParsed.length / 120) * 0.25;
  description.addText(introParsed.replace(/\r\n|\r|\n/g, ''), {
    x: 0.5,
    y: 1,
    w: 12,
    h: heightOfIntro,
    color: '000000',
    fontSize: 16,
  });
}

async function downloadPptx(strategy: Strategy, contact: string | null) {
  pageNumber = 0;
  const pptx = new pptxgen();
  pptx.layout = 'LAYOUT_WIDE';

  createIntro(pptx, strategy);
  createAgenda(pptx, strategy);
  createDescription(pptx, strategy);

  const rows_market_summary = getRows(
    recursiveParse(strategy.global_market.summary),
  ).filter(function (elem) {
    return elem.text !== ' ' && elem.text !== ' ';
  });
  const rows_market_detail = getRows(
    recursiveParse(strategy.global_market.detail),
  ).filter(function (elem) {
    return elem.text !== ' ' && elem.text !== ' ';
  });
  const rows_regional_market_summary = getRows(
    recursiveParse(strategy.regional_market.summary),
  ).filter(function (elem) {
    return elem.text !== ' ' && elem.text !== ' ';
  });
  const rows_regional_market_detail = getRows(
    recursiveParse(strategy.regional_market.detail),
  ).filter(function (elem) {
    return elem.text !== ' ' && elem.text !== ' ';
  });
  const rows_spend_summary = getRows(
    recursiveParse(strategy.spend.summary),
  ).filter(function (elem) {
    return elem.text !== ' ' && elem.text !== ' ';
  });
  const rows_spend_detail = getRows(
    recursiveParse(strategy.spend.detail),
  ).filter(function (elem) {
    return elem.text !== ' ' && elem.text !== ' ';
  });
  const rows_opportunities_summary = getRows(
    recursiveParse(strategy.opportunities.summary),
  ).filter(function (elem) {
    return elem.text !== ' ' && elem.text !== ' ';
  });
  const rows_opportunities_detail = getRows(
    recursiveParse(strategy.opportunities.detail),
  ).filter(function (elem) {
    return elem.text !== ' ' && elem.text !== ' ';
  });

  await createSlides(
    'Market perspective',
    pptx,
    'Market & Trends - Summary',
    rows_market_summary,
  );

  await createSlides(
    'Market perspective',
    pptx,
    'Market & Trends - Details',
    rows_market_detail,
  );

  if (strategy.regional_market.summary.length > 0) {
    await createSlides(
      'Market perspective',
      pptx,
      'Regional Trends - Summary',
      rows_regional_market_summary,
    );
  }

  if (strategy.regional_market.detail.length > 0) {
    await createSlides(
      'Market perspective',
      pptx,
      'Regional Trends - Details',
      rows_regional_market_detail,
    );
  }

  await createSlides('Sourcing', pptx, 'Spend - Summary', rows_spend_summary);

  await createSlides('Sourcing', pptx, 'Spend - Details', rows_spend_detail);

  await createSlides(
    'Sourcing',
    pptx,
    'Opportunities & Risks - Summary',
    rows_opportunities_summary,
  );

  await createSlides(
    'Sourcing',
    pptx,
    'Opportunities & Risks - Details',
    rows_opportunities_detail,
  );

  objectivesMethods(
    pptx,
    'Sourcing strategy - objectives',
    strategy.objectives,
  );

  objectivesMethods(pptx, 'Sourcing strategy - methods', strategy.methods);

  informationSlide(pptx, strategy, contact);

  // urlParameters.setParameters({Pptx: 1});

  pptx.writeFile({
    fileName: 'STRATEGY_' + strategy.title.toUpperCase(),
  });
}

interface StrategyPPTProps {
  strategy: Strategy;
  contact: string | null;
}

export default function DownloadPPT({ strategy, contact }: StrategyPPTProps) {
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="flex-end"
        spacing={2}
        width="100%"
      >
        <Link
          component="button"
          variant="body2"
          onClick={() => {
            downloadPptx(strategy, contact);
          }}
        >
          <Typography sx={{ color: 'error.main' }}>Download as PPT</Typography>
          <img src="/img/download.png" alt="download as pdf" loading="lazy" />
        </Link>
      </Stack>
    </Box>
  );
}
