import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';
import apiClient from '../../services/apiClient';
import { green, grey, orange, red, yellow } from '@mui/material/colors';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import InfoIcon from '@mui/icons-material/Info';


export default function SustainabilityDetail(props: {
  handleSustainabilityDialogClose: any;
  supplierId: number | undefined;
}) {
  const [colors, setColors] = useState<any>();
  const [sustainabilityScore, setSustainabilityScore] = useState<any>();
  const [supplier, setSupplier] = useState<any>();
  const sustainabilityTooltip = [
    { kpi: 'C1', value: 'NGO publishing environmental data provided by companies.' },
    { kpi: 'C2', value: '<p>Scope 1= Direct emissions from a company.</p></br><p>Scope 2= Indirect emissions from the generation of purchased electricity, heating, cooling. etc. consumed by a company.</p>' },
    { kpi: 'C3', value: "Scope 3 includes all other indirect emissions that occurs in a company's value chain." },
    { kpi: 'C4', value: 'A carbon emissions target is defined as science-based if it is in line with the scale of reductions require to keep global temperature increase below 2°C above pre-industrial temperatures and pursuing efforts to limit warming to 1.5°C.' },
    { kpi: 'C5', value: 'Ratings platform to assess corporate social responsibility and sustainable procurement.' },
    { kpi: 'C6', value: "Supplier's Emission Factor (EF) = Total Upstream2 Emissions (tCO2) + Total Revenue Supplier (m€) (as submitted in CDP reporting)" },

  ]


  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  function getSustainabilityScoreColor(score: any) {

    return score <= 100 && score >= 65
      ? '#006B3D'
      : score <= 65 && score >= 40
        ? '#7BB662'
        : score <= 40 && score >= 15
          ? '#FFD301'
          : score <= 15 && score >= 0
            ? '#E03C32'
            : grey[300];
  }
  useEffect(() => {
    if (props.supplierId) {
      apiClient
        .get(`/suppliers/${props.supplierId}`, {
          params: { hasRating: true, hasSustainabilityScores: true },
        })
        .then(({ data }) => {
          setSupplier(data.record);
          const { rating, risks, sustainabilityScores } = data.record;
          const { csr, sustainability, credit } = rating || {};
          setSustainabilityScore(
            sustainabilityScores?.filter((s: any) => s.kpi === 'SSS')[0],
          );


          setColors({
            credit: credit?.value?.startsWith('A')
              ? green[600]
              : credit?.value?.startsWith('B')
                ? yellow[600]
                : credit?.value?.match('^C.*|^D.*')
                  ? red[600]
                  : grey[300],
            csr:
              csr?.value >= 65
                ? green[600]
                : csr?.value <= 64 && csr?.value >= 44
                  ? orange[600]
                  : csr?.value <= 24
                    ? red[600]
                    : grey[300],
            sustainability:
              sustainability?.value &&
              (sustainability?.value <= 100 && sustainability?.value >= 65
                ? '#006B3D'
                : sustainability?.value <= 65 && sustainability?.value >= 40
                  ? '#7BB662'
                  : sustainability?.value <= 40 && sustainability?.value >= 15
                    ? '#FFD301'
                    : sustainability?.value <= 15 && sustainability?.value >= 0
                      ? '#E03C32'
                      : grey[300]),
          });
        });
    }
  }, [props.supplierId]);

  return (
    <>
      <Dialog
        open={true}
        onClose={props.handleSustainabilityDialogClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle >
          <div style={{ display: 'flex', justifyContent: 'space-between' }} >
            <Typography sx={{
              color: (theme: any) => theme.palette.primary.main,
            }} variant='h4'>Sustainability Scores </Typography>

            {/* {contractToDisplay?.name} */}
            <span>
              <IconButton
                onClick={props.handleSustainabilityDialogClose}
              >
                <CloseIcon />
              </IconButton>


            </span>
          </div>
        </DialogTitle>
        <DialogContent dividers>
          {supplier && (
            <>
              <ListItem>
                <ListItemText sx={{ marginLeft: '-14px', marginRight: '-14px' }}>
                  <List>
                    {(
                      supplier?.sustainabilityScores?.filter(
                        (s: any) => s.kpi !== 'SSS',
                      ) || []
                    ).sort((a: any, b: any) => {
                      return Number(a.kpi[1]) - Number(b.kpi[1]);
                    }).map((suScore: any, i: number) => (
                      <React.Fragment key={i}>
                        <ListItem style={{ display: 'flex', width: '75%' }}>
                          <ListItemText style={{ flex: '3' }}>
                            {suScore.kpi} - {suScore.description}
                            <HtmlTooltip
                              title={
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: sustainabilityTooltip.find(item => item.kpi === suScore.kpi)?.value || '',
                                  }}
                                ></span>
                              }
                            >
                              <InfoIcon
                                sx={{
                                  fontSize: '15px',
                                  marginTop: '5px !important',
                                }}
                              />
                            </HtmlTooltip>
                          </ListItemText>
                          <ListItemText style={{ flex: '0.5' }}>
                            {suScore.score}
                          </ListItemText>
                          <ListItemText style={{ flex: '1' }}>
                            <FiberManualRecordIcon
                              sx={{
                                color: getSustainabilityScoreColor(
                                  suScore.score,
                                ),
                              }}
                            />
                          </ListItemText>
                        </ListItem>
                      </React.Fragment>
                    ))}
                  </List>
                </ListItemText>
              </ListItem>
              <Box display="flex" justifyContent="flex-end" sx={{ mt: 2, pr: 2 }}>
                <Typography component="span" sx={{ fontWeight: 'bold' }}>
                  Date:
                </Typography>
                <Typography component="span" sx={{ ml: 0.5 }}>
                  {(supplier?.sustainabilityScores?.filter(
                    (s: any) => s.kpi !== 'SSS',
                  ) || [])[0].date.split('T')[0]}
                </Typography>
              </Box>
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
