import { useState } from 'react';
import Box from '@mui/material/Box';
import UploadForm from '../../Upload/UploadForm/UploadForm';
import EmailResults from '../EmailResults';

export default function SummitInvite() {
  const [errors, setErrors] = useState<any>([]);
  const [success, setSuccess] = useState<any>([]);
  const [displayResults, setDisplayResults] = useState(false);

  return (
    <Box p={1}>
      <UploadForm
        pathId={5}
        path="enrollments/invitations/upload"
        inputId="invitations-sheet"
        inputName="invitations"
        inputAccept=".csv"
        hideSuccessMessage={true}
        onSuccess={(res: any) => {
          setDisplayResults(true);
          setErrors(
            res.data.errors.map((error: any, index: number) => ({
              id: index,
              ...error,
            })),
          );
          setSuccess(
            res.data.success.map((success: any, index: number) => ({
              id: index,
              ...success,
            })),
          );
        }}
      />
      <EmailResults
        success={success}
        errors={errors}
        displayResults={displayResults}
      />
    </Box>
  );
}
