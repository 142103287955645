import { useState } from 'react';
import apiClient from '../../../services/apiClient';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import logoBuyin from '../../../images/negotiation/BUYIN-alliance_final-02-01.png';
import networkImage from '../../../images/negotiation/net1.png';
import fireIcon from '../../../images/negotiation/fireIcon.png';
import tcapIcon from '../../../images/negotiation/tcapIcon.png';
import { LoadingButton } from '@mui/lab';
import PptxGenJS from 'pptxgenjs';
import moment from 'moment';
import { INegotiation } from '../../../types';
import { getPosition } from './RoadmapViewList';

function addIntro(presentation: PptxGenJS) {
  const RECTANGLE = presentation.ShapeType.rect;
  const intro = presentation.addSlide();
  intro.addShape(RECTANGLE, {
    x: 0,
    y: 0,
    w: 5,
    h: 5,
    fill: { color: 'cccccc' },
  });
  intro.addShape(RECTANGLE, {
    x: 2,
    y: 0.19,
    w: 3.5,
    h: 7.1,
    fill: { color: 'ffffff' },
    rotate: 45,
  });
  intro.addImage({ path: networkImage, x: 0.5, y: 0.3, w: 5.0, h: 5.0 });
  intro.addShape(RECTANGLE, {
    x: 3.1,
    y: 2.9,
    w: 2.2,
    h: 2.2,
    fill: { color: 'e41b13' },
    rotate: 45,
  });
  intro.addShape(RECTANGLE, {
    x: 3.15,
    y: 2.95,
    w: 2.1,
    h: 2.1,
    fill: { color: 'ffffff' },
    rotate: 45,
  });
  intro.addImage({
    path: logoBuyin,
    x: 7.8 / 2.54,
    y: 9.29 /2.54,
    w: 5.73 /2.54, // 5,73 cm
    h: 1.75 / 2.54 //1.75 cm
  });
  intro.addText(
    [
      {
        text: 'NEGOTIATION',
        options: {
          fontSize: 36,
          color: '000000',
          align: 'right',
          breakLine: true,
        },
      },
      {
        text: 'ROADMAP',
        options: {
          fontSize: 36,
          color: '000000',
          align: 'right',
          breakLine: true,
        },
      },
      {
        text: `Exported ${moment().format('YYYY-MM-DD')}`,
        options: { fontSize: 18, color: 'e41b13', align: 'right' },
      },
    ],
    { x: 6.7, y: 3, w: 6, h: 2.0, lineSpacing: 40 },
  );
}

function addSlides(
  pptx: PptxGenJS,
  negotiations: INegotiation[],
  quarterHeader: string[],
  currentQuarter: any[],
) {
  let index = 0;
  let pageNumber = 1;
  const perPage = 5;
  const headers = [
    {
      text: 'Hot',
      options: { fill: '0088cc', color: 'ffffff', valign: 'middle' },
    },
    {
      text: 'TC@P',
      options: {
        fontSize: 9,
        fill: '0088cc',
        color: 'ffffff',
        valign: 'middle',
      },
    },
    {
      text: 'Program',
      options: { fill: '0088cc', color: 'ffffff', valign: 'middle' },
    },
    {
      text: 'Description',
      options: { fill: '0088cc', color: 'ffffff', valign: 'middle' },
    },
    {
      text: 'Category',
      fontSize: 9,
      options: { fill: '0088cc', color: 'ffffff', valign: 'middle' },
    },
    {
      text: 'Group',
      fontSize: 9,
      options: { fill: '0088cc', color: 'ffffff', valign: 'middle' },
    },
    {
      text: 'Region',
      fontSize: 9,
      options: { fill: '0088cc', color: 'ffffff', valign: 'middle' },
    },
    {
      text: 'Natco',
      fontSize: 9,
      options: { fill: '0088cc', color: 'ffffff', valign: 'middle' },
    },
    ...quarterHeader.map((text) => ({
      text,
      fontSize: 9,
      options: {
        colspan: 3,
        fill: '0088cc',
        color: 'ffffff',
        valign: 'middle',
      },
    })),
  ];

  while (index <= negotiations.length) {
    let slide: any = pptx.addSlide();
    slide.addText(pageNumber++, {
      x: 12.75,
      y: 7.1,
      fontSize: 14,
      color: 'a6a6a6',
      w: 0.5,
    });
    slide.addText('Negotiation Roadmap', {
      x: 0.25,
      y: 0.3,
      fontSize: 24,
      fontFace: 'Arial',
      color: '000000',
      bold: true,
    });
    let rows: any = [headers];
    let end =
      index + perPage < negotiations.length
        ? index + perPage
        : negotiations.length;

    negotiations.slice(index, end).forEach((negotiation: any, idx) => {
      rows.push([
        '',
        '',
        `${negotiation.title.slice(0, 50)}${
          negotiation.title.length > 50 ? '...' : ''
        }`,
        `${negotiation.description.slice(0, 100)}${
          negotiation.description.length > 100 ? '...' : ''
        }`,
        negotiation.category.code,
        negotiation.groups.map((group: any) => group.code).join(', '),
        negotiation.natcos
          .map((natco: any) => natco.code)
          .slice(0, 10)
          .join(', ') + (negotiation.natcos.length > 10 ? '...' : ''),
        negotiation.regions
          .map((region: any) => region.code)
          .slice(0, 10)
          .join(', ') + (negotiation.regions.length > 10 ? '...' : ''),
        ...Array(3).fill(''),
        ...Array(3).fill(''),
        ...Array(3).fill(''),
        ...Array(3).fill(''),
      ]);

      const position: any = getPosition(
        negotiation,
        quarterHeader,
        currentQuarter,
      );

      if (negotiation.isHot) {
        slide.addImage({
          path: fireIcon,
          x: 0.2,
          y: 1.3 * (idx + 1),
          w: 0.5,
          h: 0.5,
        });
      }
      if (negotiation.isTcap) {
        slide.addImage({
          path: tcapIcon,
          x: 0.72,
          y: 1.3 * (idx + 1),
          w: 0.5,
          h: 0.55,
        });
      }

      const percentage: any = position.width;
      const w = ((4 * percentage) / 100).toFixed(2);
      const x = position.marginLeft;
      slide.addShape(pptx.ShapeType.rect, {
        y: 1.3 * (idx + 1.15),
        x: 8.72 + (4 * x) / 100,
        w,
        h: 0.2,
        fill: '00289F',
        line: { color: '00289F' },
      });

      if (position.isBefore) {
        slide.addShape(pptx.ShapeType.leftArrow, {
          y: 1.3 * (idx + 1.07),
          x: 8.5,
          w: 0.5,
          h: 0.41,
          fill: '00289F',
        });
      }
      if (position.isAfter) {
        slide.addShape(pptx.ShapeType.rightArrow, {
          y: 1.3 * (idx + 1.07),
          x: 12.45,
          w: 0.5,
          h: 0.41,
          fill: '00289F',
        });
      }
    });

    slide.addTable(rows, {
      x: 0.23,
      y: 0.6,
      colW: [
        0.5,
        0.5,
        1.0,
        2.5,
        1,
        1,
        1,
        1,
        ...Array(3).fill(0.33),
        ...Array(3).fill(0.33),
        ...Array(3).fill(0.33),
        ...Array(3).fill(0.33),
      ],
      rowH: [0.5, ...Array(perPage).fill(1.25)],
      margin: 0.05,
      border: { color: 'CFCFCF' },
    });
    index += perPage;
  }
}

function exportPPT(
  filter: any,
  header: string[],
  quarter: string[],
  setIsLoading: (isLoading: boolean) => void,
) {
  const presentation = new PptxGenJS();
  presentation.author = 'BuyIn';
  presentation.company = 'BuyIn';
  presentation.subject = 'negotiation Roadmap';
  presentation.layout = 'LAYOUT_WIDE';
  setIsLoading(true);
  apiClient
    .get('/negotiations', {
      params: filter,
    })
    .then((res) => {
      addIntro(presentation);
      addSlides(presentation, res.data.records, header, quarter);

      presentation.writeFile({fileName: `Negotiation Roadmap (${moment().format('YYYY-MM-DD')}).pptx`});
    })
    .catch((error) => console.log(error))
    .finally(() => setIsLoading(false));
}

export default function NegotiationPptDownload({
  filter,
  header,
  quarterValue,
}: {
  filter: any;
  header: string[];
  quarterValue: string[];
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  return (
    <LoadingButton
      variant="outlined"
      startIcon={<FileDownloadIcon />}
      fullWidth
      loading={isLoading}
      loadingPosition="start"
      onClick={() => {
        exportPPT(filter, header, quarterValue, setIsLoading);
      }}
    >
      Download as PPT
    </LoadingButton>
  );
}
