import './Ribbon.css';
import { POST_TYPES } from '../../news/data';

export default function NewRibbon({
  postDate,
  postType,
}: {
  postDate: string | Date | undefined;
  postType: string;
}) {
  const checkNewRibbon = (then: Date): boolean => {
    const now = new Date();
    const msBetweenDates = Math.abs(then.getTime() - now.getTime());
    const daysBetweenDates = msBetweenDates / (24 * 60 * 60 * 1000);
    var timeLimit = 20;
    switch (postType) {
      case 'updates':
      case 'strategy':
      case POST_TYPES.BUYIN_NEWS:
      case POST_TYPES.PUBLIC_NEWS:
      case POST_TYPES.UPDATES:
        timeLimit = 15;
        break;
      case 'contract':
      case 'programs':
      case POST_TYPES.STRATEGIES:
        timeLimit = 30;
        break;
      default:
        timeLimit = 20;
    }

    if (daysBetweenDates < timeLimit) {
      return true;
    }
    return false;
  };

  return (
    <div>
      {postDate != undefined && checkNewRibbon(new Date(postDate)) && (
        <div className="ribbon ribbon-top-right">
          <span>new</span>
        </div>
      )}
    </div>
  );
}
