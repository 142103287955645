import * as React from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
export interface DialogTitleProps {
  children?: React.ReactNode;
  onClose: () => void;
  actions?: { onClickBack?: () => void };
}

export default function DialogTitle(props: DialogTitleProps) {
  const { children, onClose, actions, ...other } = props;

  return (
    <MuiDialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {actions?.onClickBack && (
        <IconButton
          aria-label="close"
          onClick={actions.onClickBack}
          sx={{
            position: 'absolute',
            right: 45,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <ArrowBackIosNewIcon />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
}
