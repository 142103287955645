export default function NegotiationsIcon({
  className = 'buyin-icon',
}: {
  className?: string;
}) {
  return (
    <svg
      className={className}
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon / ic_roadmap Copy</title>
      <g
        id="Icon-/-ic_roadmap-Copy"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M0.5,2 L7.02173913,2 L7.02173913,4 L0.5,4 Z M3.10869565,5.33333333 L10.2826087,5.33333333 L10.2826087,7.33333333 L3.10869565,7.33333333 Z M0.5,8.66666667 L12.8913043,8.66666667 L12.8913043,10.6666667 L0.5,10.6666667 Z M3.10869565,12 L15.5,12 L15.5,14 L3.10869565,14 Z"
          id="Combined-Shape"
          fill="#FFFFFF"
          fillRule="nonzero"
        ></path>
      </g>
    </svg>
  );
}
