import Chip from '@mui/material/Chip';
import { STATUSES } from '../../../data';

interface ContractStatusProps {
  value?: number;
}

export default function ContractStatus({ value }: ContractStatusProps) {
  const status = STATUSES.find((status) => status.value === value);
  return (
    <Chip sx={{ minWidth: 95 }} color={status?.color} label={status?.name} />
  );
}
