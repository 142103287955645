import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import {
  DataGrid,
  GridColDef,
  GridFooter,
  GridFooterContainer,
  GridToolbarExport,
} from '@mui/x-data-grid';
function CustomFooter() {
  return (
    <GridFooterContainer>
      <GridFooter />
      <GridToolbarExport printOptions={{ disableToolbarButton: false }} />
    </GridFooterContainer>
  );
}
const successColumns: GridColDef[] = [
  {
    field: 'firstName',
    headerName: 'First name',
    width: 150,
    renderCell: (params: any) => (
      <Tooltip title={params.row.firstName}>
        <Typography noWrap maxWidth={150}>
          {params.row.firstName}
        </Typography>
      </Tooltip>
    ),
    valueGetter: (params) => `${params.row.firstName}`,
  },
  {
    field: 'lastName',
    headerName: 'Last name',
    width: 150,
    renderCell: (params: any) => (
      <Tooltip title={params.row.lastName}>
        <Typography noWrap maxWidth={150}>
          {params.row.lastName}
        </Typography>
      </Tooltip>
    ),
    valueGetter: (params) => `${params.row.lastName}`,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 150,
    renderCell: (params: any) => (
      <Tooltip title={params.row.email}>
        <Typography noWrap maxWidth={150}>
          {params.row.email}
        </Typography>
      </Tooltip>
    ),
    valueGetter: (params) => `${params.row.email}`,
  },
  {
    field: 'session.id',
    headerName: 'Session ID',
    width: 150,
    renderCell: (params: any) => (
      <Tooltip title={params.row.session.id}>
        <Typography noWrap maxWidth={150}>
          {params.row.session.id}
        </Typography>
      </Tooltip>
    ),
    valueGetter: (params) => `${params.row.session.id}`,
  },
];

const errorColumns: GridColDef[] = [
  ...successColumns,
  {
    field: 'reason',
    headerName: 'Reason',
    width: 150,
    renderCell: (params: any) => (
      <Tooltip title={params.row.reason}>
        <Typography noWrap maxWidth={150}>
          {params.row.reason}
        </Typography>
      </Tooltip>
    ),
    valueGetter: (params) => `${params.row.reason}`,
  },
];

export default function EmailResults({ errors, success, displayResults }: any) {
  const [successView, setSuccessView] = useState(errors.length === 0);
  useEffect(() => {
    setSuccessView(errors.length === 0);
  }, [errors, success]);
  return (
    displayResults && (
      <>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          width="100%"
        >
          <ToggleButtonGroup
            color="primary"
            value={successView}
            exclusive
            onChange={(event, value) => {
              setSuccessView(!successView);
            }}
          >
            <ToggleButton color="success" value={true}>
              <CheckCircleOutlineIcon />
            </ToggleButton>
            <ToggleButton value={false}>
              <ErrorIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
        {successView && (
          <DataGrid
            sx={{ mt: 1 }}
            autoHeight
            rows={success}
            columns={successColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            components={{ Footer: CustomFooter }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        )}
        {!successView && (
          <DataGrid
            sx={{ mt: 1 }}
            autoHeight
            rows={errors}
            columns={errorColumns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            components={{ Footer: CustomFooter }}
            experimentalFeatures={{ newEditingApi: true }}
          />
        )}
      </>
    )
  );
}
