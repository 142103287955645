import CardActionArea from '@mui/material/CardActionArea';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';

export default function PostSkeleton() {
  return (
    <Paper elevation={1}>
      <CardActionArea sx={{ p: 2, minHeight: 240 }}>
        <Stack direction="row" spacing={2} alignItems="flex-start">
          <Skeleton variant="rectangular" width={200} height={130} />
          <Box sx={{ width: '100%' }}>
            <Typography variant="h6" component="h3">
              <Skeleton />
            </Typography>
            <Typography variant="body2" sx={{ color: 'gray' }}>
              <Skeleton width="40%" />
              <Skeleton width="60%" />
            </Typography>
          </Box>
        </Stack>
        <Typography variant="body2">
          <Skeleton width="100%" />
          <Skeleton width="60%" />
        </Typography>
      </CardActionArea>
    </Paper>
  );
}
