import { SyntheticEvent, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import UploadForm from '../UploadForm/UploadForm';

const section = {
  contracts: 'contracts',
  amendments: 'amendments',
};

export default function ContractUpload() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box p={1}>
      <Accordion
        expanded={expanded === section.contracts}
        onChange={handleChange(section.contracts)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Main contracts upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={1}
            path="contracts/upload"
            subPath="contracts"
            inputId="contracts-sheet"
            inputName="contracts"
            inputAccept=".csv"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === section.amendments}
        onChange={handleChange(section.amendments)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Amendments upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={1}
            path="contracts/amendments/upload"
            subPath="amandments"
            inputId="amendments-sheet"
            inputName="amendments"
            inputAccept=".csv"
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
