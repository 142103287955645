export default function PowerBIIcon({ className }: { className?: string }) {
  return (
    <svg
      width="50px"
      className={className}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 34 34"
      enable-background="new 0 0 34 34"
    >
      <g>
        <defs>
          <rect id="SVGID_1_" x="0.9" y="0" width="32.2" height="33.9" />
        </defs>
        {/* <clipPath id="SVGID_2_">
                    <use xlink:href="#SVGID_1_" overflow="visible" />
                </clipPath> */}
        <path
          clip-path="url(#SVGID_2_)"
          fill="white"
          d="M11.1,27.3c0,1.1-0.9,2.1-2.1,2.1s-2.1-0.9-2.1-2.1v-9c0-1.1,0.9-2.1,2.1-2.1
           s2.1,0.9,2.1,2.1V27.3z"
        />
        <path
          clip-path="url(#SVGID_2_)"
          fill="white"
          d="M17.1,29.7c0,1.1-0.9,2.1-2.1,2.1S13,30.9,13,29.7V16c0-1.1,0.9-2.1,2.1-2.1
           s2.1,0.9,2.1,2.1V29.7z"
        />
        <path
          clip-path="url(#SVGID_2_)"
          fill="white"
          d="M5,25.3c0,1.1-0.9,2.1-2.1,2.1s-2.1-0.9-2.1-2.1v-4.9c0-1.1,0.9-2.1,2.1-2.1
           S5,19.3,5,20.4V25.3z"
        />
        <path
          clip-path="url(#SVGID_2_)"
          fill="white"
          d="M23.1,31.9c0,1.1-0.9,2.1-2.1,2.1C19.9,34,19,33,19,31.9V13.7
           c0-1.1,0.9-2.1,2.1-2.1c1.1,0,2.1,0.9,2.1,2.1V31.9z"
        />
        <path
          clip-path="url(#SVGID_2_)"
          fill="white"
          d="M29.7,31c-0.4,0-0.7-0.1-1.1-0.2L26.1,30c-0.4-0.1-0.7-0.6-0.5-1
           c0.1-0.4,0.6-0.7,1-0.5l2.6,0.8c0.5,0.2,1.1,0.1,1.6-0.3c0.5-0.3,0.7-0.9,0.7-1.4V10.9c0-0.8-0.5-1.5-1.2-1.7L5.8,1.8
           C5.3,1.6,4.7,1.7,4.2,2C3.8,2.4,3.5,2.9,3.5,3.5v11.5c0,0.5-0.4,0.8-0.8,0.8c-0.5,0-0.8-0.4-0.8-0.8V3.5c0-1.1,0.5-2.1,1.4-2.7
           c0.9-0.7,2-0.8,3-0.5l24.4,7.4c1.4,0.4,2.4,1.8,2.4,3.3v16.7c0,1.1-0.5,2.1-1.4,2.8C31.1,30.7,30.4,31,29.7,31"
        />
      </g>
    </svg>
  );
}
