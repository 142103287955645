import { useEffect, useState } from 'react';
import { useMatch } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import apiClient from '../../../services/apiClient';
import { downloadBlocker } from '../../HomePage/SummitBooking/SummitBooking';

interface CentredStackProps {
  children: JSX.Element | JSX.Element[];
}

const CentredStack = ({ children }: CentredStackProps) => (
  <Stack
    direction="column"
    justifyContent="center"
    alignItems="center"
    height="100vh"
    spacing={4}
  >
    {children}
  </Stack>
);

export default function Enrol() {
  const [isEnrolled, setIsEnrolled] = useState<boolean>(false);
  const [error, setError] = useState<any>('');
  const match = useMatch('/public/summit-booking/enrol/:token');
  const enrollmentToken = match?.params.token;
  const navigate = useNavigate();

  useEffect(() => {
    apiClient
      .post(`enrollments/fromEmail/${enrollmentToken}`)
      .then((res: any) => {
        setIsEnrolled(true);
        downloadBlocker(res.data.enrollment.session.id);
      })
      .catch((error) => {
        setError(
          error.response.status === 422
            ? 'You are already enrolled to the session'
            : 'Unknown Error',
        );
      });
  }, []);

  if (!(error || isEnrolled)) {
    return (
      <CentredStack>
        <Typography variant="h6" component="h2">
          Loading
        </Typography>
        <CircularProgress />
      </CentredStack>
    );
  }

  return (
    <CentredStack>
      <Alert variant="outlined" severity={error ? 'error' : 'success'}>
        {error || 'Successful enrollment'} — Please click{' '}
        <Link
          href="/"
          onClick={(event) => {
            event.preventDefault();
            navigate('/');
          }}
          color="inherit"
        >
          here
        </Link>{' '}
        to continue!
      </Alert>
    </CentredStack>
  );
}
