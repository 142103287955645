import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import AppBar from './AppBar/AppBar';
import BuyinUserNavigation, {
  DrawerHeader,
  drawerWidth,
} from './Navigation/BuyinUserNavigation';
import Breadcrumbs from './Breadcrumbs/Breadcrumbs';
import { useAuth } from '../AuthProvider';
import CustomerNavigation from './Navigation/CustomerNavigation';
import { useLocation, useMatch, useNavigate } from 'react-router';

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  position: 'absolute',
  flexGrow: 1,
  width: '95%',
  right: 0,
  filter: open ? 'blur(2px)' : 'none',
}));

export default function Layout({
  noBreadcrumbs = false,
  children,
}: React.PropsWithChildren<{ noBreadcrumbs?: boolean }>) {
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { profile } = useAuth();
  const navigate = useNavigate();
  const match = useMatch('/supplier-spend-financials/:id');
  const isSpendPage = useMatch('/supplier-spend-financials');

  const handleDrawerToggle = () => {
    if (location.pathname.startsWith('/supplier-spend-financials')) {
      setOpen(true);
      navigate({
        pathname: match ? `/suppliers/${match?.params.id}` : '/',
      });
      return;
    }
    setOpen(!open);
  };

  useEffect(() => {
    if (isSpendPage || match) {
      setOpen(false);
    }
  }, [isSpendPage]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar open={open} onMenuClick={handleDrawerToggle} />
      {profile?.isCustomer ? (
        <CustomerNavigation open={open} onMenuOpen={setOpen} />
      ) : (
        <BuyinUserNavigation open={open} onMenuOpen={setOpen} />
      )}
      <Main open={open}>
        <DrawerHeader />
        <Paper
          sx={{
            m: 2,
          }}
        >
          {!noBreadcrumbs && <Breadcrumbs />}
          {children}
        </Paper>
      </Main>
    </Box>
  );
}
