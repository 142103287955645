import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import PostSkeleton from '../PostSkeleton/PostSkeleton';
import { Post } from '../types';
import NewRibbon from '../../shared/NewRibbon/NewRibbon';
import React from 'react';

interface NewsListProps {
  loading: boolean;
  isEmpty: boolean;
  posts: Post[];
  notFoundMessage?: string;
  onPostSelect: (postId: number) => void;
}

export default function NewsList({
  loading = true,
  isEmpty = false,
  posts,
  notFoundMessage,
  onPostSelect,
}: NewsListProps) {
  return (
    <Grid container spacing={1} p={1}>
      {loading &&
        Array(8)
          .fill(null)
          .map((v, i) => (
            <Grid key={i} item lg={6} sx={{ width: '100%' }}>
              <PostSkeleton />
            </Grid>
          ))}
      {isEmpty && (
        <Grid item sx={{ width: '100%' }}>
          <Box p={2}>
            <Typography textAlign="center" color="primary" variant="h5">
              {notFoundMessage ||
                'No news available for the current filter criteria'}
            </Typography>
          </Box>
        </Grid>
      )}
      {posts.map((post: Post) => (
        <Grid key={post.id} item lg={6} sx={{ width: '100%' }}>
          <Paper
            elevation={3}
            onClick={() => onPostSelect(post.id)}
            sx={{ height: '100%' }}
          >
            <CardActionArea sx={{ p: 2, minHeight: 240 }}>
              <NewRibbon postDate={post.date} postType={'updates'} />
              <Stack direction="row" spacing={2} alignItems="center">
                <img
                  alt={post.title}
                  height={130}
                  style={{ borderRadius: 20 }}
                  src={post.thumbnail}
                />
                <Box>
                  <Typography variant="h6" component="h3">
                    {post.title}
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'gray' }}>
                    {new Intl.DateTimeFormat('en-GB', {
                      weekday: 'long',
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    }).format(new Date(post.date))}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'gray' }}>
                    {post.author.name}
                  </Typography>
                  <Stack direction="row" alignItems="center" gap={1}>
                    {post.is_liked ? (
                      <ThumbUpIcon color="primary" />
                    ) : (
                      <ThumbUpOffAltIcon color="primary" />
                    )}
                    {post.likes}
                  </Stack>
                </Box>
              </Stack>
              <br />
              <Typography
                variant="body2"
                sx={{
                  fontStyle: 'italic',
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                {post.excerpt.length > 180
                  ? post.excerpt.slice(0, 180) + '...'
                  : post.excerpt}
              </Typography>
            </CardActionArea>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}
