import React, { useState } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Layout from '../Layout/Layout';
import TabPanel from '../ui/mui/TabPanel/TabPanel';
import UpdateList from '../news/UpdateList/UpdateList';
import PayloadProvider from '../news/PayloadProvider';
import { POST_TYPES } from '../news/data';
import StrategyList from '../news/StrategyList/StrategyList';
import ContactList from '../ContactList/ContactList';
import ContractList from '../ContractList/ContractList';
import NegotiationList from '../NegotiationList/NegotiationList';
import Profile from './Profile';
import Staff from './Staff';
import { NavigationFiltersProvider } from '../Layout/NavigationFiltersProvider/NavigationFiltersProvider';
import { Breadcrumbs, Typography } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNav } from '../NavProvider';
import SwotAnalysis from './SwotAnalysis';

const supplierTabs: Array<{ value: string; label: string }> = [
  { value: 'profile', label: 'Profile' },
  { value: 'swot-analysis', label: 'SWOT Analysis' },
  { value: 'spend', label: 'Spend & Financials' },
  { value: 'key-staff', label: 'Key staff' },
  { value: 'updates', label: 'Updates' },
  { value: 'negotiations', label: 'Roadmap' },
  { value: 'contracts', label: 'Contracts' },
  { value: 'strategies', label: 'Strategies' },
  { value: 'contacts', label: 'Contacts' },
];

function SupplierTabs() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const matchSupplier = useMatch('/suppliers/:supplierId/*');
  const matchTab = useMatch('/suppliers/:supplierId/:tab/*');
  const matchedTab: string = matchTab?.params.tab || 'profile';

  const handleChangeTab = (event: React.SyntheticEvent, newTab: string) => {
    navigate({
      pathname: `/suppliers/${matchSupplier?.params.supplierId}/${newTab}`,
      search: searchParams.toString(),
    });
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Divider />

      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs value={matchedTab} onChange={handleChangeTab} variant="fullWidth">
          {supplierTabs.map((tab) => (
            <Tab value={tab.value} key={tab.value} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      <NavigationFiltersProvider matchedTab={matchedTab}>
        <>
          <TabPanel value={matchedTab} index="profile">
            <Profile />
          </TabPanel>
          <TabPanel value={matchedTab} index="swot-analysis">
            <SwotAnalysis />
          </TabPanel>
          <TabPanel value={matchedTab} index="key-staff">
            <Staff />
          </TabPanel>
          <TabPanel value={matchedTab} index="updates">
            <PayloadProvider type={POST_TYPES.UPDATES} fromSearch={false}>
              <UpdateList />
            </PayloadProvider>
          </TabPanel>
          <TabPanel value={matchedTab} index="negotiations">
            <NegotiationList />
          </TabPanel>
          <TabPanel value={matchedTab} index="contracts">
            <ContractList />
          </TabPanel>
          <TabPanel value={matchedTab} index="strategies">
            <PayloadProvider type={POST_TYPES.UPDATES} fromSearch={false}>
              <StrategyList />
            </PayloadProvider>
          </TabPanel>
          <TabPanel value={matchedTab} index="contacts">
            <ContactList />
          </TabPanel>
        </>
      </NavigationFiltersProvider>
    </Box>
  );
}

export default function SupplierPage() {
  const { supplier } = useNav();
  return (
    <Layout noBreadcrumbs>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        sx={{ p: 2 }}
      >
        <Typography>Supplier</Typography>
        <Typography>{supplier?.name}</Typography>
      </Breadcrumbs>
      <SupplierTabs />
    </Layout>
  );
}
