import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { Divider, Link } from '@mui/material';
import apiClient from '../../../services/apiClient';
import { ReportList } from './ReportList';
import { ToolCard } from './ToolCard';
import DeviceNegotiationAlertToolIcon from '../../shared/Icons/tools/DeviceNegotiationAlertToolIcon';
import Guard from '../../shared/Guard/Guard';
import { useAuth } from '../../AuthProvider';
import { ErrorAlert } from '../../ui/mui/ErrorAlert/ErrorAlert';

export interface PowerBIReport {
  id: string;
  name: string;
  iconId: number;
  isPaginated: boolean;
  powerBIId: string;
  powerBIParameter: string;
  filters: any[];
  settings: any[];
  hasPageId: boolean;
  pageId: string;
  embedConfiguration: any;
}

function NegotiationAutomationTool() {
  const { profile } = useAuth();
  const { trackPageView } = useMatomo();
  return (
    <Guard
      role={profile?.isCustomer ? 'TOOLS_DP-LLNB-LIVE' : 'ROLE_REPORT_USER'}
    >
      <Link
        underline="none"
        href="https://rpa.buyin.pro/"
        target="_blank"
        rel="noopener"
      >
        <ToolCard
          label="Negotiation Automation Robot"
          icon={<DeviceNegotiationAlertToolIcon />}
          color="rgb(255,210,48)"
          onClick={() => {
            trackPageView({
              documentTitle: 'Tools/Robot',
              href:`${window.location.origin}/Tools/Robot`
            });
          }}
        />
      </Link>
    </Guard>
  );
}

export default function PowerBiReports({
  setPowerBIReports,
  isCatalogReport,
  isDependencyReport,
}: {
  setPowerBIReports?: (reports: PowerBIReport[]) => void;
  isCatalogReport?: boolean;
  isDependencyReport?: boolean;
  dependencyReport?: any;
  catalogReport?: any;
}) {
  const [searchParams] = useSearchParams();
  const [allReports, setAllReports] = useState<PowerBIReport[]>([]);
  const { trackPageView } = useMatomo();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const fetchReports = () => {
    apiClient
      .get('/reports', {
        // :todo: add domain filter
      })
      .then((res: any) => {
        if (isCatalogReport) {
          const catalog = res.data.records.find((cr: any) => cr.name.toUpperCase() === "OPTICS CATALOG") ? [res.data.records.find((cr: any) => cr.name.toUpperCase() === "OPTICS CATALOG")] : [];
          setAllReports(catalog);
        } else if (isDependencyReport) {
          const dependency = res.data.records.find((cr: any) => cr.name.toUpperCase() === "CN-TW DEPENDENCY ANALYSIS") ? [res.data.records.find((cr: any) => cr.name.toUpperCase() === "CN-TW DEPENDENCY ANALYSIS")] : [];
          setAllReports(dependency);
        } else {
          const selectedReports = res.data.records.filter((cr: any) => cr.name.toUpperCase() !== "OPTICS CATALOG" && cr.name.toUpperCase() !== "CN-TW DEPENDENCY ANALYSIS");
          setAllReports(selectedReports);
        }
        setPowerBIReports?.(res.data.records);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setError('Something went wrong');
      });
  };

  const handleReportClick = (report: PowerBIReport) => {
    trackPageView({
      documentTitle: `Tools/PBI/${report.name}`,
      href:`${window.location.origin}/Tools/PBI/${report.name}`
    });
    navigate(`/domains/tools/powerBI/${report.id}`);
  };

  useEffect(() => {
    setIsLoading(true);
    setPowerBIReports?.([]);
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return isLoading ? null : (
    <>
      <Divider sx={{ marginBottom: 2 }} />
      {error && (
        <ErrorAlert
          timeout={10}
          message={error}
          onClose={() => setError(null)}
        />
      )}
      <ReportList
        reports={allReports.length > 0 ? allReports
          : []}
        onReportClick={(report) => handleReportClick(report)}
      />
    </>
  );
}
