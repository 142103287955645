import React, {
  FormEvent,
  FormEventHandler,
  useEffect,
  useRef,
  useState,
} from 'react';
import PublishIcon from '@mui/icons-material/Publish';
import Alert, { AlertColor } from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import apiClient from '../../../../services/apiClient';
import { LinearProgress, List, ListItem, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

interface UploadFormProps {
  path: string;
  pathId: number;
  inputId: string;
  inputName: string;
  inputAccept: string;
  displayUploadedList?: boolean;
  buttonLabel?: string;
  label?: string;
  hideSuccessMessage?: boolean;
  subPath?: string;
  showProgressResult?: boolean;
  progressKey?: string;
  onSuccess?: (result: any) => void;
  handleSave?: () => boolean;
  isUpdate?: boolean;
}

function UploadForm({
  path,
  inputId,
  subPath = '',
  pathId,
  inputName,
  inputAccept,
  displayUploadedList = true,
  buttonLabel = 'Upload',
  label,
  hideSuccessMessage = false,
  showProgressResult = false,
  progressKey,
  onSuccess,
  handleSave,
  isUpdate,
}: UploadFormProps) {
  const [file, setFile] = useState<any>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [files, setFiles] = useState<any>();
  const [alert, setAlert] = useState<{
    severity: AlertColor | undefined;
    message: string;
  }>();
  const [progress, setProgress] = useState<any>(null);
  const handleChange = ({ target }: any) => {
    setFile(target.files[0]);
  };
  const Item = styled(Paper)(({ theme }: any) => ({
    ...theme.typography.subtitle2,
    padding: theme.spacing(1),
    textAlign: 'center',
    borderRadius: 0,
    color: theme.palette.primary.main,
  }));

  const interval: any = useRef();

  const checkProgress = () => {
    interval.current = setInterval(() => {
      apiClient
        .get('/progress', { params: { key: progressKey } })
        .then((res) => {
          setProgress(res.data);
        });
    }, 1000);
  };

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(undefined);
      }, 10000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alert]);

  useEffect(() => {
    if (!uploading) {
      apiClient
        .get(`/uploadedFiles/${pathId}`, { params: { subPath } })
        .then((res: any) => {
          if (res.data) {
            setFiles(res.data.content);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [pathId, uploading, subPath]);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (
    event: FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    setAlert(undefined);
    setUploading(true);
    const data = new FormData();
    data.append('subPath', subPath);
    data.append(inputName, file);

    if (showProgressResult) {
      checkProgress();
    }
    let saveResult = false;
    if (handleSave) {
      saveResult = await handleSave();
    }
    if (inputName === 'productCatalog' && saveResult === false && !isUpdate) {
      clearInterval(interval.current);
      setProgress(null);
      setUploading(false);
    }
    if (
      (inputName === 'productCatalog' && saveResult) || (inputName === 'productCatalog' && isUpdate) ||
      inputName !== 'productCatalog'
    ) {
      await apiClient
        .post(path, data)
        .then((result) => {
          if (!hideSuccessMessage) {
            setAlert({
              severity: 'success',
              message: 'File successfully uploaded',
            });
          }
          setUploading(false);
          setFile(null);
          if (onSuccess) {
            onSuccess(result);
          }
        })
        .catch((error) => {
          setAlert({
            severity: 'error',
            message:
              error?.response?.data?.message ||
              error?.response?.data?.error ||
              'An error occurred',
          });
          if(!isUpdate){
          apiClient
            .delete(`/productCatalog/${subPath}`)
            .then((res) => {
              console.log('catalog deleted', subPath);
            })
            .catch((error) => {
              console.log(error);
            });
          setUploading(false);
          //delete product catalog
          }
        })
        .finally(() => {
          clearInterval(interval.current);
          setProgress(null);
          setUploading(false);
        });
    }
  };
  const onDownload = (name: string, index?: number) => {
    apiClient
      .get(`/uploadedFiles/download/${pathId}`, {
        responseType: 'blob',
        params: { index, subPath },
      })
      .then((res: any) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.download = name;
        link.click();
      });
  };
  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Stack direction="row" sx={{ mb: 1 }}>
          <input
            accept={inputAccept}
            id={inputId}
            type="file"
            onChange={handleChange}
            onClick={({ target }: any) => {
              target.value = null;
            }}
            hidden
          />
          <Button
            component="label"
            variant="outlined"
            htmlFor={inputId}
            fullWidth
            sx={{
              backgroundColor: 'grey.100',
              borderColor: 'grey.500',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }}
          >
            {file ? file.name : label || 'Choose file...'}
          </Button>
          <Button
            type="submit"
            disabled={uploading || !Boolean(file)}
            variant="contained"
            color="primary"
            disableElevation
            sx={{
              borderLeft: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
            startIcon={
              uploading ? <CircularProgress size={14} /> : <PublishIcon />
            }
          >
            {buttonLabel}
          </Button>
        </Stack>
        {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
      </form>

      {showProgressResult && progress && !progress?.isCompleted && (
        <React.Fragment>
          <Paper sx={{ p: 1 }}>
            <Typography sx={{ color: grey[400] }}>
              Processing: {progress?.processing} ({progress?.processed}/
              {progress?.total})
            </Typography>
          </Paper>
          {/* <LinearProgress variant="determinate" value={progress.processed / (progress.total || 1) * 100} /> */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress
                variant="buffer"
                valueBuffer={(progress.processed / (progress.total || 1)) * 100}
                value={(progress.processed / (progress.total || 1)) * 100}
              />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                (progress.processed / (progress.total || 1)) * 100,
              )}%`}</Typography>
            </Box>
          </Box>
        </React.Fragment>
      )}

      {displayUploadedList && (
        <Grid container spacing={1} sx={{ p: 4 }}>
          <List sx={{ width: '100%' }}>
            <>
              <ListItem>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={4}>
                    <Item>Last Uploaded Files</Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item>At</Item>
                  </Grid>
                  <Grid item xs={4}>
                    <Item>By</Item>
                  </Grid>
                </Grid>
              </ListItem>
              {(files || []).map(({ name, author, date, index }: any) => (
                <ListItem key={index}>
                  <Grid container item xs={12} spacing={3}>
                    <Grid item xs={4}>
                      {(name && (
                        <Link
                          onClick={() => {
                            onDownload(name, index);
                          }}
                          sx={{ cursor: 'pointer' }}
                        >
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                            noWrap
                          >
                            {name}
                          </Typography>
                        </Link>
                      )) ||
                        'No uploaded File'}
                    </Grid>
                    <Grid item xs={4}>
                      {moment(date).format('MMMM Do YYYY, h:mm:ss a') ||
                        'No uploaded File'}
                    </Grid>
                    <Grid item xs={4}>
                      {author || 'No uploaded File'}
                    </Grid>
                  </Grid>
                </ListItem>
              ))}
            </>
          </List>
        </Grid>
      )}
    </Box>
  );
}

export default UploadForm;
