import { useEffect, useState } from 'react';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import apiClient from '../../../../services/apiClient';

export default function SupplierSearch({
  filter,
  onNavigate,
  onResultFound,
  onLoading,
}: {
  filter: any;
  onNavigate: () => void;
  onResultFound: any;
  onLoading: any;
}) {
  const [suppliers, setSuppliers] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isClicked, setIsClicked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { search, domain, category, subcategory, group, region, natco } =
    filter;
  useEffect(() => {
    if (!isClicked || !search) {
      setSuppliers(null);
      setPage(1);
    }
    if (search) {
      onLoading(true);
      setIsLoading(true);
      apiClient
        .get('/suppliers', {
          params: {
            search,
            group,
            hasOtherCategory:
              location.pathname.indexOf('/domains') === -1 || null,
            page,
            perPage: 3,
          },
        })
        .then((res) => {
          onLoading(false);

          if (res.data.records.length > 0) {
            onResultFound(true);  // Pass `true` to indicate that results were found
          } else {
            onResultFound(false);  // Pass `false` if no results were found
          }
          if (isClicked) {
            res.data.records = (suppliers?.records || []).concat(
              res.data.records,
            );
          }
          setSuppliers(res.data);
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setIsClicked(false);
          setIsLoading(false);
        });
    }
  }, [search, page]);

  const hancleClick = (supplierId: number) => {
    const search = createSearchParams();
    if (searchParams.get('navDomain')) {
      search.set('navDomain', searchParams.get('navDomain') || '');
    }
    if (searchParams.get('navGroup')) {
      search.set('navGroup', searchParams.get('navGroup') || '');
    }
    navigate({
      pathname: `/suppliers/${supplierId}`,
      search: search.toString(),
    });
    onNavigate();
  };

  return (
    <>
      {(suppliers?.records?.length > 0 || isLoading) && (
        <>
          <Divider />
          <Stack direction="row" paddingY={1.5} p={1} spacing={1}>
            <Typography color="primary">
              <LocationCityIcon />
            </Typography>
            <Typography color="gray">
              Suppliers {suppliers && `(${suppliers?.pagination.total})`}
            </Typography>
          </Stack>
          <Divider />
          {(suppliers?.records || []).map((supplier: any, i: number) => (
            <CardActionArea
              key={i}
              sx={{ p: 1, px: 2 }}
              onClick={() => hancleClick(supplier.id)}
            >
              <Box sx={{ typography: 'subtitle2' }}>{supplier.name}</Box>
              <Typography color="gray">
                <Stack direction="row" spacing={0.5}>
                  <Box>{supplier.negotiationsCount} negotiations, </Box>
                  <Box>{supplier.contractsCount} contracts, </Box>
                  <Box>{supplier.contactsCount} contacts </Box>
                </Stack>
              </Typography>
            </CardActionArea>
          ))}
          <Divider />
        </>
      )}

      {isLoading && (
        <>
          <Box sx={{ p: 2 }} width="100%">
            <Skeleton
              animation="wave"
              height={10}
              width="40%"
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={10} width="60%" />
          </Box>
        </>
      )}
      {suppliers?.records?.length !== 0 &&
        suppliers?.records?.length < suppliers?.pagination?.total && (
          <CardActionArea
            sx={{ p: 1, px: 2 }}
            onClick={() => {
              setIsLoading(true);
              setIsClicked(true);
              setPage(page + 1);
            }}
          >
            <Stack direction="row" spacing={1} p={1}>
              <ManageSearchIcon color="primary" />
              <Typography color="primary" style={{ marginTop: 2 }}>
                Show more suppliers
              </Typography>
            </Stack>
          </CardActionArea>
        )}
    </>
  );
}
