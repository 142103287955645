import React, { useEffect, useState } from 'react';
import { useMatch, useSearchParams } from 'react-router-dom';
import apiClient from '../../../services/apiClient';
import DialogSelect from '../../ui/mui/DialogSelect/DialogSelect';
import DialogSelectVirtualized from '../../ui/mui/DialogSelectVirtualized/DialogSelectVirtualized';

export default function RecordSelect({
  label,
  reference,
  source,
  disabled,
  filter,
  filters,
  useVirtualization = false,
  onFiltersChange,
  setRecords,
}: {
  label: string;
  reference: string;
  source: string;
  disabled?: boolean;
  filter: any;
  filters: any;
  useVirtualization?: boolean;
  onFiltersChange: (filters: any) => void;
  setRecords?: (records: any[]) => void;
}) {
  const [choices, setChoices] = useState<any[]>([]);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const match = useMatch('/domains/*');

  useEffect(() => {
    if (isTouched) {
      setLoading(true);
      apiClient
        .get(`/${reference}`, { params: filter })
        .then((result) => {
          setChoices(
            result.data.records.map((record: any) => ({
              value: `${record.id}`,
              name: record[source],
            })),
          );
          if (setRecords) {
            setRecords(result.data.records);
          }
          setLoading(false);
        })
        .catch((error) => {
          // TODO: handle error response
          console.log(error);
        });
    }
  }, [isTouched]);

  useEffect(() => {
    setIsTouched(false);
  }, [match]);

  const handleFiltersChange = (selection: string[]) => {
    onFiltersChange({ ...filters, [reference]: selection.join(',') });
  };

  return (
    <>
      {!useVirtualization && (
        <DialogSelect
          label={
            filters && filters[reference]
              ? `${label} (${filters[reference].split(',').length})`
              : `${label} ${!disabled && ' (ALL)'}`
          }
          title={label}
          disabled={disabled}
          loading={loading}
          choices={choices}
          onOpen={setIsTouched}
          onChange={handleFiltersChange}
          initialValues={filters && filters[reference]?.split(',')}
        />
      )}
      {useVirtualization && (
        <DialogSelectVirtualized
          label={
            filters && filters[reference]
              ? `${label} (${filters[reference].split(',').length})`
              : `${label} (ALL)`
          }
          title={label}
          disabled={disabled}
          loading={loading}
          choices={choices}
          onOpen={setIsTouched}
          onChange={handleFiltersChange}
          initialValues={filters && filters[reference]?.split(',')}
        />
      )}
    </>
  );
}
