import React, { FormEvent, FormEventHandler, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Alert, AlertColor, Box, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import RecordFilterSelect from '../../ContractList/ContractFilter/RecordFilterSelect/RecordFilterSelect';
import ConfirmDialog from '../../ui/mui/ConfirmDialog/ConfirmDialog';
import apiClient from '../../../services/apiClient';

interface filterType {
  productCatalogs: [];
}

interface filterTypeT {
  productCatalogs: [];
}

export default function ProductCatalogRoles() {
  const [selectedFilters, setSelectedFilters] = useState<filterType>({
    productCatalogs: [],
  });
  const [selectedFiltersEdit, setSelectedFiltersEdit] = useState<filterTypeT>({
    productCatalogs: [],
  });
  const [associatedProductCatalogs, setAssociatedProductCatalogs] = useState(
    [],
  );
  const [associatedProductCatalogsEdit, setAssociatedProductCatalogsEdit] =
    useState([]);
  const [roleName, setRoleName] = useState<string>('');
  const [alert, setAlert] = useState<{
    severity: AlertColor | undefined;
    message: string;
  }>();
  const [rows, setRows] = useState<any[]>([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [refreshPc, setRefreshPc] = useState(false);

  const handleRecordChange = (
    source: any,
    checked: any,
    record: any,
    id: any,
  ) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    if (checked) {
      newFilters[source] = [...(newFilters[source] || []), record];
    } else {
      newFilters[source] = newFilters[source].filter(
        (item: any) => item.id !== record.id,
      );
    }
    setSelectedFilters(newFilters);
  };

  const handleRecordChangeEdit = (
    source: any,
    checked: any,
    record: any,
    id: any,
  ) => {
    const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEdit));
    if (checked) {
      newFiltersEdit[source] = [...(newFiltersEdit[source] || []), record];
    } else {
      newFiltersEdit[source] = newFiltersEdit[source].filter(
        (item: any) => item.id !== record.id,
      );
    }
    setSelectedFiltersEdit(newFiltersEdit);
  };

  const handleFilterCancel = (source: string, id: any) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    newFilters.associatedProductCatalogs = associatedProductCatalogs;
    setSelectedFilters(newFilters);
  };

  const handleFilterCancelEdit = (source: string, id: any) => {
    const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEdit));
    newFiltersEdit.associatedProductCatalogsEdit =
      associatedProductCatalogsEdit;
    setSelectedFiltersEdit(newFiltersEdit);
  };

  const handleFilterApply = (source: any, id: any) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    setAssociatedProductCatalogs(newFilters.productCatalogs);
  };
  const handleFilterApplyEdit = (source: any, id: any) => {
    const newFiltersEdit = JSON.parse(JSON.stringify(selectedFiltersEdit));
    setAssociatedProductCatalogsEdit(newFiltersEdit.productCatalogs);
    apiClient
      .put(`/productCatalogRole/${id}`, {
        id: id,
        productcatalogs: selectedFiltersEdit.productCatalogs,
      })
      .then((res: any) => {
        apiClient.get('/productCatalogRole').then((res) => {
          setRows(res.data.records);
        });
      })
      .catch((err: any) => {
        setAlert({
          severity: 'error',
          message: err.message,
        });
      });
    setSelectedFiltersEdit({
      productCatalogs: [],
    });
    setAssociatedProductCatalogsEdit([]);
  };

  useEffect(() => {
    apiClient.get('/productCatalogRole').then((res) => {
      setRows(res.data.records);
    });
  }, [refreshPc]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Role Name',
      width: 250,
      editable: true,
    },
    {
      field: 'productcatalogs',
      headerName: 'Associated Product Catalogs',
      width: 600,
      renderCell: (params: any) => {
        return (
          <>
            {params.row.productcatalogs.map((s: any, index: number) => {
              const separator =
                index === params.row.productcatalogs.length - 1 ? '' : ' -- ';
              return `${s.name}${separator}`;
            })}
            &nbsp; &nbsp;
            <RecordFilterSelect
              source="productCatalogs"
              property={'name'}
              id={params.row.id}
              label="Associated product catalogs"
              selected={
                selectedFiltersEdit?.productCatalogs.length > 0
                  ? selectedFiltersEdit?.productCatalogs
                  : params.row.productcatalogs
              }
              onRecordChange={handleRecordChangeEdit}
              onFilterApply={handleFilterApplyEdit}
              onFilterCancel={handleFilterCancelEdit}
              initialisation={params.row.productcatalogs}
            />
          </>
        );
      },
    },
  ];

  const handleDelete = () => {
    if (selectedIds.length > 0) {
      apiClient
        .delete(`/productCatalogRole/${selectedIds.join(',')}`)
        .then(() => {
          setAlert({
            severity: 'success',
            message: 'Roles successfully deleted',
          });
          setRefreshPc(!refreshPc);
        })
        .catch((error) => {
          setAlert({
            severity: 'error',
            message: error.message,
          });
        });
    }
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (
    event: FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    if (associatedProductCatalogs.length !== 0) {
      setAlert(undefined);
      const data = {
        name: roleName,
        productcatalogs: associatedProductCatalogs,
      };

      await apiClient
        .post('/productCatalogRole', data)
        .then((result) => {
          if (result.data === 'Role successfully saved') {
            setAlert({
              severity: 'success',
              message: 'Role successfully saved',
            });
          } else {
            setAlert({
              severity: 'warning',
              message: 'Role already exists',
            });
          }
          emptyFields();
          setRefreshPc(!refreshPc);
        })
        .catch((error) => {
          setAlert({
            severity: 'error',
            message: error.message,
          });
        });
    } else {
      setAlert({
        severity: 'error',
        message: 'You must associate at least a product catalog to a role',
      });
    }
  };
  const emptyFields = () => {
    setRoleName('');
    setAssociatedProductCatalogs([]);
    setSelectedFilters({
      productCatalogs: [],
    });
  };
  const handleNewRole = () => {
    emptyFields();
    setAlert(undefined);
  };

  const processRowUpdate = (pcRole: any) => {
    apiClient
      .put(`/productCatalogRole/${pcRole?.id}`, pcRole)
      .catch((err: any) => {
        console.log(err);
      });
    return pcRole;
  };

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(undefined);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alert]);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Box sx={{ m: 1 }} />
        <Button sx={{ m: 1 }} startIcon={<AddIcon />} onClick={handleNewRole}>
          Add new role
        </Button>
      </Stack>
      <Divider />

      <form onSubmit={handleSubmit}>
        <Grid item xs={12}>
          <TextField
            sx={{ m: 2 }}
            error
            required
            id="outlined-required"
            label="Role Name"
            value={roleName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setRoleName(event.target.value)
            }
          />
        </Grid>

        <Grid item xs={4}>
          <Box sx={{ ml: 2, mb: 2 }}>
            <RecordFilterSelect
              source="productCatalogs"
              property={'name'}
              label="Associated product catalogs"
              selected={selectedFilters?.productCatalogs}
              onRecordChange={handleRecordChange}
              onFilterApply={handleFilterApply}
              onFilterCancel={handleFilterCancel}
            />
          </Box>
        </Grid>

        <Grid item xs={4}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disableElevation
            sx={{
              borderLeft: 0,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              m: 2,
            }}
          >
            Save Role
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            disabled={selectedIds.length === 0}
            sx={{ m: 2 }}
            onClick={() => {
              setOpenConfirm(true);
            }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Delete selection
          </Button>
        </Grid>
        {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
      </form>
      <DataGrid
        onSelectionModelChange={(ids: any) => {
          setSelectedIds(ids);
        }}
        autoHeight
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
      />

      <ConfirmDialog
        title="Confirm deleting roles"
        open={openConfirm}
        setOpen={setOpenConfirm}
        onConfirm={handleDelete}
      >
        <Typography>
          This action will delete the selected roles. Are you sure ?
        </Typography>
      </ConfirmDialog>
    </>
  );
}
