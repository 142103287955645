import { useEffect, useState } from 'react';
import ArticleIcon from '@mui/icons-material/Article';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import newsClient from '../../../../services/newsClient';
import { POST_TYPES } from '../../../news/data';
import { usePayload } from '../../../news/PayloadProvider';
import UpdateShow from '../../../news/UpdateShow/UpdateShow';
import Button from '@mui/material/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function UpdatesSearch({
  filter,
  onNavigate,
  onResultFound,
  onLoading,
}: {
  filter: any;
  onNavigate: () => void;
  onResultFound: any;
  onLoading: any;
}) {
  const [updates, setUpdates] = useState<any>();
  const [postId, setPostId] = useState<null | number>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);
  const [isClicked, setIsClicked] = useState(false);
  const { payload } = usePayload();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { search } = filter;

  useEffect(() => {
    if (!isClicked || !search) {
      setUpdates(null);
      setPage(1);
      // return;
    }

    if (search && payload) {
      onLoading(true);
      setIsLoading(true);
      newsClient
        .post('/posts', {
          post_type: [POST_TYPES.UPDATES],
          page,
          itemsPerPage: 3,
          search,
          ...payload,
        })
        .then((response: any) => {
          onLoading(false);

          if (response.data.records.length > 0) {
            onResultFound(true);  // Pass `true` to indicate that results were found
          } else {
            onResultFound(false);  // Pass `false` if no results were found
          }
          
          setHasNext(response.data?.length === 3);

          if (isClicked) {
            response.data = (updates || []).concat(response.data);
          }
          setUpdates(response.data);
        })
        .catch(() => {
          // TODO: handle error response
        })
        .finally(() => {
          setIsClicked(false);
          setIsLoading(false);
          return;
        });
    }
  }, [search, page]);

  const handleViewAll = () => {
    searchParams.delete('search');
    searchParams.set('search', search.toString());
    setSearchParams(searchParams);
    navigate({
      pathname: `/domains/updates`,
      search: searchParams.toString(),
    });
    onNavigate();
  };

  return (
    <>
      {(updates?.length > 0 || isLoading) && (
        <Box sx={{ mt: 2 }}>
          <Divider />
          <Stack direction="row" paddingY={1.5} p={1} spacing={1}>
            <Typography color="primary">
              <ArticleIcon />
            </Typography>
            <Typography color="gray">Updates</Typography>
          </Stack>
          <Divider />
          {(updates || []).map((post: any, i: number) => (
            <CardActionArea
              key={i}
              sx={{ p: 1, px: 2 }}
              onClick={() => {
                setPostId(post.id);
              }}
            >
              <Stack direction="row" gap={1} alignItems="center">
                <Box>
                  <img
                    alt={post.title}
                    height={90}
                    style={{ borderRadius: 20 }}
                    src={post.thumbnail}
                  />
                </Box>
                <Box>
                  <Box sx={{ typography: 'subtitle2' }}>{post.title}</Box>
                  <Typography color="gray">
                    <Stack direction="row" spacing={0.5}>
                      <Box>
                        {new Intl.DateTimeFormat('en-GB', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        }).format(new Date(post.date))}{' '}
                        By {post.author.name}
                      </Box>
                    </Stack>
                  </Typography>
                </Box>
              </Stack>
            </CardActionArea>
          ))}

          <Divider />
        </Box>
      )}

      {isLoading && (
        <>
          <Box sx={{ p: 2 }} width="100%">
            <Skeleton
              animation="wave"
              height={10}
              width="40%"
              style={{ marginBottom: 6 }}
            />
            <Skeleton animation="wave" height={10} width="60%" />
          </Box>
        </>
      )}
      {hasNext && search && (
        <CardActionArea
          sx={{ p: 1, px: 2 }}
          onClick={() => {
            setIsLoading(true);
            setIsClicked(true);
            setPage(page + 1);
          }}
        >
          <Stack direction="row" spacing={1} p={1}>
            <ManageSearchIcon color="primary" />
            <Typography color="primary" style={{ marginTop: 2 }}>
              Show more updates
            </Typography>
            <Stack
              direction="row"
              spacing={1}
              flexGrow={1}
              justifyContent="flex-end"
            >
              <Button onClick={handleViewAll} variant="outlined">
                Open Updates
              </Button>
            </Stack>
          </Stack>
        </CardActionArea>
      )}
      <Divider />
      {postId && (
        <UpdateShow id={postId} onClose={() => setPostId(null)} isRoot={true} />
      )}
    </>
  );
}
