import axios from 'axios';
import { UserManager } from 'oidc-client';
import { authSettings } from '../data';

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_ENTRYPOINT}/api`,
});

apiClient.interceptors.request.use(async (config) => {
  const userManager = new UserManager(authSettings);
  const user = await userManager.getUser();
  if(user?.expired) {
    userManager.signinSilent()
        .then(()=> {
          console.log("refresh depuis apiClient");
        })
        .catch((e) => {
          console.log(e.response);
          userManager.signoutRedirect({post_logout_redirect_uri: window.location.href});
        });
  }
  config.headers = {
    Authorization: `Bearer ${user?.access_token}`,
    ...config.headers,
  };
  return config;
});

export default apiClient;
