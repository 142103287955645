import { SyntheticEvent, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UploadForm from '../UploadForm/UploadForm';

const section = {
  contacts: 'contacts',
  avatars: 'avatars',
};

export default function ContactUpload() {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box p={1}>
      <Accordion
        expanded={expanded === section.contacts}
        onChange={handleChange(section.contacts)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Contacts list upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            pathId={2}
            path="contacts/upload"
            subPath="contacts"
            inputId="contacts-sheet"
            inputName="contacts"
            inputAccept=".csv"
          />
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === section.avatars}
        onChange={handleChange(section.avatars)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Contacts pictures upload</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <UploadForm
            path="contacts/avatars/upload"
            pathId={2}
            subPath="avatars"
            inputId="contacts-avatars"
            inputName="avatars"
            inputAccept=".zip"
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
