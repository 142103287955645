export default function ContractsIcon({
  className = 'buyin-icon',
}: {
  className?: string;
}) {
  return (
    <svg
      className={className}
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon/ic_contracts Copy</title>
      <g
        id="Icon/ic_contracts--Copy"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M11.1764706,0.5 L14,3.35714286 L14,14.7857143 C14,15.1802034 13.6802034,15.5 13.2857143,15.5 L2.71428571,15.5 C2.31979661,15.5 2,15.1802034 2,14.7857143 L2,1.21428571 C2,0.819796607 2.31979661,0.5 2.71428571,0.5 L11.1764706,0.5 Z M11.8823529,11.2142857 L7.64705882,11.2142857 L7.64705882,11.9285714 L11.8823529,11.9285714 L11.8823529,11.2142857 Z M6.23529412,11.2142857 L4.82352941,11.2142857 L4.82352941,11.9285714 L6.23529412,11.9285714 L6.23529412,11.2142857 Z M11.8823529,9.07142857 L7.64705882,9.07142857 L7.64705882,9.78571429 L11.8823529,9.78571429 L11.8823529,9.07142857 Z M6.23529412,9.07142857 L4.82352941,9.07142857 L4.82352941,9.78571429 L6.23529412,9.78571429 L6.23529412,9.07142857 Z M11.8823529,6.92857143 L7.64705882,6.92857143 L7.64705882,7.64285714 L11.8823529,7.64285714 L11.8823529,6.92857143 Z M6.23529412,6.92857143 L4.82352941,6.92857143 L4.82352941,7.64285714 L6.23529412,7.64285714 L6.23529412,6.92857143 Z M9.05882353,4.07142857 L4.82352941,4.07142857 L4.82352941,4.78571429 L9.05882353,4.78571429 L9.05882353,4.07142857 Z M11.1764706,1.2223875 L10.4705882,1.2223875 L10.4705882,4.07142857 L13.3017477,4.07142857 L13.3017477,3.35766129 L11.1764706,1.2223875 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </svg>
  );
}
