import React from 'react';

import { grey } from '@mui/material/colors';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
export default function MessageBox({
  children,
  direction,
  isFirst,
  isLast,
  hasNext,
}: {
  children: React.ReactNode;
  direction?: 'left' | 'right';
  isFirst?: boolean;
  isLast?: boolean;
  hasNext?: boolean;
}) {
  const borderRadius = isFirst
    ? hasNext
      ? '20px 0px 10px 10px'
      : '20px 10px 0px 10px'
    : isLast
      ? '10px 10px 0px 20px'
      : '10px 10px 10px 10px';
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        p: 2,
        backgroundColor: (theme: any) =>
          direction === 'right' ? theme.palette.primary.main : grey[100],
        width: '70%',
        minHeight: '50px',
        borderRadius:
          direction === 'right' ? borderRadius : '20px 20px 20px 0px',
        color: direction === 'right' ? 'white' : 'black',
      }}
    >
      <Typography variant="subtitle2">{children}</Typography>
    </Box>
  );
}
