import React, { FormEvent, FormEventHandler, useEffect, useState } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  Alert,
  AlertColor,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import RecordFilterSelect from '../../ContractList/ContractFilter/RecordFilterSelect/RecordFilterSelect';
import { REPORT_TYPES, Role } from '../../../data';
import apiClient from '../../../services/apiClient';
import Layout from '../../Layout/Layout';
import Guard from '../../shared/Guard/Guard';
import ConfirmDialog from '../../ui/mui/ConfirmDialog/ConfirmDialog';
import { Editor } from 'material-jsoneditor';
import { Group } from '../../../types';
import DialogList from '../../ui/mui/DialogList/DialogList';
import RefurbishedDashboardIcon from '../../../components/shared/Icons/tools/RefurbishedDashboardIcon';
import PowerBIIcon from '../../shared/Icons/PowerBiIcon';
import paginatedReportIcon from '../../../images/powerBi/paginated_power.png';
import MarketplaceIcon from '../../shared/Icons/tools/Marketplace';
import MarketIntelligencePackIcon from '../../shared/Icons/tools/MarketIntelligencePackIcon';
import LiveLocalNegotiationIcon from '../../shared/Icons/tools/LiveLocalNegotiationIcon';
import CashInDashboardIcon from '../../shared/Icons/tools/CashInDashboardIcon';
import PCDashboardIcon from '../../shared/Icons/tools/PCDashboardIcon';
import EcoRatingBenchmarkIcon from '../../shared/Icons/tools/EcoRatingBenchmarkIcon';
import RetailPriceReviewIcon from '../../shared/Icons/tools/RetailPriceReviewIcon';
import RefurbishedRetailPriceIcon from '../../shared/Icons/tools/RefurbishedRetailPriceIcon';
import UpdateRefurbishedRetailPriceIcon from '../../shared/Icons/tools/UpdateRefurbishedRetailPriceIcon';
interface filterType {
  subcategories: [];
}

interface Report {
  id: string;
  name: string;
  workspaceId: string;
  datasetId: string;
  type: string;
  subcategories: [];
}

export default function PowerBi() {
  const REPORT_LOGOS: any[] = [
    {
      value: 1,
      name: 'Power BI Icon',
      icon: <PowerBIIcon />,
      color: '#047F8C',
    },
    {
      value: 2,
      name: 'Report Icon',
      icon: (
        <img
          src={paginatedReportIcon}
          alt="Report Icon"
          style={{ width: '20px', height: '20px' }}
        />
      ),
      color: 'primary.main',
    },
    {
      value: 3,
      name: '360° Refurbished Dashboard',
      icon: <RefurbishedDashboardIcon />,
      color: '#047F8C',
    },
    {
      value: 4,
      name: 'Marketplace',
      icon: <MarketplaceIcon />,
      color: 'rgb(255,210,48)',
    },
    {
      value: 5,
      name: 'Market Intelligence Pack',
      icon: <MarketIntelligencePackIcon />,
      color: 'rgb(255,210,48)',
    },
    {
      value: 6,
      name: 'Live Local Negotiation',
      icon: <LiveLocalNegotiationIcon />,
      color: '#047F8C',
    },
    {
      value: 7,
      name: 'Cash-In Dashboard',
      icon: <CashInDashboardIcon />,
      color: '#047F8C',
    },
    {
      value: 8,
      name: 'PC Dashboard',
      icon: <PCDashboardIcon />,
      color: '#047F8C',
    },
    {
      value: 9,
      name: 'Eco Rating Benchmark',
      icon: <EcoRatingBenchmarkIcon />,
      color: '#047F8C',
    },
    {
      value: 10,
      name: 'Retail Price Review',
      icon: <RetailPriceReviewIcon />,
      color: '#047F8C',
    },
    {
      value: 11,
      name: 'Refurbished Retail Price',
      icon: <RefurbishedRetailPriceIcon />,
      color: '#047F8C',
    },
    {
      value: 12,
      name: 'Update Refurbished Retail Price',
      icon: <UpdateRefurbishedRetailPriceIcon />,
      color: '#047F8C',
    },
  ];
  const [selectedFilters, setSelectedFilters] = useState<filterType>({
    subcategories: [],
  });
  const [reportId, setReportId] = useState<string>('');
  const [reportName, setReportName] = useState<string>('');
  const [workspaceId, setWorkspaceId] = useState<string>('');
  const [datasetId, setDatasetId] = useState<string>('');
  const [roleName, setRoleName] = useState<string>('ExternalUser');

  const [hasIdentities, setHasIdentities] = useState<boolean>(true);

  const [groups, setGroups] = useState('');
  const [iconId, setIconId] = useState<string>('2');
  const [groupsIds, setGroupsIds] = useState('');
  const [parameterName, setParameterName] = useState<string>('');
  const [isPaginated, setIsPaginated] = useState<boolean>(true);
  const [associatedSubcategories, setAssociatedSubCategories] = useState([]);
  const [rows, setRows] = useState<Report[]>([]);
  const [groupRecords, setGroupRecords] = useState<Partial<Group>[]>([]);

  const [alert, setAlert] = useState<{
    severity: AlertColor | undefined;
    message: string;
  }>();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [refreshReport, setRefreshReport] = useState(true);
  const [settings, setSettings] = useState<any>({});
  const [settingsString, setSettingsString] = useState<any>('{}');
  const [isValidSettings, setIsValidSettings] = useState(true);
  const [filters, setFilters] = useState<any>(null);
  const [filtersString, setFiltersString] = useState<any>('');
  const [isValidFilters, setIsValidFilters] = useState(true);

  const [filtersEdit, setFiltersEdit] = useState<any>();
  const [settingsEdit, setSettingsEdit] = useState<any>();

  const [choices, setChoices] = useState<
    {
      name: string;
      value: string;
      type: string;
    }[]
  >([]);
  const [subcategories, setSubcategories] = useState<any[]>([]);

  const [openSubcategories, setOpenSubcategories] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [initialValues, setInitialValues] = useState<any>([]);
  const [hasPageId, setHasPageId] = useState(false);
  const [pageId, setPageId] = useState('');

  const handleRecordChange = (source: any, checked: any, record: any) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    if (checked) {
      newFilters[source] = [...(newFilters[source] || []), record];
    } else {
      newFilters[source] = newFilters[source].filter(
        (item: any) => item.id !== record.id,
      );
    }
    setSelectedFilters(newFilters);
  };

  const handleFilterCancel = (source: string) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    newFilters.subcategories = associatedSubcategories;
    setSelectedFilters(newFilters);
  };

  const handleFilterApply = (key: string, newValue: Date | null) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    setAssociatedSubCategories(newFilters.subcategories);
  };

  const handleChangeType = (
    event: React.MouseEvent<HTMLElement>,
    newValue: boolean,
  ) => {
    setIsPaginated(newValue);
    setIconId(newValue ? '2' : '1');
  };

  const emptyFields = () => {
    setIsPaginated(true);
    setIconId(isPaginated ? '2' : '1');
    setReportName('');
    setReportId('');
    setWorkspaceId('');
    setDatasetId('');
    setAssociatedSubCategories([]);
    setParameterName('');
    setSelectedFilters({
      subcategories: [],
    });
    setGroups('135000002,135000003');
  };

  const handleNewReport = () => {
    emptyFields();
    setAlert(undefined);
  };
  useEffect(() => {
    if (refreshReport === true) {
      apiClient.get('/reports').then((res) => {
        setRows(res.data.records);
      });

      if (groupRecords.length === 0) {
        apiClient
          .get('/groups', { params: { ids: '135000002,135000003' } })
          .then((res) => {
            setGroupRecords(res.data.records);
          });
      }

      if (subcategories.length === 0) {
        apiClient
          .get('/subcategories', { params: { perPage: 0 } })
          .then((res: any) => {
            setSubcategories(res.data.records);

            setChoices(
              res.data.records.map((s: any) => ({
                name: `${s.name}`,
                value: `${s.id}`,
                type: 'subcategory',
              })),
            );
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [refreshReport]);

  const processRowUpdate = async (report: any) => {
    setRefreshReport(false);
    setAlert({
      severity: 'info',
      message: 'Updating report ...',
    });

    await apiClient
      .post('/reports', {
        ...report,
        filters: filtersEdit,
        settings: settingsEdit,
        isPaginated: !isNaN(report.isPaginated)
          ? report.isPaginated
          : REPORT_TYPES.find((t) => t.name === report.isPaginated)?.value,
        iconId: !isNaN(report.iconId)
          ? report.iconId
          : REPORT_LOGOS.find((r) => r.name === report.iconId)?.value,
        groups:
          report.groups === 'OR AND DT' ||
          (Array.isArray(report.groups) &&
            report.groups.every(
              (group: any) => group.id === 135000002 || group.id === 135000003,
            ))
            ? groupRecords
            : [groupRecords.find((group) => group.code === report.groups)],
      })
      .then((result) => {
        setAlert({
          severity: 'success',
          message: 'Successful update!',
        });
        emptyFields();
      })
      .catch((error) => {
        setAlert({
          severity: 'error',
          message: 'Failed update!',
        });
      })
      .finally(() => {
        setRefreshReport(true);

        return {
          ...report,
          settings: settingsEdit,
          filters: filtersEdit,
          iconId: !isNaN(report.iconId)
            ? report.iconId
            : REPORT_LOGOS.find((r) => r.name === report.iconId)?.value,
          groups:
            report.groups === 'OR AND DT' ||
            (Array.isArray(report.groups) &&
              report.groups.every(
                (group: any) =>
                  group.id === 135000002 || group.id === 135000003,
              ))
              ? groupRecords
              : [groupRecords.find((group) => group.code === report.groups)],
        };
      });
  };
  const handleSubmit: FormEventHandler<HTMLFormElement> = async (
    event: FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    setAlert(undefined);
    const data = {
      powerBIId: reportId,
      name: reportName,
      workspaceId: workspaceId,
      datasetId: datasetId,
      isPaginated,
      iconId: iconId,
      powerBIParameter: parameterName,
      subcategories: associatedSubcategories,
      hasIdentities: hasIdentities,
      powerBIRole: roleName,
      hasPageId,
      pageId,
      settings: isPaginated ? undefined : settings,
      filters: isPaginated ? undefined : filters,
      groups: groupsIds?.split(',').map((groupId) => ({ id: Number(groupId) })),
    };
    setRefreshReport(false);

    await apiClient
      .post('/reports', data)
      .then((result) => {
        setAlert({
          severity: 'success',
          message: 'Report successfully saved',
        });

        emptyFields();
      })
      .catch((error) => {
        setAlert({
          severity: 'error',
          message: 'Report saving failes',
        });
      })
      .finally(() => {
        setRefreshReport(true);
      });
  };

  const handleDelete = () => {
    if (selectedIds.length > 0) {
      setRefreshReport(false);

      apiClient
        .delete(`/reports/${selectedIds.join(',')}`)
        .then(() => {
          setAlert({
            severity: 'success',
            message: 'reports successfully deleted',
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setRefreshReport(true);
        });
    }
  };
  const columns: GridColDef[] = [
    {
      field: 'powerBIId',
      headerName: 'Report ID',
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <Typography noWrap>{params.value}</Typography>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: 'powerBIRole',
      headerName: 'Role',
      editable: true,
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <Typography noWrap>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'pageId',
      headerName: 'PageId',
      editable: true,
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <Typography noWrap>{params.value}</Typography>
        </Tooltip>
      ),
    },
    {
      field: 'hasPageId',
      headerName: 'Has PageId',
      type: 'boolean',
      editable: true,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <Typography noWrap>{params.value}</Typography>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: 'powerBIParameter',
      headerName: 'Parameter',
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <Typography noWrap>{params.value}</Typography>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: 'iconId',
      headerName: 'Logo',
      width: 70,
      type: 'singleSelect',
      valueOptions: REPORT_LOGOS.map((logo) => logo.name),
      renderCell: (params: any) => {
        return (
          <Tooltip
            title={
              REPORT_LOGOS.find((t) => t.value === params.row.iconId)?.name
            }
          >
            <Box
              sx={{
                width:
                  REPORT_LOGOS.find((t) => t.value === params.row.iconId)
                    ?.width || '40px',
                height:
                  REPORT_LOGOS.find((t) => t.value === params.row.iconId)
                    ?.width || '40px',
                backgroundColor: REPORT_LOGOS.find(
                  (t) => t.value === params.row.iconId,
                )?.color,
                borderRadius: '12px',
                cursor: 'pointer',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding:
                  REPORT_LOGOS.find((t) => t.value === params.row.iconId)
                    ?.padding || '5px',
                '&:hover': {
                  backgroundColor: REPORT_LOGOS.find(
                    (t) => t.value === params.row.iconId,
                  )?.color,
                  opacity: [0.9, 0.8, 0.7],
                },
              }}
            >
              {REPORT_LOGOS.find((t) => t.value === params.row.iconId)?.icon}
            </Box>
          </Tooltip>
        );
      },
      editable: true,
    },
    {
      field: 'datasetId',
      headerName: 'Dataset Id',
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <Typography noWrap>{params.value}</Typography>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: 'workspaceId',
      headerName: 'Workspace Id',
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title={params.value}>
          <Typography noWrap>{params.value}</Typography>
        </Tooltip>
      ),
      editable: true,
    },
    {
      field: 'isPaginated',
      headerName: 'Type',
      type: 'singleSelect',
      valueOptions: REPORT_TYPES.map((type) => type.name),
      renderCell: (params: any) => (
        <Tooltip
          title={
            REPORT_TYPES.find((t) => t.value === params.row.isPaginated)?.name
          }
        >
          <Typography noWrap>
            {REPORT_TYPES.find((t) => t.value === params.row.isPaginated)?.name}
          </Typography>
        </Tooltip>
      ),
      flex: 1,
      editable: true,
    },
    {
      field: 'subcategories',
      headerName: 'Subcategories',
      renderCell: (params: any) => (
        <Tooltip
          title={params.row.subcategories.map((s: any) => s.name).join(', ')}
        >
          <Typography noWrap>
            {params.row.subcategories.map((s: any) => s.name).join(', ')}
          </Typography>
        </Tooltip>
      ),
      flex: 1,
      editable: false,
    },
    {
      field: 'settings',
      headerName: 'Settings',
      editable: true,
      renderCell: (params: any) => (
        <Tooltip title={JSON.stringify(params.row.settings || '', null, 2)}>
          <Typography noWrap maxWidth={80}>
            {JSON.stringify(params.row.settings)}
          </Typography>
        </Tooltip>
      ),
      renderEditCell: (params: any) => (
        <TextField
          // sx={{ m: 2 }}
          error
          required
          label="Edit settings"
          defaultValue={JSON.stringify(params.row.settings)}
          onChange={(event: any) => {
            try {
              setFiltersEdit(params.row.filters);
              setSettingsEdit(JSON.parse(event.target.value));
            } catch (error) {}
          }}
        />
      ),
      flex: 1,
    },
    {
      field: 'filters',
      headerName: 'filters',
      editable: true,
      renderCell: (params: any) => (
        <Tooltip title={JSON.stringify(params.row.filters || '', null, 2)}>
          <Typography noWrap maxWidth={80}>
            {JSON.stringify(params.row.filters, null, 2)}
          </Typography>
        </Tooltip>
      ),
      renderEditCell: (params: any) => (
        <TextField
          sx={{ m: 2 }}
          error
          required
          label="Edit filters"
          defaultValue={JSON.stringify(params.row.filters)}
          onChange={(event: any) => {
            try {
              setSettingsEdit(params.row.settings);
              setFiltersEdit(JSON.parse(event.target.value));
            } catch (error) {}
          }}
        />
      ),
      flex: 1,
    },
    {
      field: 'groups',
      headerName: 'groups',
      type: 'singleSelect',
      editable: true,
      valueOptions: groupRecords
        .map((group) => group.code || '')
        .concat(['OR AND DT']),
      renderCell: (params: any) => (
        <Tooltip
          title={
            Array.isArray(params.row.groups) &&
            params.row.groups.map((group: any) => group.code).join(', ')
          }
        >
          <Typography noWrap>
            {Array.isArray(params.row.groups) &&
              params.row.groups.map((group: any) => group.code).join(', ')}
          </Typography>
        </Tooltip>
      ),
      flex: 1,
    },
    {
      field: 'hasIdentities',
      headerName: 'Has Identities',
      type: 'boolean',
      editable: true,
      flex: 1,
    },
  ];
  return (
    <Layout noBreadcrumbs>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        sx={{ p: 2 }}
      >
        <Typography>Admin</Typography>
        <Typography>Power BI Reports</Typography>,
      </Breadcrumbs>
      <Divider />
      <Guard role={Role.PowerBIManager} message>
        <Stack direction="row" justifyContent="space-between">
          <Box sx={{ m: 1 }} />
          <Button
            sx={{ m: 1 }}
            startIcon={<RestartAltIcon />}
            onClick={handleNewReport}
          >
            Reset
          </Button>
        </Stack>
        <Divider />

        <form onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs={6} sx={{ p: 1 }}>
              <TextField
                fullWidth
                variant="filled"
                id="outlined-required"
                label="Report name"
                value={reportName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setReportName(event.target.value)
                }
              />
            </Grid>

            <Grid item xs={6} sx={{ p: 1 }}>
              <TextField
                fullWidth
                variant="filled"
                id="outlined-required"
                label="Report id"
                value={reportId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setReportId(event.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} sx={{ p: 1 }}>
              <TextField
                fullWidth
                variant="filled"
                id="outlined-required"
                label="Workspace id"
                value={workspaceId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setWorkspaceId(event.target.value)
                }
              />
            </Grid>
            <Grid item xs={6} sx={{ p: 1 }}>
              <TextField
                fullWidth
                variant="filled"
                id="outlined-required"
                label="Dataset id"
                value={datasetId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setDatasetId(event.target.value)
                }
              />
            </Grid>
            <Grid item xs={12} sx={{ p: 1 }}>
              <FormControl sx={{ m: 1, width: '99%' }}>
                <InputLabel>Report group</InputLabel>
                <Select
                  variant="filled"
                  fullWidth
                  labelId="reportGroup"
                  id="reportGroup"
                  value={groups}
                  label="Report group"
                  onChange={(event: SelectChangeEvent) => {
                    setGroups(event.target.value as string);
                    setGroupsIds(event.target.value);
                  }}
                >
                  <MenuItem value={'135000002'} defaultChecked>
                    DT
                  </MenuItem>
                  <MenuItem value={'135000003'}>OR</MenuItem>
                  <MenuItem value={'135000002,135000003'}>OR AND DT</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ p: 1 }}>
              <RecordFilterSelect
                source="subcategories"
                property={'name'}
                label="Associated subcategories"
                selected={selectedFilters?.subcategories}
                onRecordChange={handleRecordChange}
                onFilterApply={handleFilterApply}
                onFilterCancel={handleFilterCancel}
              />
            </Grid>

            {
              <Grid item xs={12} sx={{ p: 1 }}>
                <FormControl sx={{ p: 1, width: '100%' }}>
                  <InputLabel>Logo</InputLabel>
                  <Select
                    variant="filled"
                    fullWidth
                    labelId="reportLogo"
                    id="reportLogo"
                    value={iconId}
                    onChange={(event: SelectChangeEvent) => {
                      setIconId(event.target.value as string);
                    }}
                  >
                    {REPORT_LOGOS.map((logo) => (
                      <MenuItem value={logo.value}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                          padding={1}
                        >
                          <Box
                            sx={{
                              width: logo.width || '40px',
                              height: logo.width || '40px',
                              backgroundColor: logo.color,
                              borderRadius: '12px',
                              cursor: 'pointer',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              padding: logo.padding || '5px',
                              '&:hover': {
                                backgroundColor: logo.color,
                                opacity: [0.9, 0.8, 0.7],
                              },
                            }}
                          >
                            {logo.icon}
                          </Box>
                          <Typography sx={{ ml: 1 }}>{logo.name}</Typography>
                        </Stack>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            }

            <Grid item xs={12} sx={{ p: 1 }}>
              <ToggleButtonGroup
                fullWidth
                color="error"
                value={isPaginated}
                exclusive
                onChange={handleChangeType}
                aria-label="Platform"
              >
                <ToggleButton value={true}>Paginated</ToggleButton>
                <ToggleButton value={false}>Power BI</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </Grid>

          <Grid item xs={12} sx={{ p: 1 }}>
            <Stack direction="row" alignItems="center" spacing={1} padding={1}>
              <Switch
                onChange={() => setHasIdentities(!hasIdentities)}
                checked={hasIdentities}
              />

              <TextField
                fullWidth
                variant="filled"
                id="outlined-required"
                label="Role name"
                value={roleName}
                disabled={!hasIdentities}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setRoleName(event.target.value)
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={12} sx={{ p: 1 }}>
            <Stack direction="row" alignItems="center" spacing={1} padding={1}>
              <Switch
                onChange={() => setHasPageId(!hasPageId)}
                checked={hasPageId}
              />

              <TextField
                fullWidth
                variant="filled"
                id="outlined-required"
                label="Page Id"
                value={pageId}
                disabled={!hasPageId}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setPageId(event.target.value)
                }
              />
            </Stack>
          </Grid>

          {isPaginated && (
            <Grid item xs={12} sx={{ p: 1 }}>
              <TextField
                fullWidth
                variant="filled"
                id="outlined-required"
                label="Parameter Name"
                value={parameterName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setParameterName(event.target.value)
                }
              />
            </Grid>
          )}
          {!isPaginated && (
            <Box sx={{ p: 2 }}>
              <Grid container>
                <Grid sm={6} item>
                  <Typography>Settings :</Typography>
                  <Editor
                    value={settings}
                    onChange={(e: any) => {
                      setSettingsString(JSON.stringify(e, null, 2));
                      setSettings(e);
                    }}
                  />
                </Grid>
                <Grid sm={6} sx={{ p: 1 }} item>
                  <TextField
                    id="outlined-multiline-static"
                    label="Settings"
                    multiline
                    rows={11}
                    fullWidth
                    value={settingsString}
                    error={!isValidSettings}
                    helperText={!isValidSettings && 'Incorrect entry.'}
                    variant="filled"
                    onChange={(e: any) => {
                      try {
                        setIsValidSettings(true);
                        if (e.target.value === '') {
                          setSettings({});
                          return;
                        }
                        setSettings(JSON.parse(e.target.value));
                      } catch (er) {
                        setIsValidSettings(false);
                        setSettingsString(e.target.value);
                      }
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid sm={6} item>
                  <Typography>Filters :</Typography>
                  <Editor
                    value={filters}
                    onChange={(e: any) => {
                      if (Array.isArray(e)) {
                        setFiltersString(JSON.stringify(e, null, 2));
                        setFilters(e);
                      }
                    }}
                  />
                </Grid>
                <Grid sm={6} sx={{ p: 1 }} item>
                  <TextField
                    label="filters"
                    multiline
                    rows={11}
                    fullWidth
                    value={filtersString}
                    error={!isValidFilters}
                    helperText={!isValidFilters && 'Incorrect entry.'}
                    variant="filled"
                    onChange={(e: any) => {
                      try {
                        setIsValidFilters(true);
                        setFiltersString(e.target.value);

                        if (e.target.value === '') {
                          setFilters(null);
                          return;
                        }

                        if (Array.isArray(JSON.parse(e.target.value))) {
                          setFilters(JSON.parse(e.target.value));
                          return;
                        }
                      } catch (er) {
                        setIsValidFilters(false);
                        setFiltersString(e.target.value);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disableElevation
              sx={{
                m: 2,
                borderLeft: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              Save Report
            </Button>
          </Grid>
        </form>
        <Divider />
        <Grid item xs={12}>
          <Button
            sx={{ m: 2 }}
            disabled={selectedIds.length === 0}
            onClick={() => {
              setOpenConfirm(true);
            }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Delete selection
          </Button>
        </Grid>
        {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
        <DialogList
          choices={choices}
          title="Associated subcategories"
          setOpen={setOpenSubcategories}
          open={openSubcategories}
          onClose={() => {
            setOpenSubcategories(false);
          }}
          onChange={(selection: any[]) => {
            if (selectedRow && selectedRow.subcategories) {
              setAlert({
                severity: 'info',
                message: 'Updating report ...',
              });
              selectedRow.subcategories = subcategories.filter((s: any) =>
                selection.some((sel: any) => sel.value === String(s.id)),
              );

              apiClient
                .post('reports', {
                  ...selectedRow,
                })
                .then(() => {
                  setRows([...rows]);
                  setAlert({
                    severity: 'success',
                    message: 'Successful update!',
                  });
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          }}
          initialValues={initialValues}
        />
        <DataGrid
          onSelectionModelChange={(ids: any) => {
            setSelectedIds(ids);
          }}
          onCellDoubleClick={(params: { id: any; field: string }) => {
            const row: any = rows.find((row) => row.id === params.id);
            setSelectedRow(row);

            if (params.field === 'subcategories') {
              setOpenSubcategories(true);
              setInitialValues(
                (row?.subcategories || []).map((subcategory: any) => ({
                  name: `${subcategory.name}`,
                  value: `${subcategory.id}`,
                  type: 'subcategory',
                })),
              );
            }
          }}
          autoHeight
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          processRowUpdate={async (report: any) => {
            report.hasIdentities = Boolean(report.hasIdentities);
            await processRowUpdate(report);
            return {
              ...report,
              settings: report.settings || settingsEdit,
              filters: report.filters || filtersEdit,
            };
          }}
          experimentalFeatures={{ newEditingApi: true }}
        />
        <ConfirmDialog
          title="Confirm deleting reports"
          open={openConfirm}
          setOpen={setOpenConfirm}
          onConfirm={handleDelete}
        >
          <Typography>
            This action will delete the selected reports. Are you sure ?
          </Typography>
        </ConfirmDialog>
      </Guard>
    </Layout>
  );
}
