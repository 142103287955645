import { Divider, ListItemIcon, Stack, Typography } from '@mui/material';
import React from 'react';



export default function TabPanel({
  value,
  index,
  children,
}: React.PropsWithChildren<{ value: string; index: string}>) {
  return index === value ? <React.Fragment> {children}</React.Fragment> : null;
}
