import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { Status } from '../../../types';
import DialogSelect from '../../ui/mui/DialogSelect/DialogSelect';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Chip, TextField } from '@mui/material';
import { useFilters } from '../../Layout/NavigationFiltersProvider/NavigationFiltersProvider';

import { STATUSES } from '../../../data';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import RecordSelect from '../../shared/RecordSelect/RecordSelect';

export default function ContractFilters({
  metaData,
  filters,
  onFiltersChange,
}: {
  metaData?: any | null;
  filters?: any;
  onFiltersChange: (filter: any) => void;
}) {
  const [statuses, setStatuses] = useState<any>(metaData?.statuses);

  const [minStartDate, setMinStartDate] = useState<Date | null>(
    new Date(metaData?.startDate?.min),
  );

  const [maxStartDate, setMaxStartDate] = useState<Date | null>(
    new Date(metaData?.startDate?.max),
  );

  const [minEndDate, setMinEndDate] = useState<Date | null>(
    new Date(metaData?.endDate?.min),
  );

  const [maxEndDate, setMaxEndDate] = useState<Date | null>(
    new Date(metaData?.endDate?.max),
  );

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [open, setOpen] = useState(false);

  const { resetTrigger, setOtherFilterValues } =
    useFilters();
  const [filterValues, setFilterValues] = useState<any>(null);

  const [statusesInitialValues, setStatusesInitialValues] = useState<string[]>([
    '1',
    '2',
    '3',
  ]);
  const [ownerInitialValues, setOwnerInitialValues] = useState<string[]>([]);
  const [owners, setOwners] = useState<any[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValues, setSearchValues] = useState<any>();

  const handleStatusChange = (selection: string[]) => {
    setStatusesInitialValues(selection);
    setFilterValues([
      ...(filterValues || []).filter(
        ({ dimension }: { dimension: string }) => dimension !== 'Status',
      ),
      ...selection.map((s) => ({
        dimension: 'Status',
        label: STATUSES.find((st) => st.value === Number(s))?.name,
        id: s,
      })),
    ]);

    onFiltersChange({
      ...filters,
      statuses: selection.length > 0 ? selection.join(',') : null,
    });
  };

  const handleOwnerChange = (f: any) => {
    setOwnerInitialValues(f.contacts?.split(',') || []);
    setFilterValues(
      [
        ...(filterValues || []).filter(
          ({ dimension }: { dimension: string }) => dimension !== 'Owner',
        ),
      ].concat(
        (f.contacts?.split(',') || []).map((ownerId: any) => ({
          dimension: 'Owner',
          label: owners.find((owner) => owner.id === Number(ownerId))?.email,
          id: ownerId,
        })),
      ),
    );
    onFiltersChange({ ...filters, ...f });
  };

  const handleDateChange = (value: any, key: string) => {
    let date = value ? new Date(value) : null;
    // const dimension = key === 'startDate' ? 'Start Date': 'End Date'
    date?.setDate(1);

    setFilterValues(
      [
        ...(filterValues || []).filter(({ id }: { id: string }) => id !== key),
      ].concat(
        value
          ? [
              {
                dimension: key == 'fromDate' ? 'Start Date' : 'End Date',
                label: moment(date).format('MMM yyyy'),
                id: key,
              },
            ]
          : [],
      ),
    );

    onFiltersChange({
      ...filters,
      [key]: date,
    });
  };

  useEffect(() => {
    if (resetTrigger) {
      setMinStartDate(null);
      setMaxStartDate(null);
      setStartDate(null);

      setMinEndDate(null);
      setMaxEndDate(null);
      setEndDate(null);

      setOtherFilterValues(null);
      setStatusesInitialValues([]);
      setOwnerInitialValues([]);

      onFiltersChange({
        statuses: null,
        domains: null,
        categories: null,
        group: null,
        suppliers: null,
        startDate: null,
        endDate: null,
        groups: null,
        regions: null,
        natcos: null,
        page: 1,
      });
    }
  }, [resetTrigger]);

  useEffect(() => {
    const initialStatusesFilterValues = [
      ...['1', '2', '3'].map((s) => ({
        dimension: 'Status',
        label: STATUSES.find((st) => st.value === Number(s))?.name,
        id: s,
      })),
    ];

    setFilterValues(initialStatusesFilterValues);

  }, [searchParams]);

  const deleteValue = (id: string, dimension: string) => {
    switch (id) {
      case 'startDate':
        {
          setStartDate(null);
          handleDateChange(null, 'fromDate');
        }
        break;
      case 'endDate':
        {
          setStartDate(null);
          handleDateChange(null, 'toDate');
        }
        break;
      default: {
        if (dimension === 'Status') {
          handleStatusChange(
            (statusesInitialValues || []).filter(
              (status: string) => status !== id,
            ),
          );
        } else if (dimension === 'Owner') {
          handleOwnerChange({
            ...filters,
            contacts:
              (ownerInitialValues || [])
                .filter((c: string) => c !== id)
                .join(',') || null,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (searchParams.get('search') !== null) {
      setSearchValues(
        <React.Fragment>
          <Chip
            onDelete={() => {
              searchParams.delete('search');
              setSearchParams(searchParams);
            }}
            label={`search:${searchParams.get('search')} `}
            sx={{ m: 0.5 }}
          />
        </React.Fragment>,
      );
    } else {
      setSearchValues(null);
    }
  }, [searchParams]);

  useEffect(() => {
    if (filterValues) {
      setOtherFilterValues(
        filterValues
          .map((filterValue: any, index: number) => {
            return (
              <React.Fragment key={index}>
                <Chip
                  key={filterValue.id}
                  onDelete={() => {
                    deleteValue(filterValue.id, filterValue.dimension);
                  }}
                  label={`${filterValue.dimension} ${
                    filterValue.label && ' : ' + filterValue.label
                  } `}
                  icon={filterValue?.icon}
                  sx={{ m: 0.5 }}
                />
              </React.Fragment>
            );
          })
          .concat(searchValues),
      );
    }
  }, [filterValues, searchValues]);

  useEffect(() => {
    setStatuses(metaData?.statuses);
    setMinStartDate(new Date(metaData?.startDate?.min));
    setMaxStartDate(new Date(metaData?.startDate?.max));
    setMinEndDate(new Date(metaData?.startDate?.min));
    setMaxEndDate(new Date(metaData?.startDate?.max));
    // eslint-disable-next-line
  }, [metaData, open]);

  return (
    <>
      <Grid item xs={12} sm={6} md={4} xl={3}>
        <RecordSelect
          label="Owner"
          reference="contacts"
          source="email"
          filter={{ hasContracts: true }}
          filters={filters}
          setRecords={setOwners}
          onFiltersChange={handleOwnerChange}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={3}>
        <DialogSelect
          label={`Status (${
            (filters?.statuses?.split(',') || []).length || 'All'
          })`}
          title="Select status"
          choices={(statuses || []).map(({ name, value }: Status) => ({
            name: name,
            value: `${value}`,
          }))}
          initialValues={statusesInitialValues}
          onChange={handleStatusChange}
          onOpen={setOpen}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="Start Date"
            views={['year', 'month']}
            maxDate={maxStartDate}
            minDate={minStartDate}
            onChange={(value: any) => {
              setStartDate(value);
              handleDateChange(value, 'fromDate');
            }}
            value={startDate}
            renderInput={(params: any) => (
              <TextField
                {...params}
                fullWidth={true}
                size="small"
                InputLabelProps={{
                  sx: {
                    color: 'primary.main',
                    fontSize: '15px',
                    textTransform: 'uppercase',
                    marginLeft: '-4px',
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6} md={4} xl={3}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label="End date"
            views={['year', 'month']}
            maxDate={maxEndDate}
            minDate={minEndDate}
            onChange={(value: any) => {
              setEndDate(value);
              handleDateChange(value, 'toDate');
            }}
            value={endDate}
            renderInput={(params: any) => (
              <TextField
                {...params}
                fullWidth={true}
                size="small"
                InputLabelProps={{
                  sx: {
                    color: 'primary.main',
                    fontSize: '15px',
                    textTransform: 'uppercase',
                    marginLeft: '-4px',
                  },
                }}
              />
            )}
          />
        </LocalizationProvider>
      </Grid>
    </>
  );
}
