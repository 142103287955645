import { SyntheticEvent, useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Alert, { AlertColor } from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UploadForm from '../UploadForm/UploadForm';
import RecordFilterSelect from '../../../ContractList/ContractFilter/RecordFilterSelect/RecordFilterSelect';
import { Button, Grid, TextField } from '@mui/material';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import apiClient from '../../../../services/apiClient';
import ProductCatalogStatus from './ProductCatalogStatus';
import Configuration from './Configuration';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../../ui/mui/ConfirmDialog/ConfirmDialog';


interface filterType {
  subcategories: [];
}

interface ProductCatalog {
  id: number;
  name: string;
  status: number;
  subcategories: [];
}

export default function ProductCatalogUpload() {
  const [expanded, setExpanded] = useState<string | false>(false);
  const [selectedFilters, setSelectedFilters] = useState<filterType>({
    subcategories: [],
  });
  const [associatedSubcategories, setAssociatedSubCategories] = useState([]);
  const [pCName, setPCName] = useState<string>();
  const [pcDetail, setPcDetail] = useState<any>();
  const [rows, setRows] = useState<ProductCatalog[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [refreshPcList, setRefreshPcList] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [alert, setAlert] = useState<{
    severity: AlertColor | undefined;
    message: string;
  }>();
  const [alertGrid, setAlertGrid] = useState<{
    severity: AlertColor | undefined;
    message: string;
  }>();
  const [selectedPcName, setSelectedPcName] = useState<string>();


  const section = {
    new: 'New product catalog',
    list: 'product catalog list',
  };

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
      setPCName('');
      setAssociatedSubCategories([]);
      setSelectedFilters({
        subcategories: [],
      });
    };

  const handleRecordChange = (source: any, checked: any, record: any) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    if (checked) {
      newFilters[source] = [...(newFilters[source] || []), record];
    } else {
      newFilters[source] = newFilters[source].filter(
        (item: any) => item.id !== record.id,
      );
    }
    setSelectedFilters(newFilters);
  };

  const handleFilterCancel = (source: string) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    newFilters.subcategories = associatedSubcategories;
    setSelectedFilters(newFilters);
  };

  const handleFilterApply = (key: string, newValue: Date | null) => {
    const newFilters = JSON.parse(JSON.stringify(selectedFilters));
    setAssociatedSubCategories(newFilters.subcategories);
  };

  useEffect(() => {
    apiClient.get('/productCatalog').then((res) => {
      setRows(res.data.records);
    });
  }, [refreshPcList]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 120,
      editable: true,
    },
    {
      field: 'status',
      headerName: 'STATUS',
      width: 100,
      renderCell: (params: any) => {
        return <ProductCatalogStatus value={params.row?.status} />;
      },
    },
    {
      field: 'subcategories',
      headerName: 'Subcategories',
      width: 400,

      renderCell: (params: any) => {
        return (
          <>
            {params.row.subcategories.map((s: any, index: any) => {
              if (index === params.row.subcategories.length - 1) {
                return `${s.name}`;
              } else {
                return `${s.name} | `;
              }
            })}
            &nbsp; &nbsp;
          </>
        );
      },
    },
    {
      field: 'id',
      headerName: 'Configuration',
      width: 200,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setPcDetail(params.row);
                setOpen(!open);
              }}
            >
              <MiscellaneousServicesIcon />
            </Button>
          </>
        );
      },
    },
    {
      field: 'delete',
      headerName: 'Delete',
      width: 200,
      renderCell: (params: any) => {
        return (
          <>
            <Button
              size="small"
              variant="outlined"
              onClick={() => {
                setSelectedPcName(params.row.name);
                setOpenConfirm(true);
              }}
            >
              <DeleteIcon />
            </Button>
          </>
        );
      },
    },
  ];

  const handleDeleteProductCatalog = () => {
      apiClient.delete(`/productCatalog/${selectedPcName?.trim()}`).then(() => {
        setRefreshPcList(!refreshPcList);
        setAlertGrid({
          severity: 'success',
          message: 'Product catalog successfully deleted',
        });
      });
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setRefreshPcList(!refreshPcList);
  };

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(undefined);
      }, 10000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alert]);
  useEffect(() => {
    if (alertGrid) {
      const timer = setTimeout(() => {
        setAlertGrid(undefined);
      }, 10000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alertGrid]);

  const processRowUpdate = async (productCatalog: any) => {
    apiClient
    .post(`/productCatalog`, {
      id: productCatalog.id,
      name: productCatalog.name,
    })
    .then((res: any) => {
      setRefreshPcList(!refreshPcList);
    })
    .catch((err: any) => {
      setRefreshPcList(!refreshPcList);
      console.log(err);
    });
  }

  return (
    <Box p={1}>
      <Accordion
        expanded={expanded === section.new}
        onChange={handleChange(section.new)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Add new product catalog</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12}>
            <TextField
              sx={{ m: 2 }}
              error
              required
              id="outlined-required"
              label="Product Catalog Name"
              value={pCName}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setPCName(event.target.value)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <Box sx={{ m: 2 }}>
              <RecordFilterSelect
                source="subcategories"
                property={'name'}
                label="Associated subcategories"
                selected={selectedFilters?.subcategories}
                onRecordChange={handleRecordChange}
                onFilterApply={handleFilterApply}
                onFilterCancel={handleFilterCancel}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ m: 2 }}>
            <UploadForm
              path="productCatalog/upload"
              pathId={12}
              subPath={pCName}
              inputId={`productCatalog-${pCName}`}
              inputName="productCatalog"
              inputAccept=".zip"
              displayUploadedList={false}
              buttonLabel={'Save & Upload'}
              onSuccess={(res: any) => {
                setRefreshPcList(!refreshPcList);
              }}
              //@ts-ignore
              handleSave={async() => {
                const pcData = {
                  name: pCName,
                  status: 1,
                  subcategories: associatedSubcategories,
                };
                return apiClient.post('/productCatalog', pcData).then((result) => {
                  setRefreshPcList(!refreshPcList);
                  return true;
                }).catch((error) => {
                  setAlert({
                    severity: 'error',
                    message:
                      error?.response?.data?.message ||
                      error?.response?.data?.error ||
                      'An error occurred',
                  });
                  return false;
                })
              }}
            />
            {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}

          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === section.list}
        onChange={handleChange(section.list)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Product catalog List</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{m:2}}>
        {alertGrid && <Alert severity={alertGrid.severity}>{alertGrid.message}</Alert>}
        </Box>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            autoHeight
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            processRowUpdate={async (productCatalog: any) => {
              await processRowUpdate(productCatalog);
              return {
                ...productCatalog,
              };
            }}
          />
          {open && (
            <Configuration
              pcDetail={pcDetail}
              open={open}
              onClose={handleClose}
            />
          )}
        </AccordionDetails>
      </Accordion>
      <ConfirmDialog
        title="Confirm deleting Product Catalog"
        open={openConfirm}
        setOpen={setOpenConfirm}
        onConfirm={handleDeleteProductCatalog}
      >
        <Typography>
          This action will delete the selected product catalog. Are you sure ?
        </Typography>
      </ConfirmDialog>
    </Box>
  );
}
