import { useState } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import apiClient from '../../../../../services/apiClient';

interface SpeakerProperties {
  label: string;
  name: 'firstName' | 'lastName' | 'email';
  type: string;
}

export default function SpeakerForm({
  onSave,
}: {
  onSave: (speaker: SpeakerProperties) => void;
}) {
  const validationSchema = yup.object({
    firstName: yup.string().required('Firstname is required'),
    lastName: yup.string().required('Lastname is required'),
    email: yup
      .string()
      .email('Invalid email format')
      .required('Email is required'),
  });

  const properties: SpeakerProperties[] = [
    {
      label: 'Firstname',
      name: 'firstName',
      type: 'text',
    },
    {
      label: 'LastName',
      name: 'lastName',
      type: 'text',
    },
    {
      label: 'Email',
      name: 'email',
      type: 'email',
    },
  ];

  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<
    'success' | 'info' | 'warning' | 'error'
  >('success');
  const submitForm = (
    values: any,
    { setSubmitting, resetForm }: { setSubmitting: any; resetForm: any },
  ) => {
    setSubmitting(true);
    apiClient
      .post('/speakers', values)
      .then((result) => {
        setOpenAlert(true);
        setMessage('Added successfully');
        setSeverity('success');
        onSave(result.data);
        resetForm();
      })
      .catch((error) => {
        setMessage(error.message);
        setOpenAlert(true);
        setSeverity('error');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <>
      <Typography sx={{ mt: 2 }}>New speaker</Typography>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
        }}
        onSubmit={submitForm}
      >
        {({ errors, touched, isSubmitting, handleSubmit, submitForm }) => (
          <Form onSubmit={handleSubmit}>
            {properties.map((prop: SpeakerProperties, index) => (
              <Field name={prop.name} key={index}>
                {({ field }: { field: any }) => (
                  <TextField
                    {...field}
                    margin="dense"
                    variant="filled"
                    helperText={
                      Boolean(touched[prop.name]) && errors[prop.name]
                    }
                    error={Boolean(errors[prop.name] && touched[prop.name])}
                    label={prop.label}
                    type="text"
                    fullWidth
                  />
                )}
              </Field>
            ))}

            <LoadingButton
              onClick={() => submitForm()}
              variant="contained"
              loadingPosition="start"
              startIcon={<SaveIcon />}
              loading={isSubmitting}
            >
              Add
            </LoadingButton>
          </Form>
        )}
      </Formik>

      <Box sx={{ width: '100%', mt: 2 }}>
        <Collapse in={openAlert}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpenAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
            severity={severity}
          >
            {message}
          </Alert>
        </Collapse>
      </Box>
    </>
  );
}
