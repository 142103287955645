import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { green, orange, red, yellow, grey } from '@mui/material/colors';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useNav } from '../NavProvider';
import React, { useEffect, useState } from 'react';
import apiClient from '../../services/apiClient';
import { Supplier } from '../../types';
function Rating({
  backgroundColor,
  primary,
  secondary,
  notSustainabilityScore = true,
}: {
  backgroundColor?: string;
  primary?: string;
  secondary?: any;
  notSustainabilityScore?: boolean;
}) {
  return (
    <ListItem
      secondaryAction={notSustainabilityScore ?
        <Avatar
          sx={{
            backgroundColor: backgroundColor || grey[300],
            width: 26,
            height: 26,
          }}
        >
          &nbsp;
        </Avatar> : <></>
      }
    >
      <ListItemText
        primary={primary}
        secondary={secondary || <Box>No data available</Box>}
      />
    </ListItem>
  );
}
const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  summaryContent: {
    color: 'red',
    marginBottom: '-47px',
    marginRight: '17px',
    marginTop: '10px',
    transition: 'none',
    '-webkit-transition': 'none',
  },
  expandedIcon: {
    color: 'red',
    marginLeft: '23px',
    float: 'left',
    marginTop: '-33px',
  },
}));
export default function Profile() {
  const { supplierId } = useNav();
  const [colors, setColors] = useState<any>();
  const [csrAffiliates, setCsrAffiliates] = useState<any>();
  const [sustainabilityScore, setSustainabilityScore] = useState<any>();
  const [complianceAffiliates, setComplianceAffiliates] = useState<any>();
  const [financialAffiliates, setFinancialAffiliates] = useState<any>();
  const [expanded, setExpanded] = useState(false);
  const [expandedScores, setExpandedScores] = useState(false);

  const [supplier, setSupplier] = useState<Supplier>();
  const sustainabilityTooltip = [
    { kpi: 'C1', value: 'NGO publishing environmental data provided by companies.' },
    { kpi: 'C2', value: '<p>Scope 1= Direct emissions from a company.</p></br><p>Scope 2= Indirect emissions from the generation of purchased electricity, heating, cooling. etc. consumed by a company.</p>' },
    { kpi: 'C3', value: "Scope 3 includes all other indirect emissions that occurs in a company's value chain." },
    { kpi: 'C4', value: 'A carbon emissions target is defined as science-based if it is in line with the scale of reductions require to keep global temperature increase below 2°C above pre-industrial temperatures and pursuing efforts to limit warming to 1.5°C.' },
    { kpi: 'C5', value: 'Ratings platform to assess corporate social responsibility and sustainable procurement.' },
    { kpi: 'C6', value: "Supplier's Emission Factor (EF) = Total Upstream2 Emissions (tCO2) + Total Revenue Supplier (m€) (as submitted in CDP reporting)" },

  ]
  const classes = useStyles();

  useEffect(() => {
    if (supplierId) {
      apiClient
        .get(`/suppliers/${supplierId}`, {
          params: { hasRating: true, hasSustainabilityScores: true },
        })
        .then(({ data }) => {
          setSupplier(data.record);
          const { rating, risks, sustainabilityScores } = data.record;
          const { csr, sustainability, credit } = rating || {};
          setSustainabilityScore(
            sustainabilityScores?.filter((s: any) => s.kpi === 'SSS')[0],
          );
          setCsrAffiliates(risks?.filter((a: any) => a.isCsr));
          setComplianceAffiliates(risks?.filter((a: any) => a.isCompliance));
          setFinancialAffiliates(risks?.filter((a: any) => a.isFinancial));

          setColors({
            credit: credit?.value?.startsWith('A')
              ? green[600]
              : credit?.value?.startsWith('B')
                ? yellow[600]
                : credit?.value?.match('^C.*|^D.*')
                  ? red[600]
                  : grey[300],
            csr:
              csr?.value >= 65
                ? green[600]
                : csr?.value <= 64 && csr?.value >= 44
                  ? orange[600]
                  : csr?.value <= 24
                    ? red[600]
                    : grey[300],
            sustainability:
              sustainability?.value &&
              (sustainability?.value <= 100 && sustainability?.value >= 65
                ? '#006B3D'
                : sustainability?.value <= 65 && sustainability?.value >= 40
                  ? '#7BB662'
                  : sustainability?.value <= 40 && sustainability?.value >= 15
                    ? '#FFD301'
                    : sustainability?.value <= 15 && sustainability?.value >= 0
                      ? '#E03C32'
                      : grey[300]),
          });
        });
    }
  }, [supplierId]);

  function getSustainabilityScoreColor(score: any) {

    return score <= 100 && score >= 65
      ? '#006B3D'
      : score <= 65 && score >= 40
        ? '#7BB662'
        : score <= 40 && score >= 15
          ? '#FFD301'
          : score <= 15 && score >= 0
            ? '#E03C32'
            : grey[300];
  }
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  return (
    <Box sx={{ p: 1 }}>
      <List sx={{ width: '100%' }}>
        <ListItem>
          <ListItemText>{supplier?.description}</ListItemText>
        </ListItem>
        {!supplier && (
          <>
            <Stack direction={'column'} spacing={1}>
              <Skeleton height={10} width="100%" />
              <Skeleton height={10} width="70%" />
              <Skeleton height={10} width="80%" />
              <Divider />
            </Stack>

            {Array(3)
              .fill(null)
              .map((element, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  margin={2}
                >
                  <Skeleton height={10} width="100%" />
                  <Skeleton variant="circular" width={24} height={24} />
                </Stack>
              ))}
            <Divider />
            {Array(3)
              .fill(null)
              .map((element, index) => (
                <Stack
                  key={index}
                  direction="row"
                  alignItems="center"
                  spacing={2}
                  margin={2}
                >
                  <Skeleton height={10} width="100%" />
                  <Skeleton variant="circular" width={24} height={24} />
                </Stack>
              ))}
          </>
        )}
        {(supplier?.affiliates || []).length > 0 && (
          <>
            <ListItem>
              <ListItemText sx={{ marginLeft: '-14px', marginRight: '-14px' }}>
                <Accordion
                  expanded={expanded}
                  onChange={() => setExpanded(!expanded)}
                  sx={{ padding: 0 }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: 'red' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography component="span" color="primary">
                      {expanded
                        ? 'Hide linked affiliates'
                        : 'Show linked affiliates'}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {(supplier?.affiliates || []).map(
                        (affiliate, i: number) => (
                          <React.Fragment key={i}>
                            <ListItem>
                              <ListItemIcon>
                                <FiberManualRecordIcon
                                  sx={{
                                    color:
                                      (supplier?.risks?.some(
                                        (r) =>
                                          (r.isFinancial ||
                                            r.isCsr ||
                                            r.isCompliance) &&
                                          r?.description === affiliate.name,
                                      ) &&
                                        red[600]) ||
                                      green[600],
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText>{affiliate.name}</ListItemText>
                            </ListItem>
                          </React.Fragment>
                        ),
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </ListItemText>
            </ListItem>
            <Divider />
          </>
        )}
        {supplier && (
          <>
            <ListItem>
              <ListItemText sx={{ marginLeft: '-14px', marginRight: '-14px' }}>
                <Accordion
                  expanded={expandedScores}
                  onChange={() => setExpandedScores(!expandedScores)}
                // sx={{paddingLeft:"10px"}}
                >
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      padding: 0, '& .MuiAccordionSummary-expandIconWrapper': {
                        'transition': 'none'
                      }
                    }}
                    expandIcon={<ExpandMoreIcon className={expandedScores ? classes.expandedIcon : classes.summaryContent} />}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Rating
                          //A: green[700], B: yellow[600], C,D: red[600]
                          notSustainabilityScore={false}
                          primary="Sustainability Rating"
                          secondary={
                            sustainabilityScore?.score && (
                              <>
                                <Box>
                                  SSS: {sustainabilityScore?.score} of 100
                                </Box>
                                {sustainabilityScore?.date &&
                                  `Updated: ${moment(
                                    sustainabilityScore?.date,
                                  ).format('DD/MM/YYYY HH:mm')}`}
                              </>
                            )
                          }
                        />
                      </Grid>
                      <Grid item>
                        <FiberManualRecordIcon
                          sx={{
                            color: getSustainabilityScoreColor(
                              sustainabilityScore?.score,
                            ),
                            fontSize: '40px',
                            marginTop: '-39px',
                            marginRight: '-32px',
                          }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List>
                      {(
                        supplier?.sustainabilityScores?.filter(
                          (s) => s.kpi !== 'SSS',
                        ) || []
                      ).sort((a, b) => {
                        return Number(a.kpi[1]) - Number(b.kpi[1]);
                      }).map((suScore, i: number) => (
                        <React.Fragment key={i}>
                          <ListItem style={{ display: 'flex', width: '75%' }}>
                            <ListItemText style={{ flex: '2' }}>
                              {suScore.kpi} - {suScore.description}
                              <HtmlTooltip
                                title={
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: sustainabilityTooltip.find(item => item.kpi === suScore.kpi)?.value || '',
                                    }}
                                  ></span>
                                }
                              >
                                <InfoIcon
                                  sx={{
                                    fontSize: '15px',
                                    marginTop: '5px !important',
                                  }}
                                />
                              </HtmlTooltip>
                            </ListItemText>
                            <ListItemText style={{ flex: '0.5' }}>
                              {suScore.score}
                            </ListItemText>
                            <ListItemText style={{ flex: '1' }}>

                              <FiberManualRecordIcon
                                sx={{
                                  color: getSustainabilityScoreColor(
                                    suScore.score,
                                  ),
                                }}
                              />
                            </ListItemText>
                          </ListItem>
                        </React.Fragment>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </ListItemText>
            </ListItem>
            <Rating
              //A: green[700], B: yellow[600], C,D: red[600]
              backgroundColor={colors?.credit} //
              primary="Credit Rating"
              secondary={
                supplier.rating?.credit?.value && (
                  <>
                    <Box>
                      Standard & Poors: {supplier?.rating?.credit?.value}
                    </Box>
                    Updated :
                    {moment(supplier.rating.date).format('DD/MM/YYYY HH:mm')}
                  </>
                )
              }
            />
            <Rating
              primary="CSR Rating"
              //green[600]: >= 65 ,  green[300]: between 64 and 44, orange[600] : between 25 and 44,  red[600] : <=24
              backgroundColor={colors?.csr}
              secondary={
                supplier.rating?.csr?.value && (
                  <>
                    <Box> EcoVadis: {supplier.rating.csr.value} of 100</Box>
                    {supplier.rating?.csr?.date &&
                      `Updated: ${moment(supplier.rating.csr.date).format(
                        'DD/MM/YYYY HH:mm',
                      )}`}
                  </>
                )
              }
            />

          </>
        )}

        {supplier?.risks && (
          <>
            <Divider />
            <Rating
              primary="DT Financial risk assessment"
              secondary={
                (!(financialAffiliates || []).length && (
                  <> according to D&B, no affiliates with risk </>
                )) || (
                  <>
                    Affiliates with risk according to D&B: <br />
                    {(financialAffiliates || []).map((a: any, i: number) => (
                      <React.Fragment key={i}>
                        - &nbsp;
                        <Link
                          target={a.reportURL ? '_blank' : '_self'}
                          rel="noreferrer"
                          sx={{ cursor: 'pointer' }}
                          href={a.reportURL || undefined}
                        >
                          {a.description}
                        </Link>
                        {a.date &&
                          ` (updated :
                    ${moment(a.date).format('DD/MM/YYYY HH:mm')})`}
                        <br />
                      </React.Fragment>
                    ))}
                  </>
                )
              }
              backgroundColor={
                (!(financialAffiliates || []).length && green[600]) || red[600]
              }
            />
            <Rating
              primary="DT Compliance risk assessment"
              secondary={
                (!(complianceAffiliates || []).length && (
                  <> according to D&B, no affiliates with risk </>
                )) || (
                  <>
                    Affiliates with risk according to D&B: <br />
                    {(complianceAffiliates || []).map((a: any, i: number) => (
                      <React.Fragment key={i}>
                        - &nbsp;
                        <Link
                          target={a.reportURL ? '_blank' : '_self'}
                          rel="noreferrer"
                          sx={{ cursor: 'pointer' }}
                          href={a.reportURL || undefined}
                        >
                          {a.description}
                        </Link>
                        {a.date &&
                          ` (updated :
                  ${moment(a.date).format('DD/MM/YYYY HH:mm')})`}
                        <br />
                      </React.Fragment>
                    ))}
                  </>
                )
              }
              backgroundColor={
                (!(complianceAffiliates || []).length && green[600]) || red[600]
              }
            />
            <Rating
              primary="DT CSR risk assessment"
              secondary={
                (!(csrAffiliates || []).length && (
                  <> according to D&B, no affiliates with risk </>
                )) || (
                  <>
                    Affiliates with risk according to D&B: <br />
                    {(csrAffiliates || []).map((a: any, i: number) => (
                      <React.Fragment key={i}>
                        - &nbsp;
                        <Link
                          target={a.reportURL ? '_blank' : '_self'}
                          rel="noreferrer"
                          sx={{ cursor: 'pointer' }}
                          href={a.reportURL || undefined}
                        >
                          {a.description}
                        </Link>
                        {a.date &&
                          ` (updated :
                  ${moment(a.date).format('DD/MM/YYYY HH:mm')})`}
                        <br />
                      </React.Fragment>
                    ))}
                  </>
                )
              }
              backgroundColor={
                (!(csrAffiliates || []).length && green[600]) || red[600]
              }
            />
          </>
        )}
      </List>
    </Box>
  );
}
