export default function StrategiesIcon({
  className = 'buyin-icon-stroke',
}: {
  className?: string;
}) {
  return (
    <svg
      className={className}
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon / ic_strategies</title>
      <g
        id="Icon-/-ic_strategies"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
      >
        <g id="Group-2" transform="translate(0.666667, 1.666667)">
          <ellipse
            id="Oval"
            cx="1.83333333"
            cy="11.1428571"
            rx="1.83333333"
            ry="1.85714286"
          ></ellipse>
          <ellipse
            id="Oval"
            cx="11.6111111"
            cy="1.85714286"
            rx="1.83333333"
            ry="1.85714286"
          ></ellipse>
          <path
            d="M10.6696459,5.45632346 L11.3303541,5.54525595 C10.7591638,9.78881391 8.05426776,11.800252 3.34090478,11.5140063 L3.38131744,10.8485657 C7.75934949,11.1144465 10.147046,9.33888706 10.6696459,5.45632346 Z"
            id="Path-5"
            fill="#8E8E93"
            fillRule="nonzero"
          ></path>
          <path
            d="M9.19876889,6.64004992 C10.455196,5.2737746 11.1325017,4.53322734 11.2306861,4.41840814 C11.3288705,4.30358894 11.6085502,5.27604798 12.0697251,7.33578525 L9.19876889,6.64004992 Z"
            id="Path-6"
            fill="#8E8E93"
            fillRule="nonzero"
          ></path>
          <path
            d="M2.44444444,1.23809524 L4.88888889,3.71428571 M4.88888889,1.23809524 L2.44444444,3.71428571"
            id="Combined-Shape"
            strokeWidth="0.666666667"
            strokeLinecap="round"
            strokeLinejoin="round"
            fillRule="nonzero"
          ></path>
          <path
            d="M12.2222222,9.28571429 L14.6666667,11.7619048 M14.6666667,9.28571429 L12.2222222,11.7619048"
            id="Combined-Shape"
            strokeWidth="0.666666667"
            strokeLinecap="round"
            strokeLinejoin="round"
            fillRule="nonzero"
          ></path>
          <path
            d="M4.27777778,5.57142857 L6.72222222,8.04761905 M6.72222222,5.57142857 L4.27777778,8.04761905"
            id="Combined-Shape"
            strokeWidth="0.666666667"
            strokeLinecap="round"
            strokeLinejoin="round"
            fillRule="nonzero"
          ></path>
        </g>
      </g>
    </svg>
  );
}
