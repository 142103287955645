import { useSearchParams, useLocation, useMatch } from 'react-router-dom';
import { useEffect, useState } from 'react';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ShareIcon from '@mui/icons-material/Share';
import IconButton from '@mui/material/IconButton';
import { Supplier } from '../../../types';
import { ALL, useNav } from '../../NavProvider';
import { useAuth } from '../../AuthProvider';

interface BreadcrumbsItemProps {
  name: string;
  id?: string;
  isLast: boolean;
}

const BreadcrumbsItem = ({ name, id, isLast }: BreadcrumbsItemProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleItemClick = () => {
    if (id && id !== ALL) {
      searchParams.set('navDomain', id);
    } else {
      searchParams.delete('navDomain');
    }
    setSearchParams(searchParams);
  };

  return (
    <Typography key={id} color={isLast ? 'inherit' : 'text.primary'}>
      {isLast ? (
        name
      ) : (
        <Link
          onClick={handleItemClick}
          sx={{
            cursor: 'pointer',
            color: 'text.primary',
            textDecoration: 'none',
          }}
        >
          {name}
        </Link>
      )}
    </Typography>
  );
};

const getBreadcrumbsItems = (
  domain: any,
  supplier: Supplier | null,
  isCustomer: boolean,
) => {
  const items = [
    <BreadcrumbsItem
      name="Domains"
      key="all"
      id="all"
      isLast={!Boolean(domain)}
    />,
  ];

  if (!Boolean(domain)) {
    items.push(<BreadcrumbsItem name="All categories" key="none" isLast />);
  }

  if (domain) {
    if (!isCustomer) {
      items.push(
        <BreadcrumbsItem
          name={domain.name}
          key={`${domain.id}`}
          id={`${domain.id}`}
          isLast={!Boolean(domain.category) && !supplier}
        />,
      );
    }
    if (domain.category) {
      items.push(
        <BreadcrumbsItem
          name={domain.category.name}
          key={`${domain.id}-${domain.category.id}`}
          id={`${domain.id}-${domain.category.id}`}
          isLast={!Boolean(domain.category.subcategory) && !supplier}
        />,
      );
      if (domain.category.subcategory) {
        items.push(
          <BreadcrumbsItem
            name={domain.category.subcategory.name}
            key={`${domain.id}-${domain.category.id}-${domain.category.subcategory}`}
            id={`${domain.id}-${domain.category.id}-${domain.category.subcategory}`}
            isLast={!supplier}
          />,
        );
      }
    }
  }

  if (supplier) {
    items.push(
      <BreadcrumbsItem
        name={supplier.name}
        key={`supplier-${supplier.id}`}
        id={`supplier-${supplier.id}`}
        isLast
      />,
    );
  }

  return items;
};

const BreadcrumbsSkeleton = () => (
  <Box sx={{ p: 2 }}>
    <Skeleton />
  </Box>
);

export default function Breadcrumbs() {
  const { domain, supplierId, supplier, isReady } = useNav();
  const { profile } = useAuth();
  const location = useLocation();
  const [tabName, setTabName] = useState<string>(location.pathname);
  const pathPrefix = supplierId ? `/suppliers/${supplierId}` : '/domains';
  const matchTopics = useMatch(`${pathPrefix}/:tab/topics/:topics`);
  const topicParams = matchTopics?.params.topics;
  useEffect(() => {
    setTabName(
      location.pathname?.split('/')[2] === 'negotiations'
        ? 'programs'
        : location.pathname?.split('/')[1] === 'suppliers'
        ? 'suppliers'
        : location.pathname?.split('/')[2],
    );
  }, [location, tabName]);

  return isReady && (!supplierId || !!supplier) ? (
    <Box sx={{ display: 'flex', width: 'auto' }}>
      <Box>
        <MuiBreadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{ p: 2 }}
        >
          {getBreadcrumbsItems(domain, supplier, !!profile?.isCustomer)}
        </MuiBreadcrumbs>
      </Box>
      {tabName !== 'feed' && tabName !== 'tools' && tabName !== 'contacts' && (
        <Box>
          <IconButton
            aria-label="close"
            href={`mailto:?subject=Share ${tabName}&body=Hey, I´d like to share this link with you %0A${
              process.env.REACT_APP_MOBILE_API
            }/deeplink/${tabName}${
              tabName === 'updates' && topicParams
                ? '?topics=' + topicParams
                : ''
            }${supplier?.name ? '/' + supplier.name.toUpperCase() : ''}`}
            sx={{
              right: '25px',
              position: 'absolute',
              marginTop: '7px',
              width: 'auto',
              color: (theme) => theme.palette.error.light,
            }}
          >
            <ShareIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  ) : (
    <BreadcrumbsSkeleton />
  );
}
