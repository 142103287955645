import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { User, UserManager } from 'oidc-client';
import CryptoJS from 'crypto-js';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Profile } from '../types';
import { authSettings, ROLE_HIERARCHY } from '../data';
import apiClient from '../services/apiClient';
import TermsOfUse from './TermsOfUse';
import frontClient from '../services/frontClient';
import { useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import { Alert, Button } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Loader from './ui/mui/Loader/Loader';

const REQUESTED_URI_KEY = 'requestedUri';

interface AuthContextValues {
  profile: Profile | null;
  logout: () => void;
  hasRole: (role: string) => boolean;
  hasDomain: (domainId: number) => boolean;
}

const defaultAuthValues = {
  profile: null,
  logout: () => {},
  hasRole: () => false,
  hasDomain: () => false,
};

export const AuthContext = createContext<AuthContextValues>(defaultAuthValues);

interface AuthContextProviderProps {
  children: JSX.Element;
}

const AuthProvider = (props: AuthContextProviderProps) => {
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code');
  const userManager = new UserManager(authSettings);
  const location = useLocation();
  const { trackEvent } = useMatomo();
  const { pushInstruction } = useMatomo();

  const events = [
    'load',
    'mousemove',
    'mousedown',
    'click',
    'scroll',
    'keypress',
  ];

  const handleAcceptTermsClick = () => {
    if (profile?.isCustomer) {
      frontClient
        .post('/termsofuse', { termsOfUse: 1 })
        .then((response) => {
          setUser(response.data);
        })
        .catch((e) => {
          console.log(e);
          if (e?.response?.data?.message === 'jwt not active')
            return window.location.reload();
          setError(e.response.data.message);
        });
    } else {
      apiClient
        .post('/users/terms')
        .then((response) => {
          setUser(response.data);
        })
        .catch((e) => {
          console.log(e);
          if (e?.response?.data?.message === 'jwt not active')
            return window.location.reload();

          setError(e.response.data.message);
        });
    }
  };

  const hashEmail = (email: any) => {
    const hashedEmail = CryptoJS.MD5(email).toString();
    return hashedEmail;
  };

  function trackingInformation(user: any) {
    pushInstruction(
      'setUserId',
      user.profile.isCustomer
        ? user.profile.email
        : hashEmail(user.profile.email),
    );
    pushInstruction(
      'setCustomVariable',
      2,
      'natco',
      user.profile.isCustomer
        ? Object.values(user.profile.homenatco)[0]
        : user.profile.homenatco,
      'visit',
    );
    pushInstruction(
      'setCustomVariable',
      3,
      'group',
      user.profile.isCustomer
        ? Object.values(user.profile.homegroup)[0]
        : user.profile.homegroup,
      'visit',
    );
    pushInstruction(
      'setCustomVariable',
      4,
      'function',
      user.profile.customerfunction,
      'visit',
    );
    pushInstruction(
      'setCustomVariable',
      5,
      'mgmtlevel',
      user.profile.managementlevel
        ? Object.values(user.profile.managementlevel)[0]
        : user.profile.managementlevel,
      'visit',
    );
    pushInstruction(
      'setCustomVariable',
      6,
      'department',
      user.profile.department,
      'visit',
    );
  }
  useEffect(() => {
    if (code) {
      userManager
        .signinRedirectCallback()
        .then(() => {
          sessionStorage.setItem(
            `firstConnexion_${process.env.NODE_ENV}`,
            'true',
          );

          const requestedURI = localStorage.getItem(REQUESTED_URI_KEY);

          if (requestedURI !== null) {
            localStorage.removeItem(REQUESTED_URI_KEY);
            return window.location.replace(requestedURI);
          }
        })
        .catch((e) => {
          console.log(e);
          if (e?.response?.data?.message === 'jwt not active')
            return window.location.reload();
          setError(e.response?.data?.message);
        });
    } else {
      userManager
        .getUser()
        .then((user) => {
          if (user) {
            if (
              location.pathname.startsWith('/summit-booking') ||
              location.pathname === '/'
            ) {
              apiClient
                .get('/sessions', {
                  params: {
                    type: 'UPCOMING',
                  },
                })
                .then((res) => {
                  if (res.data.pagination.total === 0) {
                    return navigate('/domains/feed');
                  }
                });
            }

            if (
              location.pathname.startsWith('/summit-booking') ||
              location.pathname === '/'
            ) {
              apiClient
                .get('/sessions', {
                  params: {
                    type: 'UPCOMING',
                  },
                })
                .then((res) => {
                  if (res.data.pagination.total === 0) {
                    return navigate('/domains/feed');
                  }
                });
            }

            if (
              sessionStorage.getItem(
                `firstConnexion_${process.env.NODE_ENV}`,
              ) === 'true'
            ) {
              trackingInformation(user);
              trackEvent({
                category: 'login-success',
                action: 'login-success',
              });

              sessionStorage.setItem(
                `firstConnexion_${process.env.NODE_ENV}`,
                'false',
              );
            } else {
              trackingInformation(user);
            }
            setUser(user.profile?.attributes || user.profile);
            sessionStorage.setItem(
              `activityTrack_${process.env.NODE_ENV}`,
              Date.now().toString(),
            );
          } else {
            localStorage.setItem(REQUESTED_URI_KEY, window.location.href);
            userManager.signinRedirect().catch((e) => {
              console.log(e);
              if (e.message === 'jwt not active')
                return window.location.reload();
              setError(e.message);
            });
          }
        })
        .catch((e) => {
          trackEvent({
            category: 'login-fail',
            action: 'login-fail',
          });
          console.log(e);
          if (e?.response?.data?.message === 'jwt not active')
            return window.location.reload();
          setError(e.response.data.message);
        });
    }
    if (!sessionStorage.getItem(`${process.env.NODE_ENV}_isReset_`)) {
      const keys = Object.keys(localStorage);
      for (const key of keys) {
        if (key.includes('oidc')) {
          localStorage.removeItem(key);
        }
      }
      sessionStorage.setItem(`${process.env.NODE_ENV}_isReset_`, 'true');
      searchParams.delete('code');
      setSearchParams(searchParams);
      window.location.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  useEffect(() => {
    if (user) {
      apiClient
        .get('users/me')
        .then((response) => {
          setProfile(response.data);
        })
        .catch((e: any) => {
          console.log(e);
          if (e?.response?.data?.message === 'jwt not active')
            return window.location.reload();
          setError(e.response.data.message);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function logout(): any {
    sessionStorage.removeItem(`firstConnexion_${process.env.NODE_ENV}`);
    return userManager.signoutRedirect();
  }

  useEffect(() => {
    Object.values(events).forEach((item) => {
      if (profile) {
        window.addEventListener(item, () => {
          const activityTrack = sessionStorage.getItem(
            `activityTrack_${process.env.NODE_ENV}`,
          );
          let isActive = false;
          if (activityTrack) {
            const timeDifference = Date.now() - parseInt(activityTrack);
            if (timeDifference < 600000) {
              sessionStorage.setItem(
                `activityTrack_${process.env.NODE_ENV}`,
                Date.now().toString(),
              );

              isActive = true;
            }

            if (!isActive) {
              sessionStorage.removeItem(
                `activityTrack_${process.env.NODE_ENV}`,
              );
              logout();
            }
          }
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events]);

  function hasRole(role: string): boolean {
    return Boolean(
        profile &&
          !profile.isCustomer &&
          ((profile?.roles || []).includes(role) ||
            Object.keys(ROLE_HIERARCHY)
              .filter((key) => (profile?.roles || []).includes(key))
              .some((key) => ROLE_HIERARCHY[key].includes(role))),
      ) ||
      Boolean(
        profile?.isCustomer &&
          (profile?.roles?.map((role) => role.name) || []).includes(role) &&
          // to pretect sensitive roles from being added from crmdb
          !ROLE_HIERARCHY[role],
      )
  }

  function hasDomain(domainId: number): boolean {
    return Boolean(
        profile && (
          !profile?.isCustomer || (
            profile.domains &&
            profile.domains.some((domain) => domain.id === domainId)
          )))
    }    

    

  if (location.pathname === '/public') {
    return <></>;
  }

  if (error != null) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Button
          startIcon={<RefreshIcon />}
          onClick={logout}
          variant="contained"
        >
          Authentication failed
        </Button>
        <br />
        <Button variant="outlined" onClick={logout}>
          <LogoutIcon />
          <Typography textAlign="center">Retry Login</Typography>
        </Button>
        <br />
        <Alert severity="error">{error}</Alert>
      </Stack>
    );
  }
  if (!profile) {
    return <Loader />;
  }

  if (
    profile &&
    ((!profile.isCustomer && !hasRole('ROLE_TERMS')) ||
      (profile.isCustomer &&
        (profile?.roles || []).every((role) => role.name !== 'ROLE_TERMS')))
  ) {
    return (
      <TermsOfUse handleAcceptTermsClick={handleAcceptTermsClick}></TermsOfUse>
    );
  }

  return (
    <AuthContext.Provider value={{ profile, logout, hasRole,hasDomain }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('AuthContext must be used within a AuthProvider');
  }

  return context;
}

export default AuthProvider;
