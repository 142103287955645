import { useEffect, useState } from 'react';
import React from 'react';
import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import apiClient from '../../../services/apiClient';
import { FILTER_TYPES_PRODUCT_CATALOG } from '../../../data';
import FilterType from './FilterType';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from '../../ui/mui/ConfirmDialog/ConfirmDialog';

export default function FilterConfig({
  fieldRecords,
  productCatalogSelected,
}: any) {
  const [fieldSelected, setFieldSelected] = useState<any>(null);
  const [filterType, setFilterType] = useState<any>(0);
  const [filterName, setFilterName] = useState<any>(null);
  const [filtersList, setFiltersList] = useState<any[]>([]);
  const [refreshFiltersList, setRefreshFiltersList] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [alert, setAlert] = useState<{
    severity: AlertColor | undefined;
    message: string;
  }>();

  useEffect(() => {
    apiClient
      .get('/field/', {
        params: {
          productCatalogId: productCatalogSelected.id,
          isFilter: true,
        },
      })
      .then((res) => {
        setFiltersList(res.data.record);
      });
  }, [productCatalogSelected, refreshFiltersList]);
  const addFilter = () => {
    apiClient
      .post('/field', [
        {
          id: fieldSelected.id,
          filterType: filterType,
          filterName: filterName,
        },
      ])
      .then((result) => {
        setRefreshFiltersList(!refreshFiltersList);
        setFieldSelected(null);
        setFilterType(0);
        setFilterName(null);
      });
  };
  const columns: GridColDef[] = [
    {
      field: 'filterName',
      headerName: 'Filter Name',
      width: 120,
    },
    {
      field: 'filterType',
      headerName: 'Filter Type',
      width: 120,
      renderCell: (params: any) => {
        return <FilterType value={params.row?.filterType} />;
      },
    },
    {
      field: 'technicalId',
      headerName: 'Field ',
      width: 300,
    },
  ];

  const handleDeleteFilters = () => {
    if (selectedIds.length > 0) {
      const dataToDelete = selectedIds.map((si) => ({
        id: si,
        filterType: null,
        filterName: null,
      }));

      apiClient.post('/field', dataToDelete).then(() => {
        setRefreshFiltersList(!refreshFiltersList);
        setAlert({
          severity: 'success',
          message: 'Filters successfully deleted',
        });
      });
    }
  };
  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(undefined);
      }, 5000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alert]);
  return (
    <Stack direction="column" spacing={2}>
      <Select
        error
        placeholder="Please select the filter types"
        labelId="filter-type"
        id="filterType"
        value={filterType}
        label="Filter Type"
        onChange={(event: any) => setFilterType(event.target.value)}
      >
        {FILTER_TYPES_PRODUCT_CATALOG.map((ft: any) => (
          <MenuItem value={ft.value}>{ft.name}</MenuItem>
        ))}
      </Select>

      <TextField
        sx={{ m: 2 }}
        error
        required
        id="outlined-required"
        label="Filter Name"
        value={filterName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setFilterName(event.target.value)
        }
      />
      <Autocomplete
        sx={{ ml: 2 }}
        onChange={(event: any, v: any) => {
          setFieldSelected(v);
        }}
        value={fieldSelected}
        options={fieldRecords}
        isOptionEqualToValue={
          (option: any, value: any) => option.id === value.id //&&
        }
        fullWidth
        getOptionLabel={(option: any) => `${option.technicalId} `}
        renderOption={(
          props: any,
          option: any,
          { selected }: { selected: boolean },
        ) => (
          <Box container="li" {...props} key={`${option.id}`}>
            {`${option.technicalId}`}
          </Box>
        )}
        renderInput={(params: any) => (
          <TextField
            {...params}
            variant="filled"
            placeholder="Choose field ..."
          />
        )}
      />
      <Button
        sx={{ ml: 2 }}
        onClick={addFilter}
        variant="outlined"
        startIcon={<AddIcon />}
      >
        Add filter
      </Button>
      {filtersList.length > 0 && (
        <>
          {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
          <Button
            disabled={selectedIds.length === 0}
            sx={{ m: 2 }}
            onClick={() => {
              setOpenConfirm(true);
            }}
            variant="outlined"
            startIcon={<DeleteIcon />}
          >
            Delete selection
          </Button>
          <DataGrid
            onSelectionModelChange={(ids: any) => {
              setSelectedIds(ids);
            }}
            autoHeight
            rows={filtersList}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
          />
        </>
      )}

      <ConfirmDialog
        title="Confirm deleting Filters"
        open={openConfirm}
        setOpen={setOpenConfirm}
        onConfirm={handleDeleteFilters}
      >
        <Typography>
          This action will delete the selected filters. Are you sure ?
        </Typography>
      </ConfirmDialog>
    </Stack>
  );
}
