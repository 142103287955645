import { TreeItem, TreeView } from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import apiClient from '../../../services/apiClient';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import grey from '@mui/material/colors/grey';

export interface SimpleDialogProps {
  open: boolean;
  selectedNode: any;
  onClose: (value: string) => void;
}

interface Section {
  id: number;
  parentId: number;
  title: string;
  subSections: [];
}

interface Field {
  unit: string;
  id: number | null;
  separator: string;
  parentFieldId: number | null;
  section: Section | undefined;
  technicalId: string;
  sectionRelationDate: Date;
}

export default function SectionConfig({
  fieldRecords,
  productCatalogSelected,
}: any) {
  const [sectionTitle, setSectionTitle] = useState<string>('');
  const [fieldSelected, setFieldSelected] = useState<any>(null);
  const [refreshTreeview, setRefreshTreeview] = useState(false);
  const [displayForm, setDisplayForm] = useState<any>('section');
  const [fieldType, setFieldType] = useState<any>('simple');
  const [allSections, setAllSections] = useState([]);
  const [selectedNode, setSelectedNode] = useState<Section>();
  const [selectedFieldTree, setSelectedFieldTree] = useState<Section>();
  const [unit, setUnit] = useState<string>('');
  const [separator, setSeparator] = useState<string>('');
  const [fields, setFields] = useState<string[]>([]);
  const [fieldValues, setFieldValues] = useState<Field[]>([]);

  const handleUnitChange = (event: any, index: any) => {
    const newFieldValues = fieldValues;

    newFieldValues[index].unit = event.target.value;

    setFieldValues(newFieldValues);
  };

  const handleChangeAutoComplete = (value: any, index: number) => {
    const newFieldValues = fieldValues;
    newFieldValues[index].id = value?.id;
    newFieldValues[index].technicalId = value?.technicalId;
    newFieldValues[index].sectionRelationDate = new Date();
    setFieldValues(newFieldValues);
  };

  const addField = () => {
    setFields([...fields, '']);
    if (fieldValues.length > 0) {
      fieldValues.push({
        id: null,
        unit: '',
        technicalId: '',
        separator: separator,
        parentFieldId: fieldValues[0].id,
        sectionRelationDate: new Date(),
        section: selectedNode,
      });
    } else {
      fieldValues.push({
        id: null,
        unit: '',
        technicalId: '',
        separator: separator,
        parentFieldId: null,
        sectionRelationDate: new Date(),
        section: selectedNode,
      });
    }
    setFieldValues(fieldValues);
  };
  const Field = (index: any) => {
    index = index.index;

    return (
      <Stack direction="row" spacing={1}>
        <Autocomplete
          sx={{ mb: 2, ml: 2 }}
          defaultValue={
            fieldValues[index]?.id === null ? undefined : fieldValues[index]
          }
          onChange={(event: any, v: any) => handleChangeAutoComplete(v, index)}
          options={fieldRecords}
          isOptionEqualToValue={(option: any, value: any) =>
            option.id === value.id
          }
          fullWidth
          getOptionLabel={(option: any) => `${option.technicalId} `}
          renderOption={(
            props: any,
            option: any,
            { selected }: { selected: boolean },
          ) => (
            <Box container="li" {...props} key={`${option.id}`}>
              {`${option.technicalId}`}
            </Box>
          )}
          renderInput={(params: any) => (
            <TextField
              {...params}
              variant="filled"
              placeholder="Choose field ..."
            />
          )}
        />

        <TextField
          sx={{ m: 2 }}
          error
          id="outlined-required"
          label="Unit"
          defaultValue={fieldValues[index]?.unit}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleUnitChange(event, index)
          }
        />
      </Stack>
    );
  };

  const Fields = () => {
    return (
      <div>
        {fields.map((field, index) => (
          <div key={index}>
            <Field index={index} />
          </div>
        ))}
      </div>
    );
  };

  const DataTreeView = ({ treeItems }: any) => {
    return (
      <TreeView
        aria-label="section tree"
        defaultCollapseIcon={<AddIcon style={{ color: '#e41b13' }} />}
        defaultExpandIcon={<RemoveIcon style={{ color: '#e41b13' }} />}
        sx={{ flexGrow: 1, overflowY: 'auto' }}
      >
        {getTreeItemsFromData(treeItems)}
      </TreeView>
    );
  };

  const getTreeItemsFromData = (treeItems: any) => {
    return treeItems.map((treeItemData: any) => {
      let children;
      if (treeItemData.subSections && treeItemData.subSections.length > 0) {
        children = getTreeItemsFromData(treeItemData.subSections);
      }
      const getLabelStyle = (treeItemData: any) => ({
        fontWeight: treeItemData.type === 'field' ? 'bold' : 'normal',
        borderTop:
          treeItemData.parentId === null && treeItemData.type !== 'field'
            ? 1
            : 'none',
        borderBottom: treeItemData.type !== 'field' ? 1 : 'none',
        color:
          treeItemData.type === 'field' && treeItemData.isValid === false
            ? grey[300]
            : 'black',
      });

      return (
        <TreeItem
          key={treeItemData.id}
          nodeId={treeItemData.id.toString()}
          label={
            <div
              onClick={(event) => {
                treeItemData.type === 'field'
                  ? setSelectedFieldTree(treeItemData)
                  : setSelectedNode(treeItemData);
                setRefreshTreeview(!refreshTreeview);
                event.stopPropagation();
              }}
            >
              {treeItemData.type === 'field' ? (
                <>
                  <ArrowRightIcon
                    style={{ color: '#e41b13', marginBottom: -5 }}
                  />
                  {treeItemData.title}
                </>
              ) : (
                treeItemData.title
              )}
            </div>
          }
          sx={{ '& .MuiTreeItem-label': getLabelStyle(treeItemData) }}
          disabled={false}
          children={children}
        />
      );
    });
  };

  const handleChangeMenu = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayForm(event.target.value);
  };

  const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldType(event.target.value);
  };

  const handleSubmit = (sectionType: string) => {
    const sectionData = {
      title: sectionTitle,
      productCatalogId: productCatalogSelected?.id,
      parentId: sectionType === 'section' ? null : selectedNode,
    };
    apiClient.post('/section', sectionData).then((result) => {
      setRefreshTreeview(!refreshTreeview);
      setSectionTitle('');
    });
  };

  const deleteSection = () => {
    apiClient
      .delete(`/section/${selectedNode?.id}`)
      .then((res) => {
        setSelectedNode(undefined);
        setRefreshTreeview(!refreshTreeview);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteField = () => {
    const regex = new RegExp('field', 'gi');
    const matches = selectedFieldTree?.title.match(regex);
    const count = matches ? matches.length : 0;
    let fieldData = [
      {
        section: null,
        unit: unit,
        id: selectedFieldTree?.id,
      },
    ];
    if (count === 1) {
      apiClient.post('/field', fieldData).then((result) => {
        setSelectedFieldTree(undefined);
        setRefreshTreeview(!refreshTreeview);
      });
    } else {
      apiClient
        .get('/field', {
          params: {
            parentFieldId: selectedFieldTree?.id,
          },
        })
        .then((res) => {
          res.data.record.map((r: any) => {
            return fieldData.push({
              section: null,
              unit: unit,
              id: r?.id,
            });
          });
          apiClient.post('/field', fieldData).then((result) => {
            setSelectedFieldTree(undefined);
            setRefreshTreeview(!refreshTreeview);
          });
        });
    }
  };

  const handleFieldSubmit = () => {
    const fieldData = [
      {
        section: selectedNode,
        unit: unit,
        id: fieldSelected?.id,
        sectionRelationDate: new Date(),
      },
    ];
    apiClient.post('/field', fieldData).then((result) => {
      setUnit('');
      setFieldSelected(null);
      setRefreshTreeview(!refreshTreeview);
    });
  };

  const handleConcatenatedSubmit = () => {
    apiClient.post('/field', fieldValues).then((result) => {
      setFieldValues([]);
      setSeparator('');
      setFields([]);
      setRefreshTreeview(!refreshTreeview);
    });
  };

  useEffect(() => {
    if (productCatalogSelected) {
      apiClient
        .get(`/section/treeView/${productCatalogSelected?.id}`)
        .then((res) => {
          setAllSections(res.data);
        });
    }
  }, [productCatalogSelected, refreshTreeview]);

  return (
    <>
      <FormControl
        sx={{
          p: 2,
          m: 2,
          border: 1,
          borderRadius: 2,
          boxShadow: 1,
          borderColor: (theme) => theme.palette.primary.main,
        }}
      >
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="section"
          name="radio-buttons-group"
          value={displayForm}
          onChange={handleChangeMenu}
        >
          <FormControlLabel
            value="section"
            control={<Radio />}
            label="Add Section"
          />
          {selectedNode && (
            <>
              <FormControlLabel
                value="subSection"
                control={<Radio />}
                label="Add Sub section"
              />
              <FormControlLabel
                value="field"
                control={<Radio />}
                label="Add Fields"
              />
            </>
          )}

          <FormControlLabel
            value="deleteSection"
            control={<Radio />}
            label="Delete Section"
          />
          <FormControlLabel
            value="deleteField"
            control={<Radio />}
            label="Delete Field"
          />
        </RadioGroup>
      </FormControl>
      {displayForm === 'section' && (
        <>
          <Grid item xs={12}>
            <TextField
              sx={{ m: 2 }}
              error
              required
              id="outlined-required"
              label="Section Title"
              value={sectionTitle}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSectionTitle(event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            {' '}
            <Button
              sx={{ m: 2 }}
              variant="contained"
              onClick={() => handleSubmit('section')}
            >
              Add Section
            </Button>
          </Grid>
        </>
      )}

      {displayForm === 'subSection' && (
        <>
          <Grid item xs={12}>
            <Box sx={{ m: 2, display: 'flex' }}>
              {selectedNode ? (
                <>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Selected Node:{' '}
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 2 }}>
                    {selectedNode?.title}
                  </Typography>
                </>
              ) : (
                <Alert severity="error">
                  No node is selected, Please select a node before
                </Alert>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{ m: 2 }}
              error
              required
              id="outlined-required"
              label="Sub Section Title"
              value={sectionTitle}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setSectionTitle(event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            {' '}
            <Button
              sx={{ m: 2 }}
              variant="contained"
              onClick={() => handleSubmit('subSection')}
            >
              Add Sub Section
            </Button>
          </Grid>
        </>
      )}

      {displayForm === 'field' && (
        <>
          <Grid item xs={12}>
            <Box sx={{ m: 2, display: 'flex' }}>
              {selectedNode ? (
                <>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Selected Node:{' '}
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 2 }}>
                    {selectedNode?.title}
                  </Typography>
                </>
              ) : (
                <Alert severity="error">
                  No node is selected, Please select a node before
                </Alert>
              )}
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ m: 2 }}>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="section"
                name="radio-buttons-group"
                value={fieldType}
                onChange={handleChangeField}
              >
                <FormControlLabel
                  value="simple"
                  control={<Radio />}
                  label="Simple"
                />
                <FormControlLabel
                  value="concatenated"
                  control={<Radio />}
                  label="Concatenated"
                />
              </RadioGroup>
            </FormControl>
            {fieldType === 'simple' && (
              <>
                <Stack direction="column" spacing={3} width={500}>
                  <Stack direction="row" spacing={3}>
                    <Autocomplete
                      sx={{ ml: 2 }}
                      onChange={(event: any, v: any) => {
                        setFieldSelected(v);
                      }}
                      value={fieldSelected}
                      options={fieldRecords}
                      isOptionEqualToValue={
                        (option: any, value: any) => option.id === value.id //&&
                      }
                      fullWidth
                      getOptionLabel={(option: any) => `${option.technicalId} `}
                      renderOption={(
                        props: any,
                        option: any,
                        { selected }: { selected: boolean },
                      ) => (
                        <Box container="li" {...props} key={`${option.id}`}>
                          {`${option.technicalId}`}
                        </Box>
                      )}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder="Choose field ..."
                        />
                      )}
                    />

                    <TextField
                      error
                      required
                      id="outlined-required"
                      label="Unit"
                      value={unit}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setUnit(event.target.value)
                      }
                    />
                  </Stack>
                  <Box display="flex" justifyContent="center">
                    <Button
                      sx={{ m: 2 }}
                      variant="contained"
                      onClick={handleFieldSubmit}
                    >
                      Save Field
                    </Button>
                  </Box>
                </Stack>
              </>
            )}
            {fieldType === 'concatenated' && (
              <>
                <Stack direction="column" spacing={3} width={500}>
                  <Stack direction="row" spacing={3}>
                    <TextField
                      sx={{ m: 2 }}
                      error
                      required
                      id="outlined-required"
                      label="Separator"
                      value={separator}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setSeparator(event.target.value)
                      }
                    />
                    <IconButton
                      aria-label="add"
                      onClick={addField}
                      disabled={separator === ''}
                    >
                      <AddIcon />
                    </IconButton>
                  </Stack>
                  <Fields />
                  {fieldValues.length > 0 && separator && (
                    <Box display="flex" justifyContent="center">
                      <Button
                        variant="contained"
                        onClick={handleConcatenatedSubmit}
                      >
                        Save fields
                      </Button>
                    </Box>
                  )}
                </Stack>
              </>
            )}
          </Grid>
        </>
      )}

      {displayForm === 'deleteSection' && (
        <>
          <Grid item xs={12}>
            <Box sx={{ m: 2, display: 'flex' }}>
              {selectedNode ? (
                <>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Selected Section:{' '}
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 2 }}>
                    {selectedNode?.title}
                  </Typography>
                </>
              ) : (
                <Alert severity="error">
                  No section is selected, Please select a node before
                </Alert>
              )}
            </Box>
          </Grid>{' '}
          <Grid item xs={4}>
            <Button sx={{ m: 2 }} variant="contained" onClick={deleteSection}>
              Delete Selected Section
            </Button>
          </Grid>
        </>
      )}
      {displayForm === 'deleteField' && (
        <>
          <Grid item xs={12}>
            <Box sx={{ m: 2, display: 'flex' }}>
              {selectedFieldTree ? (
                <>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    Selected Field:{' '}
                  </Typography>
                  <Typography variant="body1" sx={{ ml: 2 }}>
                    {selectedFieldTree?.title}
                  </Typography>
                </>
              ) : (
                <Alert severity="error">
                  No field is selected, Please select a field before
                </Alert>
              )}
            </Box>
          </Grid>{' '}
          <Grid item xs={4}>
            <Button sx={{ m: 2 }} variant="contained" onClick={deleteField}>
              Delete Selected Field
            </Button>
          </Grid>
        </>
      )}
      <Divider sx={{ mt: 2 }}></Divider>
      <Stack direction="column">
        <Typography variant="subtitle1" sx={{ mt: 2, fontWeight: 'bold' }}>
          The UI configuration schema
        </Typography>
        <Grid item xs={12} sx={{ m: 2, p: 2, display: 'align-center' }}>
          {allSections.length === 0 && (
            <Alert severity="error" sx={{ mt: -1 }}>
              {' '}
              The tree sections is empty, please create sections
            </Alert>
          )}
          {allSections.length > 0 && <DataTreeView treeItems={allSections} />}
        </Grid>
      </Stack>
    </>
  );
}
