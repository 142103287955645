import Chip from '@mui/material/Chip';
import { PRODUCT_CATALOG_STATUSES } from '../../../../data';

interface ProductCatalogStatusProps {
  value?: number;
}

export default function ProductCatalogStatus({
  value,
}: ProductCatalogStatusProps) {
  const status = PRODUCT_CATALOG_STATUSES.find(
    (status: any) => status.value === value,
  );
  return <Chip color={status?.color} label={status?.name} />;
}
