import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router';

interface ContentBoxProps {
  content: string;
  highlight?: boolean;
}

function ContentBox({ content, highlight = false }: ContentBoxProps) {
  const [tooltip, setTooltip] = useState<string>('');
  const navigate = useNavigate();

  const handleContentHover = (event: any) => {
    setTooltip(
      event.target.dataset.isAcronym ? event.target.getAttribute('title') : '',
    );
  };

  const handleContentClick = (event: any) => {
    if (event.target.dataset.isSupplier) {
      event.preventDefault();
      navigate(`/suppliers/${event.target.dataset.supplierId}`);
    }
    if (event.target.dataset.isTopic) {
      event.preventDefault();
      navigate(`/domains/updates/topics/${event.target.dataset.topicId}`);
    }
  };

  return (
    <Tooltip title={tooltip} arrow followCursor>
      <Box
        dangerouslySetInnerHTML={{
          __html: content,
        }}
        onMouseMove={handleContentHover}
        onClick={handleContentClick}
        sx={(theme) => ({
          '& p': {
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
          '& ul': {
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
          '& ol': {
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
          '& h1': {
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
          '& h2': {
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
          '& h3': {
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
          '& h4': {
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
          '& h5': {
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
          '& h6': {
            marginBlockStart: 0,
            marginBlockEnd: 0,
          },
          '& p:has(img)': { textAlign: 'center' },
          '& a': { color: theme.palette.primary.main },
          '& span[data-is-acronym]': {
            color: theme.palette.primary.main,
            textDecoration: 'underline',
          },
          '& .highlight-important': highlight
            ? {
                backgroundColor: '#feff24',
              }
            : null,
        })}
      />
    </Tooltip>
  );
}

export default ContentBox;
