import Chip from '@mui/material/Chip';
import { NEGOTIATION_STATUSES } from '../../../../data';

export default function NegotiationStatus({ value }: { value?: number }) {
  if (value !== 0 && !value) return null;

  const status = NEGOTIATION_STATUSES.find((status) => status.value === value);

  if (!status) {
    console.error(`MBI:  The \`value\` provided to the NegotiationStatus component is invalid.
      You can provide one of the following values: ${NEGOTIATION_STATUSES.map(
        (s) => s.value,
      ).join(', ')}`);
    return null;
  }

  return (
    <Chip sx={{ minWidth: 83 }} color={status.color} label={status.name} />
  );
}
