import {
  CardActionArea,
  styled,
  Card,
  CardContent,
  Typography,
  Box,
  Tooltip,
} from '@mui/material';

export function ToolCard({ label, icon, color, onClick }: any) {
  const StyledCardActionArea = styled(CardActionArea)(() => ({
    height: 140,
    width: 140,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '12px',
    cursor: 'pointer',
  }));

  const StyledCard = styled(Card)(({ theme }) => ({
    borderRadius: '12px',
    maxWidth: 140,
    maxHeight: 140,
    border: 'none',
    boxShadow: 'none',
    margin: theme.spacing(2),
    marginTop: 0,
  }));

  const StyledTypography = styled(Typography)(() => ({
    color: 'white',
    textAlign: 'center',
    fontSize: '12px',
    p: 2,
    whiteSpace: 'pre-wrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }));

  return (
    <StyledCard>
      <StyledCardActionArea
        sx={{ backgroundColor: (theme) => color || theme.palette.primary.main }}
        onClick={() => onClick()}
      >
        <CardContent>
          <Box
            sx={{
              height: 70,
              width: 140,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              sx={{
                color: 'white',
                textAlign: 'center',
                fontSize: '12px',
                p: 4,
              }}
            >
              {icon}
            </Typography>
          </Box>
          <Box
            sx={{
              height: 70,
              width: 140,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Tooltip title={label}>
              <StyledTypography pl={2} pr={2}>
                {label}
              </StyledTypography>
            </Tooltip>
          </Box>
        </CardContent>
      </StyledCardActionArea>
    </StyledCard>
  );
}
