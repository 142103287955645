export default function UpdatesIcon({
  className = 'buyin-icon',
}: {
  className?: string;
}) {
  return (
    <svg
      className={className}
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Icon / ic_updates Copy</title>
      <g
        id="Icon-/-ic_updates-Copy"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M1.33333333,3.71428571 L1.33333333,3.71428571 L1.33333333,3.71428571 L1.33333333,13.1428571 L0.5,13.1428571 L0.5,4.54761905 C0.5,4.08738176 0.873096042,3.71428571 1.33333333,3.71428571 Z"
          id="Rectangle-11"
          fill="#FFFFFF"
        ></path>
        <path
          d="M14.6666667,3.71428571 C15.126904,3.71428571 15.5,4.08738176 15.5,4.54761905 L15.5,13.1428571 L15.5,13.1428571 L14.6666667,13.1428571 L14.6666667,3.71428571 Z"
          id="Rectangle-11"
          fill="#FFFFFF"
        ></path>
        <path
          d="M12.1666667,2 C13.0871412,2 13.8333333,2.74619208 13.8333333,3.66666667 L13.8333333,14 L2.16666667,14 L2.16666667,3.66666667 C2.16666667,2.74619208 2.91285875,2 3.83333333,2 L12.1666667,2 Z M12.1666667,10.5714286 L3.83333333,10.5714286 L3.83333333,11.4285714 L12.1666667,11.4285714 L12.1666667,10.5714286 Z M12.1666667,8.85714286 L3.83333333,8.85714286 L3.83333333,9.71428571 L12.1666667,9.71428571 L12.1666667,8.85714286 Z M12.1666667,4.57142857 L3.83333333,4.57142857 L3.83333333,7.14285714 L12.1666667,7.14285714 L12.1666667,4.57142857 Z"
          id="Combined-Shape"
          fill="#FFFFFF"
        ></path>
      </g>
    </svg>
  );
}
