import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Avatar, Grid, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import apiClient from '../../../../services/apiClient';
import { downloadBlocker } from '../SummitBooking';

const validationSchema = yup.object().shape({
  questions: yup.string().required('Questions are required'),
  expectations: yup.string().required('Expectations are required'),
});

export default function EnrolDialog({
  open,
  session,
  onClose,
  onSave,
}: {
  open: boolean;
  session: any;
  onClose: () => void;
  onSave: () => void;
}) {
  return (
    <div>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>{session?.title}</DialogTitle>
        <Formik
          validationSchema={validationSchema}
          initialValues={{
            questions: session?.questions || '',
            expectations: session?.expectations || '',
          }}
          onSubmit={(
            values: any,
            {
              setSubmitting,
              resetForm,
            }: { setSubmitting: any; resetForm: any },
          ) => {
            setSubmitting(true);
            const enrollment = {
              ...values,
              session: { id: session.id },
            };
            apiClient
              .post('/enrollments', enrollment)
              .then((res: any) => {
                onSave();
                resetForm();
                downloadBlocker(session.id);
              })
              .catch((error) => {
                console.log(error);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ errors, touched, handleSubmit, submitForm, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <DialogContent dividers>
                <DialogContentText>
                  <Grid container>
                    <Grid item sm={12}>
                      <Typography
                        sx={{
                          color: 'text.secondary',
                          flexShrink: 0,
                        }}
                        variant="body2"
                      >
                        {moment(session?.date).format('DD-MM-YYYY')} at{' '}
                        {moment(session?.date).format('HH:MM')}
                      </Typography>
                    </Grid>

                    <Grid item sm={12}>
                      <Typography variant="subtitle1">
                        {session?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                </DialogContentText>
                <Field name="questions">
                  {({ field }: { field: any }) => (
                    <TextField
                      {...field}
                      margin="dense"
                      variant="filled"
                      multiline
                      helperText={
                        Boolean(touched.questions) && errors.questions
                      }
                      error={Boolean(errors.questions && touched.questions)}
                      label={'Questions'}
                      type="text"
                      disabled={!!session.questions}
                      fullWidth
                    />
                  )}
                </Field>
                <Field name="expectations">
                  {({ field }: { field: any }) => (
                    <TextField
                      {...field}
                      margin="dense"
                      variant="filled"
                      multiline
                      helperText={
                        Boolean(touched.expectations) && errors.expectations
                      }
                      error={Boolean(
                        errors.expectations && touched.expectations,
                      )}
                      label={'Expectations'}
                      type="text"
                      fullWidth
                      disabled={!!session.expectations}
                    />
                  )}
                </Field>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={3}
                  paddingY={2}
                >
                  {session?.speakers?.map((speaker: any) => (
                    <React.Fragment key={speaker.id}>
                      <Avatar
                        alt={`${speaker.firstName} ${speaker.lastName}`}
                        src={
                          speaker.isContact &&
                          `${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${speaker.email}`
                        }
                        sx={{ width: 87, height: 87 }}
                      >
                        {!speaker.isContact &&
                          `${speaker.firstName[0]}${speaker.lastName[0]}`.toUpperCase()}
                      </Avatar>
                      <Typography>
                        {speaker.firstName} {speaker.lastName}
                        {speaker.isContact && (
                          <>
                            <br /> {speaker.email}
                          </>
                        )}
                        <br />
                        {speaker.fixed}
                      </Typography>
                    </React.Fragment>
                  ))}
                </Stack>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>Close</Button>
                {!session.questions && (
                  <LoadingButton
                    onClick={() => submitForm()}
                    variant="contained"
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    loading={isSubmitting}
                  >
                    Enrol
                  </LoadingButton>
                )}
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
}
