import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertColor,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useMatch, useNavigate, useSearchParams } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import CircularProgress from '@mui/material/CircularProgress';
import apiClient from '../../../services/apiClient';
import ProductDetail from './ProductDetail';
import ProductCatalogFilters from './ProductCatalogFilters';
import ComparaisonPage from './ComparaisonPage';
import ProductTableView from './ProductTableView';
import { iconOptions } from './ProductView';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function ProductCatalogPage() {
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [productData, setProductData] = useState<any[]>([]);
  const [filtersData, setFiltersData] = useState<any[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [buttonStates, setButtonStates] = useState<any[]>([]);
  const [filters, setFilters] = useState<any[]>([]);
  const [open, setOpen] = useState<boolean>(true);
  const [isProductView, setIsProductView] = useState<boolean>(true);

  const [isComparaisonPage, setIsComparaisonPage] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page') || 1);
  const perPage = Number(searchParams.get('perPage') || 12);
  const [count, setCount] = useState(0);
  const match = useMatch(`/domains/tools/productCatalog/:pcId`);
  const [alert, setAlert] = useState<{
    severity: AlertColor | undefined;
    message: string;
  }>();
  const [productCatalogId] = useState<number | null | undefined>(
    Number(match?.params.pcId) || null,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingFilters, setIsLoadingFilters] = useState(true);

  const [productId, setProductId] = useState<number | null | undefined>(null);
  const [technicalProductId, setTechnicalProductId] = useState<
    number | null | undefined
  >(null);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoadingFilters(true);

    apiClient
      .post(`/product/headers/${productCatalogId}`, filters, {
        params: {
          perPage: '',
          page: '',
          isFilters: true,
          isTableView: false,
        },
      })
      .then((res: any) => {
        setFiltersData(res.data);
        setIsLoadingFilters(false);
      });
  }, [productCatalogId, filters]);
  useEffect(() => {
    setIsLoading(true);

    apiClient
      .post(`/product/headers/${productCatalogId}`, filters, {
        params: {
          perPage: perPage,
          page: page,
          isFilters: false,
          isTableVew: false,
        },
      })
      .then((res: any) => {
        setProductData(res.data.productHeaders);
        const uniqueProducts = res.data.productHeaders.reduce(
          (acc: any, cur: any) => {
            if (
              !acc.some(
                (item: any) =>
                  item.id === cur.id && item.productName === cur.productName,
              )
            ) {
              acc.push({
                id: cur.id,
                productName: cur.productName,
                imageReference: cur.imageReference,
                technicalId: cur.technicalId,
                icon: cur.icon,
                name: cur.name,
                value: cur.value,
              });
            }
            return acc;
          },
          [],
        );
        setAllProducts(uniqueProducts);
        setIsLoading(false);
        setCount(res.data.total);
      });
  }, [page, perPage, productCatalogId, filters]);

  const handleProductDialogClose = () => {
    setOpen(false);
  };
  const handleProductSelect = (product: any) => {
    setOpen(true);
    setProductId(product.id);
    setTechnicalProductId(product.technicalId);
  };
  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number,
  ) => {
    if (newPage >= 1) {
      searchParams.set('page', newPage.toString());
    } else {
      searchParams.delete('page');
    }
    setSearchParams(searchParams);
  };
  const handleFiltersChange = (filters: any) => {
    setFilters(filters);
  };

  const toggleButtonState = (productId: number) => {
    setButtonStates((prevState) => ({
      ...prevState,
      [productId]: !prevState[productId] || false,
    }));
  };

  const handleProductSelectCompare = (productId: number) => {
    toggleButtonState(productId);
    setButtonStates((prevState) => {
      if (prevState[productId]) {
        setSelectedProducts([...selectedProducts, productId]);
      } else {
        setSelectedProducts(selectedProducts.filter((id) => id !== productId));
      }

      return prevState;
    });
  };

  const getAvatarCSS = () => {
    return {
      width: 'unset',
      height: 'unset',
      textAlign: 'center',
      objectFit: 'cover',
      color: 'transparent',
      textIndent: '10000px',
      maxWidth: '90px',
      maxHeight: '90px',
    };
  };
  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(undefined);
      }, 3000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [alert]);
  return (
    <>
      <Stack direction="column" spacing={2}>
        {isComparaisonPage && (
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: 'flex-end', pr: 2, mt: 2 }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setIsComparaisonPage(false);
              }}
            >
              Change selection
            </Button>
          </Stack>
        )}
        {!isComparaisonPage && (
          <>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                {' '}
                <IconButton
                  size="small"
                  onClick={() => {
                    navigate({
                      pathname: `/domains/tools`,
                    });
                  }}
                  sx={{
                    ml: 2,
                    mt: 2,
                    color: 'primary.main',
                    '&:hover': {
                      color: 'primary.dark',
                    },
                  }}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Box>
              <Box>
                {' '}
                <Button
                  // disabled={buttonStates[product.id]}
                  variant="contained"
                  color="primary"
                  size="small"
                  sx={{ mr: 2, mt: 2 }}
                  onClick={() => {
                    setSelectedProducts([]);
                    setButtonStates([]);
                  }}
                  disabled={selectedProducts.length<=0}
                >
                  Deselect all products
                </Button>
                <Button
                  // disabled={buttonStates[product.id]}
                  variant="contained"
                  color="success"
                  size="small"
                  sx={{ mr: 2, mt: 2 }}
                  onClick={() => {
                    selectedProducts.length <= 4
                      ? setIsComparaisonPage(true)
                      : setAlert({
                          severity: 'error',
                          message:
                            'You cannot compare more than 4 products at once!',
                        });
                  }}
                  disabled={selectedProducts.length<=0}
                >
                  Compare Selected Products ({selectedProducts.length})
                </Button>
              </Box>
            </Box>
            {alert && <Alert severity={alert.severity}>{alert.message}</Alert>}
            <ProductCatalogFilters
              productCatalogId={productCatalogId}
              filtersData={filtersData}
              onFiltersChange={handleFiltersChange}
              filters={filters}
              isLoadingFilters={isLoadingFilters}
            />
            {isProductView && (
              <>
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={2}
                  p={2}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item>
                      <Box display="flex" justifyContent="flex-end">
                        <ButtonGroup>
                          <Button
                            variant={isProductView ? 'contained' : 'outlined'}
                            onClick={() => setIsProductView(true)}
                          >
                            Product view
                          </Button>
                          <Button
                            variant={!isProductView ? 'contained' : 'outlined'}
                            onClick={() => setIsProductView(false)}
                          >
                            Table view
                          </Button>
                        </ButtonGroup>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Pagination
                        count={Math.ceil(count / perPage)}
                        page={page}
                        onChange={handlePageChange}
                      />
                    </Grid>
                  </Grid>
                </Stack>
                <Paper elevation={3} sx={{ backgroundColor: grey[200] }}>
                  {isLoading && (
                    <Grid container justifyContent="center" alignItems="center">
                      <CircularProgress sx={{ m: 2 }} />
                    </Grid>
                  )}
                  {!isLoading && (
                    <>
                      <Grid container>
                        {allProducts.map((product: any, index) => (
                          <>
                            {(index % 3 === 0 || index === 0) && (
                              <Grid item padding={0.5} sm={3}>
                                <Card
                                  key={index}
                                  sx={{
                                    height: '98%',
                                    backgroundColor: grey[200],
                                  }}
                                >
                                  <CardContent>
                                    <Box sx={{ height: 140 }}>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}
                                      ></Box>
                                      <Box
                                        sx={{
                                          typography: 'caption',
                                          textTransform: 'uppercase',
                                          fontWeight: '500',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          mt: 11.5,
                                        }}
                                      >
                                        MODEL NAME
                                      </Box>
                                      <Box sx={{ p: 1 }}>
                                        <Divider sx={{ background: 'red' }} />
                                      </Box>
                                    </Box>
                                    {productData
                                      ?.filter((pd) => pd.id === product.id)
                                      .map((p) => (
                                        <Stack direction="row" spacing={1}>
                                          {iconOptions.map(
                                            (iconO: any, index: any) => (
                                              <>
                                                {index === p.icon && (
                                                  <Box key={index}>{iconO}</Box>
                                                )}
                                              </>
                                            ),
                                          )}
                                          <Typography
                                            sx={{ fontWeight: 'bold' }}
                                            variant="caption"
                                          >
                                            {p.name.charAt(0).toUpperCase() +
                                              p.name.slice(1)}
                                          </Typography>
                                        </Stack>
                                      ))}
                                  </CardContent>
                                </Card>
                              </Grid>
                            )}
                            <Grid item key={product.id} padding={0.5} sm={3}>
                              <Card
                                key={index}
                                sx={{
                                  height: '98%',
                                  border: '1px solid lightgrey',
                                }}
                              >
                                <CardContent>
                                  <Box sx={{ height: 140 }}>
                                    <Box sx={{ height: '80%' }}>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          height: '79%',
                                        }}
                                      >
                                        <Avatar
                                          src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/product/images/${productCatalogId}/${product.imageReference}`}
                                          sx={{
                                            width: 90,
                                            height: 'unset!important',
                                            objectFit: 'cover',
                                            '& .MuiAvatar-img': getAvatarCSS(),
                                            cursor: 'pointer',
                                          }}
                                          variant="rounded"
                                          onClick={() =>
                                            handleProductSelect(product)
                                          }
                                        />
                                      </Box>
                                      <Tooltip
                                        title={product.productName}
                                        placement="bottom-end"
                                      >
                                        <Box
                                          sx={{
                                            typography: 'caption',
                                            textTransform: 'uppercase',
                                            fontWeight: '500',
                                            display: '-webkit-box',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            WebkitLineClamp: '1',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                            textAlign: 'center',
                                          }}
                                          onClick={() =>
                                            handleProductSelect(product)
                                          }
                                        >
                                          {product.productName}
                                        </Box>
                                      </Tooltip>
                                    </Box>
                                    <Box sx={{ p: 1 }}>
                                      <Divider sx={{ background: 'red' }} />
                                    </Box>
                                  </Box>
                                  {productData
                                    ?.filter((pd) => pd.id === product.id)
                                    .map((p) => (
                                      <Stack direction="row" spacing={1}>
                                        {iconOptions.map(
                                          (iconO: any, index: any) => (
                                            <>
                                              {index === p.icon && (
                                                <Box key={index}>{iconO}</Box>
                                              )}
                                            </>
                                          ),
                                        )}
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            display: '-webkit-box',
                                            WebkitLineClamp: '1',
                                            WebkitBoxOrient: 'vertical',
                                            overflow: 'hidden',
                                          }}
                                        >
                                          {p.value ? p.value : ''}
                                        </Typography>
                                      </Stack>
                                    ))}
                                  <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                  >
                                    <Button
                                      // disabled={buttonStates[product.id]}
                                      variant="contained"
                                      color={
                                        buttonStates[product.id]
                                          ? 'success'
                                          : 'primary'
                                      }
                                      size="small"
                                      onClick={() =>
                                        handleProductSelectCompare(product.id)
                                      }
                                      sx={{ mt: 2 }}
                                    >
                                      Select Product
                                    </Button>
                                  </Grid>
                                </CardContent>
                              </Card>
                            </Grid>
                          </>
                        ))}
                      </Grid>
                      <Grid
                        container
                        sx={{
                          justifyContent: 'flex-end',
                          backgroundColor: 'white',
                        }}
                      >
                        <Grid item sx={{ m: 2 }}>
                          <Pagination
                            count={Math.ceil(count / perPage)}
                            page={page}
                            onChange={handlePageChange}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Paper>
              </>
            )}
            {!isProductView && (
              <>
                <Box display="flex" justifyContent="flex-start">
                  <ButtonGroup sx={{ m: 2 }}>
                    <Button
                      variant={isProductView ? 'contained' : 'outlined'}
                      onClick={() => setIsProductView(true)}
                    >
                      Product view
                    </Button>
                    <Button
                      variant={!isProductView ? 'contained' : 'outlined'}
                      onClick={() => setIsProductView(false)}
                    >
                      Table view
                    </Button>
                  </ButtonGroup>
                </Box>

                <ProductTableView
                  filters={filters}
                  buttonStates={buttonStates}
                  handleProductSelectCompare={handleProductSelectCompare}
                  productCatalogId={productCatalogId}
                ></ProductTableView>
              </>
            )}
          </>
        )}

        {isComparaisonPage && (
          <ComparaisonPage selectedProducts={selectedProducts} />
        )}
      </Stack>
      {productId && (
        <ProductDetail
          handleProductDialogClose={handleProductDialogClose}
          productId={productId}
          open={open}
        />
      )}
    </>
  );
}
