import { Box, Button, Paper, Typography } from '@mui/material';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import apiClient from '../../../../services/apiClient';
import { useFilters } from '../../../Layout/NavigationFiltersProvider/NavigationFiltersProvider';

interface Report {
  reportId: string;
  settings: models.ISettings;
  filters: models.IFilter[];
}

interface Spend {
  record: Report;
  embedConfiguration: {
    token: string;
    url: string;
  };
}

export default function SpendFeed({
  title,
  page,
  height,
}: {
  title: string;
  page: string;
  height: string;
}) {
  const useStyles = makeStyles({
    embedContainer: {
      height: '100%',
      '& iframe': {
        border: 'none',
      },
    },
  });

  const classes = useStyles();
  const [searchParams] = useSearchParams();

  const {
    domains,
    categories,
    subcategories,
    groups,
    regions,
    natcos,
    resetTrigger,
    isReady,
  } = useFilters();

  const [spend, setSpend] = useState<Spend | null>(null);
  const navigate = useNavigate();
  const spendId = 1;

  const [domainFilter, setDomainFilter] = useState<any>({
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'Dim Domain',
      column: 'DomainID',
    },
    operator: 'NotIn',
    values: [null],
  });

  const [categoryFilter, setCategoryFilter] = useState<any>({
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'BUYIN GlobalCategory',
      column: 'GlobalCategoryID',
    },
    operator: 'NotIn',
    values: [null],
  });

  const [subcategoryFilter, setSubcategoryFilter] = useState<any>({
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'BUYIN ProductCategory',
      column: 'ProductCategoryID',
    },
    operator: 'NotIn',
    values: [null],
  });

  const [groupFilter, setGroupFilter] = useState<any>({
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'BUYIN Groups',
      column: 'GroupsID',
    },
    operator: 'NotIn',
    values: [null],
  });

  const [regionFilter, setRegionFilter] = useState<any>({
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'BUYIN Region',
      column: 'RegionID',
    },
    operator: regions?.length > 0 ? 'In' : 'NotIn',
    values: regions?.length > 0 ? regions.map((c) => Number(c)) : [null],
  });

  const [natcoFilter, setNatcoFilter] = useState<any>({
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: 'BUYIN Natco',
      column: 'NatCoID',
    },
    operator: natcos?.length > 0 ? 'In' : 'NotIn',
    values: natcos?.length > 0 ? natcos.map((c) => Number(c)) : [null],
  });

  useEffect(() => {
    apiClient
      .get(`/spends/${spendId}`, { params: { hasEmbedConfiguration: true } })
      .then(({ data }) => {
        setSpend(data);
      });
  }, [searchParams]);

  useEffect(() => {
    if (resetTrigger) {
      setDomainFilter({
        ...domainFilter,
        values: [null],
        operator: 'NotIn',
      });
      setCategoryFilter({
        ...categoryFilter,
        values: [null],
        operator: 'NotIn',
      });

      setSubcategoryFilter({
        ...subcategoryFilter,
        values: [null],
        operator: 'NotIn',
      });

      setGroupFilter({
        ...groupFilter,
        values: [null],
        operator: 'NotIn',
      });

      setRegionFilter({
        ...regionFilter,
        values: [null],
        operator: 'NotIn',
      });

      setNatcoFilter({
        ...natcoFilter,
        values: [null],
        operator: 'NotIn',
      });
    }
  }, [resetTrigger]);

  useEffect(() => {
    if (isReady) {
      setDomainFilter({
        ...domainFilter,
        operator: domains?.length > 0 ? 'In' : 'NotIn',
        values: domains?.length > 0 ? domains.map((c) => Number(c)) : [null],
      });

      setCategoryFilter({
        ...categoryFilter,
        operator: categories?.length > 0 ? 'In' : 'NotIn',
        values:
          categories?.length > 0 ? categories.map((c) => Number(c)) : [null],
      });

      setSubcategoryFilter({
        ...subcategoryFilter,
        operator: subcategories?.length > 0 ? 'In' : 'NotIn',
        values:
          subcategories?.length > 0
            ? subcategories.map((c) => Number(c))
            : [null],
      });

      setGroupFilter({
        ...groupFilter,
        operator: groups?.length > 0 ? 'In' : 'NotIn',
        values: groups?.length > 0 ? groups.map((c) => Number(c)) : [null],
      });

      setRegionFilter({
        ...regionFilter,
        operator: regions?.length > 0 ? 'In' : 'NotIn',
        values: regions?.length > 0 ? regions.map((c) => Number(c)) : [null],
      });

      setNatcoFilter({
        ...natcoFilter,
        operator: natcos?.length > 0 ? 'In' : 'NotIn',
        values: natcos?.length > 0 ? natcos.map((c) => Number(c)) : [null],
      });
    }
  }, [domains, categories, subcategories, groups, regions, natcos, isReady]);

  return (
    <React.Fragment>
      <Paper elevation={3}>
        <Typography
          sx={{ p: 1.5, color: 'white', backgroundColor: 'primary.main' }}
        >
          {title}
        </Typography>
        <Box sx={{ height: height, width: '100%' }}>
          <PowerBIEmbed
            embedConfig={{
              accessToken: spend?.embedConfiguration.token,
              embedUrl: spend?.embedConfiguration.url,
              id: spend?.record.reportId,
              tokenType: models.TokenType.Embed,
              type: 'report',
              settings: {
                hyperlinkClickBehavior:
                  models.HyperlinkClickBehavior.RaiseEvent,
                background: models.BackgroundType.Transparent,
                layoutType: models.LayoutType.Custom,
                customLayout: {
                  displayOption: models.DisplayOption.FitToPage,
                },
                navContentPaneEnabled: false,
                ...(spend?.record.settings || {}),
              },
              filters: [
                domainFilter,
                categoryFilter,
                subcategoryFilter,
                groupFilter,
                regionFilter,
                natcoFilter,
              ],
              pageName: page,
            }}
            cssClassName={classes.embedContainer}
          />
        </Box>
        <Box textAlign="right">
          <Button
            sx={{ m: 0.5 }}
            onClick={() => navigate('/supplier-spend-financials')}
          >
            View all
          </Button>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
