import { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentIcon from '@mui/icons-material/Attachment';
import ShareIcon from '@mui/icons-material/Share';
import CloseIcon from '@mui/icons-material/Close';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import newsClient from '../../../services/newsClient';
import PostSkeleton from '../PostSkeleton/PostSkeleton';
import { BuyinNews } from '../types';
import { POST_TYPES } from '../data';
import { makeStyles } from '@mui/styles';

interface BuyinNewsShowProps {
  id: number | null;
  onClose: () => void;
}

export default function BuyinNewsShow({ id, onClose }: BuyinNewsShowProps) {
  const [post, setPost] = useState<BuyinNews>();
  const [likes, setLikes] = useState<number>(0);
  const [isLiked, setIsLiked] = useState<boolean>(false);

  const useStyles = makeStyles(() => ({
    backDrop: {
      backdropFilter: 'blur(3px)',
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    newsClient
      .post('posts', {
        post_type: [POST_TYPES.BUYIN_NEWS],
        id: [id],
      })
      .then((response) => {
        setPost(response.data);
        setLikes(response.data.likes);
        setIsLiked(response.data.is_liked);
      })
      .catch((error) => {
        // TODO: handle errors
        console.log(error);
      });
  }, [id]);

  const handleLikeClick = () => {
    setIsLiked(!isLiked);
    setLikes(isLiked ? likes - 1 : likes + 1);
    newsClient.post(`post-like/${id}`);
  };

  return (
    <Dialog
      open={true}
      maxWidth="md"
      fullWidth
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <DialogTitle>
        <Typography>&#8205;</Typography>
        <IconButton
          aria-label="close"
          disabled={!post}
          href={`mailto:?subject=${encodeURIComponent(
            post?.title || '',
          )}&body=Hey, I´d like to share this link with you %0A${
            process.env.REACT_APP_MOBILE_API
          }/deeplink/buyinnews/${id}`}
          sx={{
            position: 'absolute',
            right: 50,
            top: 8,
            color: (theme) => theme.palette.error.light,
          }}
        >
          <ShareIcon />
        </IconButton>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {post ? (
        <DialogContent>
          <Stack justifyContent="center" alignItems="center">
            <img alt={post.title} height={180} src={post.thumbnail} />
          </Stack>
          <Typography
            variant="h5"
            component="h1"
            fontWeight="bold"
            sx={{ mt: 1 }}
          >
            {post.title}
          </Typography>
          <Typography variant="caption" sx={{ color: 'gray' }}>
            {new Intl.DateTimeFormat('en-GB', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            }).format(new Date(post.date))}
            By {post.author.name}
          </Typography>
          <Box sx={{ cursor: 'pointer' }} onClick={handleLikeClick}>
            <Stack direction="row" alignItems="center" gap={1}>
              {isLiked ? (
                <ThumbUpIcon color="primary" />
              ) : (
                <ThumbUpOffAltIcon color="primary" />
              )}
              {likes}
            </Stack>
          </Box>
          <Typography
            sx={{
              fontStyle: 'italic',
              color: (theme) => theme.palette.grey[500],
              pb: 1,
            }}
          >
            {post.excerpt}
          </Typography>
          <Box
            sx={() => ({
              '& p': {
                marginBlockStart: 0,
                marginBlockEnd: 0,
              },
              '& ul': {
                marginBlockStart: 0,
                marginBlockEnd: 0,
              },
              '& ol': {
                marginBlockStart: 0,
                marginBlockEnd: 0,
              },
              '& h1': {
                marginBlockStart: 0,
                marginBlockEnd: 0,
              },
              '& h2': {
                marginBlockStart: 0,
                marginBlockEnd: 0,
              },
              '& h3': {
                marginBlockStart: 0,
                marginBlockEnd: 0,
              },
              '& h4': {
                marginBlockStart: 0,
                marginBlockEnd: 0,
              },
              '& h5': {
                marginBlockStart: 0,
                marginBlockEnd: 0,
              },
              '& h6': {
                marginBlockStart: 0,
                marginBlockEnd: 0,
              },
            })}
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
          {post.attachments.length > 0 && (
            <Stack direction="row" spacing={1} pb={1}>
              <AttachmentIcon color="primary" />
              <Typography color="primary">Attachments</Typography>
            </Stack>
          )}
          {post.attachments.map((attachment, i) => (
            <Button
              key={i}
              variant="contained"
              color="inherit"
              sx={{ m: 1 }}
              startIcon={<AttachFileIcon />}
              href={attachment.url}
            >
              {attachment.name}
            </Button>
          ))}
          <Box sx={{ cursor: 'pointer', mt: 1 }} onClick={handleLikeClick}>
            <Stack direction="row" alignItems="center" gap={1}>
              {isLiked ? (
                <ThumbUpIcon color="primary" />
              ) : (
                <ThumbUpOffAltIcon color="primary" />
              )}
              {likes}
            </Stack>
          </Box>
          <Stack direction="row" alignItems="center" spacing={3} paddingY={2}>
            <Avatar
              alt={post.author.name}
              src={`${process.env.REACT_APP_API_ENTRYPOINT}/api/contacts/avatars/${post.author.email}`}
              sx={{ width: 87, height: 87 }}
            />
            <Typography>
              {post.author.name}
              <br />
              {post.author.email}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={1} pb={1}>
            <TurnedInIcon color="primary" />
            <Typography color="primary">Article tags</Typography>
          </Stack>
          <Stack direction="row" spacing={1} px={1}>
            {post.groups.map((tag) => (
              <Chip key={tag.id} label={tag.name} />
            ))}
          </Stack>
        </DialogContent>
      ) : (
        <PostSkeleton />
      )}
    </Dialog>
  );
}
